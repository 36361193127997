<ng-container *ngIf="panelData.user as user">
  <div mat-dialog-title class="panel-title">
    <a
      class="flex-row user-name"
      href="users/{{ user.cognitoId }}"
      target="new"
    >
      <span>{{ user.nameFirst }} {{ user.nameLast }}</span>
      <span class="role">
        <mat-icon>account_circle</mat-icon>
        <span>{{ user.role }}</span>
      </span>
      <mat-icon class="link-icon">link</mat-icon>
    </a>
    <div class="mat-h4 grant-info" *ngIf="user.grantId">
      <app-association-display
        [association]="panelData.grantSelection"
      ></app-association-display>
    </div>
  </div>
  <div class="mat-mdc-panel-status">
    <mat-progress-bar
      mode="indeterminate"
      *ngIf="progress$ | async"
    ></mat-progress-bar>
  </div>
  <mat-dialog-content class="panel-content">
    <div class="banner inactive" *ngIf="user.status === 'Inactive'">
      <span
        ><mat-icon>lock</mat-icon
        ><span>This user is currently inactive.</span></span
      >
      <button
        mat-flat-button
        type="button"
        color="accent"
        (click)="reactivateUser(user)"
      >
        <mat-icon>sync</mat-icon><span>Reactivate</span>
      </button>
    </div>
    <div class="banner pending" *ngIf="user.status === 'Pending'">
      <span
        ><mat-icon>pending</mat-icon
        ><span>This user has not yet signed in for the first time.</span></span
      >
    </div>
    <form [formGroup]="userForm" autocomplete="off" (ngSubmit)="updateUser()">
      <fieldset class="flex-column">
        <mat-form-field>
          <mat-label>Email address</mat-label>
          <input
            matInput
            name="email"
            formControlName="email"
            placeholder=""
            aria-required="true"
            title="Email address"
          />
          <mat-error>
            <app-form-errors
              [field]="userForm.controls.email"
            ></app-form-errors>
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>First name</mat-label>
          <input
            matInput
            name="nameFirst"
            formControlName="nameFirst"
            placeholder=""
            aria-required="true"
            title="First name"
          />
          <mat-error>
            <app-form-errors
              [field]="userForm.controls.nameFirst"
            ></app-form-errors>
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Last name</mat-label>
          <input
            matInput
            name="nameLast"
            formControlName="nameLast"
            placeholder=""
            aria-required="true"
            title="Last name"
          />
          <mat-error>
            <app-form-errors
              [field]="userForm.controls.nameLast"
            ></app-form-errors>
          </mat-error>
        </mat-form-field>
      </fieldset>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions>
    <span class="actions-left">
      <button
        type="submit"
        mat-flat-button
        color="primary"
        [disabled]="
          userForm.invalid || (loading$ | async) || (progress$ | async)
        "
      >
        Save
      </button>
      <button mat-stroked-button type="button" (click)="close()">Cancel</button>
    </span>

    <span class="actions-right">
      <button mat-stroked-button color="warn" (click)="resetUserMfa(user)">
        <mat-icon>lock_reset</mat-icon><span>Reset MFA</span>
      </button>
      <button
        mat-flat-button
        type="button"
        color="warn"
        (click)="removeUser(user)"
        [disabled]="(loading$ | async) || (progress$ | async)"
      >
        <mat-icon>delete</mat-icon><span>Remove</span>
      </button>
    </span>
  </mat-dialog-actions>
</ng-container>
