<span class="flex-row wrapper" *ngIf="grant">
  <span class="flex-row provider" *ngIf="isProvider">{{
    association?.provider?.name
  }}</span>
  <span class="flex-row grant" [class.subtitle]="isProvider">
    <span
      class="cohort"
      *ngIf="grant.grantCohort.name !== '' && includeCohort"
      >{{ grant.grantCohort?.name }}</span
    >
    <span>{{ grant.grantee.name }} {{ grant.fundingStream.name }}</span>
  </span>
</span>
<span class="flex-row wrapper" *appSecurableResource="['ViewLandingPagePo']">
  Project Officer
</span>
