import { Component, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-terms-statement',
  templateUrl: './terms-statement.component.html',
  styleUrls: ['./terms-statement.component.scss'],
})
export class TermsStatementComponent {
  constructor(
    protected dialogRef: MatDialogRef<TermsStatementComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public isModal: boolean,
  ) {}
}
