<div class="app-menu" [class.rail]="railMode">
  <div class="app-menu-container">
    <ng-container *ngFor="let list of menu; let i = index">
      <ng-container *appSecurableResource="list.resourceAccess">
        <div class="app-menu-header" *ngIf="list.title.length && !railMode">
          <span>{{ list.title }}</span>
        </div>
        <mat-divider
          *ngIf="list.title.length && (i > 0 || (i === 0 && !railMode))"
        ></mat-divider>
        <div class="app-menu-list">
          <ng-container *ngFor="let item of list.items">
            <a
              matRipple
              matRippleColor="#3333334A"
              class="app-menu-button"
              [class.active]="(activeMenu$ | async) === item.id"
              [attr.aria-label]="item.label"
              [routerLink]="[replaceMetaData(item.route)]"
              *appSecurableResource="item.resourceAccess"
            >
              <mat-icon class="material-icons-outlined">{{
                item.icon
              }}</mat-icon>
              <span
                *ngIf="!railMode"
                class="animate__animated animate__faster animate__fadeIn"
                >{{ item.label }}</span
              >
            </a>
          </ng-container>
        </div>
        <mat-divider
          *ngIf="list.title.length && i < menu.length - 1"
        ></mat-divider>
      </ng-container>
    </ng-container>
  </div>
  <div class="app-menu-footer">
    <a
      href="#security"
      class="security-privacy app-menu-button"
      (click)="openSecurityPrivacy($event)"
    >
      <mat-icon class="material-icons-outlined">privacy_tip</mat-icon>
      <span *ngIf="!railMode">Security & Privacy</span>
    </a>
  </div>
</div>
