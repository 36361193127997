import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { associationToIds } from '@app/shared/utilities';
import {
  NewProviderPayload,
  Provider,
  ProviderSubmission,
  ProviderSubmissionPayload,
  UserAssociationSelection,
} from '@core/models';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';

@Injectable({ providedIn: 'root' })
export class ProviderService extends BaseService {
  private providers = 'providers';

  constructor(private http: HttpClient) {
    super();
  }

  public addProvider(payload: NewProviderPayload): Observable<any> {
    return this.http.post<NewProviderPayload>(
      `${this.baseURL}/${this.providers}`,
      { ...payload }
    );
  }

  public getProvider(providerId: string): Observable<Provider> {
    return this.http.get<Provider>(
      `${this.baseURL}/${this.providers}/${providerId}`
    );
  }

  public getProviders(
    selectedAssociation: UserAssociationSelection
  ): Observable<Provider[]> {
    const payload = associationToIds(selectedAssociation);
    return this.http.get<Provider[]>(`${this.baseURL}/${this.providers}`, {
      params: { ...payload },
    });
  }

  public getProviderSubmission(
    providerId: string
  ): Observable<ProviderSubmission> {
    return this.http.get<ProviderSubmission>(
      `${this.baseURL}/${this.providers}/${providerId}/submission`
    );
  }

  public setProviderActiveStatus(
    providerId: string,
    active: boolean
  ): Observable<any> {
    return this.http.put(
      `${this.baseURL}/${this.providers}/${providerId}/active`,
      { active }
    );
  }

  public updateProviderSubmission(
    providerId: string,
    providerSubmission: ProviderSubmissionPayload
  ): Observable<any> {
    return this.http.put(
      `${this.baseURL}/${this.providers}/${providerId}/submission`,
      { ...providerSubmission }
    );
  }
}
