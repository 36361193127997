import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { NotificationTypes } from '@app/shared/enums';
import {
  BaseDialogComponent,
  ConfirmDialogComponent,
} from '@app/shared/ui/dialogs';
import {
  AppStoreState,
  NotificationActions,
  UserActions,
  UserSelectors,
} from '@app/store';
import {
  AssignableRole,
  ConfirmDialog,
  Grant,
  User,
  UserAssociation,
  UserPayload,
} from '@core/models';
import { ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';

@Component({
  templateUrl: './users-edit-panel.component.html',
  styleUrls: ['./users-edit-panel.component.scss'],
})
export class UsersEditPanelComponent
  extends BaseDialogComponent
  implements OnInit
{
  public userForm: UntypedFormGroup;
  public grants: Grant[];
  public loading$: Observable<boolean>;
  public progress$: Observable<boolean>;
  public selected = -1;
  public stateRolesLoading$: Observable<boolean>;
  public stateRoles$: Observable<AssignableRole[]>;

  private reactivateUserLoading$: Observable<boolean>;
  private removedUser: string;
  private removedFromGrantee: string;
  private removeUserLoading$: Observable<boolean>;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public panelData: {
      grantSelection: UserAssociation;
      user: User;
    },
    protected dialogRef: MatDialogRef<UsersEditPanelComponent>,
    private dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private store$: Store<AppStoreState.State>,
  ) {
    super();
    this.reactivateUserLoading$ = this.store$.select(
      UserSelectors.selectReactivateUserLoading,
    );
    this.removeUserLoading$ = this.store$.select(
      UserSelectors.selectRemoveUserLoading,
    );
    this.actions$
      .pipe(
        ofType(
          UserActions.reactivateUserFailure,
          UserActions.removeUserFailure,
          UserActions.resetUserMFAFailure,
        ),
        takeUntil(this.destroyed$),
        tap(({ message }) => {
          this.store$.dispatch(
            NotificationActions.add({
              notificationType: NotificationTypes.DANGER,
              notificationText: message,
            }),
          );
        }),
      )
      .subscribe();

    this.actions$
      .pipe(
        ofType(UserActions.reactivateUserSuccess),
        takeUntil(this.destroyed$),
        tap(() => {
          this.store$.dispatch(
            NotificationActions.add({
              notificationType: NotificationTypes.SUCCESS,
              notificationText: `User ${this.panelData.user.nameFirst} ${this.panelData.user.nameLast} has been reactivated.`,
            }),
          );
          this.close(true);
        }),
      )
      .subscribe();

    this.actions$
      .pipe(
        ofType(UserActions.removeUserSuccess),
        takeUntil(this.destroyed$),
        tap(() => {
          this.store$.dispatch(
            NotificationActions.add({
              notificationType: NotificationTypes.SUCCESS,
              notificationText: `Removed ${this.removedUser}${
                this.removedFromGrantee
                  ? ` from ${this.removedFromGrantee}`
                  : ''
              }`,
            }),
          );
          this.close(true);
        }),
      )
      .subscribe();

    this.actions$
      .pipe(
        ofType(UserActions.resetUserMFASuccess),
        takeUntil(this.destroyed$),
        tap(() => {
          this.store$.dispatch(
            NotificationActions.add({
              notificationType: NotificationTypes.SUCCESS,
              notificationText: `MFA settings have been successfully reset for ${this.panelData.user.nameFirst} ${this.panelData.user.nameLast}`,
            }),
          );
          this.close(true);
        }),
      )
      .subscribe();
  }

  ngOnInit(): void {
    this.progress$ = combineLatest([
      this.reactivateUserLoading$,
      this.removeUserLoading$,
    ]).pipe(map((progress) => progress.some((l) => l)));

    this.createForm();
    this.hydrateForm(this.panelData.user);
  }

  public close(saved?: boolean): void {
    this.dialogRef.close(saved);
  }

  public reactivateUser(user: User): void {
    const messageSuffix = user.grantee ? ` from ${user.grantee}` : '';
    const data: ConfirmDialog = {
      confirmColor: 'warn',
      message: `Are you sure you would like to reactivate ${user.nameFirst} ${user.nameLast}${messageSuffix}?`,
      title: 'Confirm Reactivation',
    };

    this.dialog
      .open(ConfirmDialogComponent, { data })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          const payload = {
            cognitoId: user.cognitoId,
            grantId: user.grantId,
          };
          this.store$.dispatch(UserActions.reactivateUser({ payload }));
        }
      });
  }
  public removeUser(user: User): void {
    const messageSuffix = user.grantee ? ` from ${user.grantee}` : '';
    const data: ConfirmDialog = {
      confirmColor: 'warn',
      message: `Are you sure you wish to remove ${user.nameFirst} ${user.nameLast}${messageSuffix}?`,
      title: 'Confirm Remove',
    };

    this.dialog
      .open(ConfirmDialogComponent, { data })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          const payload = {
            cognitoId: user.cognitoId,
            grantId: user.grantId,
            providerId: user.providerId,
            roleId: user.roleId,
          };
          this.removedUser = `${user.nameFirst} ${user.nameLast}`;
          this.removedFromGrantee = user.grantee ?? '';
          this.store$.dispatch(NotificationActions.reset());
          this.store$.dispatch(UserActions.removeUser({ payload }));
        }
      });
  }

  public resetUserMfa(user: User): void {
    const data: ConfirmDialog = {
      confirmColor: 'warn',
      message: `This will reset the MFA settings for ${user.nameFirst} ${user.nameLast}.  Are you sure you would like to proceed?`,
      title: 'Confirm MFA Reset',
    };

    this.dialog
      .open(ConfirmDialogComponent, { data })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          const payload = {
            cognitoId: user.cognitoId,
            grantId: user.grantId,
          };
          this.removedUser = `${user.nameFirst} ${user.nameLast}`;
          this.removedFromGrantee = user.grantee ?? '';
          this.store$.dispatch(NotificationActions.reset());
          this.store$.dispatch(UserActions.resetUserMFA({ payload }));
        }
      });
  }

  public updateUser(): void {
    const payload: UserPayload = this.userForm.value;

    this.store$.dispatch(NotificationActions.reset());
    this.store$.dispatch(UserActions.createUser({ payload }));
  }

  private createForm(): void {
    this.userForm = this.fb.group({
      nameFirst: ['', [Validators.required]],
      nameLast: ['', [Validators.required]],
      email: ['', [Validators.required]],
    });

    this.userForm.controls.email.disable();
  }

  private hydrateForm(user: User): void {
    this.userForm.patchValue(user);
  }
}
