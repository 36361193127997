<div class="toast-container">
  <ng-container *ngFor="let toast of toasts; let i = index">
    <div
      class="toast {{
        toast.type
      }} animate__animated animate__fadeIn animate__faster"
      [ngClass]="{
        animate__fadeOut: (closedId$ | async) === toast.id
      }"
    >
      <mat-icon>{{ getIcon(toast.type) }}</mat-icon>
      <ul *ngIf="splitToast(toast.text).length > 1">
        <li *ngFor="let subtoast of splitToast(toast.text)">{{ subtoast }}</li>
      </ul>
      <span *ngIf="splitToast(toast.text).length === 1">{{ toast.text }}</span>
      <button mat-icon-button (click)="close(toast.id)">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </ng-container>
</div>
