import { State as AppState } from '@app/store/app-state';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { menuFeatureKey } from './reducer';
import { State } from './state';

const setActiveAdminMenu = (state: State): string => state.activeAdminMenu;
const setActiveAdminMenuError = (state: State): string =>
  state.activeAdminMenuError;
const setActiveAdminMenuLoading = (state: State): boolean =>
  state.activeAdminMenuLoading;
const setActiveMenu = (state: State): string => state.activeMenu;
const setActiveMenuError = (state: State): string => state.activeMenuError;
const setActiveMenuLoading = (state: State): boolean => state.activeMenuLoading;

export const menuState = createFeatureSelector<AppState, State>(menuFeatureKey);

export const selectActiveAdminMenu = createSelector(
  menuState,
  setActiveAdminMenu
);
export const selectActiveMenu = createSelector(menuState, setActiveMenu);

export const selectActiveMenuError = createSelector(
  menuState,
  setActiveMenuError
);
export const selectActiveMenuLoading = createSelector(
  menuState,
  setActiveMenuLoading
);
