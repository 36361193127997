import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
} from '@angular/core';
import { UserAssociation, UserGrantDetails } from '@core/models';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-association-display',
  templateUrl: './association-display.component.html',
  styleUrls: ['./association-display.component.scss'],
})
export class AssociationDisplayComponent implements OnChanges {
  @Input() association!: UserAssociation;
  @Input() includeCohort = true;

  public isProvider!: boolean;
  public grant: UserGrantDetails;

  constructor() {}

  ngOnChanges() {
    this.isProvider = this.association?.provider !== undefined;
    this.grant = this.isProvider
      ? this.association?.provider?.grant
      : this.association?.grant;
  }
}
