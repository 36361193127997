import { ApiError, StoreFileResponse, GrantUpload, UploadFile, ValidateFileResponse, FileColumns, UploadHistory, UploadStatus } from '@core/models';
import {
  GrantSubmission,
  GrantSubmissionPayload,
} from '@core/models/grant-submission.interface';
import { Grant, GrantListResponse } from '@core/models/grant.interface';
import { createAction, props } from '@ngrx/store';

export enum GrantActionTypes {
  GET_GRANT_REQUEST = '[Grant] Get grant request',
  GET_GRANT_SUCCESS = '[Grant] Get grant success',
  GET_GRANT_FAILURE = '[Grant] Get grant failure',
  GET_GRANTS_REQUEST = '[Grant] Get grants request',
  GET_GRANTS_SUCCESS = '[Grant] Get grants success',
  GET_GRANTS_FAILURE = '[Grant] Get grants failure',
  GET_GRANT_SUBMISSION_REQUEST = '[Grant] Get grant submission request',
  GET_GRANT_SUBMISSION_SUCCESS = '[Grant] Get grant submission success',
  GET_GRANT_SUBMISSION_FAILURE = '[Grant] Get grant submission failure',
  SET_GRANT_SUBMISSION_REQUEST = '[Grant] Set grant submission request',
  SET_GRANT_SUBMISSION_SUCCESS = '[Grant] Set grant submission success',
  SET_GRANT_SUBMISSION_FAILURE = '[Grant] Set grant submission failure',
  GET_GRANT_UPLOAD = '[Grant] Get grant upload type',
  GET_GRANT_UPLOAD_SUCCESS = '[Grant] Get grant upload type success',
  GET_GRANT_UPLOAD_FAILURE = '[Grant] Get grant upload type failure',
  GET_GRANT_UPLOAD_STATUS = '[Grant] Get grant upload status',
  GET_GRANT_UPLOAD_STATUS_SUCCESS = '[Grant] Get grant upload status success',
  GET_GRANT_UPLOAD_STATUS_FAILURE = '[Grant] Get grant upload status failure',
  GET_GRANT_UPLOADS = '[Grant] Get grant uploads',
  GET_GRANT_UPLOADS_SUCCESS = '[Grant] Get grant uploads success',
  GET_GRANT_UPLOADS_FAILURE = '[Grant] Get grant uploads failure',
  VALIDATE_FILE = '[Grant] Validate file',
  VALIDATE_FILE_SUCCESS = '[Grant] Validate file success',
  VALIDATE_FILE_FAILURE = '[Grant] Validate file failure',
  STORE_FILE = '[Grant] Store file',
  STORE_FILE_SUCCESS = '[Grant] Store file success',
  STORE_FILE_FAILURE = '[Grant] Store file failure',
  CANCEL_STORE_FILE_REQUEST = '[Grant] Cancel store file request',
  UPDATE_STORE_FILE_UPLOAD_PROGRESS = '[Grant] Update store file upload progress',
  GET_FILE_COLUMNS_REQUEST = '[Grant] Get file columns request',
  GET_FILE_COLUMNS_SUCCESS = '[Grant] Get file columns success',
  GET_FILE_COLUMNS_FAILURE = '[Grant] Get file columns failure',
  GET_UPLOAD_HISTORIES_REQUEST = '[Grant] Get upload histories request',
  GET_UPLOAD_HISTORIES_SUCCESS = '[Grant] Get upload histories success',
  GET_UPLOAD_HISTORIES_FAILURE = '[Grant] Get upload histories failure',
  COMPLETE_UPLOAD_REQUEST = '[Grant] Complete upload request',
  COMPLETE_UPLOAD_SUCCESS = '[Grant] Complete upload success',
  COMPLETE_UPLOAD_FAILURE = '[Grant] Complete upload failure',
  UPDATE_GRANT_SUBMISSION_STATUS_REQUEST = '[Grant] Update grant submission status request',
  UPDATE_GRANT_SUBMISSION_STATUS_SUCCESS = '[Grant] Update grant submission status success',
  UPDATE_GRANT_SUBMISSION_STATUS_FAILURE = '[Grant] Update grant submission status failure',
}

export const getGrant = createAction(
  GrantActionTypes.GET_GRANT_REQUEST,
  props<{ grantId: string }>()
);

export const getGrantSuccess = createAction(
  GrantActionTypes.GET_GRANT_SUCCESS,
  props<{ grant: Grant }>()
);

export const getGrantFailure = createAction(
  GrantActionTypes.GET_GRANT_FAILURE,
  props<ApiError>()
);

export const getGrants = createAction(GrantActionTypes.GET_GRANTS_REQUEST);

export const getGrantsSuccess = createAction(
  GrantActionTypes.GET_GRANTS_SUCCESS,
  props<{ grants: GrantListResponse }>()
);

export const getGrantsFailure = createAction(
  GrantActionTypes.GET_GRANTS_FAILURE,
  props<ApiError>()
);

export const getGrantSubmission = createAction(
  GrantActionTypes.GET_GRANT_SUBMISSION_REQUEST,
  props<{ grantId: string }>()
);

export const getGrantSubmissionSuccess = createAction(
  GrantActionTypes.GET_GRANT_SUBMISSION_SUCCESS,
  props<{ grantSubmission: GrantSubmission }>()
);

export const getGrantSubmissionFailure = createAction(
  GrantActionTypes.GET_GRANT_SUBMISSION_FAILURE,
  props<ApiError>()
);

export const setGrantSubmissionRequest = createAction(
  GrantActionTypes.SET_GRANT_SUBMISSION_REQUEST,
  props<{ grantId: string; payload: GrantSubmissionPayload }>()
);

export const setGrantSubmissionSuccess = createAction(
  GrantActionTypes.SET_GRANT_SUBMISSION_SUCCESS
);

export const setGrantSubmissionFailure = createAction(
  GrantActionTypes.SET_GRANT_SUBMISSION_FAILURE,
  props<ApiError>()
);

export const getGrantUpload = createAction(
  GrantActionTypes.GET_GRANT_UPLOAD,
  props<{ grantId: string; uploadType: string }>()
);

export const getGrantUploadSuccess = createAction(
  GrantActionTypes.GET_GRANT_UPLOAD_SUCCESS,
  props<{ grantUpload: GrantUpload }>()
);

export const getGrantUploadFailure = createAction(
  GrantActionTypes.GET_GRANT_UPLOAD_FAILURE,
  props<ApiError>()
);

export const getGrantUploadStatus = createAction(
  GrantActionTypes.GET_GRANT_UPLOAD_STATUS,
  props<{ grantId: string }>()
);

export const getGrantUploadStatusSuccess = createAction(
  GrantActionTypes.GET_GRANT_UPLOAD_STATUS_SUCCESS,
  props<{ grantUploadStatus: UploadStatus }>()
);

export const getGrantUploadStatusFailure = createAction(
  GrantActionTypes.GET_GRANT_UPLOAD_STATUS_FAILURE,
  props<ApiError>()
);

export const getGrantUploads = createAction(
  GrantActionTypes.GET_GRANT_UPLOADS,
  props<{ grantId: string }>()
);

export const getGrantUploadsSuccess = createAction(
  GrantActionTypes.GET_GRANT_UPLOADS_SUCCESS,
  props<{ grantUploads: GrantUpload[] }>()
);

export const getGrantUploadsFailure = createAction(
  GrantActionTypes.GET_GRANT_UPLOADS_FAILURE,
  props<ApiError>()
);

export const validateFile = createAction(
  GrantActionTypes.VALIDATE_FILE,
  props<{ grantId: string; uploadLogId: string; uploadFile: UploadFile }>()
);

export const validateFileSuccess = createAction(
  GrantActionTypes.VALIDATE_FILE_SUCCESS,
  props<{ validateFileResponse: ValidateFileResponse }>()
);

export const validateFileFailure = createAction(
  GrantActionTypes.VALIDATE_FILE_FAILURE,
  props<ApiError>()
);

export const storeFile = createAction(
  GrantActionTypes.STORE_FILE,
  props<{ grantId: string; uploadType: string; file: FormData }>()
);

export const storeFileSuccess = createAction(
  GrantActionTypes.STORE_FILE_SUCCESS,
  props<{ storeFileResponse: StoreFileResponse }>()
);

export const storeFileFailure = createAction(
  GrantActionTypes.STORE_FILE_FAILURE,
  props<ApiError>()
);

export const cancelStoreFileRequest = createAction(
  GrantActionTypes.CANCEL_STORE_FILE_REQUEST,
  props<{ message: string }>()
);

export const updateStoreFileUploadProgress = createAction(
  GrantActionTypes.UPDATE_STORE_FILE_UPLOAD_PROGRESS,
  props<{ storeFileUploadProgress: number }>()
);

export const getFileColumns = createAction(
  GrantActionTypes.GET_FILE_COLUMNS_REQUEST,
  props<{ grantId: string; uploadLogId: string; uploadFile: UploadFile }>()
);

export const getFileColumnsSuccess = createAction(
  GrantActionTypes.GET_FILE_COLUMNS_SUCCESS,
  props<{ fileColumns: FileColumns }>()
);

export const getFileColumnsFailure = createAction(
  GrantActionTypes.GET_FILE_COLUMNS_FAILURE,
  props<ApiError>()
);

export const getUploadHistories = createAction(
  GrantActionTypes.GET_UPLOAD_HISTORIES_REQUEST,
  props<{ grantId: string; uploadType: string }>()
);

export const getUploadHistoriesSuccess = createAction(
  GrantActionTypes.GET_UPLOAD_HISTORIES_SUCCESS,
  props<{ uploadHistories: UploadHistory[] }>()
);

export const getUploadHistoriesFailure = createAction(
  GrantActionTypes.GET_UPLOAD_HISTORIES_FAILURE,
  props<ApiError>()
);

export const completeUpload = createAction(
  GrantActionTypes.COMPLETE_UPLOAD_REQUEST,
  props<{ grantId: string; uploadLogId: string; completed: boolean }>()
);

export const completeUploadSuccess = createAction(
  GrantActionTypes.COMPLETE_UPLOAD_SUCCESS,
);

export const completeUploadFailure = createAction(
  GrantActionTypes.COMPLETE_UPLOAD_FAILURE,
  props<ApiError>()
);

export const updateGrantSubmissionStatus = createAction(
  GrantActionTypes.UPDATE_GRANT_SUBMISSION_STATUS_REQUEST,
  props<{ grantId: string; }>()
);

export const updateGrantSubmissionStatusSuccess = createAction(
  GrantActionTypes.UPDATE_GRANT_SUBMISSION_STATUS_SUCCESS,
);

export const updateGrantSubmissionStatusFailure = createAction(
  GrantActionTypes.UPDATE_GRANT_SUBMISSION_STATUS_FAILURE,
  props<ApiError>()
);
