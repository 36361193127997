<div class="action-bar">
  <div action-bar-left>
    <fieldset>
      <mat-form-field
        class="search-box mat-mdc-form-field-100-percent"
        [ngStyle]="{ 'max-width': searchWidth }"
        *ngIf="!hideSearch"
      >
        <mat-icon matSuffix>search</mat-icon>
        <mat-label>Search</mat-label>
        <input
          matInput
          title="search"
          (keyup)="applyFilter($event.target.value)"
          #input
        />
      </mat-form-field>

      <ng-content select="[action-left-content]"></ng-content>
    </fieldset>
  </div>
  <div action-bar-right>
    <ng-content select="[action-right-content]"></ng-content>
  </div>
</div>
<div
  class="flex-column scroll-container"
  [class.include-margin]="fixedPageFooter"
>
  <app-page-loader *ngIf="loading" [component]="true"></app-page-loader>
  <mat-table
    [class.scrollable]="scrollTable"
    recycleRows
    *ngIf="dataSource && options"
    [dataSource]="dataSource"
    matSort
    [matSortActive]="sortActive"
    [matSortDirection]="sortDirection"
  >
    <ng-container matColumnDef="selectRow">
      <mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          *ngIf="multiSelect"
          color="primary"
          (change)="$event ? toggleAllRows() : null"
          [checked]="selection.hasValue() && allRowsSelected()"
          [indeterminate]="selection.hasValue() && !allRowsSelected()"
          [aria-label]="inputAriaLabel()"
          [matTooltip]="inputAriaLabel()"
          [ngClass]="{ 'wrap-header-text': options.wrapHeaderText }"
          [disabled]="!checkboxEnabled"
        >
        </mat-checkbox>
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <mat-checkbox
          *ngIf="multiSelect"
          color="primary"
          (click)="$event.stopPropagation()"
          (change)="$event ? onRowSelected(row) : null"
          [checked]="selection.isSelected(row)"
          [aria-label]="inputAriaLabel(row)"
          [disabled]="!checkboxEnabled"
        >
        </mat-checkbox>

        <mat-radio-button
          *ngIf="singleSelect && !multiSelect"
          color="primary"
          (click)="$event.stopPropagation()"
          (change)="$event ? onRowSelected(row) : null"
          [checked]="selection.isSelected(row)"
          [aria-label]="inputAriaLabel(row)"
        >
        </mat-radio-button>
      </mat-cell>
      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
    </ng-container>

    <ng-container
      *ngFor="let col of tableColumns; let i = index"
      [matColumnDef]="col.column"
    >
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        scope="col"
        [disabled]="disableSort || col.disableSort"
        [ngStyle]="getColumnStyle(col)"
        [ngClass]="{
          'help-toggletip': col.helpToggleTip,
          'wrap-header-text': options.wrapHeaderText
        }"
      >
        {{ col.headerName }}
        <app-help-toggletip
          *ngIf="col.helpToggleTip"
          label="column"
          [content]="col.helpToggleTip"
          position="bottom"
          toggleTop="4.25rem"
        >
        </app-help-toggletip>
      </mat-header-cell>
      <ng-container *appSecurableResource="col.permissions">
        <mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          scope="col"
          [disabled]="col.columnAction"
          [ngStyle]="getColumnStyle(col)"
          [ngClass]="{ 'wrap-header-text': options.wrapHeaderText }"
        >
          {{ col.headerName }}
        </mat-header-cell>
      </ng-container>

      <ng-container *ngIf="!col.columnAction">
        <mat-cell
          *matCellDef="let element; let row"
          [matTooltip]="inputAriaLabel(row)"
          matTooltipPosition="below"
          [ngStyle]="getColumnStyle(col)"
        >
          <ng-container
            *ngIf="
              options.isHtml || col.isHtml;
              then htmlContent;
              else stringContent
            "
          >
          </ng-container>
          <ng-template #htmlContent>
            <ng-container
              *ngIf="col.isCheckbox; then checkboxCell; else htmlCell"
            >
            </ng-container>
          </ng-template>
          <ng-template #checkboxCell>
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="
                $event
                  ? onCheckboxChange(col.column, element, $event.checked)
                  : null
              "
              [checked]="element[col.column]"
              [disabled]="!checkboxEnabled"
            ></mat-checkbox>
          </ng-template>
          <ng-template #htmlCell>
            <div [innerHtml]="getElementValue(element, col)"></div>
          </ng-template>
          <ng-template #stringContent>
            <mat-icon
              *ngIf="getIcon(element, col) as columnIcon"
              class="column-icon"
              [ngClass]="getIconColor(element, col)"
              >{{ columnIcon }}</mat-icon
            >
            <span>{{ getElementValue(element, col) }}</span>
          </ng-template>
        </mat-cell>
      </ng-container>

      <ng-container *ngIf="col.columnAction">
        <ng-container *appSecurableResource="col.permissions">
          <mat-cell
            *matCellDef="let element; let row"
            class="mdc-action-cell"
            [ngStyle]="getColumnStyle(col)"
            [class.disabled-cell]="
              (isRowDisabled && isRowDisabled(row)) ||
              isCellDisabled(col.columnAction, row)
            "
          >
            <button mat-button (click)="col.columnAction.action(element)">
              <mat-icon *ngIf="col.columnAction.icon">{{
                col.columnAction.icon
              }}</mat-icon
              ><span>{{
                col.columnAction.label || getElementValue(element, col)
              }}</span>
            </button>
          </mat-cell>
        </ng-container>
      </ng-container>

      <ng-container>
        <mat-footer-cell *matFooterCellDef>
          <span>{{ options.tableFooter[i] || '' }}</span>
        </mat-footer-cell>
      </ng-container>
    </ng-container>
    <ng-container matColumnDef="fillerColumn">
      <mat-header-cell *matHeaderCellDef class="filler-cell"></mat-header-cell>
      <mat-cell *matCellDef class="filler-cell"></mat-cell>
      <mat-footer-cell *matFooterCellDef class="filler-cell"></mat-footer-cell>
    </ng-container>

    <mat-header-row
      *matHeaderRowDef="displayedColumns"
      class="sticky-header"
    ></mat-header-row>
    <mat-row
      *matRowDef="let row; columns: displayedColumns"
      matRipple
      [matRippleDisabled]="
        !selectableRows || (isRowSelectable && !isRowSelectable(row))
      "
      (click)="onRowSelected(row)"
      [ngClass]="getSelectableRowClass(row)"
    ></mat-row>
    <ng-container *ngIf="options.tableFooter">
      <mat-footer-row *matFooterRowDef="displayedColumns"></mat-footer-row>
    </ng-container>
  </mat-table>
  <div class="table-bottom" *ngIf="!fixedPageFooter">
    <ng-content select="[footer-left-content]"></ng-content>
    <mat-paginator
      [pageSize]="10"
      [pageSizeOptions]="pageSizeOptions"
      *ngIf="!hidePaginator"
    >
    </mat-paginator>
  </div>
</div>
<app-fixed-content-footer [compatible]="true" *ngIf="fixedPageFooter">
  <span left><ng-content select="[footer-left-content]"></ng-content></span>
  <mat-paginator
    [pageSize]="10"
    [pageSizeOptions]="pageSizeOptions"
    *ngIf="!hidePaginator"
  >
  </mat-paginator>
</app-fixed-content-footer>
