<div id="submission-progress-heading" class="mat-h3">Submission Progress</div>
<div class="flex-column progress-container">
  <span>{{ progress }}%</span>
  <mat-progress-spinner
    class="progress"
    color="primary"
    mode="determinate"
    aria-labelledby="submission-progress-heading"
    [attr.aria-valuetext]="progress + ' percent'"
    [value]="progress"
  >
  </mat-progress-spinner>
</div>
