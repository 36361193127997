import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { UsersAddPanelComponent, UsersEditPanelComponent } from './panels';
import { UsersComponent } from './users.component';
import { UserManagementRoutingModule } from './users.routing';

const COMPONENTS = [
  UsersComponent,
  UsersAddPanelComponent,
  UsersEditPanelComponent,
];

const MODULES = [CommonModule, SharedModule, UserManagementRoutingModule];

@NgModule({
  declarations: COMPONENTS,
  imports: [...MODULES],
  exports: [COMPONENTS],
  providers: [],
})
export class UsersModule {}
