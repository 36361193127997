import { Injectable } from '@angular/core';
import { handleError } from '@core/helpers';
import { ResourceAccessResponse } from '@core/models';
import { ResourceAccessService } from '@core/services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  getResourceAccess,
  getResourceAccessFailure,
  getResourceAccessSuccess,
} from './actions';

@Injectable()
export class ResourceAccessEffects {
  getResources$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getResourceAccess),
      switchMap(({ payload }) =>
        this.resourceAccessService.getResources(payload).pipe(
          map((resourceAccess: ResourceAccessResponse) =>
            getResourceAccessSuccess({ resourceAccess })
          ),
          catchError((error) => handleError(error, getResourceAccessFailure))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private resourceAccessService: ResourceAccessService
  ) {}
}
