<app-gov-site-link></app-gov-site-link>
<app-security-link></app-security-link>
<div class="flex-column auth-container">
  <div
    class="sign-in mat-elevation-z2 animate__animated animate__faster"
    [class.animate__fadeIn]="securityNoticeShown"
  >
    <div class="flex-row-reverse flex-wrap content-container">
      <div class="flex-column content-signin-container">
        <div class="flex-column content-logo">
          <img
            src="assets/prep-logo.jpg"
            alt="PREP logo. Personal Responsibility Education Program"
          />
          <h2>Performance Measures Portal</h2>
        </div>
        <div class="signin-status">
          <mat-progress-bar
            mode="indeterminate"
            *ngIf="signInLoading$ | async"
          ></mat-progress-bar>
        </div>
        <form
          *ngIf="securityNoticeShown"
          class="content-signin"
          #form
          [formGroup]="signInForm"
          (ngSubmit)="signIn()"
          (submit)="(false)"
        >
          <fieldset class="flex-column">
            <mat-form-field>
              <mat-label>Username</mat-label>
              <input
                matInput
                name="username"
                formControlName="username"
                type="text"
                title="Username"
                aria-required="true"
              />
              <mat-error>
                <app-form-errors
                  [field]="signInForm.controls.username"
                ></app-form-errors>
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Password</mat-label>
              <input
                matInput
                name="password"
                formControlName="password"
                title="Password"
                [type]="showPasswordPlainText ? 'text' : 'password'"
                aria-required="true"
              />
              <mat-error>
                <app-form-errors
                  [field]="signInForm.controls.password"
                ></app-form-errors>
              </mat-error>
              <button
                type="button"
                mat-icon-button
                matSuffix
                (click)="showPasswordPlainText = !showPasswordPlainText"
                aria-label="Hide password"
                [attr.aria-pressed]="!showPasswordPlainText"
              >
                <mat-icon>{{
                  showPasswordPlainText ? 'visibility' : 'visibility_off'
                }}</mat-icon>
              </button>
            </mat-form-field>
          </fieldset>
          <div class="form-footer">
            <button
              type="submit"
              mat-flat-button
              color="primary"
              [disabled]="signInLoading$ | async"
            >
              Sign In
            </button>

            <a [routerLink]="['/auth/reset-password']">Forgot your password?</a>
          </div>
        </form>
        <mat-divider></mat-divider>
        <div class="flex-column flex-center content-tech-assistance">
          <div>
            <h3>Technical Assistance</h3>
            <p>
              For questions about your access to this data portal, please
              contact us at
              <a href="mailto:PREPPerformanceMeasures@mathematica-mpr.com">
                PREPPerformanceMeasures&#64;mathematica-mpr.com
              </a>
              or call toll-free <a href="tel:+18552676270">1-855-267-6270</a>.
            </p>
          </div>
          <div class="app-version">Version: {{ appVersion }}</div>
        </div>
      </div>
      <div class="flex-column content-description">
        <div class="flex-column header-top">
          <div class="wrapper"></div>
          <h1>Performance Measures Portal</h1>
        </div>
        <div class="flex-row flex-wrap">
          <div class="content-about">
            <h3>About the PREP Performance Measures</h3>
            <p>
              The federally-funded Personal Responsibility Education Program
              (PREP) supports efforts to educate youth on both abstinence and
              contraception for the prevention of pregnancy and sexually
              transmitted infections through evidence-based and
              evidence-informed programs, elements of effective programs, and
              innovative strategies. Programs also educate youth on adulthood
              preparation subjects. To understand whether grant objectives are
              being met and to contribute to lessons learned, ACF collects data
              on performance measures from all PREP grantees.
            </p>
          </div>
        </div>
        <div class="content-resources">
          <h3>Resources &amp; Training</h3>
          <p>
            <a href="https://prepeval.acf.hhs.gov" target="new"
              >The PREP Performance Measures resources website</a
            >
            provides PREP grantees access to tools and training resources for
            the collection and submission of PREP performance measures. This
            site also disseminates findings from the study on adulthood
            preparation subjects.
          </p>
        </div>
        <div class="flex-row flex-wrap img-group">
          <a href="https://www.acf.hhs.gov/opre"
            ><img alt="OPRE" src="assets/opre-logo.png"
          /></a>
          <a href="https://www.acf.hhs.gov/fysb"
            ><img alt="FYSB" src="assets/fysb.png"
          /></a>
          <a href="https://mathematica.org"
            ><img alt="Mathematica" src="assets/mathematica-pt-logo-blue.png"
          /></a>
        </div>
      </div>
    </div>
  </div>
</div>
<app-auth-footer></app-auth-footer>
