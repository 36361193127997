import { ApiError, AssignableRole, User } from '@core/models';

export interface State {
  assignableRoles: AssignableRole[];
  assignableRolesError: ApiError;
  assignableRolesLoading: boolean;
  createUserError: ApiError;
  createUserLoading: boolean;
  reactivateUserError: ApiError;
  reactivateUserLoading: boolean;
  removeUserError: ApiError;
  removeUserLoading: boolean;
  resetUserMFAError: ApiError;
  resetUserMFALoading: boolean;
  updateUserError: ApiError;
  updateUserLoading: boolean;
  user: User;
  userError: ApiError;
  userLoading: boolean;
  users: User[];
  usersError: ApiError;
  usersLoading: boolean;
}

export const initialState: State = {
  assignableRoles: [],
  assignableRolesError: null,
  assignableRolesLoading: false,
  createUserError: null,
  createUserLoading: false,
  reactivateUserError: null,
  reactivateUserLoading: false,
  removeUserError: null,
  removeUserLoading: false,
  resetUserMFAError: null,
  resetUserMFALoading: false,
  updateUserError: null,
  updateUserLoading: false,
  user: null,
  userError: null,
  userLoading: false,
  users: null,
  usersError: null,
  usersLoading: false,
};
