import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CommentListResponse,
  ProviderSubmissionStatusProgress,
  GrantSubmissionStatusProgress,
  GrantSubmissionStatusSummaryListResponse,
  GrantSubmissionStatusDetailsListResponse,
} from '@core/models';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class StatusReportService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }

  public addComment(grantId: string, comment: string): Observable<null> {
    return this.http.put<null>(`${this.getServiceUrl(grantId)}/comments/`, {
      comment,
    });
  }

  public deleteComment(grantId: string, commentId: string): Observable<null> {
    return this.http.delete<null>(
      `${this.getServiceUrl(grantId)}/comments/${commentId}`,
    );
  }

  public getComments(grantId: string): Observable<CommentListResponse> {
    return this.http.get<CommentListResponse>(
      `${this.getServiceUrl(grantId)}/comments`,
    );
  }

  public updateComment(
    grantId: string,
    comment: string,
    commentId?: string,
  ): Observable<null> {
    return this.http.put<null>(
      `${this.getServiceUrl(grantId)}/comments/${commentId}`,
      { comment },
    );
  }

  public getGrantProgress(
    grantId: string,
  ): Observable<GrantSubmissionStatusProgress> {
    return this.http.get<GrantSubmissionStatusProgress>(
      `${this.getServiceUrl(grantId)}/grant-progress`,
    );
  }

  public getGrantsDetails(
    myGrants?: boolean,
  ): Observable<GrantSubmissionStatusDetailsListResponse> {
    const params = new HttpParams({
      fromObject: myGrants ? { myGrants } : undefined,
    });

    return this.http.get<GrantSubmissionStatusDetailsListResponse>(
      `${this.baseURL}/submission-status/grant-progress/export`,
      {
        params,
      },
    );
  }

  public getGrantsSummary(
    myGrants?: boolean,
  ): Observable<GrantSubmissionStatusSummaryListResponse> {
    const params = new HttpParams({
      fromObject: myGrants ? { myGrants } : undefined,
    });

    return this.http.get<GrantSubmissionStatusSummaryListResponse>(
      `${this.baseURL}/submission-status/grant-progress`,
      {
        params,
      },
    );
  }

  private getServiceUrl(grantId: string) {
    return `${this.baseURL}/grants/${grantId}/submission-status`;
  }

  public getProviderSubmissionStatusProgress(
    providerId: string,
  ): Observable<ProviderSubmissionStatusProgress> {
    return this.http.get<ProviderSubmissionStatusProgress>(
      `${this.baseURL}/providers/${providerId}/submission-status/provider-progress`,
    );
  }
}
