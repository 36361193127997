import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { NotificationTypes, PanelWidth, ToggleNames } from '@app/shared/enums';
import { DataTableOptions } from '@app/shared/ui';
import {
  AccountSelectors,
  NotificationActions,
  ResourceAccessSelectors,
  UserActions,
  UserAppActions,
  UserAppSelectors,
  UserSelectors,
} from '@app/store';
import {
  BreadCrumb,
  TableColumn,
  User,
  UserAssociation,
  UserAssociationSelection,
} from '@core/models';
import { PanelService } from '@core/services';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, Subject, combineLatest } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { UsersAddPanelComponent, UsersEditPanelComponent } from './panels';
import { exportAsCsv } from '@app/shared/utilities';

@Component({
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit, OnDestroy {
  public breadcrumbList: BreadCrumb[];
  public dataSource: MatTableDataSource<User>;
  public dataTableOptions: DataTableOptions = null;
  public includeInactiveUsers = false;
  public pageLoading = true;
  public usersLoading$: Observable<boolean> = this.store$.select(
    UserSelectors.selectUsersLoading,
  );

  private associationDetails: UserAssociation;
  private associationDetails$: Observable<UserAssociation>;
  private cognitoId = '';
  private destroyed$ = new Subject<boolean>();
  private displayedColumns: string[];
  private includeInactiveUsers$: Observable<boolean>;
  private selectedAssociation$: Observable<UserAssociationSelection>;
  private selectedAssociation: UserAssociationSelection;
  private tableColumns: TableColumn[] = [];
  private users: User[];

  private users$: Observable<User[]> = this.store$
    .select(UserSelectors.selectUsers)
    .pipe(filter((users) => !!users));

  constructor(
    private actions$: Actions,
    private panelService: PanelService,
    private renderer: Renderer2,
    private store$: Store,
  ) {
    this.selectedAssociation$ = this.store$.select(
      AccountSelectors.selectSelectedAssociation,
    );
    this.associationDetails$ = this.store$.select(
      AccountSelectors.selectAssociationDetails,
    );
    this.actions$
      .pipe(
        ofType(UserActions.getUsersFailure),
        takeUntil(this.destroyed$),
        tap(({ message }) => {
          this.store$.dispatch(
            NotificationActions.add({
              notificationType: NotificationTypes.DANGER,
              notificationText: message,
            }),
          );
        }),
      )
      .subscribe();

    this.actions$
      .pipe(
        ofType(UserActions.removeUserSuccess),
        takeUntil(this.destroyed$),
        tap(() => { }),
      )
      .subscribe();

    this.includeInactiveUsers$ = this.store$.select(
      UserAppSelectors.selectToggleState(ToggleNames.UsersIncludeInactives),
    );
  }

  ngOnInit(): void {
    this.associationDetails$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((details) => (this.associationDetails = details));

    combineLatest([
      this.selectedAssociation$,
      this.store$.select(ResourceAccessSelectors.selectResourceAccess),
    ])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([association, resourceAccess]) => {
        this.selectedAssociation = association;

        this.breadcrumbList = [{ label: 'Users' }];

        const grantTableColumns = resourceAccess.ViewAllUsersInSystem
          ? [
            { column: 'grantCohort', headerName: 'Cohort', width: '6rem' },
            {
              column: 'fundingStream',
              headerName: 'Grantee Type',
              minWidth: '8rem',
              maxWidth: '10rem',
              isHtml: true,
            },
            {
              column: 'grantee',
              headerName: 'Grantee Name',
              minWidth: '10rem',
              maxWidth: '20rem',
              isHtml: true,
            },
          ]
          : [];

        this.tableColumns = [
          ...this.tableColumns,
          ...grantTableColumns,
          { column: 'nameFirst', headerName: 'First Name', minWidth: '8rem' },
          { column: 'nameLast', headerName: 'Last Name', minWidth: '8rem' },
          {
            column: 'email',
            headerName: 'Email',
            isHtml: true,
            minWidth: '12rem',
          },
          { column: 'role', headerName: 'Role', width: '9.25rem' },
          { column: 'status', headerName: 'Status', width: '6rem' },
        ];
        this.displayedColumns = this.tableColumns.map(
          (tableColumn) => tableColumn.column,
        );

        this.dataSource = new MatTableDataSource<User>();
        this.dataSource.filterPredicate = this.getFilterPredicate;
        this.dataSource.sortingDataAccessor = this.getSortingDataAccessor;

        this.dataTableOptions = {
          displayedColumns: this.displayedColumns,
          isHtml: true,
          selectableRowLabel: 'Open this user',
          tableColumns: this.tableColumns,
          sortActive: 'nameLast',
        };

        this.fetchData();
      });

    this.users$.pipe(takeUntil(this.destroyed$)).subscribe((users) => {
      this.users = users.filter((user) => user.cognitoId !== this.cognitoId);
      this.dataSource.data = this.getDataSourceData();
      this.pageLoading = false;
    });

    this.includeInactiveUsers$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((toggleState: boolean) => {
        this.includeInactiveUsers = toggleState;
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public addUser(): void {
    this.panelService
      .open<boolean, UserAssociationSelection>(
        UsersAddPanelComponent,
        {
          ...this.selectedAssociation,
        },
        {
          maxWidth: PanelWidth.Medium,
        },
      )
      .afterClosed()
      .subscribe((saved) => {
        if (saved) {
          this.fetchData();
        }
      });
  }

  public downloadList(): void {
    exportAsCsv(this.getDataSourceData(), 'users', this.renderer);
  }

  public openUserPanel(user: User) {
    this.panelService
      .open<boolean, { grantSelection: UserAssociation; user: User }>(
        UsersEditPanelComponent,
        {
          grantSelection: this.associationDetails,
          user,
        },
        {
          maxWidth: PanelWidth.Medium,
        },
      )
      .afterClosed()
      .subscribe((saved) => {
        if (saved) {
          this.fetchData();
        }
      });
  }

  public refreshList(): void {
    this.fetchData();
  }

  public showInactiveUsers(checked: boolean): void {
    this.includeInactiveUsers = checked;

    // refresh data source data
    this.dataSource.data = this.getDataSourceData();
    this.store$.dispatch(
      UserAppActions.updateToggleSetting({
        [ToggleNames.UsersIncludeInactives]: checked,
      }),
    );
  }

  private fetchData() {
    const selectedAssociation = this.selectedAssociation;
    this.store$.dispatch(UserActions.getUsers({ selectedAssociation }));
  }

  private getDataSourceData(): User[] {
    return this.users.filter((user) =>
      this.includeInactiveUsers
        ? true
        : !user.status || user.status?.toLowerCase().indexOf('inactive') === -1,
    );
  }

  private getFilterPredicate(data: User, dsFilter: string): boolean {
    const filterableProperties = [
      'grantee',
      'fundingStream',
      'grantCohort',
      'email',
      'nameFirst',
      'nameLast',
      'role',
      'status',
    ];
    return filterableProperties.some(
      (property) =>
        (data[property] || '')
          .toString()
          .toLowerCase()
          .indexOf(dsFilter.toLowerCase()) > -1,
    );
  }

  private getSortingDataAccessor(
    data: User,
    sortHeaderId: string,
  ): string | number {
    if (typeof data[sortHeaderId] === 'string') {
      return data[sortHeaderId].toLocaleLowerCase();
    }

    return data[sortHeaderId];
  }
}
