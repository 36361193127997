import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { environment } from 'environments/environment';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-auth-card',
  templateUrl: './auth-card.component.html',
  styleUrls: ['../styles/auth.scss', './auth-card.component.scss'],
})
export class AuthCardComponent implements OnInit {
  @Input() size: 'small' | 'large' | 'full' = 'small';
  @Input() loading = false;
  @Input() progress = false;

  constructor() {}

  ngOnInit(): void {}

  get env() {
    return environment;
  }
}
