import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { AuthenticationEffects } from './effects';
import { authenticationFeatureKey, reducer } from './reducer';

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature([AuthenticationEffects]),
    StoreModule.forFeature(authenticationFeatureKey, reducer)
  ]
})
export class AuthenticationModule {}
