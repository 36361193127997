import { HttpErrorResponse } from '@angular/common/http';
import { errorStrings } from '@core/strings';
import { ActionCreator } from '@ngrx/store';
import { Observable, of } from 'rxjs';

export const formatError = (error: HttpErrorResponse): string =>
  error.error ? error.error.message || error.error.Message : error.message;

export const handleError = (
  error: HttpErrorResponse,
  action: ActionCreator
): Observable<any> => {
  let errorMessage: string;

  switch (error.status) {
    case 401:
      errorMessage =
        formatError(error) ??
        errorStrings
          .find((e) => e.key === 'invalid_credentials')
          .value.toString();
      break;

    default:
      errorMessage =
        formatError(error) ??
        errorStrings
          .find((e) => e.key === 'server_connect_failure')
          .value.toString();
      break;
  }

  return of(action({ error, message: errorMessage }));
};
