import { Action, createReducer } from '@ngrx/store';
import {} from './actions';
import { initialState, State } from './state';

export const settingsFeatureKey = 'settings';

const settingsReducer = createReducer(initialState);

export const reducer = (state: State | undefined, action: Action) =>
  settingsReducer(state, action);
