import {
  Component,
  ContentChild,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import { BreadCrumb } from '@core/models';
import { ContentContainerTitleComponent } from './content-container-subs';

@Component({
  selector: 'app-content-container',
  templateUrl: './content-container.component.html',
  styleUrls: ['./content-container.component.scss'],
})
export class ContentContainerComponent {
  @Input() loading = false;
  @Input() progress = false;
  @Input() breadcrumbs: BreadCrumb[];

  @ViewChild('contentContainerHeader') public containerHeader: ElementRef;

  @ContentChild(ContentContainerTitleComponent) contentContainerTitle: ElementRef;

  constructor() { }
}
