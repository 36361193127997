import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { BreadCrumb } from '@core/models';

@Component({
  selector: 'app-content-breadcrumbs',
  templateUrl: './content-breadcrumbs.component.html',
  styleUrls: ['./content-breadcrumbs.component.scss'],
})
export class ContentBreadcrumbsComponent {
  @Input() crumbs: BreadCrumb[];

  constructor(private router: Router) {}

  public getCrumbLink(index: number): string[] {
    let linkList: string[] = [''];
    this.crumbs.forEach((crumb, i) => {
      const crumbLinkList = crumb.linkList ?? [crumb.link];
      if (i <= index) {
        linkList = [...linkList, ...crumbLinkList];
      }
    });

    return linkList;
  }

  public goBack(): void {
    const link = this.getCrumbLink(this.crumbs.length - 2);
    this.router.navigate(link);
  }
}
