import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { MyGrantsEffects } from './effects';
import { myGrantsReducer } from './reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('my-grants', myGrantsReducer),
    EffectsModule.forFeature([MyGrantsEffects]),
  ],
})
export class MyGrantsModule {}
