import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Resource } from '@app/shared/enums';
import { AccountSelectors, ResourceAccessSelectors } from '@app/store';
import { Store } from '@ngrx/store';
import { combineLatest, filter, map, take } from 'rxjs';

/**
 * Guard to allow Project Officer access, otherwise redirects to /.
 */
export const ProjectOfficerStatusReportRoleGuard: CanActivateFn =
  (/*route, state*/) => {
    const router = inject(Router);
    const store$ = inject(Store);

    return combineLatest([
      store$.select(ResourceAccessSelectors.selectResourceAccess),
      store$.select(AccountSelectors.selectAssociationDetails),
    ]).pipe(
      filter(([permissions]) => !!permissions),
      take(1),
      map(([permissions, details]) => {
        if (!permissions[Resource.ViewSubmissionReportAllGrants]) {
          const grantId = details?.grantId ?? details?.provider?.grantId;
          return router.parseUrl(`/status/${grantId}`);
        }

        return true;
      }),
    );
  };
