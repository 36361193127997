import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { ViewportMode } from '@app/shared/enums';
import { Account, NavMenu } from '@core/models';
import { Subject } from 'rxjs';

@Component({
  // TODO: Re-enable this with a proper check to show the grants list
  //changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-nav',
  templateUrl: 'nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class AppNavComponent implements OnChanges, OnDestroy, OnInit {
  @Input() mode = ViewportMode.Default;
  @Input() rightMenu!: NavMenu[];
  @Input() account: Account;
  @Input() version = '';

  @Output() appMenuButtonClick: EventEmitter<any> = new EventEmitter();

  public avatarInitials = '';
  public avatarURLStyle: SafeStyle;

  private destroyed$ = new Subject<boolean>();
  constructor(private sanitizer: DomSanitizer) { }

  public get isDesktop(): boolean {
    return this.mode >= ViewportMode.Desktop;
  }

  public get isTablet(): boolean {
    return this.mode === ViewportMode.Tablet;
  }

  ngOnChanges(): void {
    this.avatarInitials = !this.account
      ? ''
      : `${this.account?.nameFirst?.charAt(0)}${this.account?.nameLast?.charAt(
        0
      )}`;
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  ngOnInit(): void {
    this.avatarURLStyle = this.sanitizer.bypassSecurityTrustStyle(
      'url(' + this.account?.avatar + ')'
    );
  }

  public appMenuButtonClicked(e): void {
    this.appMenuButtonClick.emit(e);
  }

  public goToMainElement(event): void {
    event.preventDefault();
    const main = document.getElementById('content');
    main.setAttribute('tabindex', '-1');
    main.focus();
  }
}
