import { Injectable } from '@angular/core';
import { handleError } from '@core/helpers';
import { NotificationService } from '@core/services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  add,
  addFailure,
  addSuccess,
  getNotifications,
  getNotificationsFailure,
  getNotificationsSuccess,
  remove,
  removeFailure,
  removeSuccess,
  reset,
  resetFailure,
  resetSuccess,
} from './actions';

@Injectable()
export class NotificationEffects {
  add$ = createEffect(() =>
    this.actions$.pipe(
      ofType(add),
      switchMap(({ notificationType, notificationText }) =>
        this.notificationService.add(notificationType, notificationText).pipe(
          map((notifications) => addSuccess({ notifications })),
          catchError((error) => handleError(error, addFailure))
        )
      )
    )
  );

  remove$ = createEffect(() =>
    this.actions$.pipe(
      ofType(remove),
      switchMap(({ id }) =>
        this.notificationService.remove(id).pipe(
          map((notifications) => removeSuccess({ notifications })),
          catchError((error) => handleError(error, removeFailure))
        )
      )
    )
  );

  reset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(reset),
      switchMap(() =>
        this.notificationService.reset().pipe(
          map((notifications) => resetSuccess({ notifications })),
          catchError((error) => handleError(error, resetFailure))
        )
      )
    )
  );

  getNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getNotifications),
      switchMap(() =>
        this.notificationService.getAll().pipe(
          map((notifications) => getNotificationsSuccess({ notifications })),
          catchError((error) => handleError(error, getNotificationsFailure))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private notificationService: NotificationService
  ) {}
}
