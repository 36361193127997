import { createReducer, Action, on } from '@ngrx/store';
import { initialState, State } from './state';
import {
  updateGrantFavorites,
  updateGrantFavoritesSuccess,
  updateGrantFavoritesFailure,
  getGrantFavorites,
  getGrantFavoritesSuccess,
  getGrantFavoritesFailure,
} from './actions';

export const myGrantsFeatureKey = 'my-grants';

export const myGrantsReducer = createReducer(
  initialState,
  on(updateGrantFavorites, (state) => ({
    ...state,
    updateGrantFavoritesLoading: true
  })),
  on(updateGrantFavoritesSuccess, (state) => ({
    ...state,
    updateGrantFavoritesLoading: false,
  })),
  on(updateGrantFavoritesFailure, (state, { error, message }) => ({
    ...state,
    updateGrantFavoritesLoading: false,
    updateGrantFavoritesFailure: { error, message }
  })),
  on(getGrantFavorites, (state) => ({
    ...state,
    getGrantFavoritesLoading: true
  })),
  on(getGrantFavoritesSuccess, (state, { grantFavoriteIds }) => ({
    ...state,
    getGrantFavoritesLoading: false,
    grantFavoriteIds
  })),
  on(getGrantFavoritesFailure, (state, { error, message }) => ({
    ...state,
    getGrantFavoritesLoading: false,
    getGrantFavoritesFailure: { error, message }
  })),
);

export const reducer = (state: State | undefined, action: Action) =>
  myGrantsReducer(state, action);
