import { State as AppState } from '@app/store/app-state';
import { ApiError, AssignableRole, User } from '@core/models';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { userFeatureKey } from './reducer';
import { State } from './state';

const createUserError = (state: State): ApiError => state.createUserError;
const createUserLoading = (state: State): boolean => state.createUserLoading;

const getAssignableRoles = (state: State): AssignableRole[] =>
  state.assignableRoles;
const getAssignableRolesError = (state: State): ApiError =>
  state.assignableRolesError;
const getAssignableRolesLoading = (state: State): boolean =>
  state.assignableRolesLoading;
const getUser = (state: State): User => state.user;
const getUserError = (state: State): ApiError => state.userError;
const getUserLoading = (state: State): boolean => state.userLoading;
const getUsers = (state: State): User[] => state.users;
const getUsersError = (state: State): ApiError => state.usersError;
const getUsersLoading = (state: State): boolean => state.usersLoading;
const reactivateUserError = (state: State): ApiError =>
  state.reactivateUserError;
const reactivateUserLoading = (state: State): boolean =>
  state.reactivateUserLoading;
const removeUserError = (state: State): ApiError => state.removeUserError;
const removeUserLoading = (state: State): boolean => state.removeUserLoading;
const resetUserMFAError = (state: State): ApiError => state.resetUserMFAError;
const resetUserMFALoading = (state: State): boolean =>
  state.resetUserMFALoading;
const updateUserError = (state: State): ApiError => state.updateUserError;
const updateUserLoading = (state: State): boolean => state.updateUserLoading;

export const userState = createFeatureSelector<AppState, State>(userFeatureKey);

export const selectCreateUserError = createSelector(userState, createUserError);
export const selectCreateUserLoading = createSelector(
  userState,
  createUserLoading,
);

export const selectAssignableRoles = createSelector(
  userState,
  getAssignableRoles,
);

export const selectAssignableRolesError = createSelector(
  userState,
  getAssignableRolesError,
);
export const selectAssignableRolesLoading = createSelector(
  userState,
  getAssignableRolesLoading,
);
export const selectReactivateUserError = createSelector(
  userState,
  reactivateUserError,
);
export const selectReactivateUserLoading = createSelector(
  userState,
  reactivateUserLoading,
);
export const selectRemoveUserError = createSelector(userState, removeUserError);
export const selectRemoveUserLoading = createSelector(
  userState,
  removeUserLoading,
);
export const selectResetUserMFAError = createSelector(
  userState,
  resetUserMFAError,
);
export const selectResetUserMFALoading = createSelector(
  userState,
  resetUserMFALoading,
);

export const selectUpdateUserError = createSelector(userState, updateUserError);
export const selectUpdateUserLoading = createSelector(
  userState,
  updateUserLoading,
);
export const selectUser = createSelector(userState, getUser);
export const selectUserError = createSelector(userState, getUserError);
export const selectUserLoading = createSelector(userState, getUserLoading);
export const selectUsers = createSelector(userState, getUsers);
export const selectUsersError = createSelector(userState, getUsersError);
export const selectUsersLoading = createSelector(userState, getUsersLoading);
