<app-page-loader *ngIf="loading$ | async" text="Loading..."></app-page-loader>
<mat-sidenav-container autosize [hasBackdrop]="true">
  <mat-sidenav
    *ngIf="isMobile"
    #sideNav
    role="navigation"
    mode="over"
    fixedInViewport="true"
    class="app-sidenav no-print"
    [autoFocus]="false"
    (click)="closeSideNav()"
  >
    <span class="app-title">PREP</span>
    <app-app-menu
      [menu]="appMenu"
      [appTitle]="appTitle"
      [metadata]="{ grantId, providerId }"
    ></app-app-menu>
  </mat-sidenav>
  <mat-sidenav-content class="app-sidenav-content">
    <app-nav
      [mode]="viewportMode"
      [rightMenu]="rightNavMenu"
      [account]="account$ | async"
      [version]="appVersion"
      (appMenuButtonClick)="handleAppMenuClick()"
    >
      <app-association-display
        [association]="selectedAssociationDetails$ | async"
      ></app-association-display>
      <span
        toolbar-right
        class="flex-row submission-window"
        *ngIf="activeSubmissionPeriod$ | async as submissionPeriod"
      >
        <a
          mat-button
          [matMenuTriggerFor]="submissionPeriodInfo"
          matTooltip="More information about the current submission period"
        >
          <span class="flex-row submission-window-label">
            <span>{{ submissionPeriod.name }} Submission Period</span>
            <mat-icon class="drop-down-arrow">arrow_drop_down</mat-icon>
          </span>
        </a>
        <mat-menu
          #submissionPeriodInfo="matMenu"
          backdropClass="nav-menu-backdrop"
          class="mat-mdc-menu-large submission-box-container"
        >
          <ng-container
            [ngTemplateOutlet]="submissionStatus"
            [ngTemplateOutletContext]="{ submissionPeriod }"
          ></ng-container>
        </mat-menu>
      </span>
    </app-nav>
    <mat-drawer-container [hasBackdrop]="false" autosize>
      <mat-drawer
        role="navigation"
        [disableClose]="true"
        [mode]="isDesktop ? 'side' : 'over'"
        [autoFocus]="false"
        [opened]="
          (isDesktop || isTablet) && (activeMenu$ | async) !== 'settings'
        "
        [class.activated]="!isDesktop && appMenuOpened"
        [class.rail]="!isDesktop && !appMenuOpened"
        (mouseenter)="openAppMenu()"
        (mouseleave)="closeAppMenu()"
        (click)="setAppMenuUnlocked()"
        (touchend)="setAppMenuTouchEvent()"
      >
        <app-app-menu
          [menu]="appMenu"
          [appTitle]="appTitle"
          [railMode]="!isDesktop && !appMenuOpened"
          [metadata]="{ grantId, providerId }"
        >
        </app-app-menu>
      </mat-drawer>
      <mat-drawer-content
        [class.opened]="isTablet"
        (click)="closeAppMenu(true)"
      >
        <main id="content" *ngIf="(loading$ | async) === false" cdkScrollable>
          <router-outlet></router-outlet>
        </main>
      </mat-drawer-content>
    </mat-drawer-container>

    <div class="app-footer"></div>
  </mat-sidenav-content>
</mat-sidenav-container>

<ng-template #submissionStatus let-submissionPeriod="submissionPeriod">
  <ng-container *ngIf="submissionPeriod.submissionClosed">
    <span
      class="flex-row submission-box info"
      *ngIf="
        daysBefore(submissionPeriod.submissionStartDate) >= 0 &&
          daysBefore(submissionPeriod.submissionStartDate) <= 30;
        else defaultClosedMessage
      "
    >
      <mat-icon class="material-icons-outlined">info</mat-icon>
      <span>
        The data submission period for
        {{ submissionPeriod.name }} will be opening on
        {{
          submissionPeriod.submissionStartDate.split('T')[0]
            | date: 'MMMM dd, YYYY'
        }}
        and closing on
        {{
          submissionPeriod.submissionStopDate.split('T')[0]
            | date: 'MMMM dd, YYYY'
        }}.
      </span>
    </span>
    <ng-template #defaultClosedMessage>
      <span class="flex-row submission-box closed">
        <mat-icon class="material-icons-outlined">report</mat-icon>
        <span> The data submission window is currently closed. </span>
      </span>
    </ng-template>
  </ng-container>
  <ng-container *ngIf="!submissionPeriod.submissionClosed">
    <span
      class="flex-row submission-box open"
      *ngIf="daysBefore(submissionPeriod.submissionStopDate) > 10"
    >
      <mat-icon class="material-icons-outlined">info</mat-icon>
      <span>
        The data submission period for
        {{ submissionPeriod.name }} is currently open and will close on
        {{
          submissionPeriod.submissionStopDate.split('T')[0]
            | date: 'MMMM dd, YYYY'
        }}.
      </span>
    </span>
    <span
      class="flex-row submission-box open warning"
      *ngIf="daysBefore(submissionPeriod.submissionStopDate) <= 10"
    >
      <mat-icon class="material-icons-outlined">warning</mat-icon>
      <span>
        The data submission period for
        {{ submissionPeriod.name }} will be closing soon on
        {{
          submissionPeriod.submissionStopDate.split('T')[0]
            | date: 'MMMM dd, YYYY'
        }}.
      </span>
    </span>
  </ng-container>
</ng-template>
