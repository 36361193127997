export enum ViewportMode {
  Mobile = 0,
  Tablet = 2,
  Desktop = 4,
  Default = Mobile,
}

export enum ViewportHeight {
  Normal = 0,
  Short = 1,
  Default = Normal,
}
