import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-my-grants-filter',
  templateUrl: './my-grants-filter.component.html',
  styleUrls: ['./my-grants-filter.component.scss'],
})
export class MyGrantsFilterComponent implements OnInit {
  @Input() disabled = false;
  @Input() toggleState = false;

  @Output() toggle = new EventEmitter();

  public tooltipMessage =
    'My Grants is a feature that allows you to view only the most important grants for you to track.';

  constructor() {}

  ngOnInit(): void {}

  public toggleChange(): void {
    this.toggle.emit(this.toggleState);
  }
}
