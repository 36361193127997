import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { Dashboard, DashboardUrl } from '@core/models';

@Injectable({
  providedIn: 'root',
})
export class DashboardService extends BaseService {
  private dashboard = 'dashboard';

  constructor(private http: HttpClient) {
    super();
  }

  public getDashboardList(grantId: string = ''): Observable<Dashboard[]> {
    return this.http.get<Dashboard[]>(`${this.baseURL}/${this.dashboard}`, {
      params: { grantId },
    });
  }

  public getUrl(
    dashboardId: string,
    grantId: string = '',
  ): Observable<DashboardUrl> {
    return this.http.get<DashboardUrl>(
      `${this.baseURL}/${this.dashboard}/${dashboardId}`,
      { params: { grantId } },
    );
  }
}
