import { NgModule } from '@angular/core';
import { CamelCaseToSpacesPipe } from './camel-case-to-spaces.pipe';
import { CapitalizePipe } from './capitalize.pipe';

const PIPES = [
  CamelCaseToSpacesPipe,
  CapitalizePipe,
];

@NgModule({
  declarations: PIPES,
  exports: PIPES,
  providers: PIPES,
})
export class PipesModule { }
