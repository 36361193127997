import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Resource } from '@app/shared/enums';
import { ResourceAccessPayload } from '@core/models';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class ResourceAccessService extends BaseService {
  private permissionsPath = `${this.baseURL}/account/role-permissions`;

  constructor(private http: HttpClient) {
    super();
  }

  public getResources(payload: ResourceAccessPayload): Observable<Resource[]> {
    return this.http.get<Resource[]>(this.permissionsPath, {
      params: { ...payload },
    });
  }
}
