import {
  Account,
  ApiError,
  StoreError,
  UserAssociation,
  UserAssociations,
  UserAssociationSelection,
} from '@core/models';
import { CognitoUserData } from '@core/services';

export interface State {
  account: Account;
  accountError: ApiError;
  accountLoading: boolean;
  associationDetails: UserAssociation;
  associationDetailsError: ApiError;
  associationDetailsLoading: boolean;
  associations: UserAssociations;
  associationsError: ApiError;
  associationsLoading: boolean;
  changePasswordError: ApiError;
  changePasswordLoading: boolean;
  mfaTypeError: string;
  mfaTypeLoading: boolean;
  selectedAssociation: UserAssociationSelection;
  selectedAssociationError: ApiError;
  selectedAssociationLoading: boolean;
  updateAccountError: ApiError;
  updateAccountLoading: boolean;
  userData: CognitoUserData;
  userDataError: StoreError;
  userDataLoading: boolean;
  userDetails: Account;
  userDetailsError: StoreError;
  userDetailsLoading: boolean;
}

export const initialState: State = {
  account: null,
  accountError: null,
  accountLoading: false,
  associationDetails: null,
  associationDetailsError: null,
  associationDetailsLoading: false,
  associations: null,
  associationsError: null,
  associationsLoading: false,
  changePasswordError: null,
  changePasswordLoading: false,
  mfaTypeError: '',
  mfaTypeLoading: false,
  selectedAssociation: null,
  selectedAssociationError: null,
  selectedAssociationLoading: false,
  updateAccountError: null,
  updateAccountLoading: false,
  userData: null,
  userDataError: null,
  userDataLoading: false,
  userDetails: null,
  userDetailsError: null,
  userDetailsLoading: false,
};
