import { Action, createReducer, on } from '@ngrx/store';
import {
  addProvider,
  addProviderFailure,
  addProviderSuccess,
  clearProviderSubmission,
  getProvider,
  getProviderFailure,
  getProviders,
  getProvidersFailure,
  getProvidersSuccess,
  getProviderSubmission,
  getProviderSubmissionFailure,
  getProviderSubmissionSuccess,
  getProviderSuccess,
  setProviderActiveStatus,
  setProviderActiveStatusFailure,
  setProviderActiveStatusSuccess,
  updateProviderSubmission,
  updateProviderSubmissionFailure,
  updateProviderSubmissionSuccess,
} from './actions';
import { initialState, State } from './state';

export const providerFeatureKey = 'provider';

const providerReducer = createReducer(
  initialState,
  on(addProvider, (state) => ({
    ...state,
    addProviderLoading: true,
  })),
  on(addProviderFailure, (state, { error, message }) => ({
    ...state,
    addProviderError: { error, message },
    addProviderLoading: false,
  })),
  on(addProviderSuccess, (state) => ({
    ...state,
    addProviderError: null,
    addProviderLoading: false,
  })),

  on(clearProviderSubmission, (state) => ({
    ...state,
    providerSubmission: null,
  })),

  on(getProvider, (state) => ({
    ...state,
    providerLoading: true,
  })),
  on(getProviderFailure, (state, { error, message }) => ({
    ...state,
    provider: null,
    providerError: { error, message },
    providerLoading: false,
  })),
  on(getProviderSuccess, (state, { provider }) => ({
    ...state,
    provider,
    providerError: null,
    providerLoading: false,
  })),

  on(getProviders, (state) => ({
    ...state,
    providersLoading: true,
  })),
  on(getProvidersFailure, (state, { error, message }) => ({
    ...state,
    providers: null,
    providersError: { error, message },
    providersLoading: false,
  })),
  on(getProvidersSuccess, (state, { providers }) => ({
    ...state,
    providers,
    providersLoading: false,
  })),

  on(getProviderSubmission, (state) => ({
    ...state,
    providerSubmissionLoading: true,
  })),
  on(getProviderSubmissionFailure, (state, { error, message }) => ({
    ...state,
    providerSubmission: null,
    providerSubmissionLoading: false,
    providerSubmissionError: { error, message },
  })),
  on(getProviderSubmissionSuccess, (state, { providerSubmission }) => ({
    ...state,
    providerSubmissionLoading: false,
    providerSubmission,
  })),

  on(setProviderActiveStatus, (state) => ({
    ...state,
    setProviderActiveStatusLoading: true,
  })),
  on(setProviderActiveStatusFailure, (state, { error, message }) => ({
    ...state,
    setProviderActiveStatusError: { error, message },
    setProviderActiveStatusLoading: false,
  })),
  on(setProviderActiveStatusSuccess, (state) => ({
    ...state,
    setProviderActiveStatusLoading: false,
  })),

  on(updateProviderSubmission, (state) => ({
    ...state,
    updateProviderSubmissionLoading: true,
  })),
  on(updateProviderSubmissionFailure, (state, { error, message }) => ({
    ...state,
    updateProviderSubmission: null,
    updateProviderSubmissionError: { error, message },
    updateProviderSubmissionLoading: false,
  })),
  on(updateProviderSubmissionSuccess, (state) => ({
    ...state,
    updateProviderSubmissionError: null,
    updateProviderSubmissionLoading: false,
  }))
);

export const reducer = (state: State | undefined, action: Action) =>
  providerReducer(state, action);
