<ng-container *ngIf="associations$ | async as associations">
  <h1 mat-dialog-title>Select a {{ associationLabel }}</h1>
  <mat-dialog-content>
    <p>
      Your account has access to all {{ associationLabelPlural }} listed below.
      Please select a {{ associationLabel.toLowerCase() }} to enter the portal:
    </p>
    <app-associations-list
      class="flex-column association-list"
      [associations]="associations"
      (associationSelected)="setAssociation($event)"
    >
    </app-associations-list>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-stroked-button (click)="close()">Cancel</button>
  </mat-dialog-actions>
</ng-container>
