<button
  mat-icon-button
  type="button"
  class="button-toggletip"
  matTooltip="More information about {{ label.toLowerCase() }}"
  attr.aria-label="More information about {{ label.toLowerCase() }}"
  (click)="onClick($event)"
  (keydown)="$event.stopPropagation()"
  (blur)="toggled = false"
>
  <mat-icon class="material-icons-outlined">help_outline</mat-icon>
</button>
<span role="toggletip">
  <span
    *ngIf="toggled"
    class="toggletip {{ position }}"
    [style.top]="toggleTop"
    [style.left]="toggleLeft"
    [style.width]="toggleTipWidth"
    ><span class="arrow-left">&nbsp;</span
    ><span class="toggletip-text">{{ content }}</span></span
  >
</span>
