<mat-list>
  <mat-list-item>
    <span class="icon-password-match">
      <mat-icon class="no-entry" [class.hidden]="passwordEntered()"
        >circle</mat-icon
      >
      <mat-icon class="match" [class.hidden]="!passwordMinLength()">
        check_circle
      </mat-icon>
      <mat-icon
        class="no-match"
        [class.hidden]="!passwordEntered() || passwordMinLength()"
        matTooltipPosition="right"
      >
        remove_circle_outline
      </mat-icon>
    </span>
    <span class="label">Contains at least 8 characters</span>
  </mat-list-item>
  <mat-list-item>
    <span class="icon-password-match">
      <mat-icon class="no-entry" [class.hidden]="passwordEntered()"
        >circle</mat-icon
      >
      <mat-icon class="match" [class.hidden]="!passwordHasDigit()">
        check_circle
      </mat-icon>
      <mat-icon
        class="no-match"
        [class.hidden]="!passwordEntered() || passwordHasDigit()"
        matTooltipPosition="right"
      >
        remove_circle_outline
      </mat-icon>
    </span>
    <span class="label">Contains at least one number</span>
  </mat-list-item>
  <mat-list-item>
    <span class="icon-password-match">
      <mat-icon class="no-entry" [class.hidden]="passwordEntered()"
        >circle</mat-icon
      >
      <mat-icon class="match" [class.hidden]="!passwordHasCapital()">
        check_circle
      </mat-icon>
      <mat-icon
        class="no-match"
        [class.hidden]="!passwordEntered() || passwordHasCapital()"
        matTooltipPosition="right"
      >
        remove_circle_outline
      </mat-icon>
    </span>
    <span class="label">Contains at least one capital letter</span>
  </mat-list-item>
  <mat-list-item>
    <span class="icon-password-match">
      <mat-icon class="no-entry" [class.hidden]="passwordEntered()"
        >circle</mat-icon
      >
      <mat-icon class="match" [class.hidden]="!passwordHasSpecialCharacter()">
        check_circle
      </mat-icon>
      <mat-icon
        class="no-match"
        [class.hidden]="!passwordEntered() || passwordHasSpecialCharacter()"
        matTooltipPosition="right"
      >
        remove_circle_outline
      </mat-icon>
    </span>
    <span class="label">Contains at least one "special" character</span>
  </mat-list-item>
</mat-list>
