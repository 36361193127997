import { createAction, props } from '@ngrx/store';
import { State } from './state';

export enum UserAppActionTypes {
  CLEAR_SECURITY_NOTICE_SHOWN_REQUEST = '[UserApp] Clear Security Notice Shown Request',
  CLEAR_SECURITY_NOTICE_SHOWN_SUCCESS = '[UserApp] Clear Security Notice Shown Success',
  CLEAR_USER_APP_STATE_REQUEST = '[UserApp] Clear User App State Request',
  CLEAR_USER_APP_STATE_SUCCESS = '[UserApp] Clear User App State Success',
  GET_USER_APP_STATE_REQUEST = '[UserApp] Get User App State Request',
  GET_USER_APP_STATE_SUCCESS = '[UserApp] Get User App State Success',
  SET_SECURITY_NOTICE_SHOWN_REQUEST = '[UserApp] Set Security Notice Shown Request',
  SET_SECURITY_NOTICE_SHOWN_SUCCESS = '[UserApp] Set Security Notice Shown Success',
  UPDATE_TOGGLE_SETTING_REQUEST = '[UserApp] Update Toggle Setting Request',
  UPDATE_TOGGLE_SETTING_SUCCESS = '[UserApp] Update Toggle Setting Success',
}

export const clearSecurityNoticeShown = createAction(
  UserAppActionTypes.CLEAR_SECURITY_NOTICE_SHOWN_REQUEST
);

export const clearSecurityNoticeShownSuccess = createAction(
  UserAppActionTypes.CLEAR_SECURITY_NOTICE_SHOWN_SUCCESS
);

export const clearToggleSetting = createAction(
  UserAppActionTypes.CLEAR_USER_APP_STATE_REQUEST
);

export const clearToggleSettingSuccess = createAction(
  UserAppActionTypes.CLEAR_USER_APP_STATE_SUCCESS
);

export const getUserAppState = createAction(
  UserAppActionTypes.GET_USER_APP_STATE_REQUEST
);

export const getUserAppStateSuccess = createAction(
  UserAppActionTypes.GET_USER_APP_STATE_SUCCESS,
  props<{ userApp: State }>()
);

export const setSecurityNoticeShown = createAction(
  UserAppActionTypes.SET_SECURITY_NOTICE_SHOWN_REQUEST
);

export const setSecurityNoticeShownSuccess = createAction(
  UserAppActionTypes.SET_SECURITY_NOTICE_SHOWN_SUCCESS
);

export const updateToggleSetting = createAction(
  UserAppActionTypes.UPDATE_TOGGLE_SETTING_REQUEST,
  props<{ [key: string]: boolean }>()
);

export const updateToggleSettingSuccess = createAction(
  UserAppActionTypes.UPDATE_TOGGLE_SETTING_SUCCESS
);
