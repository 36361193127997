<div class="flex-row">
  <label [id]="labelId" class="label-radio-group flex-column">
    <ng-content></ng-content>
  </label>
  <div class="flex-column">
    <mat-radio-group
      class="radio-group"
      [attr.aria-labelledby]="labelId"
      [formControl]="control"
      (change)="onChange($event)"
    >
      <mat-radio-button class="radio-button" [value]="true">
        Yes
      </mat-radio-button>
      <mat-radio-button class="radio-button" [value]="false">
        No
      </mat-radio-button>
    </mat-radio-group>
  </div>
</div>
