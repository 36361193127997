import { Component, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-privacy-statement',
  templateUrl: './privacy-statement.component.html',
  styleUrls: ['./privacy-statement.component.scss'],
})
export class PrivacyStatementComponent {
  constructor(
    protected dialogRef: MatDialogRef<PrivacyStatementComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public isModal: boolean,
  ) {}
}
