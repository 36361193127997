import { State as AppState } from '@app/store/app-state';
import { ApiError, Provider, ProviderSubmission } from '@core/models';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { providerFeatureKey } from './reducer';
import { State } from './state';

const addProviderError = (state: State): ApiError => state.addProviderError;
const addProviderLoading = (state: State): boolean => state.addProviderLoading;
const getProvider = (state: State): Provider => state.provider;
const getProviderError = (state: State): ApiError => state.providerError;
const getProviderLoading = (state: State): boolean => state.providerLoading;
const getProviders = (state: State): Provider[] => state.providers;
const getProvidersError = (state: State): ApiError => state.providersError;
const getProvidersLoading = (state: State): boolean => state.providersLoading;

const getProviderSubmission = (state: State): ProviderSubmission =>
  state.providerSubmission;
const getProviderSubmissionError = (state: State): ApiError =>
  state.providerSubmissionError;
const getProviderSubmissionLoading = (state: State): boolean =>
  state.providerSubmissionLoading;

const setProviderActiveStatusError = (state: State): ApiError =>
  state.setProviderActiveStatusError;
const setProviderActiveStatusLoading = (state: State): boolean =>
  state.setProviderActiveStatusLoading;

const updateProviderSubmissionError = (state: State): ApiError =>
  state.updateProviderSubmissionError;
const updateProviderSubmissionLoading = (state: State): boolean =>
  state.updateProviderSubmissionLoading;

export const providerState = createFeatureSelector<AppState, State>(
  providerFeatureKey
);

export const selectAddProviderError = createSelector(
  providerState,
  addProviderError
);
export const selectAddProviderLoading = createSelector(
  providerState,
  addProviderLoading
);

export const selectProvider = createSelector(providerState, getProvider);

export const selectProviderError = createSelector(
  providerState,
  getProviderError
);
export const selectProviderLoading = createSelector(
  providerState,
  getProviderLoading
);

export const selectProviders = createSelector(providerState, getProviders);

export const selectProvidersError = createSelector(
  providerState,
  getProvidersError
);
export const selectProvidersLoading = createSelector(
  providerState,
  getProvidersLoading
);

export const selectProviderActiveStatusError = createSelector(
  providerState,
  setProviderActiveStatusError
);
export const selectProviderActiveStatusLoading = createSelector(
  providerState,
  setProviderActiveStatusLoading
);

export const selectProviderSubmission = createSelector(
  providerState,
  getProviderSubmission
);
export const selectProviderSubmissionError = createSelector(
  providerState,
  getProviderSubmissionError
);
export const selectProviderSubmissionLoading = createSelector(
  providerState,
  getProviderSubmissionLoading
);

export const selectUpdateProviderSubmissionError = createSelector(
  providerState,
  updateProviderSubmissionError
);

export const selectUpdateProviderSubmissionLoading = createSelector(
  providerState,
  updateProviderSubmissionLoading
);
