import {
  ApiError,
  Comment,
  GrantSubmissionStatusDetails,
  GrantSubmissionStatusSummary,
  ProviderSubmissionStatusProgress,
} from '@core/models';
import { GrantSubmissionStatusProgress } from '@core/models';

export interface State {
  addCommentLoading: boolean;
  addCommentFailure: ApiError;
  comments: Comment[];
  commentsError: ApiError;
  commentsLoading: boolean;
  updateCommentLoading: boolean;
  updateCommentFailure: ApiError;
  deleteCommentLoading: boolean;
  deleteCommentFailure: ApiError;
  grantProgress: GrantSubmissionStatusProgress;
  getGrantProgressLoading: boolean;
  getGrantProgressFailure: ApiError;
  grantsDetails: GrantSubmissionStatusDetails[];
  grantsDetailsLoading: boolean;
  grantsDetailsFailure: ApiError;
  grantsSummary: GrantSubmissionStatusSummary[];
  getGrantsSummaryLoading: boolean;
  getGrantsSummaryFailure: ApiError;
  providerProgressReport: ProviderSubmissionStatusProgress;
  getProviderProgressLoading: boolean;
  getProviderProgressFailure: ApiError;
}

export const initialState: State = {
  addCommentLoading: false,
  addCommentFailure: null,
  comments: [],
  commentsError: null,
  commentsLoading: false,
  updateCommentLoading: false,
  updateCommentFailure: null,
  deleteCommentLoading: false,
  deleteCommentFailure: null,
  grantProgress: null,
  getGrantProgressLoading: false,
  getGrantProgressFailure: null,
  grantsDetails: [],
  grantsDetailsLoading: false,
  grantsDetailsFailure: null,
  grantsSummary: [],
  getGrantsSummaryLoading: false,
  getGrantsSummaryFailure: null,
  providerProgressReport: null,
  getProviderProgressLoading: false,
  getProviderProgressFailure: null,
};
