import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppStoreState, AuthenticationActions } from '@app/store';
import { AuthService, DialogService } from '@core/services';
import { Dialog, DialogButton, DialogType } from '@core/ui/dialog';
import { Store } from '@ngrx/store';
import { NotAvailableDialogComponent } from '@shared/ui/dialogs';
import { environment as env } from 'environments/environment';
import { StatusCodes } from 'http-status-codes';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private auth: AuthService,
    private dialog: DialogService,
    private store: Store<AppStoreState.State>
  ) {}

  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error) => {
        if (this.auth.isSignedIn()) {
          this.errorFunction(error.status);
        }
        return throwError(error);
      })
    );
  }

  private errorFunction(status: number): void {
    // auto logout if 401 response returned from api
    if (status === StatusCodes.UNAUTHORIZED) {
      this.signOut();
    }

    if (status === 0 || status >= StatusCodes.INTERNAL_SERVER_ERROR) {
      this.openErrorDialog(status);
    }
  }

  private openErrorDialog(status: number): void {
    sessionStorage.clear();

    const prompt = new Dialog({
      dialogButton: DialogButton.ok,
      dialogIcon: DialogType.Error,
      dialogTemplate: NotAvailableDialogComponent,
      dialogTitle: 'Site Error',
      dialogType: DialogType.Error,
      status,
    });

    this.dialog
      .open<NotAvailableDialogComponent>(prompt)
      .pipe(take(1))
      .subscribe(() => {
        this.signOut();
      });
  }

  private signOut(): void {
    this.store.dispatch(AuthenticationActions.signedOutFromInterceptor());
    this.router.navigate([env.appRoutes.signOut]);
  }
}
