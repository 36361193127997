import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  templateUrl: './inactive-user-dialog.component.html',
  styleUrls: ['./inactive-user-dialog.component.scss'],
})
export class InactiveUserDialogComponent implements OnInit {
  constructor(private dialog: MatDialogRef<InactiveUserDialogComponent>) {}

  ngOnInit(): void {}

  public close(): void {
    this.dialog.close();
  }
}
