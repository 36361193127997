import { FocusMonitor } from '@angular/cdk/a11y';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Comment, ConfirmDialog } from '@core/models';
import { Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { ConfirmDialogComponent } from '../dialogs';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss'],
})
export class CommentComponent implements OnChanges, OnDestroy, OnInit {
  @Input() allowReplies = false;
  @Input() comment: Comment;
  @Input() readOnly = false;
  @Input() saving = false;

  @Output() delete = new EventEmitter<string>();
  @Output() edit = new EventEmitter<boolean>();
  @Output() save = new EventEmitter<Partial<Comment>>();

  @ViewChild('textAreaComment') textAreaCommentEl: ElementRef<HTMLElement>;

  public commentForm: UntypedFormGroup;
  public currentComment: Comment;
  public isAddingReply = false;
  public isEditing = false;
  public isSaving = false;

  private destroyed$ = new Subject<boolean>();

  constructor(
    private dialog: MatDialog,
    private focusMonitor: FocusMonitor,
    private formBuilder: UntypedFormBuilder
  ) {}

  public get authorName(): string {
    const { nameFirst, nameLast } = this.comment.author;

    return `${nameFirst} ${nameLast}`;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.saving) {
      if (!changes.saving.currentValue) {
        // Resetting add form to ensure clean input state for persistent form.
        setTimeout(() => {
          this.commentForm?.enable();
          this.commentForm?.reset();
          this.commentForm?.markAsPristine();
          this.isSaving = false;
          this.isAddingReply = false;
        }, 1000);
      }
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  ngOnInit(): void {
    this.commentForm = this.formBuilder.group({
      id: [null],
      comment: [''],
    });

    this.commentForm.valueChanges
      .pipe(
        takeUntil(this.destroyed$),
        tap(() => this.edit.emit(this.commentForm.dirty))
      )
      .subscribe();

    if (this.comment?.id) {
      this.commentForm.get('comment').setValidators(Validators.required);
      this.commentForm.updateValueAndValidity();
    }
  }

  public deleteComment(comment: Comment): void {
    const data: ConfirmDialog = {
      confirmColor: 'warn',
      message: 'Are you sure you want to delete this comment?',
      title: 'Confirm Delete',
    };

    this.dialog
      .open(ConfirmDialogComponent, { data })
      .afterClosed()
      .subscribe((result: boolean) => {
        if (result) {
          this.currentComment = comment;
          this.isSaving = true;
          this.delete.emit(comment.id);
        }
      });
  }

  public disableEditComment(): void {
    if (!this.commentForm.dirty) {
      this.cancelEditComment();
      return;
    }

    const confirmDialogConfig: ConfirmDialog = {
      confirmColor: 'warn',
      message: 'You have unsaved changes.  Are you sure you want to cancel?',
      title: '',
    };

    this.dialog
      .open(ConfirmDialogComponent, {
        data: confirmDialogConfig,
      })
      .afterClosed()
      .pipe(filter((result) => result))
      .subscribe(() => this.cancelEditComment());
  }

  public enableAddReply(): void {
    this.cancelEditComment();
    this.isAddingReply = true;
    this.focusInput();
  }

  public enableEditComment(currentComment: Comment): void {
    this.isAddingReply = false;
    this.currentComment = currentComment;
    this.commentForm.patchValue(currentComment);
    this.isEditing = true;
    this.focusInput();
  }

  public submitComment(): void {
    this.isSaving = true;
    this.commentForm.disable();

    const payload = this.isAddingReply
      ? {
          ...this.commentForm.value,
          parentCommentId: this.comment.id,
        }
      : this.commentForm.value;

    this.save.emit(payload);
  }

  private cancelEditComment(): void {
    this.isEditing = false;
    this.commentForm.reset();
    this.edit.emit(false);
  }

  private focusInput(): void {
    setTimeout(() => {
      this.focusMonitor.focusVia(this.textAreaCommentEl, 'program');
    }, 0);
  }
}
