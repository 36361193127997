import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import {
  Component,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatMenuPanel } from '@angular/material/menu';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import { appMenuConfig } from '@app/app-menu.config';
import {
  AccountActions,
  AccountSelectors,
  AppStoreState,
  MenuSelectors,
  ResourceAccessSelectors,
  SubmissionPeriodActions,
  SubmissionPeriodSelectors,
} from '@app/store';
import { Account, AppMenuList, NavMenu, UserAssociation } from '@core/models';
import {
  AppService,
  DialogService,
  IdleTimerService,
  PanelService,
} from '@core/services';
import { Dialog, DialogButton, DialogType } from '@core/ui/dialog';
import { Store } from '@ngrx/store';
import {
  PanelWidth,
  Resource,
  Themes,
  ViewportHeight,
  ViewportMediaQuery,
  ViewportMode,
} from '@shared/enums';
import {
  IdleTimeoutDialogComponent,
  SelectAssociationDialogComponent,
} from '@shared/ui/dialogs';
import { environment as env } from 'environments/environment';
import { combineLatest, Observable, Subject } from 'rxjs';
import { delay, filter, map, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit, OnDestroy {
  @ViewChild('sideNav') sideNav: MatSidenav;
  @ViewChild('userMenu') userMenu: MatMenuPanel;

  public account$: Observable<Account>;
  public activeSubmissionPeriod$ = this.store$.select(
    SubmissionPeriodSelectors.selectActiveSubmissionPeriod,
  );
  public accountAvatar = '';
  public activeMenu$: Observable<string>;
  public appMenu: AppMenuList[];
  public appMenuLocked = false;
  public appMenuOpened = false;
  public appMenuTouchEvent = false;
  public appTitle = '';
  public appVersion = '';
  public grantId = '';
  public loading$: Observable<boolean>;
  public providerId = '';
  public rightNavMenu: NavMenu[] = [];
  public selectedAssociationDetails$: Observable<UserAssociation>;
  public showAssociationsList = false;
  public viewportMode = ViewportMode.Default;

  private destroyed$ = new Subject<boolean>();
  private siteTheme = Themes.Light;
  private viewportHeight = ViewportHeight.Default;
  private viewportIsDesktop = true;
  private viewportIsTablet = true;

  constructor(
    private app: AppService,
    private breakpointObserver: BreakpointObserver,
    private dialogService: DialogService,
    private idleTimeout: IdleTimerService,
    private panelService: PanelService,
    private renderer: Renderer2,
    private router: Router,
    private store$: Store<AppStoreState.State>,
  ) {
    this.account$ = this.store$.select(AccountSelectors.selectAccount).pipe(
      filter((account) => !!account),
      takeUntil(this.destroyed$),
    );

    this.activeMenu$ = this.store$.select(MenuSelectors.selectActiveMenu);

    this.store$
      .select(AccountSelectors.selectAssociations)
      .pipe(
        filter((associations) => !!associations),
        takeUntil(this.destroyed$),
        tap(
          (associations) =>
            (this.showAssociationsList =
              associations.grantAssociations.length +
                associations.providerAssociations.length >
              1),
        ),
      )
      .subscribe();

    this.selectedAssociationDetails$ = this.store$.select(
      AccountSelectors.selectAssociationDetails,
    );

    this.idleTimeout
      .startTimer(env.timeoutLength * 60 - 60)
      .pipe(
        tap(() => this.timeOutPrompt()),
        takeUntil(this.destroyed$),
      )
      .subscribe();

    this.router.events
      .pipe(
        delay(100),
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.destroyed$),
        tap(() => {
          this.idleTimeout.resetTimer();
          this.closeAppMenu(true);
        }),
      )
      .subscribe();

    this.loading$ = combineLatest([
      this.store$.select(AccountSelectors.selectUserDetailsLoading),
      this.store$.select(AccountSelectors.selectAssociationDetailsLoading),
      this.store$.select(ResourceAccessSelectors.selectResourceAccessLoading),
    ]).pipe(
      map((loading) => loading.some((l) => l)),
      takeUntil(this.destroyed$),
    );

    this.setSiteTheme(this.siteTheme);
  }

  public get isDesktop(): boolean {
    return this.viewportMode >= ViewportMode.Desktop;
  }

  public get isMobile(): boolean {
    return this.viewportMode === ViewportMode.Mobile;
  }

  public get isShortScreen(): boolean {
    return this.viewportHeight === ViewportHeight.Short;
  }

  public get isTablet(): boolean {
    return this.viewportMode === ViewportMode.Tablet;
  }

  ngOnInit(): void {
    this.handleBreakpoints();
    this.rightNavMenu = this.buildRightNavMenu();

    this.appMenu = appMenuConfig;
    this.appTitle = this.app.appTitle;
    this.appVersion = env.version;

    this.selectedAssociationDetails$
      .pipe(
        filter((details) => !!details),
        tap((details) => {
          this.grantId = details.grantId ?? details.provider.grantId;
          this.providerId = details.providerId;
        }),
        takeUntil(this.destroyed$),
      )
      .subscribe();

    this.store$.dispatch(AccountActions.getAccount());
    this.store$.dispatch(AccountActions.getAssociations());
    this.store$.dispatch(SubmissionPeriodActions.getActiveSubmissionPeriod());
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this.renderer.removeClass(document.body, this.siteTheme);
  }

  public closeAppMenu(forced: boolean = false): void {
    if (!this.appMenuLocked || (forced && this.appMenuLocked)) {
      this.appMenuOpened = false;
      this.appMenuLocked = false;
    }
  }

  public closeSideNav(): void {
    this.sideNav.close();
  }

  public daysBefore(date: string): number {
    return (Date.parse(date) - new Date().getTime()) / 1000 / 60 / 60 / 24;
  }

  public handleAppMenuClick(): void {
    if (!this.isTablet && !this.isDesktop) {
      this.sideNav.toggle();
    } else {
      this.toggleAppMenu();
    }
  }

  public openAppMenu(): void {
    if (!this.appMenuTouchEvent && !this.appMenuLocked) {
      this.appMenuOpened = true;
    }

    this.appMenuTouchEvent = false;
  }

  public setAppMenuUnlocked(): void {
    this.appMenuLocked = false;
  }

  public setAppMenuTouchEvent(): void {
    this.appMenuTouchEvent = true;
  }

  public timeOutPrompt(): void {
    const prompt = new Dialog({
      dialogButton: DialogButton.continueCancel,
      dialogTemplate: IdleTimeoutDialogComponent,
      dialogTitle: 'Idle Warning',
      dialogType: DialogType.Warning,
    });

    this.dialogService
      .open<IdleTimeoutDialogComponent>(prompt)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res) =>
        res
          ? this.idleTimeout.resetTimer()
          : this.router.navigate([env.appRoutes.signOut]),
      );
  }

  public toggleAppMenu(): void {
    this.appMenuLocked = !this.appMenuOpened;
    this.appMenuOpened = !this.appMenuOpened;
  }

  private buildRightNavMenu(): NavMenu[] {
    return [
      {
        label: 'Grants and Providers List',
        icon: 'format_list_bulleted',
        clickAction: () => this.openAssociationPanel(),
        condition: () => this.showAssociationsList,
      },
      {
        label: 'Help',
        icon: 'help_outline',
        routerLink: ['/help'],
      },
      {
        label: 'Application Settings',
        icon: 'admin_panel_settings',
        routerLink: ['/admin'],
        resourceAccess: [Resource.ViewSystemAdmin],
      },
    ];
  }

  private handleBreakpoints(): void {
    this.breakpointObserver
      .observe(ViewportMediaQuery.Mobile)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.viewportMode = ViewportMode.Mobile;
        }
      });

    this.breakpointObserver
      .observe(ViewportMediaQuery.Tablet)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((state: BreakpointState) => {
        this.viewportIsTablet = state.matches;
        if (state.matches) {
          this.viewportMode = ViewportMode.Tablet;
        }
      });

    this.breakpointObserver
      .observe(ViewportMediaQuery.Desktop)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((state: BreakpointState) => {
        this.viewportIsDesktop = state.matches;
        this.viewportMode = state.matches
          ? ViewportMode.Desktop
          : this.viewportMode;
      });

    this.breakpointObserver
      .observe(ViewportMediaQuery.Short)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.viewportHeight = ViewportHeight.Normal;

        if (this.viewportIsDesktop) {
          this.viewportMode = ViewportMode.Desktop;
        }
      });
  }

  private openAssociationPanel(): void {
    this.panelService
      .open<boolean>(
        SelectAssociationDialogComponent,
        {},
        { maxWidth: PanelWidth.Large },
      )
      .afterClosed()
      .pipe(takeUntil(this.destroyed$))
      .subscribe();
  }

  private setSiteTheme(theme: Themes): void {
    this.siteTheme = theme;
    this.renderer.addClass(document.body, this.siteTheme);
  }
}
