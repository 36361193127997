/* eslint-disable @typescript-eslint/naming-convention */
import {
  ApiError,
  ResourceAccessPayload,
  ResourceAccessResponse,
} from '@core/models';
import { createAction, props } from '@ngrx/store';

export enum UserActionTypes {
  GET_RESOURCE_ACCESS_FAILURE = '[User] Get Resources Failure',
  GET_RESOURCE_ACCESS_REQUEST = '[User] Get Resources Request',
  GET_RESOURCE_ACCESS_SUCCESS = '[User] Get Resources Success',
}

export const getResourceAccess = createAction(
  UserActionTypes.GET_RESOURCE_ACCESS_REQUEST,
  props<{ payload: ResourceAccessPayload }>()
);
export const getResourceAccessFailure = createAction(
  UserActionTypes.GET_RESOURCE_ACCESS_FAILURE,
  props<ApiError>()
);
export const getResourceAccessSuccess = createAction(
  UserActionTypes.GET_RESOURCE_ACCESS_SUCCESS,
  props<{ resourceAccess: ResourceAccessResponse }>()
);
