import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { associationToIds } from '@app/shared/utilities';
import {
  AssignableRolesApiResponse,
  ReactivateUserPayload,
  RemoveUserPayload,
  ResetUserMfaPayload,
  User,
  UserAssociationSelection,
  UserPayload,
} from '@core/models';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class UserService extends BaseService {
  private usersPath = `${this.baseURL}/users`;
  private assignableRolesPath = `${this.usersPath}/assignable-roles`;

  constructor(private http: HttpClient) {
    super();
  }

  public createUser(payload: UserPayload): Observable<null> {
    return this.http.post<null>(this.usersPath, payload);
  }

  public getAssignableRoles(
    grantId: string,
  ): Observable<AssignableRolesApiResponse> {
    return this.http.get<AssignableRolesApiResponse>(this.assignableRolesPath, {
      params: { grantId },
    });
  }

  public getUser(id: string): Observable<User> {
    return this.http.get<User>(`${this.usersPath}/${id}`);
  }

  public getUsers(
    selectedAssociation: UserAssociationSelection,
  ): Observable<User[]> {
    const payload = associationToIds(selectedAssociation);
    return this.http.get<User[]>(this.usersPath, {
      params: { ...payload },
    });
  }

  public removeUser(payload: RemoveUserPayload): Observable<null> {
    return this.http.delete<null>(this.usersPath, { body: payload });
  }

  public reactivateUser(payload: ReactivateUserPayload): Observable<null> {
    return this.http.put<null>(
      `${this.baseURL}/users/${payload.cognitoId}/reactivate`,
      { grantId: payload.grantId },
    );
  }

  public resetUserMfa(payload: ResetUserMfaPayload): Observable<null> {
    return this.http.put<null>(
      `${this.baseURL}/users/${payload.cognitoId}/reset-mfa`,
      { grantId: payload.grantId },
    );
  }
}
