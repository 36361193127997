<h1 mat-dialog-title>Upload History</h1>
<mat-dialog-content class="table-wrapper">
  <app-data-table
    [dataSource]="dataSource"
    [options]="dataTableOptions"
    [loading]="uploadHistoriesLoading$ | async"
  ></app-data-table>
</mat-dialog-content>
<mat-dialog-actions>
  <button color="primary" (click)="close(false)" type="button" mat-flat-button>
    Close
  </button>
</mat-dialog-actions>
