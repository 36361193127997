import { ApiError, Dashboard } from '@core/models';

export interface State {
  dashboardList: Dashboard[];
  dashboardListLoading: boolean;
  dashboardListError: ApiError;
  dashboardUrlLoading: boolean;
  dashboardUrlError: ApiError;
  dashboardUrl: string;
}

export const initialState: State = {
  dashboardList: [],
  dashboardListLoading: false,
  dashboardListError: null,
  dashboardUrlLoading: false,
  dashboardUrlError: null,
  dashboardUrl: '',
};
