import {
  ApiError,
  NewProgramModelPayload,
  ProgramCurriculum,
  ProgramModel,
  ProgramSubimssionCohortPayload,
  ProgramSubmissionAttendanceReachDosage,
  ProgramSubmissionAttendanceReachDosagePayload,
  ProgramSubmissionCohort,
  ProgramSubmissionStructureCostSupport,
  ProgramSubmissionStructureCostSupportPayload,
  ProgramSubmissionSurveyAdministration,
  ProgramSubmissionSurveyAdministrationPayload,
} from '@core/models';
import { createAction, props } from '@ngrx/store';

export enum ProgramModelActionTypes {
  ADD_PROGRAM_MODEL = '[ProgramModel] Add Program Model',
  ADD_PROGRAM_MODEL_SUCCESS = '[ProgramModel] Add Program Model Success',
  ADD_PROGRAM_MODEL_FAILURE = '[ProgramModel] Add Program Model Failure',
  ADD_PROGRAM_MODEL_COHORT = '[ProgramModel] Add Program Model Cohort',
  ADD_PROGRAM_MODEL_COHORT_SUCCESS = '[ProgramModel] Add Program Model Cohort Success',
  ADD_PROGRAM_MODEL_COHORT_FAILURE = '[ProgramModel] Add Program Model Cohort Failure',
  GET_PROGRAM_MODEL_CURRICULUMS = '[ProgramModel] Get Program Models Curriculums',
  GET_PROGRAM_MODEL_CURRICULUMS_SUCCESS = '[ProgramModel] Get Program Models Curriculums Success',
  GET_PROGRAM_MODEL_CURRICULUMS_FAILURE = '[rogramModel] Get Program Models Curriculums Failure',
  GET_PROGRAM_MODEL = '[ProgramModel] Get Program Model',
  GET_PROGRAM_MODEL_SUCCESS = '[ProgramModel] Get Program Model Success',
  GET_PROGRAM_MODEL_FAILURE = '[ProgramModel] Get Program Model Failure',
  GET_PROGRAM_MODELS = '[ProgramModel] Get Program Models',
  GET_PROGRAM_MODELS_SUCCESS = '[ProgramModel] Get Program Models Success',
  GET_PROGRAM_MODELS_FAILURE = '[ProgramModel] Get Program Models Failure',
  SET_PROGRAM_MODEL_ACTIVE_STATUS_FAILURE = '[ProgramModel] Set Program Model Active Status Failure',
  SET_PROGRAM_MODEL_ACTIVE_STATUS_REQUEST = '[ProgramModel] Set Program Model Active Status Request',
  SET_PROGRAM_MODEL_ACTIVE_STATUS_SUCCESS = '[ProgramModel] Set Program Model Active Status Success',
  GET_PROGRAM_SUBMISSION_COHORTS = '[ProgramModel] Get Program Submission Cohorts',
  GET_PROGRAM_SUBMISSION_COHORTS_SUCCESS = '[ProgramModel] Get Program Submission Cohorts Success',
  GET_PROGRAM_SUBMISSION_COHORTS_FAILURE = '[ProgramModel] Get Program Submission Cohorts Failure',
  GET_PROGRAM_SUBMISSION_ARD = '[ProgramModel] Get Program Submission ARD',
  GET_PROGRAM_SUBMISSION_ARD_SUCCESS = '[ProgramModel] Get Program Submission ARD Success',
  GET_PROGRAM_SUBMISSION_ARD_FAILURE = '[ProgramModel] Get Program Submission ARD Failure',
  GET_PROGRAM_SUBMISSION_SCS = '[ProgramModel] Get Program Submission SCS',
  GET_PROGRAM_SUBMISSION_SCS_SUCCESS = '[ProgramModel] Get Program Submission SCS Success',
  GET_PROGRAM_SUBMISSION_SCS_FAILURE = '[ProgramModel] Get Program Submission SCS Failure',
  GET_PROGRAM_SUBMISSION_SA = '[ProgramModel] Get Program Submission SA',
  GET_PROGRAM_SUBMISSION_SA_SUCCESS = '[ProgramModel] Get Program Submission SA Success',
  GET_PROGRAM_SUBMISSION_SA_FAILURE = '[ProgramModel] Get Program Submission SA Failure',
  REMOVE_PROGRAM_MODEL_COHORT = '[ProgramModel] Remove Program Model Cohort',
  REMOVE_PROGRAM_MODEL_COHORT_SUCCESS = '[ProgramModel] Remove Program Model Cohort Success',
  REMOVE_PROGRAM_MODEL_COHORT_FAILURE = '[ProgramModel] Remove Program Model Cohort Failure',
  UPDATE_PROGRAM_SUBMISSION_COHORTS = '[ProgramModel] Update Program Submission Cohorts',
  UPDATE_PROGRAM_SUBMISSION_COHORTS_SUCCESS = '[ProgramModel] Update Program Submission Cohorts Success',
  UPDATE_PROGRAM_SUBMISSION_COHORTS_FAILURE = '[ProgramModel] Update Program Submission Cohorts Failure',
  UPDATE_PROGRAM_SUBMISSION_ARD = '[ProgramModel] Update Program Submission ARD',
  UPDATE_PROGRAM_SUBMISSION_ARD_SUCCESS = '[ProgramModel] Update Program Submission ARD Success',
  UPDATE_PROGRAM_SUBMISSION_ARD_FAILURE = '[ProgramModel] Update Program Submission ARD Failure',
  UPDATE_PROGRAM_SUBMISSION_SCS = '[ProgramModel] Update Program Submission SCS',
  UPDATE_PROGRAM_SUBMISSION_SCS_SUCCESS = '[ProgramModel] Update Program Submission SCS Success',
  UPDATE_PROGRAM_SUBMISSION_SCS_FAILURE = '[ProgramModel] Update Program Submission SCS Failure',
  UPDATE_PROGRAM_SUBMISSION_SA = '[ProgramModel] Update Program Submission SA',
  UPDATE_PROGRAM_SUBMISSION_SA_SUCCESS = '[ProgramModel] Update Program Submission SA Success',
  UPDATE_PROGRAM_SUBMISSION_SA_FAILURE = '[ProgramModel] Update Program Submission SA Failure',
}

export const addProgramModel = createAction(
  ProgramModelActionTypes.ADD_PROGRAM_MODEL,
  props<{ payload: NewProgramModelPayload }>()
);

export const addProgramModelSuccess = createAction(
  ProgramModelActionTypes.ADD_PROGRAM_MODEL_SUCCESS
);

export const addProgramModelFailure = createAction(
  ProgramModelActionTypes.ADD_PROGRAM_MODEL_FAILURE,
  props<ApiError>()
);

export const getProgramModelsCurriculums = createAction(
  ProgramModelActionTypes.GET_PROGRAM_MODEL_CURRICULUMS,
  props<{ programId: string }>()
);

export const getProgramModelsCurriculumsSuccess = createAction(
  ProgramModelActionTypes.GET_PROGRAM_MODEL_CURRICULUMS_SUCCESS,
  props<{ programCurriculums: ProgramCurriculum[] }>()
);

export const getProgramModelsCurriculumsFailure = createAction(
  ProgramModelActionTypes.GET_PROGRAM_MODEL_CURRICULUMS_FAILURE,
  props<ApiError>()
);

export const getProgramModel = createAction(
  ProgramModelActionTypes.GET_PROGRAM_MODEL,
  props<{ programId: string }>()
);

export const getProgramModelSuccess = createAction(
  ProgramModelActionTypes.GET_PROGRAM_MODEL_SUCCESS,
  props<{ programModel: ProgramModel }>()
);

export const getProgramModelFailure = createAction(
  ProgramModelActionTypes.GET_PROGRAM_MODEL_FAILURE,
  props<ApiError>()
);

export const getProgramModels = createAction(
  ProgramModelActionTypes.GET_PROGRAM_MODELS,
  props<{ providerId: string }>()
);

export const getProgramModelsSuccess = createAction(
  ProgramModelActionTypes.GET_PROGRAM_MODELS_SUCCESS,
  props<{ programModels: ProgramModel[] }>()
);

export const getProgramModelsFailure = createAction(
  ProgramModelActionTypes.GET_PROGRAM_MODELS_FAILURE,
  props<ApiError>()
);

export const setProgramModelActiveStatus = createAction(
  ProgramModelActionTypes.SET_PROGRAM_MODEL_ACTIVE_STATUS_REQUEST,
  props<{ programModelId: string; activeStatus: boolean }>()
);
export const setProgramModelActiveStatusFailure = createAction(
  ProgramModelActionTypes.SET_PROGRAM_MODEL_ACTIVE_STATUS_FAILURE,
  props<ApiError>()
);
export const setProgramModelActiveStatusSuccess = createAction(
  ProgramModelActionTypes.SET_PROGRAM_MODEL_ACTIVE_STATUS_SUCCESS,
  props<{ programModelId: string; activeStatus: boolean }>()
);

export const addProgramModelCohort = createAction(
  ProgramModelActionTypes.ADD_PROGRAM_MODEL_COHORT,
  props<ProgramSubimssionCohortPayload>()
);

export const addProgramModelCohortSuccess = createAction(
  ProgramModelActionTypes.ADD_PROGRAM_MODEL_COHORT_SUCCESS
);

export const addProgramModelCohortFailure = createAction(
  ProgramModelActionTypes.ADD_PROGRAM_MODEL_COHORT_FAILURE,
  props<ApiError>()
);

export const getProgramSubmissionCohorts = createAction(
  ProgramModelActionTypes.GET_PROGRAM_SUBMISSION_COHORTS,
  props<{ programId: string }>()
);

export const getProgramSubmissionCohortsSuccess = createAction(
  ProgramModelActionTypes.GET_PROGRAM_SUBMISSION_COHORTS_SUCCESS,
  props<{ programSubmissionCohorts: ProgramSubmissionCohort[] }>()
);

export const getProgramSubmissionCohortsFailure = createAction(
  ProgramModelActionTypes.GET_PROGRAM_SUBMISSION_COHORTS_FAILURE,
  props<ApiError>()
);

export const removeProgramModelCohort = createAction(
  ProgramModelActionTypes.REMOVE_PROGRAM_MODEL_COHORT,
  props<{ programId: string; cohortId: string }>()
);

export const removeProgramModelCohortSuccess = createAction(
  ProgramModelActionTypes.REMOVE_PROGRAM_MODEL_COHORT_SUCCESS,
  props<{ programId: string }>()
);

export const removeProgramModelCohortFailure = createAction(
  ProgramModelActionTypes.REMOVE_PROGRAM_MODEL_COHORT_FAILURE,
  props<ApiError>()
);

export const getProgramSubmissionArd = createAction(
  ProgramModelActionTypes.GET_PROGRAM_SUBMISSION_ARD,
  props<{ programId: string }>()
);

export const getProgramSubmissionArdSuccess = createAction(
  ProgramModelActionTypes.GET_PROGRAM_SUBMISSION_ARD_SUCCESS,
  props<{ programSubmissionArd: ProgramSubmissionAttendanceReachDosage }>()
);

export const getProgramSubmissionArdFailure = createAction(
  ProgramModelActionTypes.GET_PROGRAM_SUBMISSION_ARD_FAILURE,
  props<ApiError>()
);

export const getProgramSubmissionScs = createAction(
  ProgramModelActionTypes.GET_PROGRAM_SUBMISSION_SCS,
  props<{ programId: string }>()
);

export const getProgramSubmissionScsSuccess = createAction(
  ProgramModelActionTypes.GET_PROGRAM_SUBMISSION_SCS_SUCCESS,
  props<{ programSubmissionScs: ProgramSubmissionStructureCostSupport }>()
);

export const getProgramSubmissionScsFailure = createAction(
  ProgramModelActionTypes.GET_PROGRAM_SUBMISSION_SCS_FAILURE,
  props<ApiError>()
);

export const getProgramSubmissionSa = createAction(
  ProgramModelActionTypes.GET_PROGRAM_SUBMISSION_SA,
  props<{ programId: string }>()
);

export const getProgramSubmissionSaSuccess = createAction(
  ProgramModelActionTypes.GET_PROGRAM_SUBMISSION_SA_SUCCESS,
  props<{ programSubmissionSa: ProgramSubmissionSurveyAdministration }>()
);

export const getProgramSubmissionSaFailure = createAction(
  ProgramModelActionTypes.GET_PROGRAM_SUBMISSION_SA_FAILURE,
  props<ApiError>()
);

export const updateProgramSubmissionCohort = createAction(
  ProgramModelActionTypes.UPDATE_PROGRAM_SUBMISSION_COHORTS,
  props<ProgramSubimssionCohortPayload>()
);

export const updateProgramSubmissionCohortSuccess = createAction(
  ProgramModelActionTypes.UPDATE_PROGRAM_SUBMISSION_COHORTS_SUCCESS
);

export const updateProgramSubmissionCohortFailure = createAction(
  ProgramModelActionTypes.UPDATE_PROGRAM_SUBMISSION_COHORTS_FAILURE,
  props<ApiError>()
);

export const updateProgramSubmissionArd = createAction(
  ProgramModelActionTypes.UPDATE_PROGRAM_SUBMISSION_ARD,
  props<{
    programId: string;
    payload: ProgramSubmissionAttendanceReachDosagePayload;
  }>()
);

export const updateProgramSubmissionArdSuccess = createAction(
  ProgramModelActionTypes.UPDATE_PROGRAM_SUBMISSION_ARD_SUCCESS
);

export const updateProgramSubmissionArdFailure = createAction(
  ProgramModelActionTypes.UPDATE_PROGRAM_SUBMISSION_ARD_FAILURE,
  props<ApiError>()
);

export const updateProgramSubmissionScs = createAction(
  ProgramModelActionTypes.UPDATE_PROGRAM_SUBMISSION_SCS,
  props<{
    programId: string;
    payload: ProgramSubmissionStructureCostSupportPayload;
  }>()
);

export const updateProgramSubmissionScsSuccess = createAction(
  ProgramModelActionTypes.UPDATE_PROGRAM_SUBMISSION_SCS_SUCCESS
);

export const updateProgramSubmissionScsFailure = createAction(
  ProgramModelActionTypes.UPDATE_PROGRAM_SUBMISSION_SCS_FAILURE,
  props<ApiError>()
);

export const updateProgramSubmissionSa = createAction(
  ProgramModelActionTypes.UPDATE_PROGRAM_SUBMISSION_SA,
  props<{
    programId: string;
    payload: ProgramSubmissionSurveyAdministrationPayload;
  }>()
);

export const updateProgramSubmissionSaSuccess = createAction(
  ProgramModelActionTypes.UPDATE_PROGRAM_SUBMISSION_SA_SUCCESS
);

export const updateProgramSubmissionSaFailure = createAction(
  ProgramModelActionTypes.UPDATE_PROGRAM_SUBMISSION_SA_FAILURE,
  props<ApiError>()
);
