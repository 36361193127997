import { Injectable } from '@angular/core';
import { SettingsService } from '@core/services';
import { Actions } from '@ngrx/effects';
import {} from './actions';

@Injectable()
export class SettingsEffects {
  constructor(
    private actions$: Actions,
    private settingsService: SettingsService
  ) {}
}
