import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Resource } from '@app/shared/enums';
import { ResourceAccessSelectors } from '@app/store';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard {
  constructor(
    private router: Router,
    private store$: Store,
  ) {}

  canActivate(): Observable<boolean | UrlTree> | boolean | UrlTree {
    return this.store$
      .select(ResourceAccessSelectors.selectResourceAccess)
      .pipe(
        filter((resources) => !!resources),
        map((resources) =>
          resources[Resource.ViewSystemAdmin]
            ? true
            : this.router.parseUrl('/'),
        ),
      );
  }
}
