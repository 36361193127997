<div [class.standalone]="!isModal">
  <h2 mat-dialog-title>Security Notice</h2>
  <mat-dialog-content>
    <p>
      This warning provides privacy and security notices consistent with
      applicable federal laws, directives, and other federal guidance for
      accessing this Government system, which includes (1) this computer
      network, (2) all computers connected to this network, and (3) all devices
      and storage media attached to this network or to a computer on this
      network.
    </p>
    <ul>
      <li>This system is provided for Government-authorized use only.</li>
      <li>
        Unauthorized or improper use of this system is prohibited and may result
        in disciplinary action and/or civil and criminal penalties.
      </li>
      <li>
        Personal use of social media and networking sites on this system is
        limited as to not interfere with official work and duties and is subject
        to monitoring.
      </li>
    </ul>

    <p>By using this system, you understand and consent to the following:</p>
    <ul>
      <li>
        The Government may monitor, record, and audit your system usage,
        including usage of personal devices and email systems for official
        duties or to conduct HHS business. Therefore, you have no reasonable
        expectation of privacy regarding any communication or data transiting or
        stored on this system. At any time, and for any lawful Government
        purpose, the government may monitor, intercept, and search and seize any
        communication or data transiting or stored on this system.
      </li>
      <li>
        Any communication or data transiting or stored on this system may be
        disclosed or used for any lawful Government purpose.
      </li>
    </ul>
  </mat-dialog-content>
</div>
<mat-dialog-actions *ngIf="isModal">
  <button class="close" color="primary" mat-flat-button mat-dialog-close>
    OK
  </button>
</mat-dialog-actions>
