import * as DashboardActions from './actions';
import * as DashboardEffects from './effects';
import * as DashboardReducer from './reducer';
import * as DashboardSelectors from './selectors';
import * as DashboardState from './state';

export * from './dashboard.module';
export {
  DashboardActions,
  DashboardEffects,
  DashboardReducer,
  DashboardSelectors,
  DashboardState,
};
