import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ApiError } from '@core/models';
import { State } from './state';
import { myGrantsFeatureKey } from './reducer';

const updateGrantFavoritesLoading = (state: State): boolean =>
  state.updateGrantFavoritesLoading;
const updateGrantFavoritesFailure = (state: State): ApiError =>
  state.updateGrantFavoritesFailure;
  const getGrantFavoritesLoading = (state: State): boolean =>
  state.getGrantFavoritesLoading;
const getGrantFavoritesFailure = (state: State): ApiError =>
  state.getGrantFavoritesFailure;
const grantFavoriteIds = (state: State): string[] =>
  state.grantFavoriteIds;

export const myGrantsState = createFeatureSelector<State>(
  myGrantsFeatureKey
);

export const selectUpdateGrantFavoritesLoading = createSelector(
  myGrantsState,
  updateGrantFavoritesLoading
);
export const selectUpdateGrantFavoritesFailure = createSelector(
  myGrantsState,
  updateGrantFavoritesFailure
);
export const selectGetGrantFavoritesLoading = createSelector(
  myGrantsState,
  getGrantFavoritesLoading
);
export const selectGetGrantFavoritesFailure = createSelector(
  myGrantsState,
  getGrantFavoritesFailure
);
export const selectGrantFavoriteIds = createSelector(
  myGrantsState,
  grantFavoriteIds
);
