import { ApiError, Provider, ProviderSubmission } from '@core/models';

export interface State {
  addProviderError: ApiError;
  addProviderLoading: boolean;
  provider: Provider;
  providerLoading: boolean;
  providerError: ApiError;
  providers: Provider[];
  providersError: ApiError;
  providersLoading: boolean;
  providerSubmission: ProviderSubmission;
  providerSubmissionLoading: boolean;
  providerSubmissionError: ApiError;
  setProviderActiveStatusError: ApiError;
  setProviderActiveStatusLoading: boolean;
  updateProviderSubmissionError: ApiError;
  updateProviderSubmissionLoading: boolean;
}

export const initialState: State = {
  addProviderError: null,
  addProviderLoading: false,
  provider: null,
  providerLoading: false,
  providerError: null,
  providers: [],
  providersError: null,
  providersLoading: false,
  providerSubmission: null,
  providerSubmissionLoading: false,
  providerSubmissionError: null,
  setProviderActiveStatusError: null,
  setProviderActiveStatusLoading: false,
  updateProviderSubmissionError: null,
  updateProviderSubmissionLoading: false,
};
