<mat-slide-toggle
  color="primary"
  [(ngModel)]="toggleState"
  [disabled]="disabled"
  (change)="toggleChange()"
>
  Only My Grants
</mat-slide-toggle>
<app-help-toggletip
  position="bottom"
  toggleTop="4rem"
  label="the My Grants filter"
  [content]="tooltipMessage"
></app-help-toggletip>
