<h4 mat-dialog-title class="modal-title">Inactive Account</h4>
<mat-dialog-content>
  <p>
    This account is inactive, reach out to a Grant Admin or Project Officer to
    get this account reactivated.
  </p>
</mat-dialog-content>
<mat-dialog-actions class="dialog-actions">
  <button mat-flat-button color="primary" (click)="close()">Close</button>
</mat-dialog-actions>
