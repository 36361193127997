import { Injectable } from '@angular/core';
import { handleError } from '@core/helpers';
import { UserService } from '@core/services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  createUser,
  createUserFailure,
  createUserSuccess,
  getAssignableRoles,
  getAssignableRolesFailure,
  getAssignableRolesSuccess,
  getUser,
  getUserFailure,
  getUsers,
  getUsersFailure,
  getUsersSuccess,
  getUserSuccess,
  reactivateUser,
  reactivateUserFailure,
  reactivateUserSuccess,
  removeUser,
  removeUserFailure,
  removeUserSuccess,
  resetUserMFA,
  resetUserMFAFailure,
  resetUserMFASuccess,
} from './actions';

@Injectable()
export class UserEffects {
  createUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createUser),
      switchMap(({ payload }) =>
        this.userService.createUser(payload).pipe(
          map(() => createUserSuccess()),
          catchError((error) => handleError(error, createUserFailure)),
        ),
      ),
    ),
  );

  getAssignableRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAssignableRoles),
      switchMap(({ grantId }) =>
        this.userService.getAssignableRoles(grantId).pipe(
          map((assignableRoles) =>
            getAssignableRolesSuccess({ assignableRoles }),
          ),
          catchError((error) => handleError(error, getAssignableRolesFailure)),
        ),
      ),
    ),
  );

  getUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUser),
      switchMap(({ id }) =>
        this.userService.getUser(id).pipe(
          map((user) => getUserSuccess({ user })),
          catchError((error) => handleError(error, getUserFailure)),
        ),
      ),
    ),
  );

  getUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUsers),
      switchMap(({ selectedAssociation }) =>
        this.userService.getUsers(selectedAssociation).pipe(
          map((users) => getUsersSuccess({ users })),
          catchError((error) => handleError(error, getUsersFailure)),
        ),
      ),
    ),
  );

  removeUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeUser),
      switchMap(({ payload }) =>
        this.userService.removeUser(payload).pipe(
          map(() => removeUserSuccess()),
          catchError((error) => handleError(error, removeUserFailure)),
        ),
      ),
    ),
  );

  reactivateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(reactivateUser),
      switchMap(({ payload }) =>
        this.userService.reactivateUser(payload).pipe(
          map(() => reactivateUserSuccess()),
          catchError((error) => handleError(error, reactivateUserFailure)),
        ),
      ),
    ),
  );

  resetUserMFA$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resetUserMFA),
      switchMap(({ payload }) =>
        this.userService.resetUserMfa(payload).pipe(
          map(() => resetUserMFASuccess()),
          catchError((error) => handleError(error, resetUserMFAFailure)),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private userService: UserService,
  ) {}
}
