import {
  ApiError,
  FileColumns,
  Grant,
  GrantSubmission,
  GrantUpload,
  UploadStatus,
  UploadHistory,
} from '@core/models';

export interface State {
  grant: Grant;
  grantLoading: boolean;
  grantError: ApiError;
  grants: Grant[];
  grantsLoading: boolean;
  grantsError: ApiError;
  grantSubmission: GrantSubmission;
  grantSubmissionLoading: boolean;
  grantSubmissionError: ApiError;
  setGrantSubmissionLoading: boolean;
  setGrantSubmissionError: ApiError;
  grantUpload: GrantUpload;
  getGrantUploadLoading: boolean;
  getGrantUploadFailure: ApiError;
  grantUploads: GrantUpload[];
  grantUploadStatus: UploadStatus;
  getGrantUploadStatusLoading: boolean;
  getGrantUploadStatusFailure: ApiError;
  getGrantUploadsLoading: boolean;
  getGrantUploadsFailure: ApiError;
  validateFileLoading: boolean;
  validateFileFailure: ApiError;
  storeFileLoading: boolean;
  storeFileFailure: ApiError;
  storeFileUploadProgress: number;
  fileColumns: FileColumns;
  getFileColumnsLoading: boolean;
  getFileColumnsFailure: ApiError;
  uploadHistories: UploadHistory[];
  getUploadHistoriesLoading: boolean;
  getUploadHistoriesFailure: ApiError;
  completeUploadLoading: boolean;
  completeUploadFailure: ApiError;
  updateGrantSubmissionStatusLoading: boolean;
  updateGrantSubmissionStatusFailure: ApiError;
}

export const initialState: State = {
  grant: null,
  grantLoading: false,
  grantError: null,
  grants: [],
  grantsLoading: false,
  grantsError: null,
  grantSubmission: null,
  grantSubmissionLoading: false,
  grantSubmissionError: null,
  setGrantSubmissionLoading: false,
  setGrantSubmissionError: null,
  grantUpload: null,
  getGrantUploadLoading: false,
  getGrantUploadFailure: null,
  grantUploadStatus: { uploadStatus: 'NOT_ENOUGH_INFO' },
  getGrantUploadStatusLoading: false,
  getGrantUploadStatusFailure: null,
  grantUploads: [],
  getGrantUploadsLoading: false,
  getGrantUploadsFailure: null,
  validateFileLoading: false,
  validateFileFailure: null,
  storeFileLoading: false,
  storeFileFailure: null,
  storeFileUploadProgress: 0,
  fileColumns: null,
  getFileColumnsLoading: false,
  getFileColumnsFailure: null,
  uploadHistories: [],
  getUploadHistoriesLoading: false,
  getUploadHistoriesFailure: null,
  completeUploadLoading: false,
  completeUploadFailure: null,
  updateGrantSubmissionStatusFailure: null,
  updateGrantSubmissionStatusLoading: false
};
