import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { UserAssociationCategory } from '@app/shared/enums';
import { UserAssociations, UserAssociationSelection } from '@core/models';
import { Subject } from 'rxjs';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-associations-list',
  templateUrl: './associations-list.component.html',
  styleUrls: ['./associations-list.component.scss'],
})
export class AssociationsListComponent implements OnInit, OnDestroy {
  @Input() associations: UserAssociations;
  @Output() associationSelected: EventEmitter<UserAssociationSelection> =
    new EventEmitter();

  public pageSize = 10;
  public UserAssociationCategory = UserAssociationCategory;

  private destroyed$ = new Subject<boolean>();
  private pageIndex = 0;

  constructor() {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public grantOnPage(index: number): boolean {
    const previousPage = this.pageIndex;
    const page = this.pageIndex + 1;
    const idToCheck = index + 1;

    return (
      idToCheck <= page * this.pageSize &&
      idToCheck > previousPage * this.pageSize
    );
  }

  public providerOnPage(index: number): boolean {
    const previousPage = this.pageIndex;
    const page = this.pageIndex + 1;
    const idToCheck = index + 1;

    return (
      idToCheck <= page * this.pageSize &&
      idToCheck > previousPage * this.pageSize
    );
  }

  public handlePageEvent(e: PageEvent) {
    this.pageIndex = e.pageIndex;
  }

  public setSelection(selectionType: string, id: string): void {
    this.associationSelected.emit({ selectionType, id });
  }
}
