import { DatePipe } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { UploadTypes } from '@app/shared/enums';
import { DataTableOptions } from '@app/shared/ui';
import { GrantActions, GrantSelectors } from '@app/store';
import { GrantUpload, TableColumn, UploadHistory } from '@core/models';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-upload-history',
  templateUrl: './upload-history.component.html',
  styleUrls: ['./upload-history.component.scss'],
})
export class UploadHistoryComponent implements OnInit, OnDestroy {
  public uploadHistories$ = this.store$.select(
    GrantSelectors.selectUploadHistories
  );
  public uploadHistoriesLoading$ = this.store$.select(
    GrantSelectors.selectUploadHistoriesLoading
  );
  public dataTableOptions: DataTableOptions = null;
  public dataSource: MatTableDataSource<UploadHistory>;

  private destroyed$ = new Subject<boolean>();
  private tableColumns: TableColumn[] = [];
  private displayedColumns: string[];

  constructor(
    protected dialogRef: MatDialogRef<UploadHistoryComponent>,
    @Inject(MAT_DIALOG_DATA) private panelData: GrantUpload,
    private store$: Store
  ) {}

  ngOnInit(): void {
    this.tableColumns = [
      {
        column: 'fileName',
        headerName: 'File',
        minWidth: '16rem',
        maxWidth: '30rem',
      },
      { column: 'fileType', headerName: 'File Type', width: '6rem' },
      { column: 'validationIssuesCount', headerName: 'Issues', width: '6rem' },
      {
        column: 'uploadTime',
        headerName: 'Upload Date',
        width: '10rem',
        pipe: DatePipe,
      },
      { column: 'completed', headerName: 'Completed', width: '8rem' },
      {
        column: 'completedBy',
        headerName: 'Completed By',
        minWidth: '12rem',
        maxWidth: '30rem',
      },
      {
        column: 'completedByEmail',
        headerName: 'Email',
        minWidth: '16rem',
        maxWidth: '30rem',
      },
    ];

    this.uploadHistories$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((uploadHistories) => {
        this.dataSource = new MatTableDataSource<UploadHistory>(
          uploadHistories
        );
        this.dataSource.filterPredicate = this.getFilterPredicate;
        this.dataSource.sortingDataAccessor = this.getSortingDataAccessor;
        this.displayedColumns = this.tableColumns.map(
          (tableColumn) => tableColumn.column
        );

        this.dataTableOptions = {
          displayedColumns: this.displayedColumns,
          isHtml: true,
          tableColumns: this.tableColumns,
          scrollTable: true,
          sortActive: 'fileName',
          skipScrollMargin: true,
          fixedPageFooter: false,
        };
      });

    this.fetchData(this.panelData.grantId, this.panelData.uploadType);
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public close(success: boolean): void {
    this.dialogRef.close(success);
  }

  fetchData(grantId: string, uploadType: UploadTypes): void {
    this.store$.dispatch(
      GrantActions.getUploadHistories({
        grantId,
        uploadType,
      })
    );
  }

  private getFilterPredicate(data: UploadHistory, dsFilter: string): boolean {
    const filterableProperties = [
      'fileName',
      'fileType',
      'validationIssuesCount',
      'uploadTime',
      'completed',
      'completedBy',
      'completedByEmail',
    ];

    return filterableProperties.some(
      (property) =>
        (data[property] || '')
          .toString()
          .toLowerCase()
          .indexOf(dsFilter.toLowerCase()) > -1
    );
  }

  private getSortingDataAccessor(
    data: UploadHistory,
    sortHeaderId: string
  ): string | number {
    if (typeof data[sortHeaderId] === 'string') {
      return data[sortHeaderId].toLocaleLowerCase();
    }

    return data[sortHeaderId];
  }
}
