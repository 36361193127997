import { Component, OnInit } from '@angular/core';

@Component({
  standalone: true,
  selector: 'app-auth-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class AuthFooterComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
