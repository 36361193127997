<div *ngIf="crumbs" class="flex-row crumb-wrapper">
  <span>
    <button mat-icon-button (click)="goBack()">
      <mat-icon>arrow_back</mat-icon>
    </button>
  </span>
  <span>
    <a [routerLink]="['/']">Home</a>
    <span> / </span>
    <ng-container *ngFor="let crumb of crumbs; let i = index">
      <a *ngIf="crumb.link || crumb.linkList" [routerLink]="getCrumbLink(i)">{{
        crumb.label
      }}</a>
      <span *ngIf="!crumb.link && !crumb.linkList">{{ crumb.label }}</span>
      <span *ngIf="i < crumbs.length - 1"> / </span>
    </ng-container>
  </span>
</div>
