import { Injectable } from '@angular/core';

import { SurveyUploadComponent } from '@app/participant-surveys/survey-upload/survey-upload.component';
import { AppStoreState, AuthenticationSelectors } from '@app/store';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/** Prompt user when upload process is in progress and navigating away. */
@Injectable({
  providedIn: 'root',
})
export class SurveyUploadGuard  {
  constructor(private store$: Store<AppStoreState.State>) {}

  public canDeactivate(component: SurveyUploadComponent): Observable<boolean> {
    return this.store$
      .select(AuthenticationSelectors.selectSignedOutFromInterceptor)
      .pipe(
        map((signedOutFromInterceptor) => {
          if (!signedOutFromInterceptor && component.hasUnsavedChanges) {
            return confirm(
              'Are you sure you want to discard the unsaved data?'
            );
          }

          return true;
        })
      );
  }
}
