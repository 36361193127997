import { Action, createReducer, on } from '@ngrx/store';
import {
  add,
  addFailure,
  addSuccess,
  getNotifications,
  getNotificationsFailure,
  getNotificationsSuccess,
  remove,
  removeFailure,
  removeSuccess,
  reset,
  resetFailure,
  resetSuccess,
} from './actions';
import { initialState, State } from './state';

export const notificationFeatureKey = 'notification';

const NotificationReducer = createReducer(
  initialState,
  on(add, (state) => ({
    ...state,
    addLoading: true,
  })),
  on(addFailure, (state, { message }) => ({
    ...state,
    addError: message,
    addLoading: false,
  })),
  on(addSuccess, (state, { notifications }) => ({
    ...state,
    notifications,
    addLoading: false,
  })),
  on(remove, (state) => ({
    ...state,
    removeLoading: true,
  })),
  on(removeFailure, (state, { message }) => ({
    ...state,
    removeError: message,
    removeLoading: false,
  })),
  on(removeSuccess, (state, { notifications }) => ({
    ...state,
    notifications,
    removeLoading: false,
  })),
  on(reset, (state) => ({
    ...state,
    addLoading: true,
  })),
  on(resetFailure, (state, { message }) => ({
    ...state,
    resetadd: null,
    resetError: message,
    resetLoading: false,
  })),
  on(resetSuccess, (state, { notifications }) => ({
    ...state,
    notifications,
    resetLoading: false,
  })),
  on(getNotifications, (state) => ({
    ...state,
    getNotificationsLoading: true,
  })),
  on(getNotificationsFailure, (state, { message }) => ({
    ...state,
    notifications: [],
    getNotificationsError: message,
    getNotificationsLoading: false,
  })),
  on(getNotificationsSuccess, (state, { notifications }) => ({
    ...state,
    notifications,
    getNotificationsLoading: false,
  }))
);

export const reducer = (state: State | undefined, action: Action) =>
  NotificationReducer(state, action);
