import {
  ApiError,
  ProgramCurriculum,
  ProgramModel,
  ProgramSubmissionAttendanceReachDosage,
  ProgramSubmissionStructureCostSupport,
  ProgramSubmissionSurveyAdministration,
} from '@core/models';
import { ProgramSubmissionCohort } from '@core/models/program-submission-cohort.interface';

export interface State {
  addProgramModelLoading: boolean;
  addProgramModelError: ApiError;
  programCurriculums: ProgramCurriculum[];
  programCurriculumsError: ApiError;
  programCurriculumsLoading: boolean;
  programModel: ProgramModel;
  programModelLoading: boolean;
  programModelError: ApiError;
  programModels: ProgramModel[];
  programModelsLoading: boolean;
  programModelsError: ApiError;
  programModelActiveStatusError: ApiError;
  programModelActiveStatusLoading: boolean;
  programModelCohortLoading: boolean;
  programModelCohortError: ApiError;
  programSubmissionCohorts: ProgramSubmissionCohort[];
  programSubmissionCohortsLoading: boolean;
  programSubmissionCohortsError: ApiError;
  programSubmissionArd: ProgramSubmissionAttendanceReachDosage;
  programSubmissionArdLoading: boolean;
  programSubmissionArdError: ApiError;
  programSubmissionScs: ProgramSubmissionStructureCostSupport;
  programSubmissionScsLoading: boolean;
  programSubmissionScsError: ApiError;
  programSubmissionSa: ProgramSubmissionSurveyAdministration;
  programSubmissionSaLoading: boolean;
  programSubmissionSaError: ApiError;
  removeProgramSubmissionCohortLoading: boolean;
  removeProgramSubmissionCohortError: ApiError;
  updateProgramSubmissionArdLoading: boolean;
  updateProgramSubmissionArdError: ApiError;
  updateProgramSubmissionCohortLoading: boolean;
  updateProgramSubmissionCohortError: ApiError;
  updateProgramSubmissionScsLoading: boolean;
  updateProgramSubmissionScsError: ApiError;
  updateProgramSubmissionSaLoading: boolean;
  updateProgramSubmissionSaError: ApiError;
}

export const initialState: State = {
  addProgramModelLoading: false,
  addProgramModelError: null,
  programCurriculums: [],
  programCurriculumsError: null,
  programCurriculumsLoading: false,
  programModel: null,
  programModelLoading: false,
  programModelError: null,
  programModels: [],
  programModelsLoading: false,
  programModelsError: null,
  programModelActiveStatusError: null,
  programModelActiveStatusLoading: false,
  programModelCohortLoading: false,
  programModelCohortError: null,
  programSubmissionCohorts: [],
  programSubmissionCohortsLoading: false,
  programSubmissionCohortsError: null,
  programSubmissionArd: null,
  programSubmissionArdLoading: false,
  programSubmissionArdError: null,
  programSubmissionScs: null,
  programSubmissionScsLoading: false,
  programSubmissionScsError: null,
  programSubmissionSa: null,
  programSubmissionSaLoading: false,
  programSubmissionSaError: null,
  removeProgramSubmissionCohortLoading: false,
  removeProgramSubmissionCohortError: null,
  updateProgramSubmissionArdLoading: false,
  updateProgramSubmissionArdError: null,
  updateProgramSubmissionCohortLoading: false,
  updateProgramSubmissionCohortError: null,
  updateProgramSubmissionScsLoading: false,
  updateProgramSubmissionScsError: null,
  updateProgramSubmissionSaLoading: false,
  updateProgramSubmissionSaError: null,
};
