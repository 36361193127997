import { createReducer, on } from '@ngrx/store';
import {
  getActiveSubmissionPeriod,
  getActiveSubmissionPeriodFailure,
  getActiveSubmissionPeriodSuccess,
  getAllSubmissionPeriods,
  getAllSubmissionPeriodsFailure,
  getAllSubmissionPeriodsSuccess,
  updateSubmissionPeriod,
  updateSubmissionPeriodSuccess,
  updateSubmissionPeriodFailure,
  createSubmissionPeriod,
  createSubmissionPeriodSuccess,
  createSubmissionPeriodFailure,
} from './actions';
import { initialState } from './state';

export const submissionPeriodFeatureKey = 'submission-period';

export const submissionPeriodReducer = createReducer(
  initialState,
  on(getActiveSubmissionPeriod, (state) => ({
    ...state,
    getActiveSubmissionPeriodLoading: true,
  })),
  on(getActiveSubmissionPeriodSuccess, (state, { activeSubmissionPeriod }) => ({
    ...state,
    getActiveSubmissionPeriodLoading: false,
    activeSubmissionPeriod,
  })),
  on(getActiveSubmissionPeriodFailure, (state, { error, message }) => ({
    ...state,
    getActiveSubmissionPeriodLoading: false,
    getActiveSubmissionPeriodFailure: { error, message },
  })),
  on(getAllSubmissionPeriods, (state) => ({
    ...state,
    getAllSubmissionPeriodsLoading: true,
  })),
  on(getAllSubmissionPeriodsSuccess, (state, { submissionPeriods }) => ({
    ...state,
    getAllSubmissionPeriodsLoading: false,
    submissionPeriods,
  })),
  on(getAllSubmissionPeriodsFailure, (state, { error, message }) => ({
    ...state,
    getAllSubmissionPeriodsLoading: false,
    getSubmissionPeriodsFailure: { error, message },
  })),
  on(updateSubmissionPeriod, (state) => ({
    ...state,
    updateSubmissionPeriodLoading: true,
    updateSubmissionPeriodFailure: null,
  })),
  on(updateSubmissionPeriodSuccess, (state) => ({
    ...state,
    updateSubmissionPeriodLoading: false,
    updateSubmissionPeriodFailure: null,
  })),
  on(updateSubmissionPeriodFailure, (state, { error, message }) => ({
    ...state,
    updateSubmissionPeriodLoading: false,
    updateSubmissionPeriodFailure: { error, message },
  })),
  on(createSubmissionPeriod, (state) => ({
    ...state,
    createSubmissionPeriodLoading: true,
    createSubmissionPeriodFailure: null,
  })),
  on(createSubmissionPeriodSuccess, (state) => ({
    ...state,
    createSubmissionPeriodLoading: false,
    createSubmissionPeriodFailure: null,
  })),
  on(createSubmissionPeriodFailure, (state, { error, message }) => ({
    ...state,
    createSubmissionPeriodLoading: false,
    createSubmissionPeriodFailure: { error, message },
  })),
);
