import { ApiError } from '@core/models';
import { SubmissionPeriod } from '@core/models/submission-period.interface';

export interface State {
  activeSubmissionPeriod: SubmissionPeriod;
  getActiveSubmissionPeriodLoading: boolean;
  getActiveSubmissionPeriodFailure: ApiError;
  submissionPeriods: SubmissionPeriod[];
  getAllSubmissionPeriodsLoading: boolean;
  getAllSubmissionPeriodsFailure: ApiError;
  updateSubmissionPeriodLoading: boolean;
  updateSubmissionPeriodFailure: ApiError;
  createSubmissionPeriodLoading: boolean;
  createSubmissionPeriodFailure: ApiError;
}

export const initialState: State = {
  activeSubmissionPeriod: null,
  submissionPeriods: [],
  getActiveSubmissionPeriodLoading: false,
  getActiveSubmissionPeriodFailure: null,
  getAllSubmissionPeriodsLoading: false,
  getAllSubmissionPeriodsFailure: null,
  updateSubmissionPeriodLoading: false,
  updateSubmissionPeriodFailure: null,
  createSubmissionPeriodLoading: false,
  createSubmissionPeriodFailure: null,
};
