import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ResourceAccessEffects } from './effects';
import { reducer, resourceAccessFeatureKey } from './reducer';

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature([ResourceAccessEffects]),
    StoreModule.forFeature(resourceAccessFeatureKey, reducer),
  ],
})
export class ResourceAccessModule {}
