import { ToggleState } from '@core/models';

export interface State {
  securityNoticeShown: boolean;
  toggles: ToggleState;
}

export const initialState: State = {
  securityNoticeShown: false,
  toggles: {},
};
