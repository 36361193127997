import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { UserAppEffects } from './effects';
import { reducer, userAppFeatureKey } from './reducer';

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature([UserAppEffects]),
    StoreModule.forFeature(userAppFeatureKey, reducer),
  ],
})
export class UserAppModule {}
