<mat-card class="upload-card" [class.complete]="isCompleted">
  <mat-card-header>
    <div mat-card-avatar>
      <mat-icon>upload_file</mat-icon>
    </div>
    <mat-card-title>{{ uploadCardTitle }}</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="flex-column">
      <p class="flex-row status-text">
        <span>Status:</span>
        <mat-icon
          class="material-icons-outlined completed"
          *ngIf="isCompleted || isNotRequired"
        >
          check_circle
        </mat-icon>
        <mat-icon
          class="material-icons-outlined"
          *ngIf="!isCompleted && !isNotRequired"
        >
          pending
        </mat-icon>
        <span>{{ statusDisplay }}</span>
      </p>

      <p *ngIf="isCompleted" class="flex-row status-text">
        <span>Last Upload: </span
        ><span>{{ uploadTime | date: 'shortDate' }}</span>
      </p>
      <p *ngIf="isCompleted" class="flex-row status-text">
        <span>Respondents: </span><span>{{ data.rowCount }}</span>
      </p>
    </div>
  </mat-card-content>
  <mat-card-actions *ngIf="!hideActions && !isNotRequired">
    <a
      *ngIf="!disabled && !isCompleted"
      mat-flat-button
      color="primary"
      [routerLink]="uploadRouteLink"
      [disabled]="isUploadDisabled"
    >
      <mat-icon>upload</mat-icon><span>Upload Data</span>
    </a>
    <a
      *ngIf="!disabled && isCompleted"
      mat-stroked-button
      [routerLink]="uploadRouteLink"
      color="primary"
    >
      <mat-icon>published_with_changes</mat-icon><span>Replace Data</span>
    </a>
    <button
      mat-stroked-button
      *ngIf="isCompleted"
      (click)="openUploadHistory()"
    >
      <mat-icon>history</mat-icon><span>Upload History</span>
    </button>
  </mat-card-actions>
</mat-card>
