<div [class.standalone]="!isModal">
  <h2 mat-dialog-title>Privacy Statement</h2>
  <mat-dialog-content>
    <p>
      Prep Performance Measures is committed to protecting your privacy and
      developing technology that gives you the most powerful and safe online
      experience. This Statement of Privacy applies to the Prep Performance
      Measures Portal site and governs data collection and usage. By using the
      Prep Performance Measures Portal site, you consent to the data practices
      described in this statement.
    </p>
    <h3>Collection of your Personal Information</h3>
    <p>
      Prep Performance Measures collects personally identifiable information,
      such as your email address, name, home or work address or telephone
      number. Prep Performance Measures also collects anonymous demographic
      information, which is not unique to you, such as your ZIP code, age,
      gender, preferences, interests and favorites.
    </p>
    <p>
      There is also information about your computer hardware and software that
      is automatically collected by Prep Performance Measures Portal. This
      information can include: your IP address, browser type, domain names,
      access times and referring website addresses. This information is used by
      Prep Performance Measures for the operation of the service, to maintain
      quality of the service, and to provide general statistics regarding use of
      the Prep Performance Measures Portal site.
    </p>
    <p>
      Please keep in mind that if you directly disclose personally identifiable
      information or personally sensitive data through Prep Performance Measures
      public message boards, this information may be collected and used by
      others. Note: Prep Performance Measures does not read any of your private
      online communications.
    </p>
    <p>
      Prep Performance Measures encourages you to review the privacy statements
      of Web sites you choose to link to from Prep Performance Measures so that
      you can understand how those Web sites collect, use and share your
      information. Prep Performance Measures is not responsible for the privacy
      statements or other content on Web sites outside of the Prep Performance
      Measures and Prep Performance Measures family of Web sites.
    </p>
    <h3>Use of your Personal Information</h3>
    <p>
      Prep Performance Measures collects and uses your personal information to
      operate the Prep Performance Measures Portal Web site and deliver the
      services you have requested. Prep Performance Measures also uses your
      personally identifiable information to inform you of other products or
      services available from Prep Performance Measures and its affiliates. Prep
      Performance Measures may also contact you via surveys to conduct research
      about your opinion of current services or of potential new services that
      may be offered.
    </p>
    <p>
      Prep Performance Measures does not sell, rent or lease its customer lists
      to third parties. Prep Performance Measures may, from time to time,
      contact you on behalf of external business partners about a particular
      offering that may be of interest to you. In those cases, your unique
      personally identifiable information (e-mail, name, address, telephone
      number) is not transferred to the third party. In addition, Prep
      Performance Measures may share data with trusted partners to help us
      perform statistical analysis, send you email or postal mail, provide
      customer support, or arrange for deliveries. All such third parties are
      prohibited from using your personal information except to provide these
      services to Prep Performance Measures, and they are required to maintain
      the confidentiality of your information.
    </p>
    <p>
      Prep Performance Measures does not use or disclose sensitive personal
      information, such as race, religion, or political affiliations, without
      your explicit consent.
    </p>
    <p>
      Prep Performance Measures Web sites will disclose your personal
      information, without notice, only if required to do so by law or in the
      good faith belief that such action is necessary to: (a) conform to the
      edicts of the law or comply with legal process served on Prep Performance
      Measures Portal or the site; (b) protect and defend the rights or property
      of Prep Performance Measures; and, (c) act under exigent circumstances to
      protect the personal safety of users of Prep Performance Measures, or the
      public.
    </p>
    <h3>Security of your Personal Information</h3>
    <p>
      Prep Performance Measures secures your personal information from
      unauthorized access, use or disclosure. Prep Performance Measures secures
      the personally identifiable information you provide on computer servers in
      a controlled, secure environment, protected from unauthorized access, use
      or disclosure. When personal information (such as a credit card number) is
      transmitted to other Web sites, it is protected through the use of
      encryption, such as the Secure Socket Layer (SSL) protocol.
    </p>
    <h3>Changes to this Statement</h3>
    <p>
      Prep Performance Measures will occasionally update this Statement of
      Privacy to reflect company and customer feedback. Prep Performance
      Measures encourages you to periodically review this Statement to be
      informed of how Prep Performance Measures is protecting your information.
    </p>
    <h3>Contact Information</h3>
    <p>
      Prep Performance Measures welcomes your comments regarding this Statement
      of Privacy. If you believe that Prep Performance Measures has not adhered
      to this Statement, please contact Prep Performance Measures at
      <a href="mailto:PREPPerformanceMeasures@mathematica-mpr.com"
        >PREPPerformanceMeasures&#64;mathematica-mpr.com</a
      >. We will use commercially reasonable efforts to promptly determine and
      remedy the problem.
    </p>
  </mat-dialog-content>
</div>
<mat-dialog-actions *ngIf="isModal">
  <button class="close" color="primary" mat-flat-button mat-dialog-close>
    OK
  </button>
</mat-dialog-actions>
