import {
  ApiError,
  NewProviderPayload,
  Provider,
  ProviderListResponse,
  ProviderSubmission,
  ProviderSubmissionPayload,
  UserAssociationSelection,
} from '@core/models';
import { createAction, props } from '@ngrx/store';

export enum ProviderActionTypes {
  ADD_PROVIDER_FAILURE = '[Provider] Add Provider Failure',
  ADD_PROVIDER_REQUEST = '[Provider] Add Provider Request',
  ADD_PROVIDER_SUCCESS = '[Provider] Add Provider Success',
  CLEAR_PROVIDER_SUBMISSION = '[Provider] Clear Provider Submission State',
  GET_PROVIDER_FAILURE = '[Provider] Get Provider Failure',
  GET_PROVIDER_REQUEST = '[Provider] Get Provider Request',
  GET_PROVIDER_SUCCESS = '[Provider] Get Provider Success',
  GET_PROVIDERS_FAILURE = '[Provider] Get Providers Failure',
  GET_PROVIDERS_REQUEST = '[Provider] Get Providers Request',
  GET_PROVIDERS_SUCCESS = '[Provider] Get Providers Success',
  SET_PROVIDER_ACTIVE_STATUS_FAILURE = '[Provider] Set Provider Active Status Failure',
  SET_PROVIDER_ACTIVE_STATUS_REQUEST = '[Provider] Set Provider Active Status Request',
  SET_PROVIDER_ACTIVE_STATUS_SUCCESS = '[Provider] Set Provider Active Status Success',
  GET_PROVIDER_SUBMISSION_FAILURE = '[Provider] Get provider submission failure',
  GET_PROVIDER_SUBMISSION_REQUEST = '[Provider] Get provider submission request',
  GET_PROVIDER_SUBMISSION_SUCCESS = '[Provider] Get provider submission success',
  SET_PROVIDER_SUBMISSION_FAILURE = '[Provider] Set provider submission failure',
  SET_PROVIDER_SUBMISSION_REQUEST = '[Provider] Set provider submission request',
  SET_PROVIDER_SUBMISSION_SUCCESS = '[Provider] Set provider submission success',
}

export const addProvider = createAction(
  ProviderActionTypes.ADD_PROVIDER_REQUEST,
  props<{ payload: NewProviderPayload }>()
);
export const addProviderFailure = createAction(
  ProviderActionTypes.ADD_PROVIDER_FAILURE,
  props<ApiError>()
);
export const addProviderSuccess = createAction(
  ProviderActionTypes.ADD_PROVIDER_SUCCESS
);

export const clearProviderSubmission = createAction(
  ProviderActionTypes.CLEAR_PROVIDER_SUBMISSION
);

export const getProvider = createAction(
  ProviderActionTypes.GET_PROVIDER_REQUEST,
  props<{ providerId: string }>()
);
export const getProviderFailure = createAction(
  ProviderActionTypes.GET_PROVIDER_FAILURE,
  props<ApiError>()
);
export const getProviderSuccess = createAction(
  ProviderActionTypes.GET_PROVIDER_SUCCESS,
  props<{ provider: Provider }>()
);

export const getProviders = createAction(
  ProviderActionTypes.GET_PROVIDERS_REQUEST,
  props<{ selectedAssociation: UserAssociationSelection }>()
);
export const getProvidersFailure = createAction(
  ProviderActionTypes.GET_PROVIDERS_FAILURE,
  props<ApiError>()
);
export const getProvidersSuccess = createAction(
  ProviderActionTypes.GET_PROVIDERS_SUCCESS,
  props<{ providers: ProviderListResponse }>()
);

export const getProviderSubmission = createAction(
  ProviderActionTypes.GET_PROVIDER_SUBMISSION_REQUEST,
  props<{ providerId: string }>()
);

export const getProviderSubmissionSuccess = createAction(
  ProviderActionTypes.GET_PROVIDER_SUBMISSION_SUCCESS,
  props<{ providerSubmission: ProviderSubmission }>()
);

export const getProviderSubmissionFailure = createAction(
  ProviderActionTypes.GET_PROVIDER_SUBMISSION_FAILURE,
  props<ApiError>()
);

export const setProviderActiveStatus = createAction(
  ProviderActionTypes.SET_PROVIDER_ACTIVE_STATUS_REQUEST,
  props<{ providerId: string; activeStatus: boolean }>()
);
export const setProviderActiveStatusFailure = createAction(
  ProviderActionTypes.SET_PROVIDER_ACTIVE_STATUS_FAILURE,
  props<ApiError>()
);
export const setProviderActiveStatusSuccess = createAction(
  ProviderActionTypes.SET_PROVIDER_ACTIVE_STATUS_SUCCESS,
  props<{ providerId: string; activeStatus: boolean }>()
);

export const updateProviderSubmission = createAction(
  ProviderActionTypes.SET_PROVIDER_SUBMISSION_REQUEST,
  props<{ providerId: string; payload: ProviderSubmissionPayload }>()
);

export const updateProviderSubmissionFailure = createAction(
  ProviderActionTypes.SET_PROVIDER_SUBMISSION_FAILURE,
  props<ApiError>()
);

export const updateProviderSubmissionSuccess = createAction(
  ProviderActionTypes.SET_PROVIDER_SUBMISSION_SUCCESS
);
