import {
  ApiError,
  CommentListResponse,
  ProviderSubmissionStatusProgress,
  GrantSubmissionStatusProgress,
  GrantSubmissionStatusSummaryListResponse,
  GrantSubmissionStatusDetailsListResponse,
} from '@core/models';
import { createAction, props } from '@ngrx/store';

export enum StatusReportTypes {
  GET_COMMENTS_REQUEST = '[Status Report] Get Comments Request',
  GET_COMMENTS_SUCCESS = '[Status Report] Get Comments Success',
  GET_COMMENTS_FAILURE = '[Status Report] Get Comments Failure',
  ADD_COMMENT_REQUEST = '[Status Report] Add Comment Request',
  ADD_COMMENT_SUCCESS = '[Status Report] Add Comment Success',
  ADD_COMMENT_FAILURE = '[Status Report] Add Comment Failure',
  UPDATE_COMMENT_REQUEST = '[Status Report] Update Comment Request',
  UPDATE_COMMENT_SUCCESS = '[Status Report] Update Comment Success',
  UPDATE_COMMENT_FAILURE = '[Status Report] Update Comment Failure',
  DELETE_COMMENT_REQUEST = '[Status Report] Delete Comment Request',
  DELETE_COMMENT_SUCCESS = '[Status Report] Delete Comment Success',
  DELETE_COMMENT_FAILURE = '[Status Report] Delete Comment Failure',
  GET_GRANT_PROGRESS_REQUEST = '[Status Report] Get grant progress request',
  GET_GRANT_PROGRESS_SUCCESS = '[Status Report] Get grant progress success',
  GET_GRANT_PROGRESS_FAILURE = '[Status Report] Get grant progress failure',
  GET_GRANTS_DETAILS_REQUEST = '[Status Report] Get grants details request',
  GET_GRANTS_DETAILS_SUCCESS = '[Status Report] Get grants details success',
  GET_GRANTS_DETAILS_FAILURE = '[Status Report] Get grants details failure',
  GET_GRANTS_SUMMARY_REQUEST = '[Status Report] Get grants summary request',
  GET_GRANTS_SUMMARY_SUCCESS = '[Status Report] Get grants summary success',
  GET_GRANTS_SUMMARY_FAILURE = '[Status Report] Get grants summary failure',
  GET_PROVIDER_PROGRESS_REQUEST = '[Status Report] Get provider progress request',
  GET_PROVIDER_PROGRESS_SUCCESS = '[Status Report] Get provider progress success',
  GET_PROVIDER_PROGRESS_FAILURE = '[Status Report] Get provider progress failure',
}

export const getComments = createAction(
  StatusReportTypes.GET_COMMENTS_REQUEST,
  props<{ grantId: string }>(),
);

export const getCommentsSuccess = createAction(
  StatusReportTypes.GET_COMMENTS_SUCCESS,
  props<{ comments: CommentListResponse }>(),
);

export const getCommentsFailure = createAction(
  StatusReportTypes.GET_COMMENTS_FAILURE,
  props<ApiError>(),
);

export const addComment = createAction(
  StatusReportTypes.ADD_COMMENT_REQUEST,
  props<{ grantId: string; comment: string }>(),
);

export const addCommentSuccess = createAction(
  StatusReportTypes.ADD_COMMENT_SUCCESS,
);

export const addCommentFailure = createAction(
  StatusReportTypes.ADD_COMMENT_FAILURE,
  props<ApiError>(),
);

export const updateComment = createAction(
  StatusReportTypes.UPDATE_COMMENT_REQUEST,
  props<{ grantId: string; comment: string; commentId?: string }>(),
);

export const updateCommentSuccess = createAction(
  StatusReportTypes.UPDATE_COMMENT_SUCCESS,
);

export const updateCommentFailure = createAction(
  StatusReportTypes.UPDATE_COMMENT_FAILURE,
  props<ApiError>(),
);

export const deleteComment = createAction(
  StatusReportTypes.DELETE_COMMENT_REQUEST,
  props<{ grantId: string; commentId: string }>(),
);

export const deleteCommentSuccess = createAction(
  StatusReportTypes.DELETE_COMMENT_SUCCESS,
);

export const deleteCommentFailure = createAction(
  StatusReportTypes.DELETE_COMMENT_FAILURE,
  props<ApiError>(),
);

export const getGrantProgress = createAction(
  StatusReportTypes.GET_GRANT_PROGRESS_REQUEST,
  props<{ grantId: string }>(),
);

export const getGrantProgressSuccess = createAction(
  StatusReportTypes.GET_GRANT_PROGRESS_SUCCESS,
  props<{ grantProgress: GrantSubmissionStatusProgress }>(),
);

export const getGrantProgressFailure = createAction(
  StatusReportTypes.GET_GRANT_PROGRESS_FAILURE,
  props<ApiError>(),
);

export const getGrantsDetails = createAction(
  StatusReportTypes.GET_GRANTS_DETAILS_REQUEST,
  props<{ myGrants?: boolean }>(),
);

export const getGrantsDetailsSuccess = createAction(
  StatusReportTypes.GET_GRANTS_DETAILS_SUCCESS,
  props<{ grantsDetails: GrantSubmissionStatusDetailsListResponse }>(),
);

export const getGrantsDetailsFailure = createAction(
  StatusReportTypes.GET_GRANTS_DETAILS_FAILURE,
  props<ApiError>(),
);

export const getGrantsSummary = createAction(
  StatusReportTypes.GET_GRANTS_SUMMARY_REQUEST,
  props<{ myGrants?: boolean }>(),
);

export const getGrantsSummarySuccess = createAction(
  StatusReportTypes.GET_GRANTS_SUMMARY_SUCCESS,
  props<{ grantsSummary: GrantSubmissionStatusSummaryListResponse }>(),
);

export const getGrantsSummaryFailure = createAction(
  StatusReportTypes.GET_GRANTS_SUMMARY_FAILURE,
  props<ApiError>(),
);

export const getProviderProgress = createAction(
  StatusReportTypes.GET_PROVIDER_PROGRESS_REQUEST,
  props<{ providerId: string }>(),
);

export const getProviderProgressSuccess = createAction(
  StatusReportTypes.GET_PROVIDER_PROGRESS_SUCCESS,
  props<{ providerProgressReport: ProviderSubmissionStatusProgress }>(),
);

export const getProviderProgressFailure = createAction(
  StatusReportTypes.GET_PROVIDER_PROGRESS_FAILURE,
  props<ApiError>(),
);
