import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@core/helpers';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { NewUserComponent } from './new-user/new-user.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SelectAssociationComponent } from './select-association/select-association.component';
import { SetMfaComponent } from './set-mfa/set-mfa.component';
import { SignInComponent } from './signin/signin.component';
import { SignOutComponent } from './signout/signout.component';
import { VerifyComponent } from './verify/verify.component';

const routes: Routes = [
  {
    path: 'change-password',
    component: ChangePasswordComponent,
    data: { title: 'Change Your Password' },
    canActivate: [AuthGuard],
  },
  {
    path: 'new-user',
    component: NewUserComponent,
    data: { title: 'Set New Password' },
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
    data: { title: 'Reset Your Password' },
  },
  {
    path: 'security',
    component: SetMfaComponent,
    data: { title: 'Security Settings' },
  },
  {
    path: 'select',
    component: SelectAssociationComponent,
    data: { title: 'Select' },
  },
  { path: 'signin', component: SignInComponent, data: { title: 'Sign In' } },
  { path: 'signout', component: SignOutComponent, data: { title: 'Sign Out' } },
  {
    path: 'verify',
    component: VerifyComponent,
    data: { title: 'Verify Sign In' },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class AuthRoutingModule {}
