export interface State {
  activeAdminMenu: string;
  activeAdminMenuError: string;
  activeAdminMenuLoading: boolean;
  activeMenu: string;
  activeMenuError: string;
  activeMenuLoading: boolean;
}

export const initialState: State = {
  activeAdminMenu: null,
  activeAdminMenuError: '',
  activeAdminMenuLoading: false,
  activeMenu: null,
  activeMenuError: '',
  activeMenuLoading: false,
};
