import { DialogButton } from './dialog-button.enum';
import { DialogType } from './dialog-type.enum';

export class Dialog {
    dialogContent: string;
    dialogButton: DialogButton;
    dialogIcon: string;
    dialogTemplate: any;
    dialogTemplateData: any;
    dialogTitle: string;
    dialogType: DialogType;
    height: string;
    width: string;

    constructor(args: any) {
        if (!args) {
            return;
        }

        this.dialogButton = args?.dialogButton;
        this.dialogContent = args?.dialogContent;
        this.dialogIcon = args?.dialogIcon;
        this.dialogTemplate = args?.dialogTemplate;
        this.dialogTemplateData = args?.dialogTemplateData;
        this.dialogTitle = args?.dialogTitle;
        this.dialogType = args?.dialogType;
        this.height = args?.height;
        this.width = args?.width;
    }
}
