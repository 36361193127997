import { ActionReducer, INIT, MetaReducer } from '@ngrx/store';

import { AuthenticationActions } from '@app/store/authentication';
import { State as AuthenticationState } from '@app/store/authentication/state';

export const signOut =
  (
    reducer: ActionReducer<AuthenticationState>
  ): ActionReducer<AuthenticationState> =>
  (state, action) => {
    if (action != null && action.type === AuthenticationActions.signOut.type) {
      return reducer(undefined, { type: INIT });
    }
    return reducer(state, action);
  };

export const metaReducers: MetaReducer[] = [signOut];
