import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const greaterThanValidator =
  // ----------
  // fieldNames: string[]
  // * the names of the form fields to be compared,
  // * with the expected larger value first, smaller value second
  // ----------
  // errorName: string
  // * the key string for the returned error
  // ----------


    (fieldNames: string[], errorName: string): ValidatorFn =>
    (control: AbstractControl): ValidationErrors | null => {
      const large = convertToNumber(control.get(fieldNames[0]).value);
      const small = convertToNumber(control.get(fieldNames[1]).value);

      const invalid = !!(large > small);

      return invalid ? { [errorName]: true } : null;
    };

const convertToNumber = (input: any): Date | number => {
  if (typeof input === 'string') {
    return parseInt(input, 10);
  }
  return input;
};

export const fieldsDoNotMatch =
  // ----------
  // fieldNames: string[]
  // * the names of the form fields to be compared,
  // * with the expected larger value first, smaller value second
  // ----------
  // errorName: string
  // * the key string for the returned error
  // ----------


    (fieldNames: string[], errorName: string): ValidatorFn =>
    (control: AbstractControl): ValidationErrors | null => {
      const first = control.get(fieldNames[0]).value;
      const second = control.get(fieldNames[1]).value;

      const invalid = first.length && second.length && first === second;

      return invalid ? { [errorName]: true } : null;
    };
