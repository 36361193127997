import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { interval, Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  templateUrl: './idle-timeout.component.html',
  styleUrls: ['./idle-timeout.component.scss'],
})
export class IdleTimeoutDialogComponent implements OnDestroy, OnInit {
  public timeRemaining: number;
  private timeOut = 60;
  private interval$: Observable<number>;
  private countDown$: Observable<number>;
  private destroyed$ = new Subject<boolean>();
  private worker: Worker;

  constructor(
    private dialog: MatDialogRef<IdleTimeoutDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {
    this.timeRemaining = this.timeOut;

    // instantiate web worker to handle background timer, otherwise
    // fallback to main thread timer.
    if (typeof Worker !== 'undefined') {
      this.worker = new Worker(
        new URL('./idle-timeout.worker', import.meta.url)
      );
    } else {
      this.interval$ = interval(1000);
      this.countDown$ = this.interval$.pipe(
        take(this.timeOut),
        takeUntil(this.destroyed$)
      );
    }
  }

  ngOnInit(): void {
    if (this.worker) {
      this.worker.onmessage = ({ data }) => {
        if (data === 1) {
          this.close();
        }
        this.timeRemaining = data;
      };
      this.worker.postMessage(this.timeOut);
    } else {
      this.countDown$.subscribe((value) => {
        this.timeRemaining = this.timeOut - value;

        if (this.timeRemaining === 1) {
          this.close();
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.worker?.terminate();
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public close(): void {
    this.worker?.terminate();
    this.dialog.close(false);
  }

  public submit(): void {
    this.worker?.terminate();
    this.dialog.close(true);
  }
}
