import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthService, IdleTimerService } from '@core/services';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private auth: AuthService, private idle: IdleTimerService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.auth.isSignedIn()) {
      // Restart the timer on a request if not signing in or out
      if (!this.auth.isSigningIn() && !this.auth.isSigningOut()) {
        this.idle.resetTimer();
      }

      // Clone the request and add the authorization header
      request = request.clone({
        setHeaders: {
          Authorization: `${this.auth.getAccessToken()}`,
        },
      });
    }

    return next.handle(request);
  }
}
