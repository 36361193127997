import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  AdminGuard,
  AssociationSelectionGuard,
  AuthGuard,
  SupportStaffGuard,
} from '@core/helpers';
import {
  MainComponent,
  PrivacyStatementComponent,
  SecurityPrivacyComponent,
  TermsStatementComponent,
} from '@core/ui';
import { AppMenu } from './shared/enums';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'security',
    component: SecurityPrivacyComponent,
  },
  {
    path: 'privacy',
    component: PrivacyStatementComponent,
  },
  {
    path: 'terms',
    component: TermsStatementComponent,
  },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuard, AssociationSelectionGuard],
    canActivateChild: [SupportStaffGuard],
    children: [
      {
        path: 'account',
        loadChildren: () =>
          import('./account/account.module').then((m) => m.AccountModule),
        data: { allowSupportStaff: true },
      },
      {
        path: 'admin',
        loadChildren: () =>
          import('./admin/admin.module').then((m) => m.AdminModule),
        canActivate: [AdminGuard],
        data: { allowSupportStaff: true },
      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        path: 'grant',
        loadChildren: () =>
          import('./grantees/grantees.module').then((m) => m.GranteesModule),
      },
      {
        path: 'home',
        loadChildren: () =>
          import('./myprep/myprep.module').then((m) => m.MyPrepModule),
      },
      {
        path: 'help',
        loadChildren: () =>
          import('./help/help.module').then((m) => m.HelpModule),
      },
      {
        path: 'my-grants',
        loadChildren: () =>
          import('./my-grants/my-grants.module').then((m) => m.MyGrantsModule),
      },
      {
        path: 'participant-surveys',
        loadChildren: () =>
          import('./participant-surveys/participant-surveys.module').then(
            (m) => m.ParticipantSurveysModule,
          ),
      },
      {
        path: 'program-models',
        loadChildren: () =>
          import('./program-models/program-models.module').then(
            (m) => m.ProgramModelsModule,
          ),
      },
      {
        path: 'providers',
        loadChildren: () =>
          import('./providers/providers.module').then((m) => m.ProvidersModule),
      },
      {
        path: 'security-privacy',
        component: SecurityPrivacyComponent,
      },
      {
        path: 'status',
        loadChildren: () =>
          import('./status-report/status-report.module').then(
            (m) => m.StatusReportModule,
          ),
      },
      {
        path: 'users',
        loadChildren: () =>
          import('./users/users.module').then((m) => m.UsersModule),
        data: { appMenu: AppMenu.Users },
      },
    ],
  },
  { path: '**', redirectTo: 'home' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      paramsInheritanceStrategy: 'always',
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
