import { Component, Input, OnInit } from '@angular/core';
import { checkPasswordCriteria } from '@app/shared/utilities';

@Component({
  selector: 'app-password-error',
  templateUrl: './password-error.component.html',
  styleUrls: ['./password-error.component.scss']
})
export class PasswordErrorComponent implements OnInit {

  @Input()
  newPassword: string;

  constructor() { }

  ngOnInit(): void {
  }

  public passwordHasCapital(): boolean {
    return checkPasswordCriteria(this.newPassword).minCapitals;
  }

  public passwordHasDigit(): boolean {
    return checkPasswordCriteria(this.newPassword).minNumbers
  }
  
  public passwordHasSpecialCharacter(): boolean {
    return checkPasswordCriteria(this.newPassword).specialChar
  }

  public passwordMinLength(): boolean {
    return checkPasswordCriteria(this.newPassword).size
  }
  
  public passwordEntered(): boolean {
    return this.newPassword.length > 0;
  }

}
