export enum AppMenu {
  Account = 'account',
  Administration = 'settings',
  Dashboard = 'dashboard',
  Grant = 'grant',
  Home = 'home',
  Help = 'help',
  MyGrants = 'my-grants',
  ParticipantSurveys = 'participant-surveys',
  ProgramModels = 'program-models',
  Providers = 'providers',
  StatusReport = 'status-grant',
  StatusReportAll = 'status-all',
  Users = 'users',
}
