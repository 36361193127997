import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CreateSubmissionPeriodPayload,
  SubmissionPeriod,
} from '@core/models/submission-period.interface';
import { Observable } from 'rxjs';

import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class SubmissionPeriodService extends BaseService {
  private submissionPeriods = 'submission-periods';

  constructor(private http: HttpClient) {
    super();
  }

  public getSubmissionPeriods(
    active?: string,
  ): Observable<SubmissionPeriod | SubmissionPeriod[]> {
    const activeSubPath = active ? `/${active}` : '';

    return this.http.get<SubmissionPeriod>(
      `${this.baseURL}/${this.submissionPeriods}${activeSubPath}`,
    );
  }

  public updateSubmissionPeriod(submissionClosed: boolean): Observable<null> {
    return this.http.put<null>(
      `${this.baseURL}/${this.submissionPeriods}/active`,
      { submissionClosed },
    );
  }

  public createSubmissionPeriod(
    payload: CreateSubmissionPeriodPayload,
  ): Observable<null> {
    return this.http.post<null>(
      `${this.baseURL}/${this.submissionPeriods}`,
      payload,
    );
  }
}
