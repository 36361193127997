import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UploadTypes } from '@app/shared/enums';
import { GrantUpload } from '@core/models';
import { PanelService } from '@core/services';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs';
import { UploadHistoryComponent } from '../../../participant-surveys/upload-history/upload-history.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-survey-upload-card',
  templateUrl: './survey-upload-card.component.html',
  styleUrls: ['./survey-upload-card.component.scss'],
})
export class SurveyUploadCardComponent {
  @Input() public uploadType: UploadTypes;
  @Input() public data: GrantUpload;
  @Input() public disabled = false;
  @Input() public hideActions = false;
  /** Indicates no action required */
  @Input() public isNotRequired = false;
  @Input() public uploadRouteLink;

  constructor(
    private panelService: PanelService,
    private store$: Store,
  ) {}

  get uploadCardTitle(): string {
    return this.uploadType === UploadTypes.ENTRY
      ? 'Entry Survey'
      : 'Exit Survey';
  }

  get isUploadDisabled(): boolean {
    return !this.data;
  }

  get isCompleted(): boolean {
    return this.data?.completed;
  }

  // get uploadRouterLink(): string {
  //   return this.uploadType === UploadTypes.ENTRY ? 'entry' : 'exit';
  // }

  get statusDisplay(): string {
    return this.isNotRequired
      ? 'Not Required'
      : this.isCompleted
        ? 'Completed'
        : 'Not Started';
  }

  get uploadTime(): Date {
    return this.data.uploadTime;
  }

  public openUploadHistory(): void {
    this.panelService
      .open<boolean>(UploadHistoryComponent, {
        ...this.data,
      })
      .afterClosed()
      .pipe(filter((status) => !!status))
      .subscribe();
  }
}
