import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  private activeMenu = '';
  private activeAdminMenu = '';

  constructor() {}

  public setActiveAdminMenu(id: string): Observable<string> {
    this.activeAdminMenu = id;
    return of(this.activeAdminMenu);
  }

  public setActiveMenu(id: string): Observable<string> {
    this.activeMenu = id;
    return of(this.activeMenu);
  }
}
