import { Location } from '@angular/common';
import { Component, ElementRef, Input } from '@angular/core';
import { FormNavigationMenu } from '@core/models';

export interface DModel {
  [key: string]: ElementRef;
}

@Component({
  selector: 'app-form-navigation',
  templateUrl: './form-navigation.component.html',
  styleUrls: ['./form-navigation.component.scss'],
})
export class FormNavigationComponent {
  @Input() menu: FormNavigationMenu;
  @Input() title = '';

  public activeIndex = 0;
  private appContent = document.querySelector('#content');

  constructor(private location: Location) {}

  public setActive(event: Event, index: number): void {
    event.preventDefault();
    this.activeIndex = index;
  }

  public scrollTo(element: string, index: number, $event?: Event): void {
    $event?.preventDefault();

    if (!this.appContent) {
      return;
    }

    const scrollElement = document.querySelector(`#${element}`);
    const currentScrollTop = this.appContent?.scrollTop;
    scrollElement.scrollIntoView({ behavior: 'smooth' });
    const newScrollTop = this.appContent?.scrollTop;

    const currentPath = this.location.path(true);
    const newPath = [this.location.path(), element].join('#');

    this.location.replaceState(currentPath, '', {
      path: currentPath,
      scrollTop: currentScrollTop,
    });

    this.location.go(newPath, '', {
      path: newPath,
      scrollTop: newScrollTop,
    });

    this.activeIndex = index;
  }

  public scrollToTop(event: Event): void {
    event?.preventDefault();
    this.appContent?.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
