import { SubmissionPeriod } from '@core/models/submission-period.interface';
import { ApiError } from '@core/models';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from './state';
import { submissionPeriodFeatureKey } from './reducer';

const activeSubmissionPeriod = (state: State): SubmissionPeriod =>
  state.activeSubmissionPeriod;
const getActiveSubmissionPeriodLoading = (state: State): boolean =>
  state.getActiveSubmissionPeriodLoading;
const getActiveSubmissionPeriodFailure = (state: State): ApiError =>
  state.getActiveSubmissionPeriodFailure;
const allSubmissionPeriods = (state: State): SubmissionPeriod[] =>
  state.submissionPeriods;
const getAllSubmissionPeriodsLoading = (state: State): boolean =>
  state.getAllSubmissionPeriodsLoading;
const getAllSubmissionPeriodsFailure = (state: State): ApiError =>
  state.getAllSubmissionPeriodsFailure;
const updateSubmissionPeriodLoading = (state: State): boolean =>
  state.updateSubmissionPeriodLoading;
const updateSubmissionPeriodFailure = (state: State): ApiError =>
  state.updateSubmissionPeriodFailure;
const createSubmissionPeriodLoading = (state: State): boolean =>
  state.createSubmissionPeriodLoading;
const createSubmissionPeriodFailure = (state: State): ApiError =>
  state.createSubmissionPeriodFailure;

export const submissionPeriodState = createFeatureSelector<State>(
  submissionPeriodFeatureKey,
);

export const selectActiveSubmissionPeriod = createSelector(
  submissionPeriodState,
  activeSubmissionPeriod,
);
export const selectAllSubmissionPeriods = createSelector(
  submissionPeriodState,
  allSubmissionPeriods,
);
export const selectGetActiveSubmissionPeriodLoading = createSelector(
  submissionPeriodState,
  getActiveSubmissionPeriodLoading,
);
export const selectGetActiveSubmissionPeriodFailure = createSelector(
  submissionPeriodState,
  getActiveSubmissionPeriodFailure,
);
export const selectGetAllSubmissionPeriodLoading = createSelector(
  submissionPeriodState,
  getAllSubmissionPeriodsLoading,
);
export const selectGetAllSubmissionPeriodFailure = createSelector(
  submissionPeriodState,
  getAllSubmissionPeriodsFailure,
);
export const selectUpdateSubmissionPeriodLoading = createSelector(
  submissionPeriodState,
  updateSubmissionPeriodLoading,
);
export const selectUpdateSubmissionPeriodFailure = createSelector(
  submissionPeriodState,
  updateSubmissionPeriodFailure,
);
export const selectCreateSubmissionPeriodLoading = createSelector(
  submissionPeriodState,
  createSubmissionPeriodLoading,
);
export const selectCreateSubmissionPeriodFailure = createSelector(
  submissionPeriodState,
  createSubmissionPeriodFailure,
);
