import { Action, createReducer, on } from '@ngrx/store';
import {
  setActiveAdminMenu,
  setActiveAdminMenuFailure,
  setActiveAdminMenuSuccess,
  setActiveMenu,
  setActiveMenuFailure,
  setActiveMenuSuccess,
} from './actions';
import { initialState, State } from './state';

export const menuFeatureKey = 'menu';

const resourcesReducer = createReducer(
  initialState,
  on(setActiveAdminMenu, (state) => ({
    ...state,
    activeAdminMenuLoading: true,
  })),
  on(setActiveAdminMenuFailure, (state, { message }) => ({
    ...state,
    resources: null,
    activeAdminMenuError: message,
    activeAdminMenuLoading: false,
  })),
  on(setActiveAdminMenuSuccess, (state, { activeAdminMenu }) => ({
    ...state,
    activeAdminMenu,
    activeAdminMenuLoading: false,
  })),
  on(setActiveMenu, (state) => ({
    ...state,
    activeMenuLoading: true,
  })),
  on(setActiveMenuFailure, (state, { message }) => ({
    ...state,
    resources: null,
    activeMenuError: message,
    activeMenuLoading: false,
  })),
  on(setActiveMenuSuccess, (state, { activeMenu }) => ({
    ...state,
    activeMenu,
    activeMenuLoading: false,
  }))
);

export const reducer = (state: State | undefined, action: Action) =>
  resourcesReducer(state, action);
