import { Injectable } from '@angular/core';
import { DashboardService } from '@core/services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { handleError } from '@core/helpers';
import {
  getDashboardList,
  getDashboardListSuccess,
  getDashboardListFailure,
  getDashboardUrl,
  getDashboardUrlSuccess,
  getDashboardUrlFailure,
  resetDashboardUrl,
  resetDashboardUrlSuccess,
} from './actions';
import { of } from 'rxjs';

@Injectable()
export class DashboardEffects {
  getDashboardList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getDashboardList),
      switchMap(({ grantId }) =>
        this.dashboardService.getDashboardList(grantId).pipe(
          map((dashboardList) => getDashboardListSuccess({ dashboardList })),
          catchError((error) => handleError(error, getDashboardListFailure)),
        ),
      ),
    ),
  );

  getDashboardUrl$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getDashboardUrl),
      switchMap(({ dashboard, grantId }) =>
        this.dashboardService.getUrl(dashboard.id, grantId).pipe(
          map((dashboardUrl) =>
            getDashboardUrlSuccess({ dashboard, dashboardUrl }),
          ),
          catchError((error) => handleError(error, getDashboardUrlFailure)),
        ),
      ),
    ),
  );

  resetDashboardUrl$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resetDashboardUrl),
      switchMap(() =>
        of(1).pipe(
          map(() => resetDashboardUrlSuccess()),
          catchError((error) => handleError(error, getDashboardUrlFailure)),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private dashboardService: DashboardService,
  ) {}
}
