import { Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import {
  NotificationTypes,
  Resource,
  UserAssociationCategory,
} from '@app/shared/enums';
import { BaseDialogComponent } from '@app/shared/ui/dialogs';
import {
  AppStoreState,
  GrantActions,
  GrantSelectors,
  NotificationActions,
  ProviderActions,
  ProviderSelectors,
  ResourceAccessSelectors,
  UserActions,
  UserSelectors,
} from '@app/store';
import {
  AssignableRole,
  Grant,
  Provider,
  ResourceAccessObject,
  RoleName,
  UserAssociationSelection,
  UserPayload,
} from '@core/models';
import { ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, startWith, takeUntil, tap } from 'rxjs/operators';

@Component({
  templateUrl: './users-add-panel.component.html',
  styleUrls: ['./users-add-panel.component.scss'],
})
export class UsersAddPanelComponent
  extends BaseDialogComponent
  implements OnInit
{
  public assignableRoles$: Observable<AssignableRole[]>;
  public addUserForm: UntypedFormGroup;
  public addUserLoading$: Observable<boolean>;
  public grants: Grant[];
  public providers: Provider[] = [];
  public loading$: Observable<boolean>;
  public providers$: Observable<Provider[]>;
  public selected = -1;

  private assignableRolesLoading$: Observable<boolean>;
  private grants$: Observable<Grant[]>;
  private grantsLoading$: Observable<boolean>;
  private newUserName = '';
  private providersLoading = false;
  private providersLoading$: Observable<boolean>;
  private resourceAccess: ResourceAccessObject;
  private resourceAccess$: Observable<ResourceAccessObject>;
  private roleIdNameLookup: Map<string, RoleName>;
  private selectedRoleName: RoleName;

  constructor(
    protected dialogRef: MatDialogRef<UsersAddPanelComponent>,
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) private panelData: UserAssociationSelection,
    private store$: Store<AppStoreState.State>,
  ) {
    super();
    this.addUserLoading$ = this.store$.select(
      UserSelectors.selectCreateUserLoading,
    );
    this.grants$ = this.store$.select(GrantSelectors.selectGrants);
    this.grantsLoading$ = this.store$.select(
      GrantSelectors.selectGrantsLoading,
    );
    this.providers$ = this.store$.select(ProviderSelectors.selectProviders);
    this.providersLoading$ = this.store$
      .select(ProviderSelectors.selectProvidersLoading)
      .pipe(tap((loading) => (this.providersLoading = loading)));
    this.resourceAccess$ = this.store$.select(
      ResourceAccessSelectors.selectResourceAccess,
    );
    this.assignableRoles$ = this.store$
      .select(UserSelectors.selectAssignableRoles)
      .pipe(
        tap((roles) => {
          this.roleIdNameLookup = new Map(
            roles.map((role) => [role.id, role.name as RoleName]),
          );
        }),
      );
    this.assignableRolesLoading$ = this.store$.select(
      UserSelectors.selectAssignableRolesLoading,
    );
    this.actions$
      .pipe(
        ofType(
          UserActions.createUserFailure,
          UserActions.getAssignableRolesFailure,
          GrantActions.getGrantsFailure,
          ProviderActions.getProvidersFailure,
        ),
        takeUntil(this.destroyed$),
        tap(({ message }) => {
          this.store$.dispatch(
            NotificationActions.add({
              notificationType: NotificationTypes.DANGER,
              notificationText: message,
            }),
          );
        }),
      )
      .subscribe();

    this.actions$
      .pipe(
        ofType(UserActions.createUserSuccess),
        takeUntil(this.destroyed$),
        tap(() => {
          this.store$.dispatch(
            NotificationActions.add({
              notificationType: NotificationTypes.SUCCESS,
              notificationText: `User ${this.newUserName} added.`,
            }),
          );
          this.close(true);
        }),
      )
      .subscribe();
  }

  ngOnInit(): void {
    this.loading$ = combineLatest([
      this.assignableRolesLoading$,
      this.grantsLoading$,
      this.providersLoading$,
    ]).pipe(
      map((loading) => loading.some((l) => l)),
      takeUntil(this.destroyed$),
    );

    this.resourceAccess$
      .pipe(
        filter((resourceAccess) => !!resourceAccess),
        takeUntil(this.destroyed$),
      )
      .subscribe((resourceAccess) => {
        this.resourceAccess = resourceAccess;
        if (this.showGrantSelection()) {
          this.store$.dispatch(GrantActions.getGrants());
        }
      });

    this.grants$
      .pipe(
        filter((grants) => !!grants),
        takeUntil(this.destroyed$),
      )
      .subscribe((grants) => {
        this.grants = [...grants];
      });

    this.createForm();
    this.fetchData();

    this.providers$
      .pipe(
        filter((providers) => !!providers),
        takeUntil(this.destroyed$),
      )
      .subscribe((providers) => {
        const providerControl = this.addUserForm.get('providerId');

        this.providers = [...providers.filter((provider) => provider.active)];
        if (this.isProviderControlEnabled()) {
          this.enableControl(providerControl);
          providerControl.updateValueAndValidity();
        }
      });

    const providerControl = this.addUserForm.get('providerId');
    this.providers$ = providerControl.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value || '')),
    );
  }

  public addNewUser(): void {
    const grantId: string | undefined = !this.showGrantSelection()
      ? this.panelData.id
      : this.addUserForm.get('grantId').value;
    const payload: UserPayload = {
      ...this.addUserForm.value,
      grantId,
    };
    this.newUserName = `${this.addUserForm.get('nameFirst').value} ${
      this.addUserForm.get('nameLast').value
    }`;
    this.store$.dispatch(NotificationActions.reset());
    this.store$.dispatch(UserActions.createUser({ payload }));
  }

  public close(saved?: boolean): void {
    this.dialogRef.close(saved);
  }

  public onGrantChange(event: MatSelectChange): void {
    if (this.selectedRoleName === 'Provider') {
      this.store$.dispatch(
        ProviderActions.getProviders({
          selectedAssociation: {
            id: event.value,
            selectionType: UserAssociationCategory.GRANTS,
          },
        }),
      );
    }
  }

  public onRoleChange(event: MatSelectChange): void {
    const grantControl = this.addUserForm.get('grantId');
    const providerControl = this.addUserForm.get('providerId');

    this.disableControl(grantControl);
    this.disableControl(providerControl);

    this.selectedRoleName = this.roleIdNameLookup.get(event.value);

    if (
      this.showGrantSelection() &&
      (this.selectedRoleName === 'Grantee' ||
        this.selectedRoleName === 'Provider') &&
      this.grants.length !== 0
    ) {
      this.enableControl(grantControl);
    } else if (this.isProviderControlEnabled()) {
      this.enableControl(providerControl);
    }

    grantControl.updateValueAndValidity();
    providerControl.updateValueAndValidity();
  }

  /** Show grant selection for Admin user. */
  public showGrantSelection(): boolean {
    return !!this.resourceAccess[Resource.AddAllUsersInSystem];
  }

  public showProviderMessage(): boolean {
    if (
      !this.providersLoading &&
      this.providers.length === 0 &&
      this.selectedRoleName === 'Provider' &&
      (!this.showGrantSelection() || !!this.addUserForm.get('grantId').value)
    ) {
      return true;
    } else {
      return false;
    }
  }

  /** Show providers selection for Admin and Grantee users.  */
  public showProviderSelection(): boolean {
    return !!this.resourceAccess[Resource.AddUsers];
  }

  public displayFn(providerId: string): string {
    if (providerId != null) {
      return this.providers.find((provider) => provider.id === providerId).name;
    }
  }

  private createForm(): void {
    this.addUserForm = this.fb.group({
      nameFirst: ['', [Validators.required]],
      nameLast: ['', [Validators.required]],
      email: ['', [Validators.required]],
      roleId: ['', [Validators.required]],
      grantId: [{ value: null, disabled: true }],
      providerId: [{ value: null, disabled: true }],
    });
  }

  private fetchData(): void {
    this.store$.dispatch(
      UserActions.getAssignableRoles({ grantId: this.panelData.id }),
    );

    // Get providers list on load (for grantee user)
    if (this.panelData.id) {
      this.store$.dispatch(
        ProviderActions.getProviders({ selectedAssociation: this.panelData }),
      );
    }
  }

  private enableControl(control: AbstractControl): void {
    control.enable();
    control.setValidators(Validators.required);
  }

  private disableControl(control: AbstractControl): void {
    control.clearValidators();
    control.setErrors(null);
    control.setValue(null);
    control.disable();
  }

  private isProviderControlEnabled(): boolean {
    return this.selectedRoleName === 'Provider' && this.providers.length !== 0;
  }

  private _filter(name: string): Provider[] {
    const filterValue = name.toLowerCase();
    return this.providers.filter((option) =>
      option.name.toLowerCase().includes(filterValue),
    );
  }
}
