import { UntypedFormControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

export class CustomTelehealthErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null): boolean {
    const invalidControl = !!control?.invalid;
    const invalidParent = !!control?.parent?.errors?.maxSitesExceeded;

    return invalidControl || invalidParent;
  }
}

export class CustomDateErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null): boolean {
    const invalidControl = !!(
      control?.errors && !!(control?.dirty || control?.touched)
    );
    const invalidParent = !!control?.parent?.errors?.invalidDateRange;

    return invalidControl || invalidParent;
  }
}

export class CustomErrorStateMatcher implements ErrorStateMatcher {
  constructor(private errorName: string) {}
  isErrorState(control: UntypedFormControl | null): boolean {
    const invalidControl = !!(
      control?.errors && !!(control?.dirty || control?.touched)
    );
    const invalidParent = !!control?.parent?.errors?.[this.errorName];

    return invalidControl || invalidParent;
  }
}
