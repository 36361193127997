import * as ResourceAccessActions from './actions';
import * as ResourceAccessEffects from './effects';
import * as ResourceAccessReducer from './reducer';
import * as ResourceAccessSelectors from './selectors';
import * as ResourceAccessState from './state';

export * from './resources-access.module';
export {
  ResourceAccessActions,
  ResourceAccessEffects,
  ResourceAccessReducer,
  ResourceAccessSelectors,
  ResourceAccessState,
};
