import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription, timer } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IdleTimerService {
  private seconds = 0;
  private count = 0;
  private isExpectingWorkerResponse = false;
  private timeoutId: NodeJS.Timeout;
  private timerSubscription: Subscription;
  private timer: Observable<number>;
  private resetOnTrigger = false;
  private timerExpired: Subject<number> = new Subject<number>();
  private worker: Worker;

  constructor() {}

  /**
   * Uses web worker to manage timer. If for some reason web worker
   * is not supported, fallback to main thread timer implementation.
   */
  public startTimer(seconds: number): Observable<number> {
    this.seconds = seconds;

    this.startWorker();
    if (this.worker) {
      this.isExpectingWorkerResponse = true;
      this.worker.postMessage({
        command: 'startTimer',
        timeoutId: this.timeoutId,
        timeoutLength: seconds * 1000,
      });
    } else if (
      !this.timerSubscription ||
      (this.timerSubscription && this.timerSubscription.closed)
    ) {
      this.timer = timer(seconds * 1000);
      this.timerSubscription = this.timer.subscribe((n) => {
        this.timerComplete(n);
      });
    }

    return this.timerExpired;
  }

  public stopTimer(): void {
    if (this.worker) {
      this.worker.terminate();
      this.worker = null;
    } else {
      this.timerSubscription?.unsubscribe();
    }
  }

  public resetTimer(): void {
    // Skip while a previous web worker operation is in progress. There's
    // no point in going further since we know the timer state is already
    // changing.
    // Note: This is to prevent a race condition where the wrong timeout id
    // is used since web worker responses are asynchronous. This is noticeable
    // when multiple calls are made in quick succession.
    if (this.seconds === 0 || this.isExpectingWorkerResponse) {
      return;
    }

    if (this.worker) {
      this.isExpectingWorkerResponse = true;
      this.worker.postMessage({
        command: 'resetTimer',
        timeoutId: this.timeoutId,
        timeoutLength: this.seconds * 1000,
      });
    } else if (this.timerSubscription) {
      this.stopTimer();
      this.startTimer(this.seconds);
    }
  }

  private timerComplete(n: number): void {
    this.timerExpired.next(++this.count);

    if (this.resetOnTrigger) {
      this.startTimer(this.seconds);
    }
  }

  private startWorker(): void {
    if (!this.worker && typeof Worker !== 'undefined') {
      this.isExpectingWorkerResponse = false;

      // Create a new worker
      this.worker = new Worker(
        new URL('../helpers/idle-timer.worker', import.meta.url)
      );
      this.worker.onmessage = ({ data }) => {
        const { message, command, timeoutId } = data;

        this.isExpectingWorkerResponse = false;

        switch (command) {
          case 'timerStarted':
            this.timeoutId = timeoutId;
            break;
          case 'endTimer':
            this.timerComplete(0);
            break;
          case 'timerReset':
            this.timeoutId = timeoutId;
            break;
          default:
            console.error('[idle-timer] No matching command');
        }
      };
    } else {
      console.error('Web workers are not supported in this environment');
    }
  }
}
