/* eslint-disable @typescript-eslint/naming-convention */
import { ChangePasswordPayload } from '@app/auth/auth.models';
import {
  Account,
  AccountApiResponse,
  AccountUpdatePayload,
  ApiError,
  UserAssociation,
  UserAssociations,
  UserAssociationSelection,
} from '@core/models';
import { CognitoErrorResponse, CognitoUserData } from '@core/services';
import { createAction, props } from '@ngrx/store';

export enum AccountActionTypes {
  CHANGE_PASSWORD = '[Authentication] Change Password Request',
  CHANGE_PASSWORD_FAILURE = '[Authentication] Change Password Failure',
  CHANGE_PASSWORD_SUCCESS = '[Authentication] Change Password Success',
  GET_ACCOUNT_FAILURE = '[Account] Get Account Failure',
  GET_ACCOUNT_REQUEST = '[Account] Get Account Request',
  GET_ACCOUNT_SUCCESS = '[Account] Get Account Success',
  GET_ASSOCIATIONS_FAILURE = '[Account] Get Associations Failure',
  GET_ASSOCIATIONS_REQUEST = '[Account] Get Associations Request',
  GET_ASSOCIATIONS_SUCCESS = '[Account] Get Associations Success',
  GET_ASSOCIATION_DETAILS_FAILURE = '[Account] Get Association Details Failure',
  GET_ASSOCIATION_DETAILS_REQUEST = '[Account] Get Association Details Request',
  GET_ASSOCIATION_DETAILS_SUCCESS = '[Account] Get Association Details Success',
  GET_SELECTED_ASSOCIATION_FAILURE = '[Account] Get Selected Association Failure',
  GET_SELECTED_ASSOCIATION_REQUEST = '[Account] Get Selected Association Request',
  GET_SELECTED_ASSOCIATION_SUCCESS = '[Account] Get Selected Association Success',
  GET_USER_DATA_FAILURE = '[Account] Get User Data Failure',
  GET_USER_DATA_REQUEST = '[Account] Get User Data Request',
  GET_USER_DATA_SUCCESS = '[Account] Get User Data Success',
  GET_USER_DETAILS_FAILURE = '[Account] Get User Details Failure',
  GET_USER_DETAILS_REQUEST = '[Account] Get User Details Request',
  GET_USER_DETAILS_SUCCESS = '[Account] Get User Details Success',
  SET_SELECTED_ASSOCIATION_FAILURE = '[Grant] Set Selected Association Failure',
  SET_SELECTED_ASSOCIATION_REQUEST = '[Grant] Set Selected Association Request',
  SET_SELECTED_ASSOCIATION_SUCCESS = '[Grant] Set Selected Association Success',
  UPDATE_ACCOUNT_FAILURE = '[Account] Update Account Failure',
  UPDATE_ACCOUNT_REQUEST = '[Account] Update Account Request',
  UPDATE_ACCOUNT_SUCCESS = '[Account] Update Account Success',
}

export const changePassword = createAction(
  AccountActionTypes.CHANGE_PASSWORD,
  props<{ payload: ChangePasswordPayload }>()
);
export const changePasswordFailure = createAction(
  AccountActionTypes.CHANGE_PASSWORD_FAILURE,
  props<ApiError>()
);
export const changePasswordSuccess = createAction(
  AccountActionTypes.CHANGE_PASSWORD_SUCCESS
);

export const getAccount = createAction(AccountActionTypes.GET_ACCOUNT_REQUEST);
export const getAccountFailure = createAction(
  AccountActionTypes.GET_ACCOUNT_FAILURE,
  props<ApiError>()
);
export const getAccountSuccess = createAction(
  AccountActionTypes.GET_ACCOUNT_SUCCESS,
  props<{ account: AccountApiResponse }>()
);

export const getAssociations = createAction(
  AccountActionTypes.GET_ASSOCIATIONS_REQUEST
);
export const getAssociationsFailure = createAction(
  AccountActionTypes.GET_ASSOCIATIONS_FAILURE,
  props<ApiError>()
);
export const getAssociationsSuccess = createAction(
  AccountActionTypes.GET_ASSOCIATIONS_SUCCESS,
  props<{ associations: UserAssociations }>()
);

export const getAssociationDetails = createAction(
  AccountActionTypes.GET_ASSOCIATION_DETAILS_REQUEST,
  props<{ selectedAssociation: UserAssociationSelection }>()
);
export const getAssociationDetailsFailure = createAction(
  AccountActionTypes.GET_ASSOCIATION_DETAILS_FAILURE,
  props<ApiError>()
);
export const getAssociationDetailsSuccess = createAction(
  AccountActionTypes.GET_ASSOCIATION_DETAILS_SUCCESS,
  props<{ associationDetails: UserAssociation }>()
);

export const getSelectedAssociation = createAction(
  AccountActionTypes.GET_SELECTED_ASSOCIATION_REQUEST
);
export const getSelectedAssociationFailure = createAction(
  AccountActionTypes.GET_SELECTED_ASSOCIATION_FAILURE,
  props<ApiError>()
);
export const getSelectedAssociationSuccess = createAction(
  AccountActionTypes.GET_SELECTED_ASSOCIATION_SUCCESS,
  props<{ selectedAssociation: UserAssociationSelection }>()
);

export const setSelectedAssociation = createAction(
  AccountActionTypes.SET_SELECTED_ASSOCIATION_REQUEST,
  props<{ association: UserAssociationSelection }>()
);
export const setSelectedAssociationFailure = createAction(
  AccountActionTypes.SET_SELECTED_ASSOCIATION_FAILURE,
  props<ApiError>()
);
export const setSelectedAssociationSuccess = createAction(
  AccountActionTypes.SET_SELECTED_ASSOCIATION_SUCCESS,
  props<{ selectedAssociation: any }>()
);

export const getUserData = createAction(
  AccountActionTypes.GET_USER_DATA_REQUEST
);
export const getUserDataFailure = createAction(
  AccountActionTypes.GET_USER_DATA_FAILURE,
  props<{ error: CognitoErrorResponse; message: string }>()
);
export const getUserDataSuccess = createAction(
  AccountActionTypes.GET_USER_DATA_SUCCESS,
  props<{ userData: CognitoUserData }>()
);

export const getUserDetails = createAction(
  AccountActionTypes.GET_USER_DETAILS_REQUEST
);
export const getUserDetailsFailure = createAction(
  AccountActionTypes.GET_USER_DETAILS_FAILURE,
  props<{ error: CognitoErrorResponse; message: string }>()
);
export const getUserDetailsSuccess = createAction(
  AccountActionTypes.GET_USER_DETAILS_SUCCESS,
  props<{ userDetails: Account }>()
);

export const updateAccount = createAction(
  AccountActionTypes.UPDATE_ACCOUNT_REQUEST,
  props<{ payload: AccountUpdatePayload }>()
);
export const updateAccountFailure = createAction(
  AccountActionTypes.UPDATE_ACCOUNT_FAILURE,
  props<ApiError>()
);
export const updateAccountSuccess = createAction(
  AccountActionTypes.UPDATE_ACCOUNT_SUCCESS
);
