import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { handleError } from '@core/helpers';
import {
  Grant,
  GrantListResponse,
  GrantSubmission,
  StoreFileResponse,
} from '@core/models';
import { GrantService } from '@core/services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TypedAction } from '@ngrx/store/src/models';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import {
  cancelStoreFileRequest,
  getGrant,
  getGrantFailure,
  getGrantSuccess,
  getGrants,
  getGrantsFailure,
  getGrantsSuccess,
  getGrantSubmission,
  getGrantSubmissionFailure,
  getGrantSubmissionSuccess,
  getGrantUpload,
  getGrantUploadFailure,
  getGrantUploads,
  getGrantUploadsFailure,
  getGrantUploadsSuccess,
  getGrantUploadSuccess,
  setGrantSubmissionFailure,
  setGrantSubmissionRequest,
  setGrantSubmissionSuccess,
  storeFile,
  storeFileFailure,
  storeFileSuccess,
  updateStoreFileUploadProgress,
  updateGrantSubmissionStatus,
  updateGrantSubmissionStatusFailure,
  updateGrantSubmissionStatusSuccess,
  validateFile,
  validateFileFailure,
  validateFileSuccess,
  getFileColumns,
  getFileColumnsSuccess,
  getFileColumnsFailure,
  getUploadHistories,
  getUploadHistoriesSuccess,
  getUploadHistoriesFailure,
  completeUpload,
  completeUploadSuccess,
  completeUploadFailure,
  getGrantUploadStatus,
  getGrantUploadStatusSuccess,
  getGrantUploadStatusFailure,
  GrantActionTypes,
} from './actions';

@Injectable()
export class GrantEffects {
  getGrant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getGrant),
      switchMap(({ grantId }) =>
        this.grantService.getGrant(grantId).pipe(
          map((grant: Grant) => getGrantSuccess({ grant })),
          catchError((error) => handleError(error, getGrantFailure)),
        ),
      ),
    ),
  );

  getGrants$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getGrants),
      switchMap(() =>
        this.grantService.getGrants().pipe(
          map((grants: GrantListResponse) => getGrantsSuccess({ grants })),
          catchError((error) => handleError(error, getGrantsFailure)),
        ),
      ),
    ),
  );

  getGrantSubmission$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getGrantSubmission),
      switchMap(({ grantId }) =>
        this.grantService.getGrantSubmission(grantId).pipe(
          map((grantSubmission: GrantSubmission) =>
            getGrantSubmissionSuccess({ grantSubmission }),
          ),
          catchError((error) => handleError(error, getGrantSubmissionFailure)),
        ),
      ),
    ),
  );

  setGrantSubmission$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setGrantSubmissionRequest),
      switchMap((action) =>
        this.grantService
          .setGrantSubmission(action.grantId, action.payload)
          .pipe(
            map(() => setGrantSubmissionSuccess()),
            catchError((error) =>
              handleError(error, setGrantSubmissionFailure),
            ),
          ),
      ),
    ),
  );

  getGrantUploadType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getGrantUpload),
      switchMap((action) =>
        this.grantService
          .getGrantUploadType(action.grantId, action.uploadType)
          .pipe(
            map((grantUpload) => getGrantUploadSuccess({ grantUpload })),
            catchError((error) => handleError(error, getGrantUploadFailure)),
          ),
      ),
    ),
  );

  getGrantUploads$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getGrantUploads),
      switchMap((action) =>
        this.grantService.getGrantUploads(action.grantId).pipe(
          map((grantUploads) => getGrantUploadsSuccess({ grantUploads })),
          catchError((error) => handleError(error, getGrantUploadsFailure)),
        ),
      ),
    ),
  );

  getGrantUploadStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getGrantUploadStatus),
      switchMap((action) =>
        this.grantService.getGrantUploadStatus(action.grantId).pipe(
          map((grantUploadStatus) =>
            getGrantUploadStatusSuccess({ grantUploadStatus }),
          ),
          catchError((error) =>
            handleError(error, getGrantUploadStatusFailure),
          ),
        ),
      ),
    ),
  );

  validateFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(validateFile),
      switchMap((action) =>
        this.grantService
          .validateFile(action.grantId, action.uploadLogId, action.uploadFile)
          .pipe(
            map((validateFileResponse) =>
              validateFileSuccess({ validateFileResponse }),
            ),
            catchError((error) => handleError(error, validateFileFailure)),
          ),
      ),
    ),
  );

  storeFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(storeFile),
      switchMap((action) =>
        this.grantService
          .storeFile(action.grantId, action.uploadType, action.file)
          .pipe(
            takeUntil(this.actions$.pipe(ofType(cancelStoreFileRequest))),
            map((response) => this.getUploadProgressFromHttpEvent(response)),
            catchError((error) => handleError(error, storeFileFailure)),
          ),
      ),
    ),
  );

  getFileColumns$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getFileColumns),
      switchMap((action) =>
        this.grantService
          .getFileColumns(action.grantId, action.uploadLogId, action.uploadFile)
          .pipe(
            map((fileColumns) => getFileColumnsSuccess({ fileColumns })),
            catchError((error) => handleError(error, getFileColumnsFailure)),
          ),
      ),
    ),
  );

  getUploadHistories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUploadHistories),
      switchMap((action) =>
        this.grantService
          .getUploadHistories(action.grantId, action.uploadType)
          .pipe(
            map((uploadHistories) =>
              getUploadHistoriesSuccess({ uploadHistories }),
            ),
            catchError((error) =>
              handleError(error, getUploadHistoriesFailure),
            ),
          ),
      ),
    ),
  );

  completeUpload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(completeUpload),
      switchMap((action) =>
        this.grantService
          .completeUpload(action.grantId, action.uploadLogId, action.completed)
          .pipe(
            map(() => completeUploadSuccess()),
            catchError((error) => handleError(error, completeUploadFailure)),
          ),
      ),
    ),
  );

  updateGrantSubmissionStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateGrantSubmissionStatus),
      switchMap((action) =>
        this.grantService
          .updateGrantSubmissionStatus(action.grantId)
          .pipe(
            map(() => updateGrantSubmissionStatusSuccess()),
            catchError((error) => handleError(error, updateGrantSubmissionStatusFailure)),
          ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private grantService: GrantService,
  ) { }

  private getUploadProgressFromHttpEvent(
    response: HttpEvent<StoreFileResponse>,
  ): TypedAction<
    | GrantActionTypes.STORE_FILE_SUCCESS
    | GrantActionTypes.UPDATE_STORE_FILE_UPLOAD_PROGRESS
  > {
    switch (response.type) {
      case HttpEventType.Response:
        return storeFileSuccess({ storeFileResponse: response.body });

      case HttpEventType.UploadProgress: {
        const loaded = response?.loaded ?? 0;
        const storeFileUploadProgress =
          response.total === 0
            ? 0
            : Math.round((100 * loaded) / response.total);
        return updateStoreFileUploadProgress({ storeFileUploadProgress });
      }
    }

    return updateStoreFileUploadProgress({ storeFileUploadProgress: 0 });
  }
}
