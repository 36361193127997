import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { NotificationTypes } from '@app/shared/enums';
import {
  AccountSelectors,
  GrantActions,
  GrantSelectors,
  NotificationActions,
} from '@app/store';
import { UserAssociation } from '@core/models';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, Subject, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'app-upload-widget',
  templateUrl: './upload-widget.component.html',
  styleUrls: ['./upload-widget.component.scss'],
})
export class UploadWidgetComponent implements OnChanges, OnDestroy {
  @ViewChild('fileBrowser') fileBrowser: ElementRef;
  @Input() uploadType = 'entry';
  @Input() grantUploadId = '';
  public formData: FormData = new FormData();
  public validFileTypes = ['.xls', '.xlsx', '.csv'];
  public isFileDraggedOver = false;
  public fileName: string;
  public fileSize = 0;
  public isFileAttached = false;
  public isFileUploaded = false;
  public userAssociation$: Observable<UserAssociation>;
  public storeFileLoading$ = this.store$.select(
    GrantSelectors.selectStoreFileLoading,
  );
  public storeFileUploadProgress$ = this.store$.select(
    GrantSelectors.selectStoreFileUploadProgress,
  );

  private destroyed$ = new Subject<boolean>();

  public get validFileTypesString(): string {
    return this.validFileTypes.join(', ');
  }

  constructor(
    private actions$: Actions,
    private router: Router,
    private store$: Store,
  ) {
    this.userAssociation$ = this.store$.select(
      AccountSelectors.selectAssociationDetails,
    );

    this.actions$
      .pipe(
        ofType(GrantActions.storeFileFailure),
        takeUntil(this.destroyed$),
        tap(({ message }) => {
          this.store$.dispatch(
            NotificationActions.add({
              notificationType: NotificationTypes.DANGER,
              notificationText: message,
            }),
          );
        }),
      )
      .subscribe();

    this.actions$
      .pipe(
        ofType(GrantActions.storeFileSuccess),
        takeUntil(this.destroyed$),
        tap(() => {
          this.isFileUploaded = true;
          this.store$.dispatch(
            NotificationActions.add({
              notificationType: NotificationTypes.SUCCESS,
              notificationText: 'File uploaded successfully',
            }),
          );
        }),
      )
      .subscribe();
  }

  ngOnChanges(): void {
    if (this.isFileAttached === false) {
      if (this.fileBrowser) {
        this.fileBrowser.nativeElement.value = null;
      }
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  get canUpload(): boolean {
    return this.isFileAttached;
  }

  public byteConverter(bytes: number): number {
    const k = 1024;
    const convertedBytes = +(bytes / k).toFixed(2);
    return convertedBytes;
  }

  public cancelUpload(): void {
    this.store$.dispatch(
      GrantActions.cancelStoreFileRequest({
        message: 'File upload process cancelled.',
      }),
    );
    this.router.navigate(['/participant-surveys']);
  }

  public handleFileDropped(event: DragEvent): void {
    event.preventDefault();
    const fileName = event.dataTransfer.files[0].name;

    if (!this.validFileTypes.some((type) => fileName.includes(type))) {
      this.store$.dispatch(
        NotificationActions.add({
          notificationType: NotificationTypes.DANGER,
          notificationText: `Invalid file type. File must be of type: ${this.validFileTypesString}`,
        }),
      );
      this.isFileDraggedOver = false;
      return;
    }
    const file = event.dataTransfer.files[0];

    if (file.size === 0) {
      this.store$.dispatch(
        NotificationActions.add({
          notificationType: NotificationTypes.DANGER,
          notificationText: 'File cannot be empty.',
        }),
      );
      return;
    }

    this.selectDataFile(file);
  }

  public handleFileSelected(event: InputEvent): void {
    event.preventDefault();
    const target = event.target as HTMLInputElement;
    const fileName = target.files[0].name;

    if (!this.validFileTypes.some((type) => fileName.includes(type))) {
      this.store$.dispatch(
        NotificationActions.add({
          notificationType: NotificationTypes.DANGER,
          notificationText: `Invalid file type. File must be of type: ${this.validFileTypesString}`,
        }),
      );
      this.isFileDraggedOver = false;
      return;
    }

    const file = target.files[0];

    if (file.size === 0) {
      this.store$.dispatch(
        NotificationActions.add({
          notificationType: NotificationTypes.DANGER,
          notificationText: 'File cannot be empty.',
        }),
      );
      return;
    }

    this.selectDataFile(file);
  }

  public onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.isFileDraggedOver = true;
  }

  public onDragLeave(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.isFileDraggedOver = false;
  }

  public selectDataFile(file: File) {
    this.isFileAttached = true;
    this.isFileUploaded = false;
    this.formData.set('file', file);
    this.fileName = file.name;
    this.fileSize = file.size;
  }

  public setBorder(): string {
    return this.isFileDraggedOver
      ? 'drop-zone--file-dragged'
      : 'drop-zone--file-default';
  }

  public uploadFile(): void {
    this.store$.dispatch(
      GrantActions.storeFile({
        grantId: this.grantUploadId,
        uploadType: this.uploadType,
        file: this.formData,
      }),
    );
  }
}
