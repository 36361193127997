import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-not-available',
  templateUrl: './not-available.component.html',
  styleUrls: ['./not-available.component.scss'],
})
export class NotAvailableDialogComponent implements OnDestroy, OnInit {
  constructor(
    private dialog: MatDialogRef<NotAvailableDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}
}
