import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StringUtil } from '@shared/utilities';
import { DialogButtonText } from './dialog-button-text.enum';
import { DialogButton } from './dialog-button.enum';
import { Dialog } from './dialog';
import { DialogType } from './dialog-type.enum';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent {
  public dialogDialog: Dialog;

  constructor(
    private dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: Dialog
  ) {}

  get DialogButton() {
    return DialogButton;
  }

  get dialogTitle(): string {
    return this.dialogData.dialogTitle ?? '';
  }

  get dialogIcon(): string {
    return this.dialogData.dialogIcon
      ? this.dialogData.dialogIcon 
      : this.dialogData.dialogType;
  }

  get isHtmlMessage(): boolean {
    return StringUtil.isHtml(this.dialogData.dialogContent);
  }

  public getButtonText(isCloseConfirm: boolean): string {
    const dialogButton: DialogButton = this.dialogData.dialogButton;
    if (
      dialogButton === DialogButton.ok ||
      dialogButton === DialogButton.okCancel
    ) {
      return isCloseConfirm ? DialogButtonText.ok : DialogButtonText.cancel;
    } else if (dialogButton === DialogButton.yesNo) {
      return isCloseConfirm ? DialogButtonText.yes : DialogButtonText.no;
    } else if (dialogButton === DialogButton.continueCancel) {
      return isCloseConfirm ? DialogButtonText.continue : DialogButtonText.cancel;
    }
  }

  public close(): void {
    this.dialogRef.close(false);
  }

  public submit(): void {
    this.dialogRef.close(true);
  }
}
