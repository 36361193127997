import { Component } from '@angular/core';

@Component({
  selector: 'app-gov-site-link',
  templateUrl: './gov-site-link.component.html',
  styleUrls: ['./gov-site-link.component.scss'],
})
export class GovSiteLinkComponent {
  public noticeOpen = false;
  public everOpened = false;

  public toggleNotice($event: Event): void {
    $event.preventDefault();
    this.noticeOpen = !this.noticeOpen;
    if (!this.everOpened) {
      this.everOpened = true;
    }
  }
}
