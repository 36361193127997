import {
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';

export const fieldRequiredIfValidator =
  (formControlName: string, testValue: string): ValidatorFn =>
  (formControl: AbstractControl): ValidationErrors | null => {
    if (
      formControl.parent &&
      (formControl.touched || formControl.pristine) &&
      formControl.parent.get(formControlName).value === testValue
    ) {
      return Validators.required(formControl);
    }

    return null;
  };

export const noWhitespaceValidator =
  (): ValidatorFn =>
  (formControl: AbstractControl): ValidationErrors | null => {
    const regex = new RegExp(/^\s+$/);
    const invalid = regex.test(formControl.value);
    return invalid ? { whitespace: true } : null;
  };
