import { formatNumber } from '@angular/common';
import {
  Attribute,
  Component,
  forwardRef,
  Input,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
  ValidationErrors,
  Validator,
  ValidatorFn,
} from '@angular/forms';

type InputValue = null | number | string;
@Component({
  selector: 'app-number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.scss'],
  providers: [
    {
      multi: true,
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumberInputComponent),
    },
    {
      multi: true,
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => NumberInputComponent),
    },
  ],
})
export class NumberInputComponent implements ControlValueAccessor, Validator
{
  @Input() public decimals = 0;
  @Input() public disabled = false;
  @Input() public displayOnly = false;
  @Input() public isCurrency = false;
  @Input() public isPercentage = false;
  @Input() public label = '';
  @Input() public max: number;
  @Input() public min: number;
  @Input() public required = false;
  @Input() public hasSubIssue = false;
  @Input() public maxDescription: string;
  @Input() public suppressMaxErrorMessage = false;

  public ariaLabelledBy: string;
  public displayValue: string;

  public numberControl: UntypedFormControl = new UntypedFormControl(null, [
    this.validateInput(),
  ]);


  constructor(@Attribute('aria-labelledby') ariaLabelledBy: string) {
    this.ariaLabelledBy = ariaLabelledBy;
  }

  public get value(): null | number {
    if (this.controlValue === null || this.controlValue === '') {
      return null;
    }
    return this.getNumberValue(this.controlValue);
  }

  @Input() public set value(value: InputValue) {
    this.displayValue = this.getFormattedValue(value);
    this.numberControl?.setValue(this.displayValue);
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  private get controlValue(): null | string {
    return this.numberControl?.value;
  }

  public getLabel(): string {
    return this.label ? this.label : 'Value';
  }

  public hasError(error: string): boolean {
    return this.numberControl.hasError(error);
  }

  public onChange(): void {
    this.propagateChange(this.value);
    this.propagateTouched();
  }

  public onTouched(): void {
    this.numberControl?.setValue(this.getFormattedValue(this.controlValue));
    this.propagateTouched();
  }

  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.propagateTouched = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    if (this.disabled) {
      this.numberControl?.disable();
    } else {
      this.numberControl?.enable();
    }
  }

  public writeValue(value: InputValue): void {
    this.value = value;
  }

  public validate(): ValidationErrors {
    return this.numberControl?.valid ? null : this.numberControl?.errors;
  }

  private getFormattedValue(value: InputValue): string {
    const numberValue = this.getNumberValue(value);
    const digitsInfo =
      this.decimals > 0
        ? `1.1-${this.decimals}`
        : this.isCurrency
        ? '1.2-2' // min 2 digits - max 2 digits (after decimal point)
        : '1.0-0'; // min 0 digits - max 0 digits (after decimal point)
    return this.isNotZeroAndEmpty(value)
      ? null
      : numberValue === null || isNaN(numberValue)
      ? value.toString()
      : formatNumber(
          this.isCurrency || this.decimals > 0
            ? numberValue
            : parseInt(numberValue.toString(), 10),
          'en-US',
          digitsInfo
        );
  }

  private getNumberValue(value: InputValue): null | number {
    if (!value || !value.toString().trim()) {
      return null;
    }
    const commaRegExp = new RegExp(',', 'g');
    return Number(
      parseFloat(value.toString().replace(commaRegExp, '')).toFixed(2)
    );
  }

  private isNotZeroAndEmpty(value: InputValue): boolean {
    return value !== 0 && !value;
  }
  private propagateChange = (_: any) => {};
  private propagateTouched = () => {};

  private validateInput(): ValidatorFn {
    return (): ValidationErrors => {
      const errors: ValidationErrors = {};
      const valid = false;
      const value = this.value;

      if (this.isNotZeroAndEmpty(this.controlValue)) {
        return null;
      }

      if (value === null || value === Infinity || isNaN(value)) {
        // validate numeric value
        errors.numericValue = { valid };
      } else if (value < 0) {
        // validate positive number
        errors.positiveNumber = { valid };
      } else {
        // validate min/max
        const actualValue = value;
        if (this.min && value < this.min) {
          errors.minimumValue = {
            min: this.min,
            actualValue,
            valid,
          };
        } else if (this.max && value > this.max) {
          errors.maximumValue = {
            max: this.min,
            actualValue,
            valid,
          };
        }
      }
      return Object.keys(errors).length ? errors : null;
    };
  }
}
