<app-auth-card [progress]="verifySignInLoading$ | async">
  <span auth-card-header>Verify Sign In</span>

  <form
    #form
    [formGroup]="verifySignInForm"
    (ngSubmit)="submit()"
    autocomplete="off"
  >
    <p>Please enter your verification code.</p>

    <fieldset class="flex-column">
      <mat-form-field>
        <mat-label>Verification Code</mat-label>
        <input
          matInput
          autofocus
          name="code"
          formControlName="code"
          placeholder=""
          title="Code"
          type="text"
          aria-required="true"
        />
        <mat-error>
          <app-form-errors
            [field]="verifySignInForm.controls.code"
          ></app-form-errors>
        </mat-error>
      </mat-form-field>
    </fieldset>
  </form>
  <ng-container auth-card-actions>
    <button
      type="button"
      (click)="submit()"
      mat-flat-button
      color="primary"
      [disabled]="(verifySignInLoading$ | async) || verifySignInForm.invalid"
    >
      Verify
    </button>
    <button mat-stroked-button (click)="goBack()">Go Back</button>
  </ng-container>
</app-auth-card>
