import { createReducer, on } from '@ngrx/store';
import {
  addComment,
  addCommentFailure,
  addCommentSuccess,
  deleteComment,
  deleteCommentFailure,
  deleteCommentSuccess,
  getComments,
  getCommentsFailure,
  getCommentsSuccess,
  updateComment,
  updateCommentFailure,
  updateCommentSuccess,
  getGrantProgress,
  getGrantProgressSuccess,
  getGrantProgressFailure,
  getGrantsDetails,
  getGrantsDetailsSuccess,
  getGrantsDetailsFailure,
  getGrantsSummary,
  getGrantsSummarySuccess,
  getGrantsSummaryFailure,
} from './actions';
import { initialState } from './state';

export const statusReportFeatureKey = 'statusReport';

export const statusReportReducer = createReducer(
  initialState,

  on(addComment, (state) => ({
    ...state,
    addCommentLoading: true,
  })),
  on(addCommentSuccess, (state) => ({
    ...state,
    addCommentLoading: false,
  })),
  on(addCommentFailure, (state, apiError) => ({
    ...state,
    addCommentFailure: apiError,
    addCommentLoading: false,
  })),
  on(getComments, (state) => ({
    ...state,
    commentsLoading: true,
  })),
  on(getCommentsSuccess, (state, { comments }) => ({
    ...state,
    comments,
    commentsLoading: false,
    commentsError: null,
  })),
  on(getCommentsFailure, (state, apiError) => ({
    ...state,
    commentsError: apiError,
    commentsLoading: false,
    comments: [],
  })),
  on(updateComment, (state) => ({
    ...state,
    updateCommentLoading: true,
  })),
  on(updateCommentSuccess, (state) => ({
    ...state,
    updateCommentLoading: false,
  })),
  on(updateCommentFailure, (state, apiError) => ({
    ...state,
    updateCommentFailure: apiError,
    updateCommentLoading: false,
  })),
  on(deleteComment, (state) => ({
    ...state,
    deleteCommentLoading: true,
  })),
  on(deleteCommentSuccess, (state) => ({
    ...state,
    deleteCommentLoading: false,
    deleteCommentFailure: null,
  })),
  on(deleteCommentFailure, (state, apiError) => ({
    ...state,
    deleteCommentFailure: apiError,
    deleteCommentLoading: false,
  })),
  on(getGrantProgress, (state) => ({
    ...state,
    getGrantProgressLoading: true,
  })),
  on(getGrantProgressSuccess, (state, { grantProgress }) => ({
    ...state,
    getGrantProgressLoading: false,
    getGrantProgressFailure: null,
    grantProgress,
  })),
  on(getGrantProgressFailure, (state, apiError) => ({
    ...state,
    getGrantProgressFailure: apiError,
    getGrantProgressLoading: false,
  })),
  on(getGrantsDetails, (state) => ({
    ...state,
    getGrantsDetailsLoading: true,
  })),
  on(getGrantsDetailsSuccess, (state, { grantsDetails }) => ({
    ...state,
    getGrantsDetailsLoading: false,
    getGrantsDetailsFailure: null,
    grantsDetails,
  })),
  on(getGrantsDetailsFailure, (state, apiError) => ({
    ...state,
    getGrantsDetailsFailure: apiError,
    getGrantsDetailsLoading: false,
  })),
  on(getGrantsSummary, (state) => ({
    ...state,
    getGrantsSummaryLoading: true,
  })),
  on(getGrantsSummarySuccess, (state, { grantsSummary }) => ({
    ...state,
    getGrantsSummaryLoading: false,
    getGrantsSummaryFailure: null,
    grantsSummary,
  })),
  on(getGrantsSummaryFailure, (state, apiError) => ({
    ...state,
    getGrantsSummaryFailure: apiError,
    getGrantsSummaryLoading: false,
  })),
);
