import { Injectable } from '@angular/core';
import { handleError } from '@core/helpers/format-error';
import { Account } from '@core/models';
import { AccountService, CognitoUserData } from '@core/services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  changePassword,
  changePasswordFailure,
  changePasswordSuccess,
  getAccount,
  getAccountFailure,
  getAccountSuccess,
  getAssociationDetails,
  getAssociationDetailsFailure,
  getAssociationDetailsSuccess,
  getAssociations,
  getAssociationsFailure,
  getAssociationsSuccess,
  getSelectedAssociation,
  getSelectedAssociationFailure,
  getSelectedAssociationSuccess,
  getUserData,
  getUserDataFailure,
  getUserDataSuccess,
  getUserDetails,
  getUserDetailsFailure,
  getUserDetailsSuccess,
  setSelectedAssociation,
  setSelectedAssociationFailure,
  setSelectedAssociationSuccess,
  updateAccount,
  updateAccountFailure,
  updateAccountSuccess,
} from './actions';

@Injectable()
export class AccountEffects {
  changePassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(changePassword),
      switchMap(({ payload }) =>
        this.accountService.changePassword(payload).pipe(
          map(() => changePasswordSuccess()),
          catchError((error) => handleError(error, changePasswordFailure))
        )
      )
    )
  );
  getAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAccount),
      switchMap(() =>
        this.accountService.getAccount().pipe(
          map((account) => getAccountSuccess({ account })),
          catchError((error) => handleError(error, getAccountFailure))
        )
      )
    )
  );

  getAssociationDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAssociationDetails),
      switchMap(({ selectedAssociation }) =>
        this.accountService.getAssociation(selectedAssociation).pipe(
          map((associationDetails) =>
            getAssociationDetailsSuccess({ associationDetails })
          ),
          catchError((error) =>
            handleError(error, getAssociationDetailsFailure)
          )
        )
      )
    )
  );

  getAssociations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAssociations),
      switchMap(() =>
        this.accountService.getAssociations().pipe(
          map((associations) => getAssociationsSuccess({ associations })),
          catchError((error) => handleError(error, getAssociationsFailure))
        )
      )
    )
  );

  getSelectedAssociation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSelectedAssociation),
      switchMap(() =>
        this.accountService.getSelectedAssociation().pipe(
          map((selectedAssociation) =>
            getSelectedAssociationSuccess({ selectedAssociation })
          ),
          catchError((error) =>
            handleError(error, getSelectedAssociationFailure)
          )
        )
      )
    )
  );

  getUserData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUserData),
      switchMap(() =>
        this.accountService.getUserData().pipe(
          map((userData: CognitoUserData) => getUserDataSuccess({ userData })),
          catchError((error) => handleError(error, getUserDataFailure))
        )
      )
    )
  );

  getUserDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUserDetails),
      switchMap(() =>
        this.accountService.getUserDetails().pipe(
          map((userDetails: Account) => getUserDetailsSuccess({ userDetails })),
          catchError((error) => handleError(error, getUserDetailsFailure))
        )
      )
    )
  );

  setSelectedAssociation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setSelectedAssociation),
      switchMap(({ association }) =>
        this.accountService.setSelectedAssociation(association).pipe(
          map((selectedAssociation) =>
            setSelectedAssociationSuccess({ selectedAssociation })
          ),
          catchError((error) =>
            handleError(error, setSelectedAssociationFailure)
          )
        )
      )
    )
  );

  updateAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateAccount),
      switchMap(({ payload }) =>
        this.accountService.updateAccount(payload).pipe(
          map(() => updateAccountSuccess()),
          catchError((error) => handleError(error, updateAccountFailure))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private accountService: AccountService
  ) {}
}
