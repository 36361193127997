import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-form-section-status',
  templateUrl: './form-section-status.component.html',
  styleUrls: ['./form-section-status.component.scss']
})
export class SubsectionStatusComponent implements OnChanges {

  @Input() status: boolean | null = null;
  public iconLabel = '';

  ngOnChanges(): void {
    this.iconLabel = this.status
      ? 'check_circle'
      : 'pending';
  }
}
