import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ConfirmResetPasswordPayload,
  ConfirmResetPasswordResponse,
  ConfirmSignInPayload,
  ConfirmSignInResponse,
  NewUserPayload,
  NewUserResponse,
  ResetPasswordPayload,
  ResetPasswordResponse,
  SetMFAPreferencePayload,
  SetNewPasswordPayload,
  SignInPayload,
  SignInResponse,
  UpdateUserAttributesPayload,
  VerifyAttributePayload,
} from '@auth/auth.models';
import { VerifySoftwareTokenParams } from '@core/models';
import {
  CognitoUserAttribute,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BaseService } from './base.service';
import {
  CognitoResponse,
  CognitoService,
  CognitoUserData,
} from './cognito.service';
@Injectable({
  providedIn: 'root',
})
export class AuthService extends BaseService {
  private authPath = `${this.baseURL}/auth`;
  private signingIn = false;
  private signingOut = false;
  private tokenKey = 'token';

  constructor(private cognito: CognitoService, private http: HttpClient) {
    super();
  }

  public associateSoftwareToken(): Observable<CognitoResponse> {
    return this.cognito.associateSoftwareToken();
  }

  public confirmResetPassword(
    payload: ConfirmResetPasswordPayload
  ): Observable<ConfirmResetPasswordResponse> {
    this.signingIn = true;
    return this.http.put<ConfirmResetPasswordResponse>(
      `${this.authPath}/reset-password`,
      payload
    );
  }

  public confirmSignIn(
    payload: ConfirmSignInPayload
  ): Observable<ConfirmSignInResponse> {
    this.signingIn = true;
    //return this.http.post(`${this.apiUrl}/${this.authPath}/verify-mfa`,
    //  {}
    //);
    return this.cognito.confirmSignIn(payload.code, payload.mfaType);
  }

  public confirmCustomSignIn(challengeAnswer: string): Observable<any> {
    this.signingIn = true;
    return this.cognito.sendCustomChallengeAnswer(challengeAnswer);
  }

  public getInputVerificationCode(name: string): Observable<CognitoResponse> {
    return this.cognito.getInputVerificationCode(name);
  }

  public getSignInUserSession(): Observable<CognitoUserSession> {
    return this.cognito.getSignInUserSession();
  }

  /**
   * Gets the authPath token from cognito
   *
   * @returns the authPath token string
   */
  public getAccessToken(): string {
    return this.cognito.getAccessToken().getJwtToken();
  }
  public getToken(): string {
    return this.cognito.getIdToken().getJwtToken();
  }

  public getUserAttributes(): Observable<CognitoUserAttribute[]> {
    return this.cognito.getUserAttributes();
  }

  public getUserData(): Observable<CognitoUserData> {
    return this.cognito.getUserData();
  }

  public hasMFA(): Observable<boolean> {
    return this.cognito.hasMFA();
  }

  public isSignedIn(): boolean {
    //const decoded = this.getDecodedToken();
    //return decoded && decoded.isFullyauthenticated ? true : false;
    // return false;
    return this.cognito.sessionIsValid();
  }

  public isSigningIn(): boolean {
    return this.signingIn;
  }

  public isSigningOut(): boolean {
    return this.signingOut;
  }

  public refreshSession(): void {
    return this.cognito.refreshSession();
  }

  public setNewPassword(
    payload: SetNewPasswordPayload
  ): Observable<CognitoResponse> {
    this.signingIn = true;
    return this.cognito.confirmPassword(payload.resetCode, payload.newPassword);
  }

  public resetPassword(
    payload: ResetPasswordPayload
  ): Observable<ResetPasswordResponse> {
    this.signingIn = true;
    return this.http.post<ResetPasswordResponse>(
      `${this.authPath}/reset-password`,
      payload
    );
  }

  public setMFAPreference(
    payload: SetMFAPreferencePayload
  ): Observable<CognitoResponse> {
    this.signingIn = true;
    return this.cognito.setMFAPreference(
      payload.smsSettings,
      payload.tokenSettings
    );
  }

  public setNewUser(payload: NewUserPayload): Observable<NewUserResponse> {
    this.signingIn = true;
    return this.cognito.completeNewPasswordChallenge(
      payload.newPassword,
      payload.requiredAttributeData
    );
  }

  public signIn(user: SignInPayload): Observable<SignInResponse> {
    this.signingIn = true;
    // return this.http.post(`${this.apiUrl}/${this.authPath}/signin`, {
    //   ...user,
    // });
    return this.cognito.signIn(user.username, user.password);
  }

  public signOut(): Observable<void> {
    this.signingOut = true;

    return this.cognito.signOut().pipe(
      tap(() => {
        sessionStorage.clear();
        this.signingOut = false;
      })
    );
  }

  public updateUserAttributes(
    attributes: UpdateUserAttributesPayload
  ): Observable<string> {
    return this.cognito.updateUserAttributes(attributes);
  }

  public verifyAttribute(payload: VerifyAttributePayload): Observable<string> {
    return this.cognito.verifyAttribute(
      payload.attributeName,
      payload.confirmationCode
    );
  }

  public verifySoftwareToken(
    payload: VerifySoftwareTokenParams
  ): Observable<CognitoUserSession> {
    return this.cognito.verifySoftwareToken(
      payload.AccessToken,
      payload.UserCode,
      payload.FriendlyDeviceName
    );
  }
}
