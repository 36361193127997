import { Injector } from '@angular/core';

export class AppInjector {
  private static injector: Injector;

  public static get(): Injector {
    return this.injector;
  }

  public static set(injector: Injector): void {
    this.injector = injector;
  }
}
