import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Resource } from '@app/shared/enums';
import { ResourceAccessGuard } from '@core/helpers';
import { UsersComponent } from './users.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [ResourceAccessGuard],
    component: UsersComponent,
    data: {
      title: 'Users',
      permissions: [Resource.ViewUsersBase],
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class UserManagementRoutingModule {}
