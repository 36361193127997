import { Renderer2 } from '@angular/core';
import * as Papa from 'papaparse';

export type ExportCsvConfig = Pick<Papa.UnparseConfig, 'header' | 'columns'>;

export const exportAsCsv = (
  data: any,
  fileName: string,
  renderer: Renderer2,
  timestamp?: string,
  config?: ExportCsvConfig,
): void => {
  const csv = Papa.unparse(data, config);
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);

  if (!timestamp) {
    timestamp = toIsoString(new Date()).replace(/[T:]/g, '_').split('.')[0];
  }

  const fileTitle = `${fileName}_${timestamp}`;

  const link = renderer.createElement('a');
  link.href = url;
  link.setAttribute('download', fileTitle);
  link.click();
  link.remove();
};

function toIsoString(date) {
  const tzo = -date.getTimezoneOffset();
  const dif = tzo >= 0 ? '+' : '-';
  const pad = (num) => {
    return (num < 10 ? '0' : '') + num;
  };

  return date.getFullYear() +
    '-' + pad(date.getMonth() + 1) +
    '-' + pad(date.getDate()) +
    'T' + pad(date.getHours()) +
    ':' + pad(date.getMinutes()) +
    ':' + pad(date.getSeconds());
  //dif + pad(Math.floor(Math.abs(tzo) / 60)) +
  //':' + pad(Math.abs(tzo) % 60);
}