import { IMFASettings, UserAttribute } from '@core/services';

export enum MFATypes {
  SMS = 'SMS_MFA',
  Token = 'SOFTWARE_TOKEN_MFA',
  Custom = 'CUSTOM_CHALLENGE',
}

export interface CognitoCommandOutput {
  $metadata: {
    httpStatusCode: number;
    requestId: string;
    attempts: number;
    totalRetryDelay: number;
  };
}

export interface ChangePasswordPayload {
  newPassword: string;
  oldPassword: string;
}

export type ChangePasswordResponse = CognitoCommandOutput;

export interface ConfirmResetPasswordPayload {
  confirmationCode: string;
  password: string;
  username: string;
}

export type ConfirmResetPasswordResponse = CognitoCommandOutput;

export interface ConfirmSignInPayload {
  code: string;
  mfaType: MFATypes;
}

export interface ConfirmSignInResponse {
  // TODO: REPLACE WITH PROPER TYPING BASED ON RESPONSE
  [key: string]: any;
}

export interface NewUserPayload {
  newPassword: string;
  requiredAttributeData?: any;
}

export interface NewUserResponse {
  // TODO: REPLACE WITH PROPER TYPING BASED ON RESPONSE
  [key: string]: any;
}

export interface NewPasswordResponse {
  // TODO: REPLACE WITH PROPER TYPING BASED ON RESPONSE
  [key: string]: any;
}

export interface PasswordResetCodeResponse {
  // TODO: REPLACE WITH PROPER TYPING BASED ON RESPONSE
  [key: string]: any;
}

export interface SetNewPasswordPayload {
  newPassword: string;
  resetCode: string;
}

export interface ResetPasswordPayload {
  username: string;
}

export interface ResetPasswordResponse extends CognitoCommandOutput {
  // TODO: REPLACE WITH PROPER TYPING BASED ON RESPONSE
  CodeDeliveryDetails?: {
    AttributeName?: string;
    DeliveryMedium?: string;
    Destination?: string;
  };
}

export interface SignInPayload {
  password: string;
  username: string;
}

export interface SignInResponse {
  // TODO: REPLACE WITH PROPER TYPING BASED ON RESPONSE
  [key: string]: any;
}

export interface SetMFAPreferencePayload {
  smsSettings: IMFASettings;
  tokenSettings: IMFASettings;
}

export type UpdateUserAttributesPayload = UserAttribute[];

export interface VerifyAttributePayload {
  attributeName: string;
  confirmationCode: string;
}
