import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { QRCodeComponent } from 'dfx-qrcode';
import { MarkdownModule } from 'ngx-markdown';
import { SecurableResourceDirective } from './directives';
import { MaterialModule } from './material.module';
import { PipesModule } from './pipes/pipes.module';
import {
  AssociationDisplayComponent,
  AssociationsListComponent,
  BannerComponent,
  CommentComponent,
  ContentBreadcrumbsComponent,
  ContentContainerComponent,
  ContentContainerTitleComponent,
  DataTableComponent,
  FixedContentFooterComponent,
  FormErrorsComponent,
  FormNavigationComponent,
  FormProgressBarComponent,
  HelpToggletipComponent,
  LabelRadioGroupComponent,
  MyGrantsFilterComponent,
  NumberInputComponent,
  PageLoaderComponent,
  PasswordErrorComponent,
  QrCodeComponent,
  SubsectionStatusComponent,
  UploadWidgetComponent,
} from './ui';
import {
  ConfirmDialogComponent,
  IdleTimeoutDialogComponent,
  InactiveUserDialogComponent,
  NotAvailableDialogComponent,
  SelectAssociationDialogComponent,
} from './ui/dialogs';
import { SurveyUploadCardComponent } from './ui/survey-upload-card/survey-upload-card.component';

const COMPONENTS = [
  AssociationDisplayComponent,
  AssociationsListComponent,
  BannerComponent,
  CommentComponent,
  ConfirmDialogComponent,
  ContentBreadcrumbsComponent,
  ContentContainerComponent,
  ContentContainerTitleComponent,
  DataTableComponent,
  FixedContentFooterComponent,
  FormErrorsComponent,
  FormNavigationComponent,
  FormProgressBarComponent,
  HelpToggletipComponent,
  LabelRadioGroupComponent,
  IdleTimeoutDialogComponent,
  InactiveUserDialogComponent,
  NotAvailableDialogComponent,
  NumberInputComponent,
  PageLoaderComponent,
  PasswordErrorComponent,
  QrCodeComponent,
  SecurableResourceDirective,
  SelectAssociationDialogComponent,
  UploadWidgetComponent,
  MyGrantsFilterComponent,
  SubsectionStatusComponent,
  SurveyUploadCardComponent,
];

const MODULES = [
  CommonModule,
  FormsModule,
  MarkdownModule.forRoot(),
  MaterialModule,
  PipesModule,
  QRCodeComponent,
  ReactiveFormsModule,
  RouterModule,
];

@NgModule({
  declarations: COMPONENTS,
  imports: MODULES,
  exports: [
    FormsModule,
    MarkdownModule,
    MaterialModule,
    PipesModule,
    ReactiveFormsModule,
    ...COMPONENTS,
  ],
  providers: [{ provide: MatDialogRef, useValue: {} }, DatePipe],
})
export class SharedModule {}
