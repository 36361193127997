<mat-tab-group
  mat-stretch-tabs="true"
  #associationLists
  animationDuration="0ms"
  [selectedIndex]="associations.grantAssociations.length ? 0 : 1"
>
  <mat-tab
    aria-label="Grants"
    class="tab"
    [disabled]="!associations.grantAssociations.length"
  >
    <ng-template mat-tab-label>
      <span>Grants</span>
    </ng-template>

    <div class="grant-list-container">
      <mat-selection-list>
        <ng-container
          *ngFor="let grant of associations.grantAssociations; let i = index"
        >
          <mat-list-item
            matRipple
            *ngIf="grantOnPage(i)"
            (click)="
              setSelection(UserAssociationCategory.GRANTS, grant.grantId)
            "
          >
            <span matListItemTitle role="heading" aria-level="3"
              >{{ grant.grant.grantee.name
              }}{{ grant.grant.grantCohort.name === '' ? '' : ' - '
              }}{{ grant.grant.grantCohort.name }}</span
            >
            <span matListItemLine>{{ grant.grant.fundingStream.name }}</span>
          </mat-list-item>
        </ng-container>
      </mat-selection-list>
    </div>

    <mat-paginator
      #paginator
      (page)="handlePageEvent($event)"
      [length]="associations.grantAssociations.length"
      [pageSize]="pageSize"
      [showFirstLastButtons]="true"
      [hidePageSize]="true"
      role="navigation"
      aria-label="Select Grant"
    >
    </mat-paginator>
  </mat-tab>
  <mat-tab
    aria-label="Providers"
    class="tab"
    [disabled]="!associations.providerAssociations.length"
  >
    <ng-template mat-tab-label>
      <span>Providers</span>
    </ng-template>
    <div class="provider-list-container">
      <mat-selection-list>
        <ng-container
          *ngFor="
            let provider of associations.providerAssociations;
            let i = index
          "
        >
          <mat-list-item
            matRipple
            *ngIf="providerOnPage(i)"
            (click)="
              setSelection(
                UserAssociationCategory.PROVIDERS,
                provider.providerId
              )
            "
          >
            <span matListItemTitle role="heading" aria-level="3">{{
              provider.provider.name
            }}</span>
            <span matListItemLine
              >{{ provider.provider.grant.grantee.name
              }}{{ provider.provider.grant.grantCohort.name === '' ? '' : ' - '
              }}{{ provider.provider.grant.grantCohort.name }} ::
              {{ provider.provider.grant.fundingStream.name }}</span
            >
          </mat-list-item>
        </ng-container>
      </mat-selection-list>
    </div>

    <mat-paginator
      #paginator
      (page)="handlePageEvent($event)"
      [length]="associations.providerAssociations.length"
      [pageSize]="pageSize"
      [showFirstLastButtons]="true"
      [hidePageSize]="true"
      role="navigation"
      aria-label="Select Provider"
    >
    </mat-paginator>
  </mat-tab>
</mat-tab-group>
