import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-help-toggletip',
  templateUrl: './help-toggletip.component.html',
  styleUrl: './help-toggletip.component.scss',
})
export class HelpToggletipComponent {
  @Input() content!: string;
  @Input() label = '';
  @Input() position = 'right';
  @Input() toggleTipWidth = '';
  @Input() toggleLeft = '';
  @Input() toggleTop = '0rem';
  public toggled = false;

  public onClick(event: MouseEvent): void {
    event.stopPropagation();
    this.toggled = !this.toggled;
  }
}
