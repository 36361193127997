import { ApiError, ResourceAccessObject } from '@core/models';

export interface State {
  resourceAccess: ResourceAccessObject;
  resourceAccessError: ApiError;
  resourceAccessLoading: boolean;
}

export const initialState: State = {
  resourceAccess: null,
  resourceAccessError: null,
  resourceAccessLoading: false,
};
