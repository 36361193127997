import { Action, createReducer, on } from '@ngrx/store';
import {
  getGrant,
  getGrantFailure,
  getGrantSuccess,
  getGrants,
  getGrantsFailure,
  getGrantsSuccess,
  getGrantSubmission,
  getGrantSubmissionFailure,
  getGrantSubmissionSuccess,
  getGrantUpload,
  getGrantUploadFailure,
  getGrantUploadSuccess,
  setGrantSubmissionFailure,
  setGrantSubmissionRequest,
  setGrantSubmissionSuccess,
  getGrantUploads,
  getGrantUploadsSuccess,
  getGrantUploadsFailure,
  validateFile,
  validateFileSuccess,
  validateFileFailure,
  storeFile,
  storeFileSuccess,
  storeFileFailure,
  cancelStoreFileRequest,
  updateStoreFileUploadProgress,
  getFileColumns,
  getFileColumnsSuccess,
  getFileColumnsFailure,
  getUploadHistories,
  getUploadHistoriesSuccess,
  getUploadHistoriesFailure,
  completeUpload,
  completeUploadSuccess,
  completeUploadFailure,
  getGrantUploadStatus,
  getGrantUploadStatusSuccess,
  getGrantUploadStatusFailure,
  updateGrantSubmissionStatus,
  updateGrantSubmissionStatusFailure,
  updateGrantSubmissionStatusSuccess,
} from './actions';
import { initialState, State } from './state';

export const grantFeatureKey = 'grant';

export const grantReducer = createReducer(
  initialState,
  on(getGrant, (state) => ({
    ...state,
    grantLoading: true,
  })),
  on(getGrantSuccess, (state, { grant }) => ({
    ...state,
    grant,
    grantLoading: false,
  })),
  on(getGrantFailure, (state, { error, message }) => ({
    ...state,
    grant: null,
    grantLoading: false,
    grantError: { error, message },
  })),
  on(getGrants, (state) => ({
    ...state,
    grantListLoading: true,
  })),
  on(getGrantsSuccess, (state, { grants }) => ({
    ...state,
    grants,
    grantsLoading: false,
  })),
  on(getGrantsFailure, (state, { error, message }) => ({
    ...state,
    grants: [],
    grantsLoading: false,
    grantsError: { error, message },
  })),
  on(getGrantSubmission, (state) => ({
    ...state,
    grantSubmissionLoading: true,
  })),
  on(getGrantSubmissionSuccess, (state, { grantSubmission }) => ({
    ...state,
    grantSubmission,
    grantSubmissionLoading: false,
  })),
  on(getGrantSubmissionFailure, (state, { error, message }) => ({
    ...state,
    grantSubmission: null,
    grantSubmissionLoading: false,
    grantSubmissionError: { error, message },
  })),
  on(setGrantSubmissionRequest, (state) => ({
    ...state,
    setGrantSubmissionLoading: true,
  })),
  on(setGrantSubmissionSuccess, (state) => ({
    ...state,
    setGrantSubmissionLoading: false,
  })),
  on(setGrantSubmissionFailure, (state, { error, message }) => ({
    ...state,
    setGrantSubmissionLoading: false,
    setGrantSubmissionError: { error, message },
  })),
  on(getGrantUpload, (state) => ({
    ...state,
    getGrantUploadLoading: true
  })),
  on(getGrantUploadSuccess, (state, { grantUpload }) => ({
    ...state,
    getGrantUploadLoading: false,
    grantUpload
  })),
  on(getGrantUploadFailure, (state, { error, message }) => ({
    ...state,
    getGrantUploadLoading: false,
    getGrantUploadFailure: { error, message }
  })),
  on(getGrantUploads, (state) => ({
    ...state,
    getGrantUploadsLoading: true
  })),
  on(getGrantUploadsSuccess, (state, { grantUploads }) => ({
    ...state,
    getGrantUploadsLoading: false,
    grantUploads
  })),
  on(getGrantUploadsFailure, (state, { error, message }) => ({
    ...state,
    getGrantUploadsLoading: false,
    getGrantUploadsFailure: { error, message }
  })),
  on(getGrantUploadStatus, (state) => ({
    ...state,
    getGrantUploadStatusLoading: true
  })),
  on(getGrantUploadStatusSuccess, (state, { grantUploadStatus }) => ({
    ...state,
    getGrantUploadStatusLoading: false,
    grantUploadStatus
  })),
  on(getGrantUploadStatusFailure, (state, { error, message }) => ({
    ...state,
    getGrantUploadStatusLoading: false,
    getGrantUploadStatusFailure: { error, message }
  })),
  on(validateFile, (state) => ({
    ...state,
    validateFileLoading: true
  })),
  on(validateFileSuccess, (state) => ({
    ...state,
    validateFileLoading: false,
  })),
  on(validateFileFailure, (state, { error, message }) => ({
    ...state,
    validateFileLoading: false,
    validateFileFailure: { error, message }
  })),
  on(storeFile, (state) => ({
    ...state,
    storeFileLoading: true
  })),
  on(storeFileSuccess, (state) => ({
    ...state,
    storeFileLoading: false,
  })),
  on(storeFileFailure, (state, { error, message }) => ({
    ...state,
    storeFileLoading: false,
    storeFileFailure: { error, message }
  })),
  on(cancelStoreFileRequest, (state) => ({
    ...state,
    storeFileLoading: false,
  })),
  on(updateStoreFileUploadProgress, (state, { storeFileUploadProgress }) => ({
    ...state,
    storeFileUploadProgress
  })),
  on(getFileColumns, (state) => ({
    ...state,
    getFileColumnsLoading: true
  })),
  on(getFileColumnsSuccess, (state, { fileColumns }) => ({
    ...state,
    getFileColumnsLoading: false,
    fileColumns
  })),
  on(getFileColumnsFailure, (state, { error, message }) => ({
    ...state,
    getFileColumnsLoading: false,
    getFileColumnsFailure: { error, message }
  })),
  on(getUploadHistories, (state) => ({
    ...state,
    getUploadHistoriesLoading: true
  })),
  on(getUploadHistoriesSuccess, (state, { uploadHistories }) => ({
    ...state,
    getUploadHistoriesLoading: false,
    uploadHistories
  })),
  on(getUploadHistoriesFailure, (state, { error, message }) => ({
    ...state,
    getUploadHistoriesLoading: false,
    getUploadHistoriesFailure: { error, message }
  })),
  on(completeUpload, (state) => ({
    ...state,
    completeUploadLoading: true
  })),
  on(completeUploadSuccess, (state) => ({
    ...state,
    completeUploadLoading: false,
  })),
  on(completeUploadFailure, (state, { error, message }) => ({
    ...state,
    completeUploadLoading: false,
    completeUploadFailure: { error, message }
  })),
  on(updateGrantSubmissionStatus, (state) => ({
    ...state,
    updateGrantSubmissionStatusLoading: true
  })),
  on(updateGrantSubmissionStatusSuccess, (state) => ({
    ...state,
    updateGrantSubmissionStatusLoading: false,
  })),
  on(updateGrantSubmissionStatusFailure, (state, { error, message }) => ({
    ...state,
    updateGrantSubmissionStatusLoading: false,
    updateGrantSubmissionStatusFailure: { error, message }
  })),
);

export const reducer = (state: State | undefined, action: Action) =>
  grantReducer(state, action);
