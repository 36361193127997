import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {} from '@core/models';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class SettingsService extends BaseService {
  private reportsUrl = `${this.baseURL}/reports`;

  constructor(private http: HttpClient) {
    super();
  }
}
