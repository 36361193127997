import { Resource } from '@shared/enums';

export const appMenuConfig = [
  {
    title: '',
    items: [
      {
        id: 'home',
        label: 'Home',
        icon: 'home',
        route: '/home',
      },
      {
        id: 'my-grants',
        label: 'My Grants',
        icon: 'bookmark_added',
        route: '/my-grants',
        resourceAccess: [Resource.ViewMyGrants],
      },
      {
        id: 'status-all',
        label: 'Status Report',
        icon: 'playlist_add_check',
        route: '/status',
        resourceAccess: [Resource.ViewSubmissionReportAllGrants],
      },
    ],
  },
  {
    title: 'Data Entry',
    items: [
      {
        id: 'status-grant',
        label: 'Status Report',
        icon: 'playlist_add_check',
        route: '/status/:grantId',
        resourceAccess: [Resource.ViewGrantees, Resource.ViewProvider],
      },
      {
        id: 'grants',
        label: 'Grant',
        icon: 'ballot',
        route: '/grant',
        resourceAccess: [Resource.ViewGrantees],
      },
      {
        id: 'providers',
        label: 'Providers',
        icon: 'dataset',
        route: '/providers',
        resourceAccess: [Resource.ViewProvider, Resource.ViewProviders],
      },
      {
        id: 'programs',
        label: 'Program Models',
        icon: 'library_books',
        route: '/providers/:providerId/program-models',
        resourceAccess: [Resource.ViewProgramModelsMenu],
      },
      {
        id: 'participant-surveys',
        label: 'Participant Surveys',
        icon: 'cloud_upload',
        route: '/participant-surveys',
        resourceAccess: [Resource.ViewParticipantSurveys],
      },
    ],
  },
  {
    title: '',
    items: [
      {
        id: 'dashboard',
        label: 'Dashboard',
        icon: 'bar_chart_4_bars',
        route: '/dashboard',
        resourceAccess: [Resource.ViewDashboard],
      },
      {
        id: 'users',
        label: 'Users',
        icon: 'people_alt',
        route: '/users',
        resourceAccess: [Resource.ViewUsersBase],
      },
      {
        id: 'help',
        label: 'Help',
        icon: 'help_outline',
        route: '/help',
      },
    ],
  },
];
