<app-page-loader
  *ngIf="associationsLoading$ | async"
  text="Loading..."
></app-page-loader>
<ng-container *ngIf="associations$ | async as associations">
  <app-auth-card
    size="large"
    *ngIf="showSelection"
    [progress]="setAssociationsLoading$ | async"
  >
    <span auth-card-header>Select a {{ associationLabel }}</span>
    <div class="association-description">
      <p>
        Your account has access to all {{ associationLabelPlural }} listed
        below. Please select a {{ associationLabel.toLowerCase() }} to enter the
        portal:
      </p>
    </div>
    <app-associations-list
      class="flex-column association-list"
      [associations]="associations"
      (associationSelected)="setAssociation($event)"
    >
    </app-associations-list>
    <ng-container auth-card-actions>
      <button mat-stroked-button color="primary" (click)="signOut()">
        Sign Out
      </button>
    </ng-container>
  </app-auth-card>
</ng-container>
