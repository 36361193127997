import { Action, createReducer, on } from '@ngrx/store';
import { signInSuccess } from '../authentication/actions';
import {
  changePassword,
  changePasswordFailure,
  changePasswordSuccess,
  getAccount,
  getAccountFailure,
  getAccountSuccess,
  getAssociationDetails,
  getAssociationDetailsFailure,
  getAssociationDetailsSuccess,
  getAssociations,
  getAssociationsFailure,
  getAssociationsSuccess,
  getSelectedAssociation,
  getSelectedAssociationFailure,
  getSelectedAssociationSuccess,
  getUserData,
  getUserDataFailure,
  getUserDataSuccess,
  getUserDetails,
  getUserDetailsFailure,
  getUserDetailsSuccess,
  setSelectedAssociation,
  setSelectedAssociationFailure,
  setSelectedAssociationSuccess,
  updateAccount,
  updateAccountFailure,
  updateAccountSuccess,
} from './actions';
import { initialState, State } from './state';

export const accountFeatureKey = 'account';

const accountReducer = createReducer(
  initialState,
  on(changePassword, (state) => ({
    ...state,
    changePasswordLoading: true,
  })),
  on(changePasswordFailure, (state, { error, message }) => ({
    ...state,
    changePasswordError: { error, message },
    changePasswordLoading: false,
  })),
  on(changePasswordSuccess, (state) => ({
    ...state,
    changePasswordError: null,
    changePasswordLoading: false,
  })),
  on(getAccount, (state) => ({ ...state, accountLoading: true })),
  on(getAccountFailure, (state, { error, message }) => ({
    ...state,
    account: null,
    accountError: { error, message },
    accountLoading: false,
  })),
  on(getAccountSuccess, (state, { account }) => ({
    ...state,
    account,
    accountLoading: false,
  })),

  on(getAssociationDetails, (state) => ({
    ...state,
    associationDetailsLoading: true,
  })),
  on(getAssociationDetailsFailure, (state, { error, message }) => ({
    ...state,
    associationDetails: null,
    associationDetailsError: { error, message },
    associationDetailsLoading: false,
  })),
  on(getAssociationDetailsSuccess, (state, { associationDetails }) => ({
    ...state,
    associationDetails,
    associationDetailsError: null,
    associationDetailsLoading: false,
  })),

  on(getAssociations, (state) => ({ ...state, associationsLoading: true })),
  on(getAssociationsFailure, (state, { error, message }) => ({
    ...state,
    associations: null,
    associationsError: { error, message },
    associationsLoading: false,
  })),
  on(getAssociationsSuccess, (state, { associations }) => ({
    ...state,
    associations,
    associationsError: null,
    associationsLoading: false,
  })),

  on(getSelectedAssociation, (state) => ({
    ...state,
    selectedAssociationLoading: true,
  })),
  on(getSelectedAssociationFailure, (state, { error, message }) => ({
    ...state,
    selectedAssociation: null,
    selectedAssociationError: { error, message },
    selectedAssociationLoading: false,
  })),
  on(getSelectedAssociationSuccess, (state, { selectedAssociation }) => ({
    ...state,
    selectedAssociation,
    selectedAssociationError: null,
    selectedAssociationLoading: false,
  })),

  on(getUserData, (state) => ({ ...state, userDataLoading: true })),
  on(getUserDataFailure, (state, { error, message }) => ({
    ...state,
    userData: null,
    userDataError: { error, message },
    userDataLoading: false,
  })),
  on(getUserDataSuccess, (state, { userData }) => ({
    ...state,
    userData,
    userDataLoading: false,
  })),

  on(getUserDetails, (state) => ({ ...state, userDetailsLoading: true })),
  on(getUserDetailsFailure, (state, { error, message }) => ({
    ...state,
    userDetails: null,
    userDetailsError: { error, message },
    userDetailsLoading: false,
  })),
  on(getUserDetailsSuccess, (state, { userDetails }) => ({
    ...state,
    userDetails,
    userDetailsLoading: false,
  })),

  on(setSelectedAssociation, (state) => ({
    ...state,
    selectedAssociationLoading: true,
  })),
  on(setSelectedAssociationFailure, (state, { error, message }) => ({
    ...state,
    selectedAssociation: null,
    selectedAssociationError: { error, message },
    selectedAssociationLoading: false,
  })),
  on(setSelectedAssociationSuccess, (state, { selectedAssociation }) => ({
    ...state,
    selectedAssociation,
    selectedAssociationError: null,
    selectedAssociationLoading: false,
  })),

  on(signInSuccess, (state, { response }) => ({
    ...state,
    mfaType: response.results.challengeName,
  })),
  on(updateAccount, (state) => ({ ...state, updateAccountLoading: true })),
  on(updateAccountFailure, (state, { error, message }) => ({
    ...state,
    updateAccountError: { error, message },
    updateAccountLoading: false,
  })),
  on(updateAccountSuccess, (state) => ({
    ...state,
    updateAccountLoading: false,
  }))
);

export const reducer = (state: State | undefined, action: Action) =>
  accountReducer(state, action);
