import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ChangePasswordPayload } from '@app/auth/auth.models';
import {
  Account,
  AccountApiResponse,
  AccountUpdatePayload,
  UpdateAccountResponse,
  UserAssociation,
  UserAssociations,
  UserAssociationSelection,
} from '@core/models';
import { environment as env } from 'environments/environment';
import { map, Observable, of } from 'rxjs';
import { BaseService } from './base.service';
import {
  CognitoAccount,
  CognitoService,
  CognitoUserData,
} from './cognito.service';

@Injectable({
  providedIn: 'root',
})
export class AccountService extends BaseService {
  private accountPath = `${this.baseURL}/account`;
  private accountAssociationsPath = `${this.accountPath}/associations`;

  constructor(
    private http: HttpClient,
    private cognito: CognitoService,
  ) {
    super();
  }

  public associationSelected(): Observable<boolean> {
    return this.getSelectedAssociation().pipe(
      map((association) => association !== null),
    );
  }

  public changePassword(payload: ChangePasswordPayload): Observable<null> {
    return this.http.put<null>(`${this.accountPath}/password`, payload);
  }

  public get(): Observable<Account> {
    return new Observable<Account>();
  }

  public getAccount(): Observable<AccountApiResponse> {
    return this.http.get<AccountApiResponse>(`${this.accountPath}/profile`);
  }

  public getAssociations(): Observable<UserAssociations> {
    return this.http.get<UserAssociations>(this.accountAssociationsPath);
  }

  public getAssociation(
    association: UserAssociationSelection,
  ): Observable<UserAssociation> {
    return this.http.get<UserAssociation>(
      `${this.accountAssociationsPath}/${association.selectionType}/${association.id}`,
    );
  }

  public getSelectedAssociation(): Observable<UserAssociationSelection> {
    const association = JSON.parse(
      sessionStorage.getItem('selectedAssociation'),
    ) as unknown as UserAssociationSelection;
    return of(association);
  }

  public getUserAvatar(id: string, location: string, userName: string): string {
    const loc = location.split('-')[0];
    return `${env.avatarBase}${loc}/${userName}-${id}.jpg`;
  }

  public getUserData(): Observable<CognitoUserData> {
    return this.cognito.getUserData();
  }

  public getUserDetails(): Observable<CognitoAccount> {
    return this.cognito.getUserDetails();
  }

  public setSelectedAssociation(
    association: UserAssociationSelection,
  ): Observable<UserAssociationSelection> {
    sessionStorage.setItem('selectedAssociation', JSON.stringify(association));
    return of(association);
  }

  public updateAccount(
    payload: AccountUpdatePayload,
  ): Observable<UpdateAccountResponse> {
    return this.http.put<UpdateAccountResponse>(
      `${this.accountPath}/profile`,
      payload,
    );
  }

  private getStorageItem(field: string): string {
    return sessionStorage.getItem(field) || '';
  }
}
