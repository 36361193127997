import { INotification } from '@core/models';

export interface State {
  addError: string;
  addLoading: boolean;
  removeError: string;
  removeLoading: boolean;
  reset: string;
  resetError: string;
  resetLoading: boolean;
  notifications: INotification[];
  notificationsError: string;
  notificationsLoading: boolean;
}

export const initialState: State = {
  addError: '',
  addLoading: false,
  removeError: '',
  removeLoading: false,
  reset: null,
  resetError: '',
  resetLoading: false,
  notifications: [],
  notificationsError: '',
  notificationsLoading: false,
};
