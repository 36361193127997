<div class="flex-row mat-h3">{{ title }}</div>
<div class="list">
  <ul>
    <li *ngFor="let link of menu; let i = index">
      <a
        [class.active]="activeIndex === i"
        matRipple
        href="#{{ link.id }}"
        (click)="scrollTo(link.id, i, $event)"
      >
        <mat-icon>navigate_next</mat-icon>
        <span>{{ link.label }}</span>
      </a>
    </li>
  </ul>
</div>
<div class="flex-row mat-h3">
  <a href="#" class="flex-row" (click)="scrollToTop($event)">
    <mat-icon>arrow_circle_up</mat-icon>
    <span>Back to top</span>
  </a>
</div>
