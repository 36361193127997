import { Injectable } from '@angular/core';
import { handleError } from '@core/helpers';
import {
  Provider,
  ProviderListResponse,
  ProviderSubmission,
} from '@core/models';
import { ProviderService } from '@core/services/provider.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  addProvider,
  addProviderFailure,
  addProviderSuccess,
  getProvider,
  getProviderFailure,
  getProviders,
  getProvidersFailure,
  getProvidersSuccess,
  getProviderSubmission,
  getProviderSubmissionFailure,
  getProviderSubmissionSuccess,
  getProviderSuccess,
  setProviderActiveStatus,
  setProviderActiveStatusFailure,
  setProviderActiveStatusSuccess,
  updateProviderSubmission,
  updateProviderSubmissionFailure,
  updateProviderSubmissionSuccess,
} from './actions';

@Injectable()
export class ProviderEffects {
  addProviders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addProvider),
      switchMap(({ payload }) =>
        this.providerService.addProvider(payload).pipe(
          map(() => addProviderSuccess()),
          catchError((error) => handleError(error, addProviderFailure))
        )
      )
    )
  );

  getProvider$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getProvider),
      switchMap(({ providerId }) =>
        this.providerService.getProvider(providerId).pipe(
          map((provider: Provider) => getProviderSuccess({ provider })),
          catchError((error) => handleError(error, getProviderFailure))
        )
      )
    )
  );

  getProviders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getProviders),
      switchMap(({ selectedAssociation }) =>
        this.providerService.getProviders(selectedAssociation).pipe(
          map((providers: ProviderListResponse) =>
            getProvidersSuccess({ providers })
          ),
          catchError((error) => handleError(error, getProvidersFailure))
        )
      )
    )
  );

  getProviderSubmission$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getProviderSubmission),
      switchMap(({ providerId }) =>
        this.providerService.getProviderSubmission(providerId).pipe(
          map((providerSubmission: ProviderSubmission) =>
            getProviderSubmissionSuccess({ providerSubmission })
          ),
          catchError((error) =>
            handleError(error, getProviderSubmissionFailure)
          )
        )
      )
    )
  );

  setProviderActiveStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setProviderActiveStatus),
      switchMap(({ providerId, activeStatus }) =>
        this.providerService
          .setProviderActiveStatus(providerId, activeStatus)
          .pipe(
            map(() =>
              setProviderActiveStatusSuccess({ providerId, activeStatus })
            ),
            catchError((error) =>
              handleError(error, setProviderActiveStatusFailure)
            )
          )
      )
    )
  );

  updateProviderSubmission$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateProviderSubmission),
      switchMap(({ providerId, payload }) =>
        this.providerService.updateProviderSubmission(providerId, payload).pipe(
          map(() => updateProviderSubmissionSuccess()),
          catchError((error) =>
            handleError(error, updateProviderSubmissionFailure)
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private providerService: ProviderService
  ) {}
}
