import { AccountState } from './account';
import { SettingsState } from './admin';
import { AuthenticationState } from './authentication';
import { DashboardState } from './dashboard';
import { GrantState } from './grant';
import { MenuState } from './menu';
import { NotificationState } from './notification';
import { ProgramModelState } from './program-model';
import { ProviderState } from './provider';
import { ResourceAccessState } from './resource-access';
import { StatusReportState } from './status-report';
import { SubmissionPeriodState } from './submission-period';
import { UserAppState } from './user-app';
import { UserState } from './users';
/** Add Feature module interfaces here */
export interface State {
  account: AccountState.State;
  authentication: AuthenticationState.State;
  dashboard: DashboardState.State;
  grant: GrantState.State;
  menu: MenuState.State;
  notification: NotificationState.State;
  programModel: ProgramModelState.State;
  provider: ProviderState.State;
  resourceAccess: ResourceAccessState.State;
  settings: SettingsState.State;
  statusReport: StatusReportState.State;
  submissionPeriod: SubmissionPeriodState.State;
  user: UserState.State;
  userApp: UserAppState.State;
}
