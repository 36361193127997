import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';

/**
 * Displays (all inline) label and radio group with yes/no radio buttons.
 * This takes a FormControl object to bind with the radio group.
 */
@Component({
  selector: 'app-label-radio-group',
  templateUrl: './label-radio-group.component.html',
  styleUrls: ['./label-radio-group.component.scss'],
})
export class LabelRadioGroupComponent {
  @Input() id: string;
  @Input() control: FormControl;
  @Output() radioChange = new EventEmitter();

  public get labelId(): string {
    return `${this.id}-label`;
  }

  public onChange(event: MatRadioChange): void {
    this.radioChange.emit(event.value);
  }
}
