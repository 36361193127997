import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivationStart, NavigationEnd, Router } from '@angular/router';
import {
  AccountActions,
  AccountSelectors,
  AppStoreState,
  MenuActions,
  NotificationSelectors,
  ResourceAccessActions,
} from '@app/store';
import { INotification, UserAssociationSelection } from '@core/models';
import { Store } from '@ngrx/store';
import { environment as env } from 'environments/environment';
import { Observable, Subject } from 'rxjs';
import { delay, filter, map, takeUntil, tap } from 'rxjs/operators';
import { TsAppVersion, versions } from '../_versions';
import { associationToIds } from './shared/utilities';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy, OnInit {
  @HostBinding('attr.app-version') appVersion = '';
  @HostBinding('attr.app-version-date') appVersionDate = '';

  public appLoading = false;
  public appLoadingText = 'Loading...';
  public notifications$: Observable<INotification[]>;

  private destroyed$ = new Subject<boolean>();
  private selectedAssociation$: Observable<UserAssociationSelection>;

  constructor(
    private router: Router,
    private store$: Store<AppStoreState.State>,
    private title: Title,
  ) {
    this.notifications$ = this.store$.select(
      NotificationSelectors.selectNotifications,
    );

    this.selectedAssociation$ = this.store$.select(
      AccountSelectors.selectSelectedAssociation,
    );
  }

  public get versions(): TsAppVersion {
    return versions;
  }

  ngOnInit(): void {
    this.appVersion = versions.versionLong;
    this.appVersionDate = versions.versionDate;

    // Set the title based on the route
    this.router.events
      .pipe(
        filter((event) => event instanceof ActivationStart),
        // eslint-disable-next-line @typescript-eslint/dot-notation
        map((event) => event['snapshot'].data),
        filter((data) => !!data),
      )
      .subscribe((data) => {
        this.title.setTitle(
          data.title ? data.title + ' | ' + env.appTitle : env.appTitle,
        );

        if (data.adminMenu) {
          this.store$.dispatch(
            MenuActions.setActiveAdminMenu({ payload: data.adminMenu }),
          );
        } else if (data.appMenu) {
          this.store$.dispatch(
            MenuActions.setActiveMenu({ payload: data.appMenu }),
          );
        }
      });

    // Scroll to top (main app)
    this.router.events
      .pipe(
        delay(100),
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.destroyed$),
        tap(() => document.querySelector('main')?.scrollTo(0, 0)),
      )
      .subscribe();

    this.selectedAssociation$
      .pipe(
        filter((selectedAssociation) => !!selectedAssociation),
        takeUntil(this.destroyed$),
        tap((selectedAssociation) => {
          const payload = associationToIds(selectedAssociation);

          if (Object.values(payload).some((value) => value.length > 0)) {
            this.store$.dispatch(
              AccountActions.getAssociationDetails({ selectedAssociation }),
            );
          }
          this.store$.dispatch(
            ResourceAccessActions.getResourceAccess({ payload }),
          );
          this.store$.dispatch(AccountActions.getUserData());
        }),
      )
      .subscribe();

    this.store$.dispatch(AccountActions.getSelectedAssociation());
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
