import { Injectable } from '@angular/core';
import {
  ConfirmResetPasswordResponse,
  ConfirmSignInResponse,
  NewPasswordResponse,
  NewUserResponse,
  ResetPasswordResponse,
  SignInResponse,
} from '@auth/auth.models';
import { handleError } from '@core/helpers/format-error';
import { AuthService, CognitoResponse } from '@core/services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  associateSoftwareToken,
  associateSoftwareTokenFailure,
  associateSoftwareTokenSuccess,
  confirmCustomSignIn,
  confirmCustomSignInFailure,
  confirmCustomSignInSuccess,
  confirmResetPassword,
  confirmResetPasswordFailure,
  confirmResetPasswordSuccess,
  confirmSignIn,
  confirmSignInFailure,
  confirmSignInSuccess,
  getInputVerificationCode,
  getInputVerificationCodeFailure,
  getInputVerificationCodeSuccess,
  resetPassword,
  resetPasswordFailure,
  resetPasswordSuccess,
  setMFASelection,
  setMFASelectionFailure,
  setMFASelectionSuccess,
  setNewPassword,
  setNewPasswordFailure,
  setNewPasswordSuccess,
  setNewUser,
  setNewUserFailure,
  setNewUserSuccess,
  signIn,
  signInFailure,
  signInSuccess,
  signOut,
  signOutFailure,
  signOutSuccess,
  updateUserAttributes,
  updateUserAttributesFailure,
  updateUserAttributesSuccess,
  verifyAttribute,
  verifyAttributeFailure,
  verifyAttributeSuccess,
  verifySoftwareToken,
  verifySoftwareTokenFailure,
  verifySoftwareTokenSuccess,
} from './actions';

@Injectable()
export class AuthenticationEffects {
  associateSoftwareToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(associateSoftwareToken),
      switchMap(() =>
        this.authenticationService.associateSoftwareToken().pipe(
          map((response: CognitoResponse) =>
            associateSoftwareTokenSuccess({ response })
          ),
          catchError((error) =>
            handleError(error, associateSoftwareTokenFailure)
          )
        )
      )
    )
  );

  // commenting out because this will eventually become setNew
  confirmResetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(confirmResetPassword),
      switchMap(({ payload }) =>
        this.authenticationService.confirmResetPassword(payload).pipe(
          map((response: ConfirmResetPasswordResponse) =>
            confirmResetPasswordSuccess({ response })
          ),
          catchError((error) => handleError(error, confirmResetPasswordFailure))
        )
      )
    )
  );

  confirmSignIn$ = createEffect(() =>
    this.actions$.pipe(
      ofType(confirmSignIn),
      switchMap(({ payload }) =>
        this.authenticationService.confirmSignIn(payload).pipe(
          map((response: ConfirmSignInResponse) =>
            confirmSignInSuccess({ response })
          ),
          catchError((error) => handleError(error, confirmSignInFailure))
        )
      )
    )
  );

  confirmCustomSignIn$ = createEffect(() =>
    this.actions$.pipe(
      ofType(confirmCustomSignIn),
      switchMap(({ payload }) =>
        this.authenticationService.confirmCustomSignIn(payload).pipe(
          map((response: ConfirmSignInResponse) =>
            confirmCustomSignInSuccess({ response })
          ),
          catchError((error) => handleError(error, confirmCustomSignInFailure))
        )
      )
    )
  );

  getInputVerificationCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getInputVerificationCode),
      switchMap(({ payload }) =>
        this.authenticationService.getInputVerificationCode(payload).pipe(
          map((response: any) => getInputVerificationCodeSuccess({ response })),
          catchError((error) =>
            handleError(error, getInputVerificationCodeFailure)
          )
        )
      )
    )
  );

  resetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resetPassword),
      switchMap(({ payload }) =>
        this.authenticationService.resetPassword(payload).pipe(
          map((response: ResetPasswordResponse) =>
            resetPasswordSuccess({ response })
          ),
          catchError((error) => handleError(error, resetPasswordFailure))
        )
      )
    )
  );

  setNewPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setNewPassword),
      switchMap(({ payload }) =>
        this.authenticationService.setNewPassword(payload).pipe(
          map((response: NewPasswordResponse) =>
            setNewPasswordSuccess({ response })
          ),
          catchError((error) => handleError(error, setNewPasswordFailure))
        )
      )
    )
  );

  setMFASelection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setMFASelection),
      switchMap(({ payload }) =>
        this.authenticationService.setMFAPreference(payload).pipe(
          map((response: any) => setMFASelectionSuccess({ response })),
          catchError((error) => handleError(error, setMFASelectionFailure))
        )
      )
    )
  );

  setNewUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setNewUser),
      switchMap(({ payload }) =>
        this.authenticationService.setNewUser(payload).pipe(
          map((response: NewUserResponse) => setNewUserSuccess({ response })),
          catchError((error) => handleError(error, setNewUserFailure))
        )
      )
    )
  );

  signIn$ = createEffect(() =>
    this.actions$.pipe(
      ofType(signIn),
      switchMap(({ payload }) =>
        this.authenticationService.signIn(payload).pipe(
          map((response: SignInResponse) => signInSuccess({ response })),
          catchError((error) => handleError(error, signInFailure))
        )
      )
    )
  );

  signOut$ = createEffect(() =>
    this.actions$.pipe(
      ofType(signOut),
      switchMap(() =>
        this.authenticationService.signOut().pipe(
          map(() => signOutSuccess()),
          catchError((error) => handleError(error, signOutFailure))
        )
      )
    )
  );

  updateUserAttributes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateUserAttributes),
      switchMap(({ payload }) =>
        this.authenticationService.updateUserAttributes(payload).pipe(
          map((response: any) => updateUserAttributesSuccess({ response })),
          catchError((error) => handleError(error, updateUserAttributesFailure))
        )
      )
    )
  );

  verifyAttribute$ = createEffect(() =>
    this.actions$.pipe(
      ofType(verifyAttribute),
      switchMap(({ payload }) =>
        this.authenticationService.verifyAttribute(payload).pipe(
          map((response: any) => verifyAttributeSuccess({ response })),
          catchError((error) => handleError(error, verifyAttributeFailure))
        )
      )
    )
  );

  verifySoftwareToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(verifySoftwareToken),
      switchMap(({ payload }) =>
        this.authenticationService.verifySoftwareToken(payload).pipe(
          map((response: CognitoUserSession) =>
            verifySoftwareTokenSuccess({ response })
          ),
          catchError((error) => handleError(error, verifySoftwareTokenFailure))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private authenticationService: AuthService
  ) {}
}
