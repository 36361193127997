import { ApiError, Dashboard, DashboardUrl } from '@core/models';
import { createAction, props } from '@ngrx/store';

export enum DashboardActionTypes {
  GET_DASHBOARD_LIST_REQUEST = '[Dashboard] Get Dashboard list request',
  GET_DASHBOARD_LIST_SUCCESS = '[Dashboard] Get Dashboard list success',
  GET_DASHBOARD_LIST_FAILURE = '[Dashboard] Get Dashboard list failure',
  GET_DASHBOARD_URL_REQUEST = '[Dashboard] Get Dashboard URL request',
  GET_DASHBOARD_URL_SUCCESS = '[Dashboard] Get Dashboard URL success',
  GET_DASHBOARD_URL_FAILURE = '[Dashboard] Get Dashboard URL failure',
  RESET_DASHBOARD_URL_REQUEST = '[Dashboard] Reset Dashboard URL request',
  RESET_DASHBOARD_URL_SUCCESS = '[Dashboard] Reset Dashboard URL success',
  RESET_DASHBOARD_URL_FAILURE = '[Dashboard] Reset Dashboard URL failure',
}

export const getDashboardList = createAction(
  DashboardActionTypes.GET_DASHBOARD_LIST_REQUEST,
  props<{ grantId: string }>(),
);

export const getDashboardListSuccess = createAction(
  DashboardActionTypes.GET_DASHBOARD_LIST_SUCCESS,
  props<{ dashboardList: Dashboard[] }>(),
);

export const getDashboardListFailure = createAction(
  DashboardActionTypes.GET_DASHBOARD_LIST_FAILURE,
  props<ApiError>(),
);

export const getDashboardUrl = createAction(
  DashboardActionTypes.GET_DASHBOARD_URL_REQUEST,
  props<{ dashboard: Dashboard; grantId: string }>(),
);

export const getDashboardUrlSuccess = createAction(
  DashboardActionTypes.GET_DASHBOARD_URL_SUCCESS,
  props<{ dashboard: Dashboard; dashboardUrl: DashboardUrl }>(),
);

export const getDashboardUrlFailure = createAction(
  DashboardActionTypes.GET_DASHBOARD_URL_FAILURE,
  props<ApiError>(),
);

export const resetDashboardUrl = createAction(
  DashboardActionTypes.RESET_DASHBOARD_URL_REQUEST,
);

export const resetDashboardUrlSuccess = createAction(
  DashboardActionTypes.RESET_DASHBOARD_URL_SUCCESS,
);

export const resetDashboardUrlFailure = createAction(
  DashboardActionTypes.RESET_DASHBOARD_URL_FAILURE,
  props<ApiError>(),
);
