<h1 mat-dialog-title>
  <mat-icon *ngIf="dialogData.dialogIcon">{{ dialogData.dialogIcon }}</mat-icon>
  <span>{{ dialogData.dialogTitle }}</span>
</h1>
<mat-dialog-content>
  This site has encountered an error. For security reasons you will now be
  signed out.
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-flat-button color="primary" mat-dialog-close>OK</button>
</mat-dialog-actions>
