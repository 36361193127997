export enum Resource {
  AddPrograms = 'AddPrograms',
  AddProviders = 'AddProviders',
  AddAllUsersInSystem = 'AddAllUsersInSystem',
  AddNonPrivilegedUsersInSystem = 'AddNonPrivilegedUsersInSystem',
  AddSubmissionPeriod = 'AddSubmissionPeriod',
  AddUsers = 'AddUsers',
  MultipleGrantRequest = 'MultipleGrantRequest',
  RemoveAllUsersInSystem = 'RemoveAllUsersInSystem',
  RemoveNonPrivilegedUsersInSystem = 'RemoveNonPrivilegedUsersInSystem',
  RemoveUser = 'RemoveUser',
  UpdateGrantSubmissionData = 'UpdateGrantSubmissionData',
  UpdateProgramSubmissionData = 'UpdateProgramSubmissionData',
  UpdateProviderActive = 'UpdateProviderActive',
  UpdateProviderSubmissionData = 'UpdateProviderSubmissionData',
  UpdateSubmissionPeriod = 'UpdateSubmissionPeriod',
  ViewAllUsersInSystem = 'ViewAllUsersInSystem',
  ViewDashboard = 'ViewDashboard',
  ViewGrantees = 'ViewGrantees',
  ViewGrantSubmissionData = 'ViewGrantSubmissionData',
  ViewSubmissionReportAllGrants = 'ViewSubmissionReportAllGrants',
  ViewNonPrivilegedUsersInSystem = 'ViewNonPrivilegedUsersInSystem',
  ViewParticipantSurveys = 'ViewParticipantSurveys',
  ViewLandingPagePO = 'ViewLandingPagePo',
  ViewMyGrants = 'ViewMyGrants',
  ViewProgramModels = 'ViewProgramModels',
  ViewProgramModelsMenu = 'ViewProgramModelsMenu',
  ViewProgramSubmissionData = 'ViewProgramSubmissionData',
  ViewProviders = 'ViewProviders',
  ViewProvider = 'ViewProvider',
  ViewProviderSubmissionData = 'ViewProviderSubmissionData',
  ViewSystemAdmin = 'ViewSystemAdmin',
  ViewUsers = 'ViewUsers',
  ViewUsersBase = 'ViewUsersBase',
}
