import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { MenuEffects } from './effects';
import { menuFeatureKey, reducer } from './reducer';

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature([MenuEffects]),
    StoreModule.forFeature(menuFeatureKey, reducer),
  ],
})
export class MenuModule {}
