import { ApiError } from '@core/models';

export interface State {
  updateGrantFavoritesLoading: boolean;
  updateGrantFavoritesFailure: ApiError;
  getGrantFavoritesLoading: boolean;
  getGrantFavoritesFailure: ApiError;
  grantFavoriteIds: string[];
}

export const initialState: State = {
  updateGrantFavoritesLoading: false,
  updateGrantFavoritesFailure: null,
  getGrantFavoritesLoading: false,
  getGrantFavoritesFailure: null,
  grantFavoriteIds: [],
};
