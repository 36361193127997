import * as SettingsActions from './actions';
import * as SettingsEffects from './effects';
import * as SettingsReducer from './reducer';
import * as SettingsSelectors from './selectors';
import * as SettingsState from './state';
export * from './settings.module';
export {
  SettingsActions,
  SettingsEffects,
  SettingsReducer,
  SettingsSelectors,
  SettingsState,
};
