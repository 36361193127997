<h2 mat-dialog-title>Still There?</h2>
<mat-dialog-content class="dialog-content">
  <p>
    You have been idle for almost fifteen minutes. Click the button below to
    remain signed in.
  </p>
  <br />
  <p>
    You will be automatically signed out in
    <span>{{ timeRemaining }}</span> seconds.
  </p>
</mat-dialog-content>
<mat-dialog-actions class="dialog-actions">
  <button mat-flat-button color="primary" (click)="submit()">
    Stay Signed In
  </button>
  <button mat-stroked-button (click)="close()">Sign Out</button>
</mat-dialog-actions>
