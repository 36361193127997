import { ApiError } from '@core/models';
import { CreateSubmissionPeriodPayload, SubmissionPeriod } from '@core/models/submission-period.interface';
import { createAction, props } from '@ngrx/store';

export enum SubmissionPeriodActionTypes {
  GET_ACTIVE_SUBMISSION_PERIOD_REQUEST = '[SubmissionPeriod] Get active submission period request',
  GET_ACTIVE_SUBMISSION_PERIOD_SUCCESS = '[SubmissionPeriod] Get active submission period success',
  GET_ACTIVE_SUBMISSION_PERIOD_FAILURE = '[SubmissionPeriod] Get active submission period failure',
  GET_ALL_SUBMISSION_PERIODS_REQUEST = '[SubmissionPeriod] Get all submission periods request',
  GET_ALL_SUBMISSION_PERIODS_SUCCESS = '[SubmissionPeriod] Get all submission periods success',
  GET_ALL_SUBMISSION_PERIODS_FAILURE = '[SubmissionPeriod] Get all submission periods failure',
  UPDATE_SUBMISSION_PERIOD_REQUEST = '[SubmissionPeriod] Update submission period request',
  UPDATE_SUBMISSION_PERIOD_SUCCESS = '[SubmissionPeriod] Update submission period success',
  UPDATE_SUBMISSION_PERIOD_FAILURE = '[SubmissionPeriod] Update submission period failure',  
  CREATE_SUBMISSION_PERIOD_REQUEST = '[SubmissionPeriod] Create submission period request',
  CREATE_SUBMISSION_PERIOD_SUCCESS = '[SubmissionPeriod] Create submission period success',
  CREATE_SUBMISSION_PERIOD_FAILURE = '[SubmissionPeriod] Create submission period failure',  
}

export const getActiveSubmissionPeriod = createAction(
  SubmissionPeriodActionTypes.GET_ACTIVE_SUBMISSION_PERIOD_REQUEST
);

export const getActiveSubmissionPeriodSuccess = createAction(
  SubmissionPeriodActionTypes.GET_ACTIVE_SUBMISSION_PERIOD_SUCCESS,
  props<{ activeSubmissionPeriod: SubmissionPeriod }>()
);

export const getActiveSubmissionPeriodFailure = createAction(
  SubmissionPeriodActionTypes.GET_ACTIVE_SUBMISSION_PERIOD_FAILURE,
  props<ApiError>()
);

export const getAllSubmissionPeriods = createAction(
  SubmissionPeriodActionTypes.GET_ALL_SUBMISSION_PERIODS_REQUEST
);

export const getAllSubmissionPeriodsSuccess = createAction(
  SubmissionPeriodActionTypes.GET_ALL_SUBMISSION_PERIODS_SUCCESS,
  props<{ submissionPeriods: SubmissionPeriod[] }>()
);

export const getAllSubmissionPeriodsFailure = createAction(
  SubmissionPeriodActionTypes.GET_ALL_SUBMISSION_PERIODS_FAILURE,
  props<ApiError>()
);
export const updateSubmissionPeriod = createAction(
  SubmissionPeriodActionTypes.UPDATE_SUBMISSION_PERIOD_REQUEST,
  props<{ submissionClosed: boolean }>()
);

export const updateSubmissionPeriodSuccess = createAction(
  SubmissionPeriodActionTypes.UPDATE_SUBMISSION_PERIOD_SUCCESS,
);

export const updateSubmissionPeriodFailure = createAction(
  SubmissionPeriodActionTypes.UPDATE_SUBMISSION_PERIOD_FAILURE,
  props<ApiError>()
);

export const createSubmissionPeriod = createAction(
  SubmissionPeriodActionTypes.CREATE_SUBMISSION_PERIOD_REQUEST,
  props<{ payload: CreateSubmissionPeriodPayload }>()
);

export const createSubmissionPeriodSuccess = createAction(
  SubmissionPeriodActionTypes.CREATE_SUBMISSION_PERIOD_SUCCESS,
);

export const createSubmissionPeriodFailure = createAction(
  SubmissionPeriodActionTypes.CREATE_SUBMISSION_PERIOD_FAILURE,
  props<ApiError>()
);

