import {
  ConfirmResetPasswordResponse,
  ConfirmSignInResponse,
  NewUserResponse,
  ResetPasswordResponse,
  SignInResponse,
} from '@app/auth/auth.models';
import { State as AppState } from '@app/store/app-state';
import { StoreError } from '@core/models';
import { CognitoResponse } from '@core/services';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { authenticationFeatureKey } from './reducer';
import { State } from './state';

const getAssociateSoftwareToken = (state: State): CognitoResponse =>
  state.associateSoftwareToken;
const getAssociateSoftwareTokenError = (state: State): StoreError =>
  state.associateSoftwareTokenError;
const getAssociateSoftwareTokenLoading = (state: State): boolean =>
  state.associateSoftwareTokenLoading;
const getConfirmResetPassword = (state: State): ConfirmResetPasswordResponse =>
  state.confirmResetPassword;
const getConfirmResetPasswordError = (state: State): StoreError =>
  state.confirmResetPasswordError;
const getConfirmResetPasswordLoading = (state: State): boolean =>
  state.confirmResetPasswordLoading;
const getConfirmSignIn = (state: State): ConfirmSignInResponse =>
  state.confirmSignIn;
const getConfirmSignInError = (state: State): StoreError =>
  state.confirmSignInError;
const getConfirmSignInLoading = (state: State): boolean =>
  state.confirmSignInLoading;
const getConfirmCustomSignIn = (state: State): ConfirmSignInResponse =>
  state.confirmCustomSignIn;
const getConfirmCustomSignInError = (state: State): StoreError =>
  state.confirmCustomSignInError;
const getConfirmCustomSignInLoading = (state: State): boolean =>
  state.confirmCustomSignInLoading;
const getInputVerificationCode = (state: State): CognitoResponse =>
  state.inputVerificationCode;
const getInputVerificationCodeError = (state: State): StoreError =>
  state.inputVerificationCodeError;
const getInputVerificationCodeLoading = (state: State): boolean =>
  state.inputVerificationCodeLoading;
const getMfa = (state: State): string => state.mfa;
const getMfaError = (state: State): StoreError => state.mfaError;
const getMfaLoading = (state: State): boolean => state.mfaLoading;
const getResetPassword = (state: State): ResetPasswordResponse =>
  state.resetPassword;
const getResetPasswordError = (state: State): StoreError =>
  state.resetPasswordError;
const getResetPasswordLoading = (state: State): boolean =>
  state.resetPasswordLoading;
const getSetMFASelection = (state: State): any => state.setMFASelection;
const getSetMFASelectionError = (state: State): StoreError =>
  state.setMFASelectionError;
const getSetMFASelectionLoading = (state: State): boolean =>
  state.setMFASelectionLoading;
const getSetNewPassword = (state: State): NewUserResponse =>
  state.setNewPassword;
const getSetNewPasswordError = (state: State): StoreError =>
  state.setNewPasswordError;
const getSetNewPasswordLoading = (state: State): boolean =>
  state.setNewPasswordLoading;
const getSetNewUser = (state: State): NewUserResponse => state.setNewUser;
const getSetNewUserError = (state: State): StoreError => state.setNewUserError;
const getSetNewUserLoading = (state: State): boolean => state.setNewUserLoading;
const getSignedOutFromInterceptor = (state: State): boolean =>
  state.signedOutFromInterceptor;
const getSignIn = (state: State): SignInResponse => state.signIn;
const getSignInError = (state: State): StoreError => state.signInError;
const getSignInLoading = (state: State): boolean => state.signInLoading;
const getSignOutError = (state: State): StoreError => state.signOutError;
const getSignOutLoading = (state: State): boolean => state.signOutLoading;
const getUpdateUserAttributes = (state: State): CognitoResponse =>
  state.updateUserAttributes;
const getUpdateUserAttributesError = (state: State): StoreError =>
  state.updateUserAttributesError;
const getUpdateUserAttributesLoading = (state: State): boolean =>
  state.updateUserAttributesLoading;
const getVerifyAttribute = (state: State): CognitoResponse =>
  state.verifyAttribute;
const getVerifyAttributeError = (state: State): StoreError =>
  state.verifyAttributeError;
const getVerifyAttributeLoading = (state: State): boolean =>
  state.verifyAttributeLoading;
const getVerifySoftwareToken = (state: State): CognitoUserSession =>
  state.verifySoftwareToken;
const getVerifySoftwareTokenError = (state: State): StoreError =>
  state.verifySoftwareTokenError;
const getVerifySoftwareTokenLoading = (state: State): boolean =>
  state.verifySoftwareTokenLoading;
export const selectAuthenticationState = createFeatureSelector<AppState, State>(
  authenticationFeatureKey
);

export const selectAssociateSoftwareToken = createSelector(
  selectAuthenticationState,
  getAssociateSoftwareToken
);

export const selectAssociateSoftwareTokenError = createSelector(
  selectAuthenticationState,
  getAssociateSoftwareTokenError
);
export const selectAssociateSoftwareTokenLoading = createSelector(
  selectAuthenticationState,
  getAssociateSoftwareTokenLoading
);

export const selectConfirmResetPassword = createSelector(
  selectAuthenticationState,
  getConfirmResetPassword
);
export const selectConfirmResetPasswordError = createSelector(
  selectAuthenticationState,
  getConfirmResetPasswordError
);
export const selectConfirmResetPasswordLoading = createSelector(
  selectAuthenticationState,
  getConfirmResetPasswordLoading
);

export const selectConfirmSignIn = createSelector(
  selectAuthenticationState,
  getConfirmSignIn
);

export const selectConfirmSignInError = createSelector(
  selectAuthenticationState,
  getConfirmSignInError
);
export const selectConfirmSignInLoading = createSelector(
  selectAuthenticationState,
  getConfirmSignInLoading
);

export const selectConfirmCustomSignIn = createSelector(
  selectAuthenticationState,
  getConfirmCustomSignIn
);

export const selectConfirmCustomSignInError = createSelector(
  selectAuthenticationState,
  getConfirmCustomSignInError
);
export const selectConfirmCustomSignInLoading = createSelector(
  selectAuthenticationState,
  getConfirmCustomSignInLoading
);

export const selectInputVerificationCode = createSelector(
  selectAuthenticationState,
  getInputVerificationCode
);

export const selectInputVerificationCodeError = createSelector(
  selectAuthenticationState,
  getInputVerificationCodeError
);
export const selectInputVerificationCodeLoading = createSelector(
  selectAuthenticationState,
  getInputVerificationCodeLoading
);
export const selectMfa = createSelector(selectAuthenticationState, getMfa);

export const selectMfaError = createSelector(
  selectAuthenticationState,
  getMfaError
);
export const selectMfaLoading = createSelector(
  selectAuthenticationState,
  getMfaLoading
);
export const selectResetPassword = createSelector(
  selectAuthenticationState,
  getResetPassword
);
export const selectResetPasswordError = createSelector(
  selectAuthenticationState,
  getResetPasswordError
);
export const selectResetPasswordLoading = createSelector(
  selectAuthenticationState,
  getResetPasswordLoading
);

export const selectSetMFASelection = createSelector(
  selectAuthenticationState,
  getSetMFASelection
);
export const selectSetMFASelectionError = createSelector(
  selectAuthenticationState,
  getSetMFASelectionError
);
export const selectSetMFASelectionLoading = createSelector(
  selectAuthenticationState,
  getSetMFASelectionLoading
);

export const selectSetNewUser = createSelector(
  selectAuthenticationState,
  getSetNewUser
);
export const selectSetNewUserError = createSelector(
  selectAuthenticationState,
  getSetNewUserError
);
export const selectSetNewUserLoading = createSelector(
  selectAuthenticationState,
  getSetNewUserLoading
);

export const selectSetNewPassword = createSelector(
  selectAuthenticationState,
  getSetNewPassword
);
export const selectSetNewPasswordError = createSelector(
  selectAuthenticationState,
  getSetNewPasswordError
);
export const selectSetNewPasswordLoading = createSelector(
  selectAuthenticationState,
  getSetNewPasswordLoading
);

export const selectSignedOutFromInterceptor = createSelector(
  selectAuthenticationState,
  getSignedOutFromInterceptor
);

export const selectSignIn = createSelector(
  selectAuthenticationState,
  getSignIn
);
export const selectSignInError = createSelector(
  selectAuthenticationState,
  getSignInError
);
export const selectSignInLoading = createSelector(
  selectAuthenticationState,
  getSignInLoading
);

export const selectSignOutError = createSelector(
  selectAuthenticationState,
  getSignOutError
);
export const selectSignOutLoading = createSelector(
  selectAuthenticationState,
  getSignOutLoading
);

export const updateUserAttributes = createSelector(
  selectAuthenticationState,
  getUpdateUserAttributes
);
export const updateUserAttributesError = createSelector(
  selectAuthenticationState,
  getUpdateUserAttributesError
);
export const updateUserAttributesLoading = createSelector(
  selectAuthenticationState,
  getUpdateUserAttributesLoading
);

export const verifyAttribute = createSelector(
  selectAuthenticationState,
  getVerifyAttribute
);
export const verifyAttributeError = createSelector(
  selectAuthenticationState,
  getVerifyAttributeError
);
export const verifyAttributeLoading = createSelector(
  selectAuthenticationState,
  getVerifyAttributeLoading
);

export const selectVerifySoftwareToken = createSelector(
  selectAuthenticationState,
  getVerifySoftwareToken
);
export const selectVerifySoftwareTokenError = createSelector(
  selectAuthenticationState,
  getVerifySoftwareTokenError
);
export const selectVerifySoftwareTokenLoading = createSelector(
  selectAuthenticationState,
  getVerifySoftwareTokenLoading
);
