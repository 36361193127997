import {
  ConfirmResetPasswordPayload,
  ConfirmResetPasswordResponse,
  ConfirmSignInPayload,
  ConfirmSignInResponse,
  NewPasswordResponse,
  NewUserPayload,
  NewUserResponse,
  ResetPasswordPayload,
  ResetPasswordResponse,
  SetMFAPreferencePayload,
  SignInPayload,
  SignInResponse,
  UpdateUserAttributesPayload,
  VerifyAttributePayload,
} from '@auth/auth.models';
import { VerifySoftwareTokenParams } from '@core/models';
import { CognitoErrorResponse, CognitoResponse } from '@core/services';
import { createAction, props } from '@ngrx/store';
import { CognitoUserSession } from 'amazon-cognito-identity-js';

export enum AuthenticationActionTypes {
  ASSOCIATE_SOFTWARE_TOKEN = '[Authentication] Associate Software Token',
  ASSOCIATE_SOFTWARE_TOKEN_FAILURE = '[Authentication] Associate Software Token Failure',
  ASSOCIATE_SOFTWARE_TOKEN_SUCCESS = '[Authentication] Associate Software Token Success',
  CONFIRM_RESET_PASSWORD = '[Authentication] Confirm Reset Password Request',
  CONFIRM_RESET_PASSWORD_FAILURE = '[Authentication] Confirm Reset Password Failure',
  CONFIRM_RESET_PASSWORD_SUCCESS = '[Authentication] Confirm Reset Password Success',
  CONFIRM_SIGNIN = '[Authentication] Confirm Sign in Request',
  CONFIRM_SIGNIN_FAILURE = '[Authentication] Confirm Sign in Failure',
  CONFIRM_SIGNIN_SUCCESS = '[Authentication] Confirm Sign in Success',
  CONFIRM_CUSTOM_SIGNIN = '[Authentication] Confirm Custom Sign in Request',
  CONFIRM_CUSTOM_SIGNIN_FAILURE = '[Authentication] Custom Confirm Sign in Failure',
  CONFIRM_CUSTOM_SIGNIN_SUCCESS = '[Authentication] Custom Confirm Sign in Success',
  GET_INPUT_VERIFICATION_CODE = '[Authentication] Get Input Verification Code Request',
  GET_INPUT_VERIFICATION_CODE_FAILURE = '[Authentication] Get Input Verification Code Failure',
  GET_INPUT_VERIFICATION_CODE_SUCCESS = '[Authentication] Get Input Verification Code Success',
  RESET_PASSWORD = '[Authentication] Reset Password Request',
  RESET_PASSWORD_FAILURE = '[Authentication] Reset Password Failure',
  RESET_PASSWORD_SUCCESS = '[Authentication] Reset Password Success',
  SET_NEW_PASSWORD = '[Authentication] Set New Password Request',
  SET_NEW_PASSWORD_FAILURE = '[Authentication] Set New Password Failure',
  SET_NEW_PASSWORD_SUCCESS = '[Authentication] Set New Password Success',
  SET_MFA_SELECTION = '[Authentication] Send MFA Selection Request',
  SET_MFA_SELECTION_FAILURE = '[Authentication] Send MFA Selection Failure',
  SET_MFA_SELECTION_SUCCESS = '[Authentication] Send MFA Selection Success',
  SET_NEW_USER = '[Authentication] Set New User Request',
  SET_NEW_USER_FAILURE = '[Authentication] Set New User Failure',
  SET_NEW_USER_SUCCESS = '[Authentication] Set New User Success',
  SIGNIN = '[Authentication] Sign in Request',
  SIGNIN_FAILURE = '[Authentication] Sign in Failure',
  SIGNIN_SUCCESS = '[Authentication] Sign in Success',
  SIGNOUT = '[Authentication] Sign out Request',
  SIGNOUT_FAILURE = '[Authentication] Sign out Failure',
  SIGNED_OUT_FROM_INTERCEPTOR = '[Authentication] Signed Out From Interceptor',
  SIGNOUT_SUCCESS = '[Authentication] Sign out Success',
  UPDATE_USER_ATTRIBUTES = '[Authentication] Update User Attributes Request',
  UPDATE_USER_ATTRIBUTES_FAILURE = '[Authentication] Update User Attributes Failure',
  UPDATE_USER_ATTRIBUTES_SUCCESS = '[Authentication] Update User Attributes Success',
  VERIFY_ATTRIBUTE = '[Authentication] Verify Attribute Request',
  VERIFY_ATTRIBUTE_FAILURE = '[Authentication] Verify Attribute Failure',
  VERIFY_ATTRIBUTE_SUCCESS = '[Authentication] Verify Attribute Success',
  VERIFY_SOFTWARE_TOKEN = '[Authentication] Verify Software Token',
  VERIFY_SOFTWARE_TOKEN_FAILURE = '[Authentication] Verify Software Token Failure',
  VERIFY_SOFTWARE_TOKEN_SUCCESS = '[Authentication] Verify Software Token Success',
}

export const associateSoftwareToken = createAction(
  AuthenticationActionTypes.ASSOCIATE_SOFTWARE_TOKEN
);
export const associateSoftwareTokenFailure = createAction(
  AuthenticationActionTypes.ASSOCIATE_SOFTWARE_TOKEN_FAILURE,
  props<{ error: CognitoErrorResponse; message: string }>()
);
export const associateSoftwareTokenSuccess = createAction(
  AuthenticationActionTypes.ASSOCIATE_SOFTWARE_TOKEN_SUCCESS,
  props<{ response: CognitoResponse }>()
);

export const confirmResetPassword = createAction(
  AuthenticationActionTypes.CONFIRM_RESET_PASSWORD,
  props<{ payload: ConfirmResetPasswordPayload }>()
);
export const confirmResetPasswordFailure = createAction(
  AuthenticationActionTypes.CONFIRM_RESET_PASSWORD_FAILURE,
  props<{ error: CognitoErrorResponse; message: string }>()
);
export const confirmResetPasswordSuccess = createAction(
  AuthenticationActionTypes.CONFIRM_RESET_PASSWORD_SUCCESS,
  props<{ response: ConfirmResetPasswordResponse }>()
);

export const confirmSignIn = createAction(
  AuthenticationActionTypes.CONFIRM_SIGNIN,
  props<{ payload: ConfirmSignInPayload }>()
);
export const confirmSignInFailure = createAction(
  AuthenticationActionTypes.CONFIRM_SIGNIN_FAILURE,
  props<{ error: CognitoErrorResponse; message: string }>()
);
export const confirmSignInSuccess = createAction(
  AuthenticationActionTypes.CONFIRM_SIGNIN_SUCCESS,
  props<{ response: ConfirmSignInResponse }>()
);
export const confirmCustomSignIn = createAction(
  AuthenticationActionTypes.CONFIRM_CUSTOM_SIGNIN,
  props<{ payload: string }>()
);
export const confirmCustomSignInFailure = createAction(
  AuthenticationActionTypes.CONFIRM_CUSTOM_SIGNIN_FAILURE,
  props<{ error: CognitoErrorResponse; message: string }>()
);
export const confirmCustomSignInSuccess = createAction(
  AuthenticationActionTypes.CONFIRM_CUSTOM_SIGNIN_SUCCESS,
  props<{ response: ConfirmSignInResponse }>()
);
export const getInputVerificationCode = createAction(
  AuthenticationActionTypes.GET_INPUT_VERIFICATION_CODE,
  props<{ payload: string }>()
);
export const getInputVerificationCodeFailure = createAction(
  AuthenticationActionTypes.GET_INPUT_VERIFICATION_CODE_FAILURE,
  props<{ error: CognitoErrorResponse; message: string }>()
);
export const getInputVerificationCodeSuccess = createAction(
  AuthenticationActionTypes.GET_INPUT_VERIFICATION_CODE_SUCCESS,
  props<{ response: CognitoResponse }>()
);

export const resetPassword = createAction(
  AuthenticationActionTypes.RESET_PASSWORD,
  props<{ payload: ResetPasswordPayload }>()
);
export const resetPasswordFailure = createAction(
  AuthenticationActionTypes.RESET_PASSWORD_FAILURE,
  props<{ error: CognitoErrorResponse; message: string }>()
);
export const resetPasswordSuccess = createAction(
  AuthenticationActionTypes.RESET_PASSWORD_SUCCESS,
  props<{ response: ResetPasswordResponse }>()
);

export const setMFASelection = createAction(
  AuthenticationActionTypes.SET_MFA_SELECTION,
  props<{ payload: SetMFAPreferencePayload }>()
);
export const setMFASelectionFailure = createAction(
  AuthenticationActionTypes.SET_MFA_SELECTION_FAILURE,
  props<{ error: CognitoErrorResponse; message: string }>()
);
export const setMFASelectionSuccess = createAction(
  AuthenticationActionTypes.SET_MFA_SELECTION_SUCCESS,
  props<{ response: NewUserResponse }>()
);

export const setNewUser = createAction(
  AuthenticationActionTypes.SET_NEW_USER,
  props<{ payload: NewUserPayload }>()
);
export const setNewUserFailure = createAction(
  AuthenticationActionTypes.SET_NEW_USER_FAILURE,
  props<{ error: CognitoErrorResponse; message: string }>()
);
export const setNewUserSuccess = createAction(
  AuthenticationActionTypes.SET_NEW_USER_SUCCESS,
  props<{ response: NewUserResponse }>()
);

export const setNewPassword = createAction(
  AuthenticationActionTypes.SET_NEW_PASSWORD,
  props<{ payload: any }>()
);
export const setNewPasswordFailure = createAction(
  AuthenticationActionTypes.SET_NEW_PASSWORD_FAILURE,
  props<{ error: CognitoErrorResponse; message: string }>()
);
export const setNewPasswordSuccess = createAction(
  AuthenticationActionTypes.SET_NEW_PASSWORD_SUCCESS,
  props<{ response: NewPasswordResponse }>()
);

export const signedOutFromInterceptor = createAction(
  AuthenticationActionTypes.SIGNED_OUT_FROM_INTERCEPTOR
);

export const signIn = createAction(
  AuthenticationActionTypes.SIGNIN,
  props<{ payload: SignInPayload }>()
);
export const signInFailure = createAction(
  AuthenticationActionTypes.SIGNIN_FAILURE,
  props<{ error: CognitoErrorResponse; message: string }>()
);
export const signInSuccess = createAction(
  AuthenticationActionTypes.SIGNIN_SUCCESS,
  props<{ response: SignInResponse }>()
);

export const signOut = createAction(AuthenticationActionTypes.SIGNOUT);
export const signOutFailure = createAction(
  AuthenticationActionTypes.SIGNOUT_FAILURE,
  props<{ error: CognitoErrorResponse; message: string }>()
);

export const signOutSuccess = createAction(
  AuthenticationActionTypes.SIGNOUT_SUCCESS
);
export const updateUserAttributes = createAction(
  AuthenticationActionTypes.UPDATE_USER_ATTRIBUTES,
  props<{ payload: UpdateUserAttributesPayload }>()
);
export const updateUserAttributesFailure = createAction(
  AuthenticationActionTypes.UPDATE_USER_ATTRIBUTES_FAILURE,
  props<{ error: CognitoErrorResponse; message: string }>()
);
export const updateUserAttributesSuccess = createAction(
  AuthenticationActionTypes.UPDATE_USER_ATTRIBUTES_SUCCESS,
  props<{ response: CognitoResponse }>()
);

export const verifyAttribute = createAction(
  AuthenticationActionTypes.VERIFY_ATTRIBUTE,
  props<{ payload: VerifyAttributePayload }>()
);
export const verifyAttributeFailure = createAction(
  AuthenticationActionTypes.VERIFY_ATTRIBUTE_FAILURE,
  props<{ error: CognitoErrorResponse; message: string }>()
);
export const verifyAttributeSuccess = createAction(
  AuthenticationActionTypes.VERIFY_ATTRIBUTE_SUCCESS,
  props<{ response: CognitoResponse }>()
);

export const verifySoftwareToken = createAction(
  AuthenticationActionTypes.VERIFY_SOFTWARE_TOKEN,
  props<{ payload: VerifySoftwareTokenParams }>()
);
export const verifySoftwareTokenFailure = createAction(
  AuthenticationActionTypes.VERIFY_SOFTWARE_TOKEN_FAILURE,
  props<{ error: CognitoErrorResponse; message: string }>()
);
export const verifySoftwareTokenSuccess = createAction(
  AuthenticationActionTypes.VERIFY_SOFTWARE_TOKEN_SUCCESS,
  props<{ response: CognitoUserSession }>()
);
