import { Injectable } from '@angular/core';
import { handleError } from '@core/helpers';
import {
  ProgramCurriculumResponse,
  ProgramModel,
  ProgramSubmissionAttendanceReachDosage,
  ProgramSubmissionCohort,
  ProgramSubmissionStructureCostSupport,
  ProgramSubmissionSurveyAdministration,
} from '@core/models';
import { ProgramModelService } from '@core/services/program-model.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  addProgramModel,
  addProgramModelCohort,
  addProgramModelCohortFailure,
  addProgramModelCohortSuccess,
  addProgramModelFailure,
  addProgramModelSuccess,
  getProgramModel,
  getProgramModelFailure,
  getProgramModels,
  getProgramModelsCurriculums,
  getProgramModelsCurriculumsFailure,
  getProgramModelsCurriculumsSuccess,
  getProgramModelsFailure,
  getProgramModelsSuccess,
  getProgramModelSuccess,
  getProgramSubmissionArd,
  getProgramSubmissionArdFailure,
  getProgramSubmissionArdSuccess,
  getProgramSubmissionCohorts,
  getProgramSubmissionCohortsFailure,
  getProgramSubmissionCohortsSuccess,
  getProgramSubmissionSa,
  getProgramSubmissionSaFailure,
  getProgramSubmissionSaSuccess,
  getProgramSubmissionScs,
  getProgramSubmissionScsFailure,
  getProgramSubmissionScsSuccess,
  removeProgramModelCohort,
  removeProgramModelCohortFailure,
  removeProgramModelCohortSuccess,
  setProgramModelActiveStatus,
  setProgramModelActiveStatusFailure,
  setProgramModelActiveStatusSuccess,
  updateProgramSubmissionArd,
  updateProgramSubmissionArdFailure,
  updateProgramSubmissionArdSuccess,
  updateProgramSubmissionCohort,
  updateProgramSubmissionCohortFailure,
  updateProgramSubmissionCohortSuccess,
  updateProgramSubmissionSa,
  updateProgramSubmissionSaFailure,
  updateProgramSubmissionSaSuccess,
  updateProgramSubmissionScs,
  updateProgramSubmissionScsFailure,
  updateProgramSubmissionScsSuccess,
} from './actions';

@Injectable()
export class ProgramModelEffects {
  addProgramModel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addProgramModel),
      switchMap(({ payload }) =>
        this.programModelService.addProgramModel(payload).pipe(
          map(() => addProgramModelSuccess()),
          catchError((error) => handleError(error, addProgramModelFailure))
        )
      )
    )
  );

  addProgramModelCohort$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addProgramModelCohort),
      switchMap(({ programId, hoursDelivered }) =>
        this.programModelService
          .addProgramModelCohort(programId, hoursDelivered)
          .pipe(
            map(() => addProgramModelCohortSuccess()),
            catchError((error) =>
              handleError(error, addProgramModelCohortFailure)
            )
          )
      )
    )
  );

  getProgramCurriculums$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getProgramModelsCurriculums),
      switchMap(({ programId }) =>
        this.programModelService.getCurriculumOptions(programId).pipe(
          map((programCurriculums: ProgramCurriculumResponse) =>
            getProgramModelsCurriculumsSuccess({ programCurriculums })
          ),
          catchError((error) =>
            handleError(error, getProgramModelsCurriculumsFailure)
          )
        )
      )
    )
  );

  getProgramModel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getProgramModel),
      switchMap(({ programId }) =>
        this.programModelService.getProgramModel(programId).pipe(
          map((programModel: ProgramModel) =>
            getProgramModelSuccess({ programModel })
          ),
          catchError((error) => handleError(error, getProgramModelFailure))
        )
      )
    )
  );

  getProgramModels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getProgramModels),
      switchMap(({ providerId }) =>
        this.programModelService.getProgramModels(providerId).pipe(
          map((programModels: ProgramModel[]) =>
            getProgramModelsSuccess({ programModels })
          ),
          catchError((error) => handleError(error, getProgramModelsFailure))
        )
      )
    )
  );

  setProgramModelActiveStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setProgramModelActiveStatus),
      switchMap(({ programModelId, activeStatus }) =>
        this.programModelService
          .setProgramModelActiveStatus(programModelId, activeStatus)
          .pipe(
            map(() =>
              setProgramModelActiveStatusSuccess({
                programModelId,
                activeStatus,
              })
            ),
            catchError((error) =>
              handleError(error, setProgramModelActiveStatusFailure)
            )
          )
      )
    )
  );

  getProgramSubmissionCohorts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getProgramSubmissionCohorts),
      switchMap(({ programId }) =>
        this.programModelService.getProgramSubmissionCohorts(programId).pipe(
          map((programSubmissionCohorts: ProgramSubmissionCohort[]) =>
            getProgramSubmissionCohortsSuccess({ programSubmissionCohorts })
          ),
          catchError((error) =>
            handleError(error, getProgramSubmissionCohortsFailure)
          )
        )
      )
    )
  );

  getProgramSubmissionArd$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getProgramSubmissionArd),
      switchMap(({ programId }) =>
        this.programModelService.getProgramSubmissionArd(programId).pipe(
          map((programSubmissionArd: ProgramSubmissionAttendanceReachDosage) =>
            getProgramSubmissionArdSuccess({ programSubmissionArd })
          ),
          catchError((error) =>
            handleError(error, getProgramSubmissionArdFailure)
          )
        )
      )
    )
  );

  getProgramSubmissionScs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getProgramSubmissionScs),
      switchMap(({ programId }) =>
        this.programModelService.getProgramSubmissionScs(programId).pipe(
          map((programSubmissionScs: ProgramSubmissionStructureCostSupport) =>
            getProgramSubmissionScsSuccess({ programSubmissionScs })
          ),
          catchError((error) =>
            handleError(error, getProgramSubmissionScsFailure)
          )
        )
      )
    )
  );

  getProgramSubmissionSa$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getProgramSubmissionSa),
      switchMap(({ programId }) =>
        this.programModelService.getProgramSubmissionSa(programId).pipe(
          map((programSubmissionSa: ProgramSubmissionSurveyAdministration) =>
            getProgramSubmissionSaSuccess({ programSubmissionSa })
          ),
          catchError((error) =>
            handleError(error, getProgramSubmissionSaFailure)
          )
        )
      )
    )
  );

  removeProgramModelCohort$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeProgramModelCohort),
      switchMap(({ programId, cohortId }) =>
        this.programModelService
          .removeProgramModelCohort(programId, cohortId)
          .pipe(
            map(() => removeProgramModelCohortSuccess({ programId })),
            catchError((error) =>
              handleError(error, removeProgramModelCohortFailure)
            )
          )
      )
    )
  );

  updateProgramSubmissionArd$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateProgramSubmissionArd),
      switchMap(({ programId, payload }) =>
        this.programModelService
          .updateProgramSubmissionArd(programId, payload)
          .pipe(
            map(
              (programSubmissionArd: ProgramSubmissionAttendanceReachDosage) =>
                updateProgramSubmissionArdSuccess()
            ),
            catchError((error) =>
              handleError(error, updateProgramSubmissionArdFailure)
            )
          )
      )
    )
  );

  updateProgramModelCohort$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateProgramSubmissionCohort),
      switchMap(({ programId, hoursDelivered, cohortId }) =>
        this.programModelService
          .updateProgramSubmissionCohort(programId, hoursDelivered, cohortId)
          .pipe(
            map(() => updateProgramSubmissionCohortSuccess()),
            catchError((error) =>
              handleError(error, updateProgramSubmissionCohortFailure)
            )
          )
      )
    )
  );

  updateProgramSubmissionScs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateProgramSubmissionScs),
      switchMap(({ programId, payload }) =>
        this.programModelService
          .updateProgramSubmissionScs(programId, payload)
          .pipe(
            map((programSubmissionScs: ProgramSubmissionStructureCostSupport) =>
              updateProgramSubmissionScsSuccess()
            ),
            catchError((error) =>
              handleError(error, updateProgramSubmissionScsFailure)
            )
          )
      )
    )
  );

  updateProgramSubmissionSa$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateProgramSubmissionSa),
      switchMap(({ programId, payload }) =>
        this.programModelService
          .updateProgramSubmissionSa(programId, payload)
          .pipe(
            map((programSubmissionSa: ProgramSubmissionSurveyAdministration) =>
              updateProgramSubmissionSaSuccess()
            ),
            catchError((error) =>
              handleError(error, updateProgramSubmissionSaFailure)
            )
          )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private programModelService: ProgramModelService
  ) {}
}
