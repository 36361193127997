import { Injectable } from '@angular/core';
import { handleError } from '@core/helpers';
import { SubmissionPeriod } from '@core/models/submission-period.interface';
import { SubmissionPeriodService } from '@core/services/submission-period.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';

import {
  getActiveSubmissionPeriod,
  getActiveSubmissionPeriodFailure,
  getActiveSubmissionPeriodSuccess,
  getAllSubmissionPeriods,
  getAllSubmissionPeriodsFailure,
  getAllSubmissionPeriodsSuccess,
  updateSubmissionPeriod,
  updateSubmissionPeriodSuccess,
  updateSubmissionPeriodFailure, 
  createSubmissionPeriod,
  createSubmissionPeriodSuccess,
  createSubmissionPeriodFailure,  
} from './actions';

@Injectable()
export class SubmissionPeriodEffects {
  getActiveSubmissionPeriod$ = createEffect(() =>
    this.action$.pipe(
      ofType(getActiveSubmissionPeriod),
      switchMap(() =>
        this.submissionPeriodService.getSubmissionPeriods('active').pipe(
          map((activeSubmissionPeriod: SubmissionPeriod) =>
            getActiveSubmissionPeriodSuccess({ activeSubmissionPeriod })
          ),
          catchError((error) =>
            handleError(error, getActiveSubmissionPeriodFailure)
          )
        )
      )
    )
  );

  getAllSubmissionPeriods$ = createEffect(() =>
    this.action$.pipe(
      ofType(getAllSubmissionPeriods),
      switchMap(() =>
        this.submissionPeriodService.getSubmissionPeriods().pipe(
          map((submissionPeriods: SubmissionPeriod[]) =>
            getAllSubmissionPeriodsSuccess({ submissionPeriods })
          ),
          catchError((error) =>
            handleError(error, getAllSubmissionPeriodsFailure)
          )
        )
      )
    )
  );

  updateSubmissionPeriod$ = createEffect(() =>
    this.action$.pipe(
      ofType(updateSubmissionPeriod),
      switchMap(({submissionClosed}) =>
        this.submissionPeriodService.updateSubmissionPeriod(submissionClosed).pipe(
          map(() => updateSubmissionPeriodSuccess()),
          catchError((error) =>
            handleError(error, updateSubmissionPeriodFailure)
            )
        )
      )
    )
  );
  
  createSubmissionPeriods$ = createEffect(() =>
    this.action$.pipe(
      ofType(createSubmissionPeriod),
      switchMap(({ payload }) =>
        this.submissionPeriodService.createSubmissionPeriod(payload).pipe(
          map(() => createSubmissionPeriodSuccess()),
          catchError((error) =>
            handleError(error, createSubmissionPeriodFailure)
          )
        )
      )
    )
  );

  constructor(
    private action$: Actions,
    private submissionPeriodService: SubmissionPeriodService
  ) {}
}
