import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StatusReportEffects } from './effects';
import { statusReportFeatureKey, statusReportReducer } from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(statusReportFeatureKey, statusReportReducer),
    EffectsModule.forFeature([StatusReportEffects]),
  ],
})
export class StatusReportModule {}
