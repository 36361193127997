import { Injectable } from '@angular/core';
import { MyGrantsService } from '@core/services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { handleError } from '@core/helpers';
import {
  updateGrantFavorites,
  updateGrantFavoritesSuccess,
  updateGrantFavoritesFailure,
  getGrantFavorites,
  getGrantFavoritesSuccess,
  getGrantFavoritesFailure,
} from './actions';

@Injectable()
export class MyGrantsEffects {
  updateGrantFavorites$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateGrantFavorites),
      switchMap((action) =>
        this.myGrantsService
          .updateGrantFavorites(action.grantIds)
          .pipe(
            map(() => updateGrantFavoritesSuccess()),
            catchError((error) => handleError(error, updateGrantFavoritesFailure))
          )
      )
    )
  );

  getGrantFavorites$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getGrantFavorites),
      switchMap(() =>
        this.myGrantsService
          .getGrantFavorites()
          .pipe(
            map((grantFavoriteIds) => getGrantFavoritesSuccess({ grantFavoriteIds })),
            catchError((error) => handleError(error, getGrantFavoritesFailure))
          )
      )
    )
  );

  constructor(private actions$: Actions, private myGrantsService: MyGrantsService) { }
}
