import { State as AppState } from '@app/store/app-state';
import { ApiError, FileColumns, Grant, GrantSubmission, GrantUpload, UploadStatus, UploadHistory } from '@core/models';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { grantFeatureKey } from './reducer';
import { State } from './state';

const getGrant = (state: State): Grant => state.grant;
const getGrantLoading = (state: State): boolean => state.grantLoading;
const getGrantError = (state: State): ApiError => state.grantError;
const getGrants = (state: State): Grant[] => state.grants;
const getGrantsLoading = (state: State): boolean => state.grantsLoading;
const getGrantsError = (state: State): ApiError => state.grantsError;
const getGrantSubmission = (state: State): GrantSubmission =>
  state.grantSubmission;
const getGrantSubmissionLoading = (state: State): boolean =>
  state.grantSubmissionLoading;
const getGrantSubmissionError = (state: State): ApiError =>
  state.grantSubmissionError;
const setGrantSubmissionLoading = (state: State): boolean =>
  state.setGrantSubmissionLoading;
const setGrantSubmissionFailure = (state: State): ApiError =>
  state.setGrantSubmissionError;
const grantUpload = (state: State): GrantUpload => state.grantUpload;
const getGrantUploadLoading = (state: State): boolean =>
  state.getGrantUploadLoading;
const getGrantUploadFailure = (state: State): ApiError =>
  state.getGrantUploadFailure;
const grantUploads = (state: State): GrantUpload[] => state.grantUploads;
const getGrantUploadsLoading = (state: State): boolean =>
  state.getGrantUploadsLoading;
const getGrantUploadsFailure = (state: State): ApiError =>
  state.getGrantUploadsFailure;
const grantUploadStatus = (state: State): UploadStatus => state.grantUploadStatus;
const getGrantUploadStatusLoading = (state: State): boolean =>
  state.getGrantUploadsLoading;
const getGrantUploadStatusFailure = (state: State): ApiError =>
  state.getGrantUploadsFailure;
const validateFileLoading = (state: State): boolean =>
  state.validateFileLoading;
const validateFileFailure = (state: State): ApiError =>
  state.validateFileFailure;
const storeFileLoading = (state: State): boolean =>
  state.storeFileLoading;
const storeFileFailure = (state: State): ApiError =>
  state.storeFileFailure;
const storeFileUploadProgress = (state: State): number =>
  state.storeFileUploadProgress;
const fileColumns = (state: State): FileColumns =>
  state.fileColumns;
const getFileColumnsLoading = (state: State): boolean =>
  state.getFileColumnsLoading;
const getFileColumnsFailure = (state: State): ApiError =>
  state.getFileColumnsFailure;
const getUploadHistories = (state: State): UploadHistory[] =>
  state.uploadHistories;
const getUploadHistoriesLoading = (state: State): boolean =>
  state.getUploadHistoriesLoading;
const getUploadHistoriesFailure = (state: State): ApiError =>
  state.getUploadHistoriesFailure;
const completeUploadLoading = (state: State): boolean =>
  state.completeUploadLoading;
const completeUploadFailure = (state: State): ApiError =>
  state.completeUploadFailure;
const updateGrantSubmissionStatusLoading = (state: State): boolean =>
  state.updateGrantSubmissionStatusLoading;
const updateGrantSubmissionStatusFailure = (state: State): ApiError =>
  state.updateGrantSubmissionStatusFailure;

export const grantState = createFeatureSelector<AppState, State>(
  grantFeatureKey
);

export const selectGrant = createSelector(grantState, getGrant);
export const selectGrantError = createSelector(grantState, getGrantError);
export const selectGrantLoading = createSelector(grantState, getGrantLoading);
export const selectGrants = createSelector(grantState, getGrants);
export const selectGrantsError = createSelector(grantState, getGrantsError);
export const selectGrantsLoading = createSelector(grantState, getGrantsLoading);
export const selectGrantSubmission = createSelector(
  grantState,
  getGrantSubmission
);
export const selectGrantSubmissionLoading = createSelector(
  grantState,
  getGrantSubmissionLoading
);
export const selectGrantSubmissionError = createSelector(
  grantState,
  getGrantSubmissionError
);
export const selectSetGrantSubmissionLoading = createSelector(
  grantState,
  setGrantSubmissionLoading
);
export const selectSetGrantSubmissionFailure = createSelector(
  grantState,
  setGrantSubmissionFailure
);
export const selectGrantUpload = createSelector(grantState, grantUpload);
export const selectGetGrantUploadLoading = createSelector(
  grantState,
  getGrantUploadLoading
);
export const selectGetGrantUploadFailure = createSelector(
  grantState,
  getGrantUploadFailure
);
export const selectGrantUploads = createSelector(grantState, grantUploads);
export const selectgetGrantUploadsLoading = createSelector(
  grantState,
  getGrantUploadsLoading
);
export const selectgetGrantUploadsFailure = createSelector(
  grantState,
  getGrantUploadsFailure
);
export const selectGrantUploadStatus = createSelector(
  grantState,
  grantUploadStatus
);
export const selectGetGrantUploadStatusLoading = createSelector(
  grantState,
  getGrantUploadStatusLoading
);
export const selectGetGrantUploadStatusFailure = createSelector(
  grantState,
  getGrantUploadStatusFailure
);
export const selectValidateFileLoading = createSelector(
  grantState,
  validateFileLoading
);
export const selectValidateFileFailure = createSelector(
  grantState,
  validateFileFailure
);
export const selectStoreFileLoading = createSelector(
  grantState,
  storeFileLoading
);
export const selectStoreFileFailure = createSelector(
  grantState,
  storeFileFailure
);
export const selectStoreFileUploadProgress = createSelector(
  grantState,
  storeFileUploadProgress
);
export const selectFileColumns = createSelector(
  grantState,
  fileColumns
);
export const selectGetFileColumnsLoading = createSelector(
  grantState,
  getFileColumnsLoading
);
export const selectGetFileColumnsFailure = createSelector(
  grantState,
  getFileColumnsFailure
);
export const selectUploadHistories = createSelector(
  grantState,
  getUploadHistories
);
export const selectUploadHistoriesLoading = createSelector(
  grantState,
  getUploadHistoriesLoading
);
export const selectUploadHistoriesFailure = createSelector(
  grantState,
  getUploadHistoriesFailure
);

export const selectCompleteUploadLoading = createSelector(
  grantState,
  completeUploadLoading
);
export const selectCompleteUploadFailure = createSelector(
  grantState,
  completeUploadFailure
);

export const selectUpdateGrantSubmissionStatusLoading = createSelector(
  grantState,
  updateGrantSubmissionStatusLoading
);
export const selectUpdateGrantSubmissionStatusFailure = createSelector(
  grantState,
  updateGrantSubmissionStatusFailure
);
