import { Injectable } from '@angular/core';
import { environment as env } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BaseService {
  // Define any common properties or methods here
  public apiVersion = 'v1';
  public baseURL = `${env.apiURL}/${this.apiVersion}`;
}
