import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { grantReducer } from './reducer';
import { GrantEffects } from './effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('grant', grantReducer),
    EffectsModule.forFeature([GrantEffects])
  ]
})
export class GrantModule {}
