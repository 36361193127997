import { Renderer2 } from '@angular/core';
import { exportAsCsv } from '@app/shared/utilities';

interface CsvRow {
  type: string;
  message: string;
}

export class DownloadMessagesUtil {
  /** Transforms messages into model list used for CSV creation. */
  public static map(type: string, messages: string[]): CsvRow[] {
    return messages.map((message): CsvRow => ({ type, message }));
  }

  /** Initiaties download to CSV. */
  public static download(
    messages: CsvRow[],
    fileName: string,
    renderer: Renderer2
  ): void {
    exportAsCsv(messages, fileName, renderer);
  }
}
