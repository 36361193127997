<app-content-container [loading]="pageLoading" [breadcrumbs]="breadcrumbList">
  <app-content-container-title>Users</app-content-container-title>
  <div class="app-content-description"></div>
  <app-data-table
    [dataSource]="dataSource"
    [options]="dataTableOptions"
    [loading]="usersLoading$ | async"
    [selectableRows]="true"
    (rowTouched)="openUserPanel($event)"
  >
    <div action-left-content>
      <mat-checkbox
        matCheckBox
        [(ngModel)]="includeInactiveUsers"
        (change)="showInactiveUsers($event.checked)"
        ><span>Include inactive users</span>
      </mat-checkbox>
    </div>

    <div action-right-content>
      <button mat-stroked-button class="refresh-list" (click)="refreshList()">
        <mat-icon>refresh</mat-icon>
        <span>Refresh</span>
      </button>
      <button mat-stroked-button class="download-list" (click)="downloadList()">
        <mat-icon>download</mat-icon>
        <span>Download</span>
      </button>
      <button
        mat-flat-button
        color="primary"
        class="add-user"
        (click)="addUser()"
      >
        <mat-icon>person_add</mat-icon>
        <span>Add User</span>
      </button>
    </div>
  </app-data-table>
</app-content-container>
