import { Injectable } from '@angular/core';
import { handleError } from '@core/helpers';
import { StatusReportService } from '@core/services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  addComment,
  addCommentFailure,
  addCommentSuccess,
  deleteComment,
  deleteCommentFailure,
  deleteCommentSuccess,
  getComments,
  getCommentsFailure,
  getCommentsSuccess,
  updateComment,
  updateCommentFailure,
  updateCommentSuccess,
  getGrantProgress,
  getGrantProgressSuccess,
  getGrantProgressFailure,
  getGrantsDetails,
  getGrantsDetailsSuccess,
  getGrantsDetailsFailure,
  getGrantsSummary,
  getGrantsSummarySuccess,
  getGrantsSummaryFailure,
} from './actions';

@Injectable()
export class StatusReportEffects {
  addComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addComment),
      switchMap(({ grantId, comment }) =>
        this.statusReportService.addComment(grantId, comment).pipe(
          map(() => addCommentSuccess()),
          catchError((error) => handleError(error, addCommentFailure)),
        ),
      ),
    ),
  );

  getComments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getComments),
      switchMap(({ grantId }) =>
        this.statusReportService.getComments(grantId).pipe(
          map((response) => getCommentsSuccess({ comments: response })),
          catchError((error) => handleError(error, getCommentsFailure)),
        ),
      ),
    ),
  );

  updateComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateComment),
      switchMap(({ grantId, comment, commentId }) =>
        this.statusReportService
          .updateComment(grantId, comment, commentId)
          .pipe(
            map(() => updateCommentSuccess()),
            catchError((error) => handleError(error, updateCommentFailure)),
          ),
      ),
    ),
  );

  deleteComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteComment),
      switchMap(({ grantId, commentId }) =>
        this.statusReportService.deleteComment(grantId, commentId).pipe(
          map(() => deleteCommentSuccess()),
          catchError((error) => handleError(error, deleteCommentFailure)),
        ),
      ),
    ),
  );

  getGrantProgress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getGrantProgress),
      switchMap(({ grantId }) =>
        this.statusReportService.getGrantProgress(grantId).pipe(
          map((grantProgress) => getGrantProgressSuccess({ grantProgress })),
          catchError((error) => handleError(error, getGrantProgressFailure)),
        ),
      ),
    ),
  );

  getGrantsSummary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getGrantsSummary),
      switchMap(({ myGrants }) =>
        this.statusReportService.getGrantsSummary(myGrants).pipe(
          map((grantsSummary) => getGrantsSummarySuccess({ grantsSummary })),
          catchError((error) => handleError(error, getGrantsSummaryFailure)),
        ),
      ),
    ),
  );

  getGrantsDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getGrantsDetails),
      switchMap(({ myGrants }) =>
        this.statusReportService.getGrantsDetails(myGrants).pipe(
          map((grantsDetails) => getGrantsDetailsSuccess({ grantsDetails })),
          catchError((error) => handleError(error, getGrantsDetailsFailure)),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private statusReportService: StatusReportService,
  ) { }
}
