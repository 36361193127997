import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Dialog, DialogComponent } from '@core/ui/dialog';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DialogService {
  constructor(public dialog: MatDialog) {}

  open<T = DialogComponent, R = boolean>(
    dialog: Dialog
  ): Observable<R | undefined> {
    if (!dialog) {
      return;
    }

    return this.dialog
      .open<T, MatDialogConfig, R>(dialog.dialogTemplate || DialogComponent, {
        autoFocus: true,
        data: dialog,
        disableClose: true,
        height: dialog.height || null,
        minWidth: '400px',
        panelClass: 'app-dialog',
        width: dialog.width || null,
      })
      .afterClosed();
  }
}
