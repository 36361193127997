import { UserAssociationCategory } from '@app/shared/enums';
import { UserAssociationPayload, UserAssociationSelection } from '@core/models';

export const associationToIds = (
  selectedAssociation: UserAssociationSelection
): UserAssociationPayload => ({
  grantId:
    selectedAssociation.selectionType === UserAssociationCategory.GRANTS
      ? selectedAssociation.id
      : '',
  providerId:
    selectedAssociation.selectionType === UserAssociationCategory.PROVIDERS
      ? selectedAssociation.id
      : '',
});
