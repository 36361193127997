import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  FileColumns,
  Grant,
  GrantSubmission,
  GrantSubmissionPayload,
  GrantUpload,
  UploadStatus,
  StoreFileResponse,
  UploadFile,
  UploadHistory,
  ValidateFileResponse,
} from '@core/models';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class GrantService extends BaseService {
  private grants = 'grants';

  constructor(private http: HttpClient) {
    super();
  }

  public getGrant(grantId: string): Observable<Grant> {
    return this.http.get<Grant>(`${this.baseURL}/${this.grants}/${grantId}`);
  }

  public getGrants(): Observable<Grant[]> {
    return this.http.get<Grant[]>(`${this.baseURL}/${this.grants}`);
  }

  public getGrantSubmission(grantId: string): Observable<GrantSubmission> {
    return this.http.get<GrantSubmission>(
      `${this.baseURL}/${this.grants}/${grantId}/submission`,
    );
  }

  public setGrantSubmission(
    grantId: string,
    grantSubmission: GrantSubmissionPayload,
  ): Observable<null> {
    return this.http.put<null>(
      `${this.baseURL}/${this.grants}/${grantId}/submission`,
      { ...grantSubmission },
    );
  }

  public getGrantUploadType(
    grantId: string,
    uploadType: string,
  ): Observable<GrantUpload> {
    return this.http.get<GrantUpload>(
      `${this.baseURL}/${this.grants}/${grantId}/uploads/${uploadType}`,
    );
  }

  public getGrantUploads(grantId: string): Observable<GrantUpload[]> {
    return this.http.get<GrantUpload[]>(
      `${this.baseURL}/${this.grants}/${grantId}/uploads`,
    );
  }

  public getGrantUploadStatus(grantId: string): Observable<UploadStatus> {
    return this.http.get<UploadStatus>(
      `${this.baseURL}/${this.grants}/${grantId}/upload-status`,
    );
  }

  public validateFile(
    grantId: string,
    uploadLogId: string,
    uploadFile: UploadFile,
  ): Observable<ValidateFileResponse> {
    return this.http.post<ValidateFileResponse>(
      `${this.baseURL}/${this.grants}/${grantId}/uploads/${uploadLogId}/validate`,
      uploadFile,
    );
  }

  public storeFile(
    grantId: string,
    uploadType: string,
    file: FormData,
  ): Observable<HttpEvent<StoreFileResponse>> {
    const options = {
      reportProgress: true,
    };

    const req = new HttpRequest(
      'POST',
      `${this.baseURL}/${this.grants}/${grantId}/uploads/${uploadType}/files`,
      file,
      options,
    );

    return this.http.request<StoreFileResponse>(req);
  }

  public getFileColumns(
    grantId: string,
    uploadLogId: string,
    uploadFile: UploadFile,
  ): Observable<FileColumns> {
    return this.http.post<FileColumns>(
      `${this.baseURL}/${this.grants}/${grantId}/uploads/${uploadLogId}/columns`,
      uploadFile,
    );
  }

  public getUploadHistories(
    grantId: string,
    uploadType: string,
  ): Observable<UploadHistory[]> {
    return this.http.get<UploadHistory[]>(
      `${this.baseURL}/${this.grants}/${grantId}/uploads/${uploadType}/history`,
    );
  }

  public completeUpload(
    grantId: string,
    uploadLogId: string,
    completed: boolean,
  ): Observable<null> {
    return this.http.put<null>(
      `${this.baseURL}/${this.grants}/${grantId}/uploads/${uploadLogId}/completed`,
      { completed },
    );
  }

  public updateGrantSubmissionStatus(
    grantId: string,
  ): Observable<null> {
    return this.http.put<null>(
      `${this.baseURL}/${this.grants}/${grantId}/submission-status`,
      {},
    );
  }
}
