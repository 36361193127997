<div class="flex-row flex-wrap footer-top">
  <span><img alt="ACF" src="assets/acf_main_logo_white.png" /></span>
  <span></span>
  <span>
    <h4>U.S. Department of Health & Human Services</h4>
    <p>330 C Street, S.W., Washington, D.C. 20201</p>
    <p class="flex-row link-list">
      <a href="/privacy" target="new">Privacy</a>
      <a href="/terms" target="new">Terms of Use</a>
    </p>
  </span>
</div>
