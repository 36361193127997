<app-auth-card size="large">
  <span auth-card-header>Additional Security</span>
  <p>Please select your preference for additional security:</p>
  <form
    [formGroup]="setMFASelectionForm"
    autocomplete="off"
    (ngSubmit)="submitMFASelectionForm()"
  >
    <fieldset>
      <mat-radio-group class="radio-group" formControlName="mfaSelection">
        <!-- <mat-radio-button class="radio-button" [value]="MFATypes.SMS"
          >Text Message (SMS)
        </mat-radio-button> -->
        <mat-radio-button
          class="radio-button"
          [value]="MFATypes.Token"
          (change)="totpSelected()"
          checked
        >
          Software Token Authenticaton (e.g., Google Authenticator, Microsoft
          Authenticator)
        </mat-radio-button>
      </mat-radio-group>
    </fieldset>
    <div class="mfa-container" *ngIf="mfaSelection() === MFATypes.SMS">
      <p>Please enter your mobile phone number:</p>
      <fieldset class="flex-inline">
        <mat-form-field class="country-code width-auto">
          <mat-label>Country Code</mat-label>
          <mat-select formControlName="countryCode">
            <mat-option value="+1">+1</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="phone-number">
          <mat-label>Mobile Phone Number</mat-label>
          <input
            type="text"
            matInput
            name="phoneNumber"
            title="Phone Number"
            formControlName="phoneNumber"
            maxlength="10"
          />
          <mat-error>
            <app-form-errors
              [field]="setMFASelectionForm.controls.phoneNumber"
            ></app-form-errors>
          </mat-error>
        </mat-form-field>
      </fieldset>
    </div>
    <div class="mfa-container">
      <h3 class="steps-title">Software Token Authentication Steps</h3>
      <ol>
        <li>
          Download a software token authentication app to your mobile device
          from the App Store or Google Play.
          <br /><br />
          We recommend Google Authenticator or Microsoft Authenticator.
          <div class="image-container">
            <div>
              <span>Google Authenticator:</span>
              <span>
                <a
                  mat-stroked-button
                  title="Google Play store"
                  matTooltip="Download from the Goole Play Store"
                  href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                  ><img alt="@" src="assets/icons/icon-playstore.png" /><span
                    >Google Play Store</span
                  ></a
                >
                <a
                  mat-stroked-button
                  title="Apple App store"
                  matTooltip="Download from the Apple App Store"
                  href="https://apps.apple.com/us/app/google-authenticator/id388497605"
                  ><img alt="@" src="assets/icons/icon-appstore.png" /><span
                    >Apple App Store</span
                  ></a
                >
              </span>
            </div>
            <div>
              <span>Microsoft Authenticator:</span>
              <span>
                <a
                  mat-stroked-button
                  title="Google Play store"
                  matTooltip="Download from the Goole Play Store"
                  href="https://play.google.com/store/apps/details?id=com.azure.authenticator"
                  ><img alt="@" src="assets/icons/icon-playstore.png" /><span
                    >Google Play Store</span
                  ></a
                >
                <a
                  mat-stroked-button
                  title="Apple App store"
                  matTooltip="Download from the Apple App Store"
                  href="https://apps.apple.com/us/app/microsoft-authenticator/id983156458"
                  ><img alt="@" src="assets/icons/icon-appstore.png" /><span
                    >Apple App Store</span
                  ></a
                >
              </span>
            </div>
          </div>
        </li>
        <li>
          If this is the first time you are using a software token
          authentication app then follow the "get started" instructions for your
          app. Otherwise, select the "Scan QR Code" option and allow the app to
          access your camera (if prompted).
        </li>
        <li>
          Scan this QR code
          <div>
            <app-qr-code [qrcode]="qrcode"></app-qr-code>
          </div>
        </li>
        <li>
          Enter the verification code returned from the authentication app, then
          press the "Continue" button. Please note for security purposes the
          verification code changes every 30 seconds.
        </li>
      </ol>
      <fieldset class="auth-code">
        <mat-form-field>
          <mat-label>Verification Code</mat-label>
          <input
            title="Verification Code"
            matInput
            name="code"
            formControlName="code"
            placeholder=""
            type="text"
            aria-required="true"
          />
          <mat-error>
            <app-form-errors
              [field]="setMFASelectionForm.controls.code"
            ></app-form-errors>
          </mat-error>
        </mat-form-field>
      </fieldset>
    </div>
  </form>
  <ng-container auth-card-actions>
    <button
      mat-flat-button
      color="primary"
      type="button"
      (click)="submitMFASelectionForm()"
      [disabled]="!mfaSelected()"
    >
      Continue
    </button>
    <button mat-stroked-button type="button" (click)="goBack()">Go Back</button>
  </ng-container>
</app-auth-card>
