<div class="flex-column file-upload">
  <div
    (drop)="handleFileDropped($event)"
    (dragover)="onDragOver($event)"
    (dragleave)="onDragLeave($event)"
    (click)="dataFile.click()"
    (keypress)="dataFile.click()"
    [ngClass]="setBorder()"
    [class.file-attached]="isFileAttached"
    class="drag-area"
    matTooltip="Select a file or drag and drop a file here."
    matRipple
    matRippleColor="#888888A"
    tabindex="1"
  >
    <div
      class="content-wrapper"
      [class.no-file-selected]="!isFileAttached && !isFileUploaded"
      [class.file-selected]="isFileAttached && !isFileUploaded"
      [class.file-uploaded]="isFileUploaded"
    >
      <span class="icon-wrapper" *ngIf="(storeFileLoading$ | async) === false">
        <mat-icon *ngIf="!isFileAttached && !isFileUploaded"
          >upload_file</mat-icon
        >
        <mat-icon *ngIf="isFileAttached && !isFileUploaded">task</mat-icon>
        <mat-icon *ngIf="isFileUploaded">done</mat-icon>
      </span>

      <div
        class="flex-column file-uploading-progress-bar"
        *ngIf="storeFileLoading$ | async"
      >
        <ng-container *ngIf="(storeFileUploadProgress$ | async) < 100">
          <mat-progress-spinner
            mode="determinate"
            [value]="storeFileUploadProgress$ | async"
          ></mat-progress-spinner>
          <span class="label">Uploading file...</span>
        </ng-container>

        <ng-container *ngIf="(storeFileUploadProgress$ | async) === 100">
          <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
          <span class="label">Scanning for viruses and malware...</span>
        </ng-container>
      </div>
      <span class="label" *ngIf="!isFileAttached"
        >No file selected.<br />Select a file or drag and drop a file
        here.</span
      >
      <div class="flex-column file-info" *ngIf="isFileAttached">
        <h3>{{ fileName }}</h3>
        <h5>{{ byteConverter(fileSize) }} KB</h5>
      </div>
    </div>
    <label for="dataFile">
      <input
        #dataFile
        type="file"
        id="dataFile"
        hidden
        (change)="handleFileSelected($event)"
        [accept]="validFileTypesString"
      />
    </label>
  </div>
  <div class="action-buttons">
    <button
      mat-flat-button
      color="accent"
      [disabled]="(storeFileLoading$ | async) || !canUpload || fileSize === 0"
      (click)="uploadFile()"
    >
      Upload Data
    </button>
    <button mat-stroked-button color="warn" (click)="cancelUpload()">
      Cancel
    </button>
  </div>
</div>
