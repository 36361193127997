import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ProgramModelEffects } from './effects';
import { programModelFeatureKey, programModelReducer } from './reducer';

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature([ProgramModelEffects]),
    StoreModule.forFeature(programModelFeatureKey, programModelReducer),
  ],
})
export class ProgramModelModule {}
