import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class MyGrantsService extends BaseService {
  private myGrants = 'my-grants';

  constructor(private http: HttpClient) {
    super();
  }

  public updateGrantFavorites(grantIds: string[]): Observable<null> {
    return this.http.put<null>(`${this.baseURL}/${this.myGrants}/favorites`, { grantIds });
  }

  public getGrantFavorites(): Observable<string[]> {
    return this.http.get<string[]>(`${this.baseURL}/${this.myGrants}/favorites`);
  }
}
