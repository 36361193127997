import {
  ApiError,
  ProgramCurriculum,
  ProgramModel,
  ProgramSubmissionAttendanceReachDosage,
  ProgramSubmissionStructureCostSupport,
  ProgramSubmissionSurveyAdministration,
} from '@core/models';
import { ProgramSubmissionCohort } from '@core/models/program-submission-cohort.interface';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { programModelFeatureKey } from './reducer';
import { State } from './state';

const addProgramModelLoading = (state: State): boolean =>
  state.addProgramModelLoading;
const addProgramModelError = (state: State): ApiError =>
  state.addProgramModelError;
const getProgramCurriculums = (state: State): ProgramCurriculum[] =>
  state.programCurriculums;
const getProgramCurriculumsError = (state: State): ApiError =>
  state.programCurriculumsError;
const getProgramCurriculumsLoading = (state: State): boolean =>
  state.programCurriculumsLoading;
const getProgramModel = (state: State): ProgramModel => state.programModel;
const getProgramModelLoading = (state: State): boolean =>
  state.programModelLoading;
const getProgramModelError = (state: State): ApiError =>
  state.programModelError;
const getProgramModels = (state: State): ProgramModel[] => state.programModels;
const getProgramModelsLoading = (state: State): boolean =>
  state.programModelsLoading;
const getProgramModelsError = (state: State): ApiError =>
  state.programModelsError;
const setProgramModelActiveStatusError = (state: State): ApiError =>
  state.programModelActiveStatusError;
const setProgramModelActiveStatusLoading = (state: State): boolean =>
  state.programModelActiveStatusLoading;
const addProgramModelCohortLoading = (state: State): boolean =>
  state.programModelCohortLoading;
const addProgramModelCohortError = (state: State): ApiError =>
  state.programModelCohortError;
const getProgramSubmissionCohorts = (state: State): ProgramSubmissionCohort[] =>
  state.programSubmissionCohorts;
const getProgramsubmissionCohortsLoading = (state: State): boolean =>
  state.programSubmissionCohortsLoading;
const getProgramSubmissionCohortsError = (state: State): ApiError =>
  state.programSubmissionCohortsError;
const getProgramSubmissionArd = (
  state: State
): ProgramSubmissionAttendanceReachDosage => state.programSubmissionArd;
const getProgramSubmissionArdLoading = (state: State): boolean =>
  state.programSubmissionArdLoading;
const getProgramSubmissionArdError = (state: State): ApiError =>
  state.programSubmissionArdError;
const getProgramSubmissionScs = (
  state: State
): ProgramSubmissionStructureCostSupport => state.programSubmissionScs;
const getProgramSubmissionScsLoading = (state: State): boolean =>
  state.programSubmissionScsLoading;
const getProgramSubmissionScsError = (state: State): ApiError =>
  state.programSubmissionScsError;
const getProgramSubmissionSa = (
  state: State
): ProgramSubmissionSurveyAdministration => state.programSubmissionSa;
const getProgramSubmissionSaLoading = (state: State): boolean =>
  state.programSubmissionSaLoading;
const getProgramSubmissionSaError = (state: State): ApiError =>
  state.updateProgramSubmissionSaError;
const removeProgramSubmissionCohortLoading = (state: State): boolean =>
  state.removeProgramSubmissionCohortLoading;
const removeProgramSubmissionCohortError = (state: State): ApiError =>
  state.removeProgramSubmissionCohortError;
const updateProgramSubmissionArdLoading = (state: State): boolean =>
  state.updateProgramSubmissionArdLoading;
const updateProgramSubmissionArdError = (state: State): ApiError =>
  state.updateProgramSubmissionArdError;
const updateProgramSubmissionCohortLoading = (state: State): boolean =>
  state.updateProgramSubmissionCohortLoading;
const updateProgramSubmissionCohortError = (state: State): ApiError =>
  state.updateProgramSubmissionCohortError;
const updateProgramSubmissionScsLoading = (state: State): boolean =>
  state.updateProgramSubmissionScsLoading;
const updateProgramSubmissionScsError = (state: State): ApiError =>
  state.updateProgramSubmissionScsError;
const updateProgramSubmissionSaLoading = (state: State): boolean =>
  state.updateProgramSubmissionSaLoading;
const updateProgramSubmissionSaError = (state: State): ApiError =>
  state.updateProgramSubmissionSaError;

export const programModelState = createFeatureSelector<State>(
  programModelFeatureKey
);

export const selectAddProgramModelLoading = createSelector(
  programModelState,
  addProgramModelLoading
);
export const selectAddProgramModelError = createSelector(
  programModelState,
  addProgramModelError
);

export const selectProgramCurriculums = createSelector(
  programModelState,
  getProgramCurriculums
);

export const selectProgramCurriculumsError = createSelector(
  programModelState,
  getProgramCurriculumsError
);
export const selectProgramCurriculumsLoading = createSelector(
  programModelState,
  getProgramCurriculumsLoading
);

export const selectProgramModel = createSelector(
  programModelState,
  getProgramModel
);
export const selectProgramModelLoading = createSelector(
  programModelState,
  getProgramModelLoading
);
export const selectProgramModelError = createSelector(
  programModelState,
  getProgramModelError
);

export const selectProgramModels = createSelector(
  programModelState,
  getProgramModels
);
export const selectProgramModelsLoading = createSelector(
  programModelState,
  getProgramModelsLoading
);
export const selectProgramModelsError = createSelector(
  programModelState,
  getProgramModelsError
);
export const selectProgramModelActiveStatusError = createSelector(
  programModelState,
  setProgramModelActiveStatusError
);
export const selectProgramModelActiveStatusLoading = createSelector(
  programModelState,
  setProgramModelActiveStatusLoading
);
export const selectProgramModelActiveSubmissionCohortLoading = createSelector(
  programModelState,
  addProgramModelCohortLoading
);
export const selectProgramModelActiveSubmissionCohortError = createSelector(
  programModelState,
  addProgramModelCohortError
);
export const selectProgramSubmissionCohorts = createSelector(
  programModelState,
  getProgramSubmissionCohorts
);
export const selectProgramSubmissionCohortsLoading = createSelector(
  programModelState,
  getProgramsubmissionCohortsLoading
);
export const selectProgramSubmissionCohortsError = createSelector(
  programModelState,
  getProgramSubmissionCohortsError
);

export const selectAddProgramSubmissionCohortLoading = createSelector(
  programModelState,
  addProgramModelCohortLoading
);
export const selectAddProgramSubmissionCohortError = createSelector(
  programModelState,
  addProgramModelCohortError
);
export const selectRemoveProgramSubmissionCohortLoading = createSelector(
  programModelState,
  removeProgramSubmissionCohortLoading
);
export const selectRemoveProgramSubmissionCohortError = createSelector(
  programModelState,
  removeProgramSubmissionCohortError
);
export const selectProgramSubmissionArd = createSelector(
  programModelState,
  getProgramSubmissionArd
);
export const selectProgramSubmissionArdLoading = createSelector(
  programModelState,
  getProgramSubmissionArdLoading
);
export const selectProgramSubmissionArdError = createSelector(
  programModelState,
  getProgramSubmissionArdError
);
export const selectProgramSubmissionScs = createSelector(
  programModelState,
  getProgramSubmissionScs
);
export const selectProgramSubmissionScsLoading = createSelector(
  programModelState,
  getProgramSubmissionScsLoading
);
export const selectProgramSubmissionScsError = createSelector(
  programModelState,
  getProgramSubmissionScsError
);
export const selectProgramSubmissionSa = createSelector(
  programModelState,
  getProgramSubmissionSa
);
export const selectProgramSubmissionSaLoading = createSelector(
  programModelState,
  getProgramSubmissionSaLoading
);
export const selectProgramSubmissionSaError = createSelector(
  programModelState,
  getProgramSubmissionSaError
);

export const selectUpdateProgramSubmissionArdLoading = createSelector(
  programModelState,
  updateProgramSubmissionArdLoading
);
export const selectUpdateProgramSubmissionArdError = createSelector(
  programModelState,
  updateProgramSubmissionArdError
);
export const selectUpdateProgramSubmissionCohortLoading = createSelector(
  programModelState,
  updateProgramSubmissionCohortLoading
);
export const selectUpdateProgramSubmissionCohortError = createSelector(
  programModelState,
  updateProgramSubmissionCohortError
);
export const selectUpdateProgramSubmissionScsLoading = createSelector(
  programModelState,
  updateProgramSubmissionScsLoading
);
export const selectUpdateProgramSubmissionScsError = createSelector(
  programModelState,
  updateProgramSubmissionScsError
);
export const selectUpdateProgramSubmissionSaLoading = createSelector(
  programModelState,
  updateProgramSubmissionSaLoading
);
export const selectUpdateProgramSubmissionSaError = createSelector(
  programModelState,
  updateProgramSubmissionSaError
);
