<div mat-dialog-title>Add User</div>
<div class="mat-mdc-panel-status">
  <mat-progress-bar
    mode="indeterminate"
    *ngIf="(loading$ | async) || (addUserLoading$ | async)"
  ></mat-progress-bar>
</div>
<mat-dialog-content>
  <form [formGroup]="addUserForm" autocomplete="off" (ngSubmit)="addNewUser()">
    <fieldset>
      <mat-form-field class="mat-mdc-form-field-100-percent">
        <mat-label>Role:</mat-label>
        <mat-select
          formControlName="roleId"
          aria-required="true"
          (selectionChange)="onRoleChange($event)"
        >
          <mat-option
            *ngFor="let role of assignableRoles$ | async; let i = index"
            [value]="role.id"
          >
            {{ role.name }}
          </mat-option>
        </mat-select>
        <mat-error>
          <app-form-errors
            [field]="addUserForm.controls.roleName"
          ></app-form-errors>
        </mat-error>
      </mat-form-field>
      <mat-form-field
        class="mat-mdc-form-field-100-percent"
        *ngIf="showGrantSelection()"
      >
        <mat-label>Grant:</mat-label>
        <mat-select
          formControlName="grantId"
          aria-required="true"
          (selectionChange)="onGrantChange($event)"
        >
          <mat-option
            *ngFor="let grant of grants; let i = index"
            [value]="grant.grantId"
          >
            {{ grant.grantee }}
          </mat-option>
        </mat-select>
        <mat-error>
          <app-form-errors
            [field]="addUserForm.controls.grantId"
          ></app-form-errors>
        </mat-error>
      </mat-form-field>
      <div class="providerMessage" *ngIf="showProviderMessage()">
        No Providers Available
      </div>
      <mat-form-field
        class="mat-mdc-form-field-100-percent"
        *ngIf="showProviderSelection()"
      >
        <mat-label>Provider</mat-label>
        <input
          type="text"
          placeholder="Pick one"
          aria-label="Provider"
          matInput
          formControlName="providerId"
          [matAutocomplete]="auto"
        />
        <mat-autocomplete
          #auto="matAutocomplete"
          [displayWith]="displayFn.bind(this)"
        >
          <mat-option
            *ngFor="let provider of providers$ | async"
            [value]="provider.id"
          >
            {{ provider.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-divider></mat-divider>
      <mat-form-field class="mat-mdc-form-field-100-percent">
        <mat-label>First name</mat-label>
        <input
          matInput
          name="nameFirst"
          title="First Name"
          formControlName="nameFirst"
          placeholder=""
          aria-required="true"
        />
        <mat-error>
          <app-form-errors
            [field]="addUserForm.controls.nameFirst"
          ></app-form-errors>
        </mat-error>
      </mat-form-field>
      <mat-form-field class="mat-mdc-form-field-100-percent">
        <mat-label>Last name</mat-label>
        <input
          matInput
          name="nameLast"
          title="Last Name"
          formControlName="nameLast"
          placeholder=""
          aria-required="true"
        />
        <mat-error>
          <app-form-errors
            [field]="addUserForm.controls.nameLast"
          ></app-form-errors>
        </mat-error>
      </mat-form-field>
      <mat-form-field class="mat-mdc-form-field-100-percent">
        <mat-label>Email address</mat-label>
        <input
          matInput
          name="email"
          title="Email"
          formControlName="email"
          placeholder=""
          aria-required="true"
        />
        <mat-error>
          <app-form-errors
            [field]="addUserForm.controls.email"
          ></app-form-errors>
        </mat-error>
      </mat-form-field>
    </fieldset>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button
    type="button"
    mat-flat-button
    color="primary"
    (click)="addNewUser()"
    [disabled]="addUserForm.invalid || (addUserLoading$ | async)"
  >
    Save
  </button>
  <button mat-stroked-button type="button" (click)="close()">Cancel</button>
</mat-dialog-actions>
