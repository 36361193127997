import { createReducer, on } from '@ngrx/store';
import {
  addProgramModel,
  addProgramModelCohort,
  addProgramModelCohortFailure,
  addProgramModelCohortSuccess,
  addProgramModelFailure,
  addProgramModelSuccess,
  getProgramModel,
  getProgramModelFailure,
  getProgramModels,
  getProgramModelsCurriculums,
  getProgramModelsCurriculumsFailure,
  getProgramModelsCurriculumsSuccess,
  getProgramModelsFailure,
  getProgramModelsSuccess,
  getProgramModelSuccess,
  getProgramSubmissionArd,
  getProgramSubmissionArdFailure,
  getProgramSubmissionArdSuccess,
  getProgramSubmissionCohorts,
  getProgramSubmissionCohortsFailure,
  getProgramSubmissionCohortsSuccess,
  getProgramSubmissionSa,
  getProgramSubmissionSaFailure,
  getProgramSubmissionSaSuccess,
  getProgramSubmissionScs,
  getProgramSubmissionScsFailure,
  getProgramSubmissionScsSuccess,
  removeProgramModelCohort,
  removeProgramModelCohortFailure,
  removeProgramModelCohortSuccess,
  setProgramModelActiveStatus,
  setProgramModelActiveStatusFailure,
  setProgramModelActiveStatusSuccess,
  updateProgramSubmissionArd,
  updateProgramSubmissionArdFailure,
  updateProgramSubmissionArdSuccess,
  updateProgramSubmissionSa,
  updateProgramSubmissionSaFailure,
  updateProgramSubmissionSaSuccess,
  updateProgramSubmissionScs,
  updateProgramSubmissionScsFailure,
  updateProgramSubmissionScsSuccess,
} from './actions';
import { initialState } from './state';

export const programModelFeatureKey = 'programModel';

export const programModelReducer = createReducer(
  initialState,

  on(addProgramModel, (state) => ({
    ...state,
    addProgramModelLoading: true,
  })),
  on(addProgramModelSuccess, (state) => ({
    ...state,
    addProgramModelLoading: false,
  })),
  on(addProgramModelFailure, (state, { error, message }) => ({
    ...state,
    addProgramModelLoading: false,
    addProgramModelError: { error, message },
  })),

  on(getProgramModelsCurriculums, (state) => ({
    ...state,
    programCurriculumsLoading: true,
  })),
  on(getProgramModelsCurriculumsFailure, (state, { error, message }) => ({
    ...state,
    programCurriculums: [],
    programCurriculumsError: { error, message },
    programCurriculumsLoading: false,
  })),
  on(getProgramModelsCurriculumsSuccess, (state, { programCurriculums }) => ({
    ...state,
    programCurriculums,
    programCurriculumsLoading: false,
  })),

  on(getProgramModel, (state) => ({
    ...state,
    programModelLoading: true,
  })),
  on(getProgramModelSuccess, (state, { programModel }) => ({
    ...state,
    programModelLoading: false,
    programModel,
  })),
  on(getProgramModelFailure, (state, { error, message }) => ({
    ...state,
    programModelLoading: false,
    programModelError: { error, message },
  })),

  on(getProgramModels, (state) => ({
    ...state,
    programModelsLoading: true,
  })),
  on(getProgramModelsSuccess, (state, { programModels }) => ({
    ...state,
    programModelsLoading: false,
    programModels,
  })),
  on(getProgramModelsFailure, (state, { error, message }) => ({
    ...state,
    programModelsLoading: false,
    programModelsError: { error, message },
  })),

  on(setProgramModelActiveStatus, (state) => ({
    ...state,
    programModelActiveStatusLoading: true,
  })),
  on(setProgramModelActiveStatusFailure, (state, { error, message }) => ({
    ...state,
    programModelActiveStatusError: { error, message },
    programModelActiveStatusLoading: false,
  })),
  on(setProgramModelActiveStatusSuccess, (state) => ({
    ...state,
    programModelActiveStatusLoading: false,
  })),
  on(addProgramModelCohort, (state) => ({
    ...state,
    programModelCohortLoading: true,
  })),
  on(addProgramModelCohortSuccess, (state) => ({
    ...state,
    programModelCohortLoading: false,
  })),
  on(addProgramModelCohortFailure, (state, { error, message }) => ({
    ...state,
    programModelCohortLoading: false,
    programModelCohortError: { error, message },
  })),
  on(getProgramSubmissionCohorts, (state) => ({
    ...state,
    programSubmissionCohortsLoading: true,
  })),
  on(
    getProgramSubmissionCohortsSuccess,
    (state, { programSubmissionCohorts }) => ({
      ...state,
      programSubmissionCohortsLoading: false,
      programSubmissionCohorts,
    })
  ),
  on(getProgramSubmissionCohortsFailure, (state, { error, message }) => ({
    ...state,
    programSubmissionCohortsLoading: false,
    programSubmissionCohortsError: { error, message },
  })),
  on(getProgramSubmissionArd, (state) => ({
    ...state,
    programSubmissionArdLoading: true,
  })),
  on(getProgramSubmissionArdSuccess, (state, { programSubmissionArd }) => ({
    ...state,
    programSubmissionArdLoading: false,
    programSubmissionArd,
  })),
  on(getProgramSubmissionArdFailure, (state, { error, message }) => ({
    ...state,
    programSubmissionArdLoading: false,
    programSubmissionArdError: { error, message },
  })),
  on(getProgramSubmissionScs, (state) => ({
    ...state,
    programSubmissionScsLoading: true,
  })),
  on(getProgramSubmissionScsSuccess, (state, { programSubmissionScs }) => ({
    ...state,
    programSubmissionScsLoading: false,
    programSubmissionScs,
  })),
  on(getProgramSubmissionScsFailure, (state, { error, message }) => ({
    ...state,
    programSubmissionScsLoading: false,
    programSubmissionScsError: { error, message },
  })),
  on(getProgramSubmissionSa, (state) => ({
    ...state,
    programSubmissionSaLoading: true,
  })),
  on(getProgramSubmissionSaSuccess, (state, { programSubmissionSa }) => ({
    ...state,
    programSubmissionSaLoading: false,
    programSubmissionSa,
  })),
  on(getProgramSubmissionSaFailure, (state, { error, message }) => ({
    ...state,
    programSubmissionSaLoading: false,
    programSubmissionSaError: { error, message },
  })),
  on(removeProgramModelCohort, (state) => ({
    ...state,
    removeProgramModelCohortLoading: true,
  })),
  on(removeProgramModelCohortSuccess, (state) => ({
    ...state,
    removeProgramModelCohortLoading: false,
  })),
  on(removeProgramModelCohortFailure, (state, { error, message }) => ({
    ...state,
    removeProgramModelCohortLoading: false,
    removeProgramModelCohortError: { error, message },
  })),
  on(updateProgramSubmissionArd, (state) => ({
    ...state,
    updateProgramSubmissionArdLoading: true,
  })),
  on(updateProgramSubmissionArdSuccess, (state) => ({
    ...state,
    updateProgramSubmissionArdLoading: false,
    updateProgramSubmissionArdError: null,
  })),
  on(updateProgramSubmissionArdFailure, (state, { error, message }) => ({
    ...state,
    updateProgramSubmissionArdLoading: false,
    updateProgramSubmissionArdError: { error, message },
  })),
  on(updateProgramSubmissionScs, (state) => ({
    ...state,
    updateProgramSubmissionScsLoading: true,
  })),
  on(updateProgramSubmissionScsSuccess, (state) => ({
    ...state,
    updateProgramSubmissionScsLoading: false,
    updateProgramSubmissionScsError: null,
  })),
  on(updateProgramSubmissionScsFailure, (state, { error, message }) => ({
    ...state,
    updateProgramSubmissionScsLoading: false,
    updateProgramSubmissionScsError: { error, message },
  })),
  on(updateProgramSubmissionSa, (state) => ({
    ...state,
    updateProgramSubmissionSaLoading: true,
  })),
  on(updateProgramSubmissionSaSuccess, (state) => ({
    ...state,
    updateProgramSubmissionSaLoading: false,
    updateProgramSubmissionSaError: null,
  })),
  on(updateProgramSubmissionSaFailure, (state, { error, message }) => ({
    ...state,
    updateProgramSubmissionSaLoading: false,
    updateProgramSubmissionSaError: { error, message },
  }))
);
