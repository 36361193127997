import { createReducer, Action, on } from '@ngrx/store';
import { initialState, State } from './state';
import {
  getDashboardList,
  getDashboardListSuccess,
  getDashboardListFailure,
  getDashboardUrl,
  getDashboardUrlSuccess,
  getDashboardUrlFailure,
  resetDashboardUrlSuccess,
  resetDashboardUrl,
} from './actions';

export const dashboardFeatureKey = 'dashboard';

export const dashboardReducer = createReducer(
  initialState,
  on(getDashboardList, (state) => ({
    ...state,
    dashboardListLoading: true,
  })),
  on(getDashboardListSuccess, (state, { dashboardList }) => ({
    ...state,
    dashboardList,
    dashboardListLoading: false,
    dashboardListError: null,
  })),
  on(getDashboardListFailure, (state, { error, message }) => ({
    ...state,
    dashboardListLoading: false,
    dashboardListError: { error, message },
  })),
  on(getDashboardUrl, (state) => ({
    ...state,
    dashboardUrlLoading: true,
  })),
  on(getDashboardUrlSuccess, (state, { dashboardUrl }) => ({
    ...state,
    dashboardUrlLoading: false,
    dashboardUrlError: null,
    dashboardUrl: dashboardUrl.dashboardUrl,
  })),
  on(getDashboardUrlFailure, (state, { error, message }) => ({
    ...state,
    dashboardUrlLoading: false,
    dashboardUrlError: { error, message },
  })),
  on(resetDashboardUrl, (state) => ({
    ...state,
    dashboardUrlLoading: true,
  })),
  on(resetDashboardUrlSuccess, (state) => ({
    ...state,
    dashboardUrlLoading: false,
    dashboardUrlError: null,
    dashboardUrl: '',
  })),
);

export const reducer = (state: State | undefined, action: Action) =>
  dashboardReducer(state, action);
