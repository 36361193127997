import {
  ApiError,
  Comment,
  GrantSubmissionStatusSummary,
  GrantSubmissionStatusProgress,
  GrantSubmissionStatusDetails,
} from '@core/models';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { statusReportFeatureKey } from './reducer';
import { State } from './state';

const addCommentLoading = (state: State): boolean => state.addCommentLoading;
const addCommentFailure = (state: State): ApiError => state.addCommentFailure;
const getComments = (state: State): Comment[] => state.comments;
const getCommentsFailure = (state: State): ApiError => state.commentsError;
const getCommentsLoading = (state: State): boolean => state.commentsLoading;
const updateCommentLoading = (state: State): boolean =>
  state.updateCommentLoading;
const updateCommentFailure = (state: State): ApiError =>
  state.updateCommentFailure;
const deleteCommentFailure = (state: State): ApiError =>
  state.deleteCommentFailure;
const deleteCommentLoading = (state: State): boolean =>
  state.deleteCommentLoading;
const grantProgress = (state: State): GrantSubmissionStatusProgress =>
  state.grantProgress;
const grantProgressLoading = (state: State): boolean =>
  state.getGrantProgressLoading;
const grantProgressFailure = (state: State): ApiError =>
  state.getGrantProgressFailure;
const grantsDetails = (state: State): GrantSubmissionStatusDetails[] =>
  state.grantsDetails;
const grantsDetailsLoading = (state: State): boolean =>
  state.grantsDetailsLoading;
const grantsDetailsFailure = (state: State): ApiError =>
  state.grantsDetailsFailure;
const grantsSummary = (state: State): GrantSubmissionStatusSummary[] =>
  state.grantsSummary;
const grantsSummaryLoading = (state: State): boolean =>
  state.getGrantsSummaryLoading;
const grantsSummaryFailure = (state: State): ApiError =>
  state.getGrantsSummaryFailure;

export const statusReportState = createFeatureSelector<State>(
  statusReportFeatureKey,
);

export const selectAddCommentLoading = createSelector(
  statusReportState,
  addCommentLoading,
);
export const selectAddCommentFailure = createSelector(
  statusReportState,
  addCommentFailure,
);
export const selectComments = createSelector(statusReportState, getComments);
export const selectCommentFailure = createSelector(
  statusReportState,
  getCommentsFailure,
);
export const selectCommentsLoading = createSelector(
  statusReportState,
  getCommentsLoading,
);
export const selectUpdateCommentLoading = createSelector(
  statusReportState,
  updateCommentLoading,
);
export const selectUpdateCommentFailure = createSelector(
  statusReportState,
  updateCommentFailure,
);
export const selectDeleteCommentFailure = createSelector(
  statusReportState,
  deleteCommentFailure,
);
export const selectDeleteCommentLoading = createSelector(
  statusReportState,
  deleteCommentLoading,
);
export const selectGrantProgress = createSelector(
  statusReportState,
  grantProgress,
);
export const selectGetGrantProgressLoading = createSelector(
  statusReportState,
  grantProgressLoading,
);
export const selectGetGrantProgressFailure = createSelector(
  statusReportState,
  grantProgressFailure,
);
export const selectGrantsDetails = createSelector(
  statusReportState,
  grantsDetails,
);
export const selectGrantsDetailsLoading = createSelector(
  statusReportState,
  grantsDetailsLoading,
);
export const selectGrantsDetailsFailure = createSelector(
  statusReportState,
  grantsDetailsFailure,
);


export const selectGrantsSummary = createSelector(
  statusReportState,
  grantsSummary,
);
export const selectGetGrantsSummaryLoading = createSelector(
  statusReportState,
  grantsSummaryLoading,
);
export const selectGetGrantsSummaryFailure = createSelector(
  statusReportState,
  grantsSummaryFailure,
);
