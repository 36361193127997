<mat-toolbar role="navigation" [class.large]="isDesktop">
  <span class="toolbar-left flex-row">
    <a class="skip-content" href="" (click)="goToMainElement($event)"
      >Skip navigation</a
    >
    <span class="app-menu-button" *ngIf="!isDesktop">
      <button
        #appMenuButton
        aria-label="Application Menu"
        mat-icon-button
        (click)="appMenuButtonClicked($event)"
        class="app-button"
      >
        <mat-icon>menu</mat-icon>
      </button>
    </span>
    <span class="app-title">
      <img
        src="assets/prep-logo-nav.jpg"
        alt="PREP logo. Personal Responsibility Education Program"
      />
    </span>
  </span>
  <span class="toolbar-spacer flex-row"><ng-content></ng-content></span>
  <span class="toolbar-right flex-row">
    <ng-content select="[toolbar-right]"></ng-content>
    <ng-container *ngFor="let button of rightMenu">
      <ng-container *ngIf="!button.condition || button?.condition()">
        <button
          mat-icon-button
          *appSecurableResource="button?.resourceAccess"
          [routerLink]="button?.routerLink"
          [matTooltip]="button.label"
          [matMenuTriggerFor]="button?.menuTrigger"
          (click)="button?.clickAction ? button?.clickAction() : null"
        >
          <mat-icon>{{ button.icon }}</mat-icon>
        </button>
      </ng-container>
    </ng-container>
    <button
      mat-icon-button
      class="user-menu-button"
      [matMenuTriggerFor]="userMenu"
      matTooltip="Your Account"
    >
      <span class="user-avatar-initials">{{ avatarInitials }}</span>
      <mat-icon class="drop-down-arrow">arrow_drop_down</mat-icon>
    </button>
  </span>
</mat-toolbar>

<mat-menu
  #userMenu="matMenu"
  class="nav-menu"
  backdropClass="nav-menu-backdrop"
>
  <div
    class="user-menu"
    (keydown.tab)="$event.stopPropagation()"
    (keydown.shift.tab)="$event.stopPropagation()"
  >
    <div class="user-info-container">
      <div class="user-avatar">
        <mat-icon>person</mat-icon>
      </div>
      <div class="user-info">
        <div class="user-name">
          {{ account?.nameFirst }} {{ account?.nameLast }}
        </div>
        <div class="user-email">
          {{ account?.email }}
        </div>
        <div class="user-role"></div>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="user-actions" [cdkTrapFocus]="true">
      <span mat-mdc-menu-item mat-mdc-menu-item-container tabindex="-1">
        <a
          mat-stroked-button
          [routerLink]="['/account']"
          cdkFocusInitial
          tabindex="1"
        >
          <mat-icon>manage_accounts</mat-icon>
          <span>My Account</span>
        </a>
      </span>
      <span mat-mdc-menu-item mat-mdc-menu-item-container tabindex="-1">
        <a mat-stroked-button [routerLink]="['/auth/signout']" tabindex="2">
          <mat-icon>exit_to_app</mat-icon>
          <span>Sign Out</span>
        </a>
      </span>
    </div>
    <mat-divider></mat-divider>
    <div class="app-version">
      <div>&copy; Mathematica</div>
      <div>Version {{ version }}</div>
    </div>
  </div>
</mat-menu>
