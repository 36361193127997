import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-fixed-content-footer',
  templateUrl: './fixed-content-footer.component.html',
  styleUrls: ['./fixed-content-footer.component.scss'],
})
export class FixedContentFooterComponent {
  @Input() compatible = false;

  @HostBinding('class') get CompatibleClass() {
    return this.compatible ? 'compatible' : '';
  }
}
