import { ApiError } from '@core/models';
import { createAction, props } from '@ngrx/store';

export enum MyGrantsActionTypes {
  UPDATE_GRANT_FAVORITES_REQUEST = '[Grant] Update grant favorites request',
  UPDATE_GRANT_FAVORITES_SUCCESS = '[Grant] Update grant favorites success',
  UPDATE_GRANT_FAVORITES_FAILURE = '[Grant] Update grant favorites failure',
  GET_GRANT_FAVORITES_REQUEST = '[Grant] Get grant favorites request',
  GET_GRANT_FAVORITES_SUCCESS = '[Grant] Get grant favorites success',
  GET_GRANT_FAVORITES_FAILURE = '[Grant] Get grant favorites failure',
}

export const updateGrantFavorites = createAction(
  MyGrantsActionTypes.UPDATE_GRANT_FAVORITES_REQUEST,
  props<{ grantIds: string[] }>()
);

export const updateGrantFavoritesSuccess = createAction(
  MyGrantsActionTypes.UPDATE_GRANT_FAVORITES_SUCCESS,
);

export const updateGrantFavoritesFailure = createAction(
  MyGrantsActionTypes.UPDATE_GRANT_FAVORITES_FAILURE,
  props<ApiError>()
);

export const getGrantFavorites = createAction(
  MyGrantsActionTypes.GET_GRANT_FAVORITES_REQUEST,
);

export const getGrantFavoritesSuccess = createAction(
  MyGrantsActionTypes.GET_GRANT_FAVORITES_SUCCESS,
  props<{ grantFavoriteIds: string[] }>()
);

export const getGrantFavoritesFailure = createAction(
  MyGrantsActionTypes.GET_GRANT_FAVORITES_FAILURE,
  props<ApiError>()
);
