import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-qr-code',
  templateUrl: './qr-code.component.html',
  styleUrls: ['./qr-code.component.scss'],
})
export class QrCodeComponent implements OnInit {
  @Input() qrcode = '';
  @Input() elementType: string;
  @Input() cssClass: string;
  @Input() alt: string;
  @Input() version: string;
  @Input() errorCorrectionLevel: string;
  @Input() margin: number;
  @Input() scale: number;
  @Input() width: number;
  @Input() colorDark: string;
  @Input() colorLight: string;
  constructor() {}

  ngOnInit(): void {}
}
