import { Action, createReducer, on } from '@ngrx/store';
import {
  clearToggleSetting,
  getUserAppStateSuccess,
  setSecurityNoticeShown,
  updateToggleSetting,
} from './actions';
import { initialState, State } from './state';

export const userAppFeatureKey = 'userApp';

const userAppReducer = createReducer(
  initialState,
  on(clearToggleSetting, (state) => ({
    ...state,
    toggles: {},
  })),
  on(updateToggleSetting, (state, toggleName) => {
    const { type, ...toggle } = toggleName;
    return {
      ...state,
      toggles: { ...state.toggles, ...toggle },
    };
  }),
  on(getUserAppStateSuccess, (state, userApp) => {
    const { type, ...userAppState } = userApp;
    return {
      ...state,
      ...userAppState,
    };
  }),
  on(setSecurityNoticeShown, (state) => ({
    ...state,
    securityNoticeShown: true,
  }))
);

export const reducer = (state: State | undefined, action: Action) =>
  userAppReducer(state, action);
