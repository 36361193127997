import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment as env } from 'environments/environment';

import {
  AppStoreState,
  AuthenticationActions,
  AuthenticationSelectors,
} from '@app/store';

import { IdleTimerService } from '@core/services';

import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { delay, take, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-signout',
  templateUrl: './signout.component.html',
  styleUrls: ['./signout.component.scss'],
})
export class SignOutComponent implements OnInit {
  private returnUrl = '/';
  private signOutLoading$ = new Observable<boolean>();

  private destroyed$ = new Subject<boolean>();

  constructor(
    private idleTimeout: IdleTimerService,
    private router: Router,
    private route: ActivatedRoute,
    private store$: Store<AppStoreState.State>,
    private actions$: Actions
  ) {
    this.signOutLoading$ = this.store$.select(
      AuthenticationSelectors.selectSignOutLoading
    );

    this.actions$
      .pipe(
        ofType(AuthenticationActions.signOutFailure),
        takeUntil(this.destroyed$),
        tap((error) => {
          console.error(error);
        })
      )
      .subscribe();

    this.actions$
      .pipe(
        ofType(AuthenticationActions.signOutSuccess),
        delay(500),
        takeUntil(this.destroyed$),
        tap(() => {
          this.idleTimeout.stopTimer();
          this.router.navigate([env.appRoutes.signIn]);
        })
      )
      .subscribe();
  }

  ngOnInit() {
    this.route.queryParamMap.subscribe((queryParams) => {
      this.returnUrl = queryParams.get('returnUrl');
    });

    this.store$
      .select(AuthenticationSelectors.selectSignedOutFromInterceptor)
      .pipe(take(1))
      .subscribe((status) => {
        //if (!status) {
        //  this.store$.dispatch(NotificationActions.reset());
        //}

        this.store$.dispatch(AuthenticationActions.signOut());
      });
  }
}
