import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
} from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-form-progress-bar',
  templateUrl: './form-progress-bar.component.html',
  styleUrls: ['./form-progress-bar.component.scss'],
})
export class FormProgressBarComponent implements OnChanges, OnInit {
  @Input() formSections!: Record<string, boolean>;

  public progress = 0;

  ngOnChanges(): void {
    if (this.formSections) {
      this.progress = parseInt(
        (
          (Object.keys(this.formSections)
            .map((section) => this.formSections[section] === true)
            .filter((section) => !!section).length /
            Object.keys(this.formSections).length) *
          100
        ).toString(),
        10
      );
    }
  }

  ngOnInit(): void {}
}
