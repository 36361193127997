import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationTypes, UserAssociationCategory } from '@app/shared/enums';
import {
  AccountActions,
  AccountSelectors,
  NotificationActions,
} from '@app/store';
import { UserAssociations, UserAssociationSelection } from '@core/models';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { environment as env } from 'environments/environment';
import { StatusCodes } from 'http-status-codes';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';

@Component({
  templateUrl: './select-association.component.html',
  styleUrls: ['./select-association.component.scss'],
})
export class SelectAssociationComponent implements OnInit, OnDestroy {
  public associationLabel = '';
  public associationLabelPlural = '';
  public associations$: Observable<UserAssociations>;
  public associationsLoading$: Observable<boolean>;
  public setAssociationsLoading$: Observable<boolean>;
  public showSelection = false;

  private destroyed$ = new Subject<boolean>();

  constructor(
    private actions$: Actions,
    private router: Router,
    private store$: Store
  ) {
    this.associations$ = this.store$
      .select(AccountSelectors.selectAssociations)
      .pipe(
        filter((associations) => !!associations),
        takeUntil(this.destroyed$),
        tap((associations) => {
          const numGrantAssociations = associations.grantAssociations.length;
          const numProviderAssociations = associations.providerAssociations.length;

          if (numGrantAssociations === 0 && numProviderAssociations === 0) {
            this.setAssociation({
              selectionType: '',
              id: '',
            });
          }

          if (numGrantAssociations === 1 && numProviderAssociations === 0) {
            this.setAssociation({
              selectionType: UserAssociationCategory.GRANTS,
              id: associations.grantAssociations[0].grantId,
            });
            this.associationLabel = 'grant';
            this.associationLabelPlural = 'grants';
          }

          if (numGrantAssociations === 0 && numProviderAssociations === 1) {
            this.setAssociation({
              selectionType: UserAssociationCategory.PROVIDERS,
              id: associations.providerAssociations[0].providerId,
            });
            this.associationLabel = 'provider';
            this.associationLabelPlural = 'providers';
          }

          if (numGrantAssociations + numProviderAssociations > 1) {
            this.associationLabel = 'Grant or Provider';
            this.associationLabelPlural = 'grants and providers';
            this.showSelection = true;
          }
        })
      );

    this.associationsLoading$ = this.store$.select(
      AccountSelectors.selectAssociationsLoading
    );

    this.actions$
      .pipe(
        ofType(AccountActions.setSelectedAssociationFailure),
        takeUntil(this.destroyed$),
        tap(({ message }) => {
          this.store$.dispatch(
            NotificationActions.add({
              notificationType: NotificationTypes.DANGER,
              notificationText: message,
            })
          );
        })
      )
      .subscribe();

    this.actions$
      .pipe(
        ofType(AccountActions.setSelectedAssociationSuccess),
        takeUntil(this.destroyed$),
        tap(() => this.router.navigateByUrl('/'))
      )
      .subscribe();

    this.actions$
      .pipe(
        ofType(AccountActions.getAssociationsFailure),
        takeUntil(this.destroyed$),
        tap(({ error, message }) => {
          if (error.status === StatusCodes.FORBIDDEN) {
            this.router.navigateByUrl('/auth/change-password');
          }
          this.store$.dispatch(
            NotificationActions.add({
              notificationType: NotificationTypes.DANGER,
              notificationText: message,
            })
          );
        })
      )
      .subscribe();
  }

  public ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public ngOnInit(): void {
    this.store$.dispatch(AccountActions.getAssociations());
  }

  public goBack(): void {
    this.router.navigate([env.appRoutes.signIn]);
  }

  public setAssociation(association: UserAssociationSelection): void {
    this.store$.dispatch(
      AccountActions.setSelectedAssociation({ association })
    );
  }

  public signOut(): void {
    this.router.navigate([env.appRoutes.signOut]);
  }
}
