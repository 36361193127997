<app-auth-card [progress]="changePasswordLoading$ | async">
  <span auth-card-header>Change Password</span>
  <p>
    Your password has expired. Please provide a new password that meets the
    required criteria.
  </p>
  <form
    [formGroup]="changePasswordForm"
    autocomplete="reset-password"
    (ngSubmit)="submitChangePasswordForm()"
  >
    <fieldset>
      <div class="flex-field">
        <mat-form-field>
          <mat-label>Old password</mat-label>
          <input
            matInput
            name="oldPassword"
            formControlName="oldPassword"
            placeholder=""
            type="password"
            autocomplete="old-password"
            aria-required="true"
          />
          <mat-error>
            <app-form-errors
              [field]="changePasswordForm.controls.oldPassword"
            ></app-form-errors>
          </mat-error>
        </mat-form-field>
        <span class="icon-password-match"></span>
      </div>
      <app-password-error
        [newPassword]="changePasswordForm.controls.newPassword.value"
      ></app-password-error>
      <div class="flex-field">
        <mat-form-field>
          <mat-label>New Password</mat-label>
          <input
            matInput
            name="newPassword"
            formControlName="newPassword"
            placeholder=""
            type="password"
            autocomplete="new-password"
            aria-required="true"
            [errorStateMatcher]="customErrorStateMatcher"
          />
          <mat-error>
            <app-form-errors
              [field]="changePasswordForm.controls.newPassword"
            ></app-form-errors>
            <app-form-errors [field]="changePasswordForm"></app-form-errors>
          </mat-error>
        </mat-form-field>
        <span class="icon-password-match">
          <mat-icon
            class="match"
            [class.hidden]="!passwordIsValid()"
            matTooltip="Password Meets Criteria"
          >
            check_circle
          </mat-icon>
          <mat-icon
            class="no-match"
            [class.hidden]="!passwordIsEntered() || passwordIsValid()"
            matTooltipPosition="right"
          >
            remove_circle_outline
          </mat-icon>
        </span>
      </div>
      <div class="flex-field">
        <mat-form-field>
          <mat-label>Re-Enter New Password</mat-label>
          <input
            matInput
            name="verifyPassword"
            formControlName="verifyPassword"
            placeholder=""
            type="password"
            autocomplete="new-password"
            aria-required="true"
          />
          <mat-error>
            <app-form-errors
              [field]="changePasswordForm.controls.verifyPassword"
            ></app-form-errors>
          </mat-error>
        </mat-form-field>
        <span class="icon-password-match">
          <mat-icon
            class="match"
            [class.hidden]="!passwordsMatch()"
            matTooltip="Passwords Match"
            matTooltipPosition="right"
          >
            check_circle
          </mat-icon>
        </span>
      </div>
    </fieldset>
  </form>
  <ng-container auth-card-actions>
    <button
      type="button"
      (click)="submitChangePasswordForm()"
      mat-flat-button
      color="primary"
      [disabled]="
        (changePasswordLoading$ | async) ||
        changePasswordForm.invalid ||
        !passwordsMatch() ||
        !passwordIsValid()
      "
    >
      Change Password
    </button>
  </ng-container>
</app-auth-card>
