import {
  Component,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'app-page-loader',
  templateUrl: 'page-loader.component.html',
  styleUrls: ['./page-loader.component.scss'],
})
export class PageLoaderComponent implements OnChanges, OnInit {
  @Input() text = '';
  @Input() component = false;

  @HostBinding('class.component') isComponent = false;

  constructor() {}

  ngOnChanges() {
    this.isComponent = this.component;
  }

  ngOnInit() {}
}
