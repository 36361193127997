import { Injectable } from '@angular/core';
import { handleError } from '@core/helpers';
import { MenuService } from '@core/services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  setActiveAdminMenu,
  setActiveAdminMenuFailure,
  setActiveAdminMenuSuccess,
  setActiveMenu,
  setActiveMenuFailure,
  setActiveMenuSuccess,
} from './actions';

@Injectable()
export class MenuEffects {
  setActiveAdminMenu$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setActiveAdminMenu),
      switchMap(({ payload }) =>
        this.menuService.setActiveAdminMenu(payload).pipe(
          map(
            (activeAdminMenu) => setActiveAdminMenuSuccess({ activeAdminMenu }),
            catchError((error) => handleError(error, setActiveAdminMenuFailure))
          )
        )
      )
    )
  );

  setActiveMenu$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setActiveMenu),
      switchMap(({ payload }) =>
        this.menuService.setActiveMenu(payload).pipe(
          map(
            (activeMenu) => setActiveMenuSuccess({ activeMenu }),
            catchError((error) => handleError(error, setActiveMenuFailure))
          )
        )
      )
    )
  );

  constructor(private actions$: Actions, private menuService: MenuService) {}
}
