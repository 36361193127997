<div class="app-content-status" [class.invisible]="!loading && !progress">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>
<div class="app-content-overlay" [class.loaded]="!loading"></div>
<div
  class="animate__animated animate__faster animate__fadeIn app-content-wrapper"
  *ngIf="!loading"
>
  <div #contentContainerHeader class="app-content-header">
    <app-content-breadcrumbs
      *ngIf="breadcrumbs"
      class="app-content-breadcrumbs"
      [crumbs]="breadcrumbs"
    ></app-content-breadcrumbs>
    <h1 *ngIf="contentContainerTitle" class="mat-h1">
      <ng-content
        select="app-content-container-title, [contentContainerTitle]"
      ></ng-content>
    </h1>
    <ng-content [select]="[app - content - header]"> </ng-content>
  </div>
  <div class="app-content-container">
    <div class="app-content-container-inner">
      <ng-content></ng-content>
    </div>
  </div>
</div>

<app-fixed-content-footer #contentContainerFooter class="app-content-footer">
  <ng-content select="[fixed-footer]"></ng-content>
</app-fixed-content-footer>
