import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ApiError, Dashboard } from '@core/models';
import { State } from './state';
import { dashboardFeatureKey } from './reducer';

const getDashboardList = (state: State): Dashboard[] => state.dashboardList;
const getDashboardListLoading = (state: State): boolean =>
  state.dashboardListLoading;
const getDashboardListError = (state: State): ApiError =>
  state.dashboardListError;
const getDashboardUrlLoading = (state: State): boolean =>
  state.dashboardUrlLoading;
const getDashboardUrlError = (state: State): ApiError =>
  state.dashboardUrlError;
const getDashboardUrl = (state: State): string => state.dashboardUrl;

export const dashboardState = createFeatureSelector<State>(dashboardFeatureKey);

export const selectDashboardList = createSelector(
  dashboardState,
  getDashboardList,
);
export const selectGetDashboardListLoading = createSelector(
  dashboardState,
  getDashboardListLoading,
);
export const selectGetDashboardListError = createSelector(
  dashboardState,
  getDashboardListError,
);
export const selectGetDashboardUrlLoading = createSelector(
  dashboardState,
  getDashboardUrlLoading,
);
export const selectGetDashboardUrlError = createSelector(
  dashboardState,
  getDashboardUrlError,
);
export const selectDashboardUrl = createSelector(
  dashboardState,
  getDashboardUrl,
);
