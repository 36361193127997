import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NotificationTypes } from '@app/shared/enums';
import {
  AccountActions,
  AccountSelectors,
  NotificationActions,
} from '@app/store';
import { UserAssociations, UserAssociationSelection } from '@core/models';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, Observable, Subject, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'app-select-association',
  templateUrl: './select-association.component.html',
  styleUrls: ['./select-association.component.scss'],
})
export class SelectAssociationDialogComponent implements OnDestroy, OnInit {
  public associationLabel = '';
  public associationLabelPlural = '';
  public defaultAssociation: any;
  public associations$: Observable<UserAssociations>;
  public associationsLoading$: Observable<boolean>;
  public setAssociationsLoading$: Observable<boolean>;

  private destroyed$ = new Subject<boolean>();
  constructor(
    private actions$: Actions,
    private router: Router,
    private store$: Store,
    private dialog: MatDialogRef<SelectAssociationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {
    this.associations$ = this.store$
      .select(AccountSelectors.selectAssociations)
      .pipe(
        filter((associations) => !!associations),
        takeUntil(this.destroyed$),
        tap((associations) => {
          if (
            associations.grantAssociations.length > 1 &&
            associations.providerAssociations.length === 0
          ) {
            this.associationLabel = 'Grant';
            this.associationLabelPlural = 'grants';
          }
          if (
            associations.grantAssociations.length === 0 &&
            associations.providerAssociations.length > 1
          ) {
            this.associationLabel = 'Grant';
            this.associationLabelPlural = 'grants';
          }
          if (
            associations.grantAssociations.length > 0 &&
            associations.providerAssociations.length > 0
          ) {
            this.associationLabel = 'Grant or Provider';
            this.associationLabelPlural = 'grants and providers';
          }
        })
      );

    this.associationsLoading$ = this.store$.select(
      AccountSelectors.selectAssociationsLoading
    );

    this.actions$
      .pipe(
        ofType(AccountActions.setSelectedAssociationFailure),
        takeUntil(this.destroyed$),
        tap(({ message }) => {
          this.store$.dispatch(
            NotificationActions.add({
              notificationType: NotificationTypes.DANGER,
              notificationText: message,
            })
          );
        })
      )
      .subscribe();

    this.actions$
      .pipe(
        ofType(AccountActions.setSelectedAssociationSuccess),
        takeUntil(this.destroyed$),
        tap(() => {
          this.close();
          this.router.navigateByUrl('/');
        })
      )
      .subscribe();
  }

  ngOnInit(): void {
    this.store$.dispatch(AccountActions.getAssociations());
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public close(): void {
    this.dialog.close();
  }

  public setAssociation(association: UserAssociationSelection): void {
    this.store$.dispatch(
      AccountActions.setSelectedAssociation({ association })
    );
  }
}
