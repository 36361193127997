import { Action, createReducer, on } from '@ngrx/store';
import {
  createUser,
  createUserFailure,
  createUserSuccess,
  getAssignableRoles,
  getAssignableRolesFailure,
  getAssignableRolesSuccess,
  getUser,
  getUserFailure,
  getUsers,
  getUsersFailure,
  getUsersSuccess,
  getUserSuccess,
  reactivateUser,
  reactivateUserFailure,
  reactivateUserSuccess,
  removeUser,
  removeUserFailure,
  removeUserSuccess,
  resetUserMFA,
  resetUserMFAFailure,
  resetUserMFASuccess,
} from './actions';
import { initialState, State } from './state';

export const userFeatureKey = 'user';

const UserReducer = createReducer(
  initialState,
  on(createUser, (state) => ({
    ...state,
    createUserLoading: true,
  })),
  on(createUserFailure, (state, { error, message }) => ({
    ...state,
    createUserError: { error, message },
    createUserLoading: false,
  })),
  on(createUserSuccess, (state) => ({
    ...state,
    createUserError: null,
    createUserLoading: false,
  })),
  on(getAssignableRoles, (state) => ({
    ...state,
    assignableRolesLoading: true,
  })),
  on(getAssignableRolesFailure, (state, { error, message }) => ({
    ...state,
    assignableRoles: [],
    assignableRolesError: { error, message },
    assignableRolesLoading: false,
  })),
  on(getAssignableRolesSuccess, (state, { assignableRoles }) => ({
    ...state,
    assignableRoles,
    assignableRolesError: null,
    assignableRolesLoading: false,
  })),
  on(getUser, (state) => ({
    ...state,
    userLoading: true,
  })),
  on(getUserFailure, (state, { error, message }) => ({
    ...state,
    user: null,
    userError: { error, message },
    userLoading: false,
  })),
  on(getUserSuccess, (state, { user }) => ({
    ...state,
    user,
    userError: null,
    userLoading: false,
  })),
  on(getUsers, (state) => ({
    ...state,
    usersLoading: true,
  })),
  on(getUsersFailure, (state, { error, message }) => ({
    ...state,
    users: null,
    usersError: { error, message },
    usersLoading: false,
  })),
  on(getUsersSuccess, (state, { users }) => ({
    ...state,
    users,
    usersError: null,
    usersLoading: false,
  })),
  on(reactivateUser, (state) => ({
    ...state,
    reactivateUserLoading: true,
  })),
  on(reactivateUserFailure, (state, { error, message }) => ({
    ...state,
    reactivateUserError: { error, message },
    reactivateUserLoading: false,
  })),
  on(reactivateUserSuccess, (state) => ({
    ...state,
    reactivateUserError: null,
    reactivateUserLoading: false,
  })),
  on(removeUser, (state) => ({
    ...state,
    removeUserLoading: true,
  })),
  on(removeUserFailure, (state, { error, message }) => ({
    ...state,
    removeUserError: { error, message },
    removeUserLoading: false,
  })),
  on(removeUserSuccess, (state) => ({
    ...state,
    removeUserError: null,
    removeUserLoading: false,
  })),
  on(resetUserMFA, (state) => ({
    ...state,
    resetUserMFALoading: true,
  })),
  on(resetUserMFAFailure, (state, { error, message }) => ({
    ...state,
    resetUserMFAError: { error, message },
    resetUserMFALoading: false,
  })),
  on(resetUserMFASuccess, (state) => ({
    ...state,
    resetUserMFAError: null,
    resetUserMFALoading: false,
  })),
);

export const reducer = (state: State | undefined, action: Action) =>
  UserReducer(state, action);
