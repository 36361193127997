import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  NewProgramModelPayload,
  ProgramCurriculum,
  ProgramModel,
  ProgramSubmissionAttendanceReachDosage,
  ProgramSubmissionAttendanceReachDosagePayload,
  ProgramSubmissionCohort,
  ProgramSubmissionStructureCostSupport,
  ProgramSubmissionStructureCostSupportPayload,
  ProgramSubmissionSurveyAdministration,
  ProgramSubmissionSurveyAdministrationPayload,
} from '@core/models';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';

@Injectable({ providedIn: 'root' })
export class ProgramModelService extends BaseService {
  private programs = 'programs';

  constructor(private http: HttpClient) {
    super();
  }

  public addProgramModel(payload: NewProgramModelPayload): Observable<any> {
    return this.http.post<NewProgramModelPayload>(
      `${this.baseURL}/${this.programs}`,
      { ...payload }
    );
  }

  public getCurriculumOptions(
    programId: string
  ): Observable<ProgramCurriculum[]> {
    return this.http.get<ProgramCurriculum[]>(
      `${this.baseURL}/${this.programs}/${programId}/curriculum-options`
    );
  }

  public getProgramModel(programId: string): Observable<ProgramModel> {
    return this.http.get<ProgramModel>(
      `${this.baseURL}/${this.programs}/${programId}`
    );
  }

  public getProgramModels(providerId: string): Observable<ProgramModel[]> {
    return this.http.get<ProgramModel[]>(`${this.baseURL}/${this.programs}`, {
      params: { providerId },
    });
  }

  public setProgramModelActiveStatus(
    programModelId: string,
    active: boolean
  ): Observable<any> {
    return this.http.put(
      `${this.baseURL}/${this.programs}/${programModelId}/active`,
      { active }
    );
  }

  public addProgramModelCohort(
    programId: string,
    hoursDelivered: number
  ): Observable<any> {
    return this.http.put(
      `${this.baseURL}/${this.programs}/${programId}/submission/cohorts`,
      { hoursDelivered }
    );
  }

  public getProgramSubmissionCohorts(programId: string): Observable<any> {
    return this.http.get<ProgramSubmissionCohort[]>(
      `${this.baseURL}/${this.programs}/${programId}/submission/cohorts`
    );
  }

  public getProgramSubmissionArd(
    programId: string
  ): Observable<ProgramSubmissionAttendanceReachDosage> {
    return this.http.get<ProgramSubmissionAttendanceReachDosage>(
      `${this.baseURL}/${this.programs}/${programId}/submission/attendance`
    );
  }

  public getProgramSubmissionSa(
    programId: string
  ): Observable<ProgramSubmissionSurveyAdministration> {
    return this.http.get<ProgramSubmissionSurveyAdministration>(
      `${this.baseURL}/${this.programs}/${programId}/submission/survey`
    );
  }

  public getProgramSubmissionScs(
    programId: string
  ): Observable<ProgramSubmissionStructureCostSupport> {
    return this.http.get<ProgramSubmissionStructureCostSupport>(
      `${this.baseURL}/${this.programs}/${programId}/submission/structure`
    );
  }

  public removeProgramModelCohort(
    programId: string,
    cohortId: string
  ): Observable<any> {
    return this.http.delete(
      `${this.baseURL}/${this.programs}/${programId}/submission/cohorts/${cohortId}`
    );
  }

  public updateProgramSubmissionArd(
    programModelId: string,
    payload: ProgramSubmissionAttendanceReachDosagePayload
  ): Observable<ProgramSubmissionAttendanceReachDosage> {
    return this.http.put<ProgramSubmissionAttendanceReachDosage>(
      `${this.baseURL}/${this.programs}/${programModelId}/submission/attendance`,
      { ...payload }
    );
  }

  public updateProgramSubmissionCohort(
    programId: string,
    hoursDelivered: number,
    cohortId: string
  ): Observable<any> {
    return this.http.put(
      `${this.baseURL}/${this.programs}/${programId}/submission/cohorts/${cohortId}`,
      { hoursDelivered }
    );
  }

  public updateProgramSubmissionSa(
    programModelId: string,
    payload: ProgramSubmissionSurveyAdministrationPayload
  ): Observable<ProgramSubmissionSurveyAdministration> {
    return this.http.put<ProgramSubmissionSurveyAdministration>(
      `${this.baseURL}/${this.programs}/${programModelId}/submission/survey`,
      { ...payload }
    );
  }

  public updateProgramSubmissionScs(
    programModelId: string,
    payload: ProgramSubmissionStructureCostSupportPayload
  ): Observable<ProgramSubmissionStructureCostSupport> {
    return this.http.put<ProgramSubmissionStructureCostSupport>(
      `${this.baseURL}/${this.programs}/${programModelId}/submission/structure`,
      { ...payload }
    );
  }
}
